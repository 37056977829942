app.controller("newsViewCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification', "$uibModal",'$translate',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, Notification,$uibModal, $translate) {
        $scope.loading = true;
        $scope.id = $routeParams.newsId;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
       
        var path = "notsecure/news/get.do?id="+$scope.id; //kullanıcı giris yapmamissa
        if($rootScope.currentUser)
          var path = "private/news/get.do?id="+$scope.id;

        $http.get(path)
            .then(function (response) {
                if(response.data.success){
                    $scope.news = response.data.data;
                    $scope.loading = false;
                    $scope.isAdmin = $rootScope.currentUser ? $rootScope.currentUser.authorization.hasAdminAccess : false;
                }
                else{
                    Notification.error(response.data.message);
                    $location.path('/news-list');
                }
                    

            }, function (response) {
                Notification.error('hata!');
            });


        $scope.changeStateModal = function (t,type) {
                $rootScope.modalInstance = $uibModal.open({
                    animation: true,backdrop: 'static',
                    controller: function ($scope, $uibModalInstance) {
                        $scope.news = t.news;
                       if($scope.news.status == 1)
                         $scope.modalNewsDescription = "label.news.change.state.close";
                       else 
                         $scope.modalNewsDescription = "label.news.change.state.open";

                         $scope.closeCampaignModalOk = function () {
                         //save          
                           
                            console.log(JSON.stringify($scope.news))
                            if($scope.news.status != 1)
                                $scope.news.status = 1;
                            else
                              $scope.news.status = 0;
                            delete $scope.news.createdAt;
                            delete $scope.news.updatedAt;
                            $http({
                            method: 'POST',
                            url: 'private/news/changeState.do',
                            data: 'news=' + encodeURIComponent(JSON.stringify($scope.news)) ,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                            }
                            })
                            .then(function (res) {
                                $scope.gruplist = [];
                                if (res.data.success == true) {
                                Notification.success($translate.instant( "label.save.return.success"));
                                $scope.news.updatedAt = new Date();
                                } else {
                                Notification.error($translate.instant("error.generic"));
                                }
                            });
                            
                            
                         //save
                          $uibModalInstance.close();
                        }
                        $scope.closeCampaignModalClose = function () {
                          $uibModalInstance.close();
                        }
                    },
                    ariaLabelledBy: "modal-title",
                    ariaDescribedBy: "modal-body",
                    templateUrl: "closeCampaignModal.html"
                });
            };



    }
]);