app.directive("applyDatePlan", [
  "$http",
  "$translate",
  "Notification",
  "$uibModal",
  "$rootScope",
  "$filter",
  "dateFormat",
  function ($http, $translate, Notification, $uibModal, $rootScope, $filter, dateFormat) {
    return {
      restrict: "E",
      scope: {
        impId: "=",
        reloadImp: "&",
        commands: "=",
        commandType: "=",
        label: "=",
        showToggle: "=",

      },

      templateUrl: "partial/directive-imp/apply-date-plan.html",
      controller: function ($scope) {
        $scope.applyCalender = {
          id: 0,
          dateEvalStart: "",
          dateAnnouncement: "",
          improvementId: $scope.impId,
          type: 1,
          editCommand: '',
          viewCommand: '',

        };


        var NextDate = function (date) {
          if (date instanceof Date) {
            var today = angular.copy(date);
            today.setDate(today.getDate() + 1);
            return today;
          }
        }
        var BeforeDate = function (date) {
          if (date instanceof Date) {
            var today = angular.copy(date);
            today.setDate(today.getDate() - 1);
            return today;
          }
        }
        $scope.dpEvalStart = {
          opened: false
        };
        $scope.dpAnnouncement = {
          opened: false
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[3];
        $scope.altInputFormats = ['d!/M!/yyyy'];

        $scope.dateFormats = {
         format : 'd!/M!/yyyy',
         altInputFormats : ['d!/M!/yyyy']
        };

        $scope.dateOptions = {
          formatYear: "yy",
          maxDate: new Date(2023, 4, 22),
          max1Date: new Date(2023, 4, 22),
          minDate: NextDate(new Date()),
          startingDay: 1
        };

        $scope.openEvalStart = function () {
          $scope.dateOptions.minDate = $scope.applyCalender.dateAnnouncement == undefined ? $scope.dateOptions.minDate : NextDate($scope.applyCalender.dateAnnouncement);
          $scope.dateOptions.maxDate = $scope.dateOptions.maxDate1;
          $scope.dpEvalStart.opened = true;
          $scope.refreshImp();
          
        };

        $scope.openAnnouncement = function () {
          $scope.dateOptions.minDate = NextDate(new Date());
          $scope.dateOptions.maxDate = BeforeDate($scope.applyCalender.dateEvalStart);
          $scope.dpAnnouncement.opened = true;
        };


        $scope.isLoading = false;

        $scope.refreshImp = function () {
          $scope.reloadImp();
        };

        $scope.$watch('commands', function (newVal) {
          if (newVal) {
            $scope.availableCommands = $scope.commands;
            //$scope.show=false;
            $scope.show = false;
            for (var i = 0; i < $scope.availableCommands.length; i++) {
              if ($scope.availableCommands[i] === $scope.commandType) {
                $scope.show = true;
              }
            }
          }
        });
        $scope.getApplyCalenderPlan = function () {
          var path = "private/improvement-apply/getByImprovement.do?impId=" + $scope.impId + "&type=1";
          $http.get(path).then(
            function (response) {
              if(response.data.total>0 ){
                $scope.applyCalender = response.data.data[0];
                $scope.applyCalender.dateAnnouncement = new Date($scope.applyCalender.startDate);
                $scope.applyCalender.dateEvalStart = new Date($scope.applyCalender.endDate);
                delete $scope.applyCalender.endDate;
                delete $scope.applyCalender.startDate;
              }
         

              $scope.isLoading = true;
            },
            function (response) {
              //Second function handles error
              //  Notification.error("ERROR improvement fields");
            }
          );
        };

        $scope.saveImpField = function () {

       //   $scope.applyCalender.endDate = angular.copy(new Date($scope.applyCalender.dateEvalStart));
       //  $scope.applyCalender.startDate = angular.copy(new Date($scope.applyCalender.dateAnnouncement));
          $scope.applyCalender.endDateStr = angular.copy(dateFormat.formatter($scope.applyCalender.dateEvalStart));
          $scope.applyCalender.startDateStr = angular.copy(dateFormat.formatter($scope.applyCalender.dateAnnouncement));
          delete $scope.applyCalender.endDate;
          delete $scope.applyCalender.startDate;

          $http({
            method: "POST",
            url: "private/improvement-apply/dateSave.do",
            data:
              "applyCalender=" + encodeURIComponent(JSON.stringify(new Array($scope.applyCalender))) + "&commandType=" + $scope.commandType,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
          }).then(function (response) {
            if (response.data.success == true) {
    
              Notification.success($translate.instant("label.save.return.success"));

              if (response.data.data.id)
                  $scope.applyCalender = response.data.data;

              $scope.applyCalender.dateAnnouncement = new Date($scope.applyCalender.startDate);
              $scope.applyCalender.dateEvalStart = new Date($scope.applyCalender.endDate);
              delete $scope.applyCalender.endDate;
              delete $scope.applyCalender.startDate;

              $scope.refreshImp();
            } else {
              $translate("error.generic").then(function (msg) {
                Notification.error(msg);
              });
            }
          });
        };
        $scope.getApplyCalenderPlan();

      }

    };
  }
]);


