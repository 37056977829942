app.controller("reportUserCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate', 'Notification', '$uibModal', 'dateFormat',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, Notification, $uibModal, dateFormat) {


        /*Init */
        $scope.isDetail = false;
        $scope.loading = false;
        $scope.pageSize = 50;
        $scope.pagingMaxSize= 4;
        $scope.currentPage = 1;
        $scope.clearFilter = function () {
            var date = new Date();
            var year = date.getFullYear();
            year = year - 1;
            date.setFullYear(year);
            $scope.basTarih = date;
            $scope.bitTarih = new Date();
            $scope.query = '';
            $scope.orderBy = "";
            $scope.sort = "";
        };
        $scope.clearFilter();
        $scope.init = function () {
            $scope.loading = true;
            $scope.getUserReport();
        };
        $scope.orderBy = "";
        $scope.sort = "";
        $scope.suggestionForCont = [];
        /*Default Variables*/

        $scope.theader = {
            total: $translate.instant('label.total') + " " + $translate.instant('label.suggestion')
        };
        $scope.dates = {};
        /*Functions*/
        $scope.getUserReport = function () {
            var basTarih = dateFormat.formatter($scope.basTarih);
            var bitTarih = dateFormat.formatter($scope.bitTarih);
            $scope.dates.basTarih = $scope.basTarih;
            $scope.dates.bitTarih = $scope.bitTarih;
            if (typeof $scope.query == 'undefined') $scope.query = '';
            var page = $location.search().page || $scope.currentPage;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
           $scope.myPromise = $http.get("private/user/retrievePointsAdmin.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize + "&problemState="+ $scope.orderBy +"&sort="+$scope.sort +"&basTar=" + basTarih + "&bitTar=" + bitTarih+ "&query=" + $scope.query)
                .then(function (response) {
                    $scope.usersData = response.data.data;
                    $scope.usersTotal = response.data.total;
                    $scope.currentPage = page;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.displayRangeEnd = ($scope.currentIndex + $scope.pageSize) > $scope.usersTotal ? $scope.usersTotal : ($scope.currentIndex + $scope.pageSize);
                    $scope.loading = false;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.pageChanged = function () {
            $scope.currentIndex = ($scope.currentPage - 1) * $scope.pageSize;
        //    $location.path('/report-user').search('page', $scope.currentPage).search('problemState', $scope.orderBy).search('sort', $scope.sort);
            $scope.getUserReport();

        };

        $scope.sortingDecide = function(orderBy){
            if(   $scope.orderBy != orderBy){
                $scope.orderBy = orderBy;
                $scope.sort  ="";
            }
            if($scope.sort == "" || $scope.sort =='asc')
                 $scope.sort = 'desc'
            else if($scope.sort =='desc')
                 $scope.sort = 'asc'
            $scope.getUserReport();
        }

        $scope.pageRedirectContributions = function (data) {
            $location.path('/contributions/'+data.userid + '/' + data.name);
        };
        $scope.pageRedirectScore = function (data) {
            $location.path('/score/'+data.userid);
        };

        $scope.openDetail = function (size, data, dates) {
            var modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'userDetailModal.html',
                controller: 'reportUserDetailCtrl',
                size: size,
                resolve: {
                    items: function () {
                        return data;
                    },
                    dates: function () {
                        return dates;
                    }
                }
            });

            modalInstance.result.then(function () {
                //$scope.update();
            }, function () {
                console.info('Modal dismissed at: ' + new Date());
            });
        };

        $scope.openDetailContributions = function (size, data, dates) {
            var modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'userDetailContributionsModal.html',
                controller: 'reportContributionsDetailCtrl',
                size: size,
                resolve: {
                    items: function () {
                        return data;
                    },
                    dates: function () {
                        return dates;
                    }
                }
            });

            modalInstance.result.then(function () {
                //$scope.update();
            }, function () {
                console.info('Modal dismissed at: ' + new Date());
            });
        };

        /* DatePicker Setup*/

        $scope.clear = function () {
            $scope.dt = null;
        };

        $scope.inlineOptions = {
            customClass: getDayClass,
            minDate: new Date(),
            showWeeks: true
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2035, 5, 22),
            startingDay: 1
        };
        

        $scope.toggleMin = function () {
            $scope.inlineOptions.minDate = $scope.inlineOptions.minDate ? null : new Date();
            $scope.dateOptions.minDate = $scope.inlineOptions.minDate;
        };

        $scope.toggleMin();

        $scope.open1 = function () {
            $scope.popup1.opened = true;
        };

        $scope.open2 = function () {
            $scope.popup2.opened = true;
        };

        $scope.setDate = function (year, month, day) {
            $scope.dt = new Date(year, month, day);
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[3];
        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.popup1 = {
            opened: false
        };

        $scope.popup2 = {
            opened: false
        };

        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var afterTomorrow = new Date();
        afterTomorrow.setDate(tomorrow.getDate() + 1);
        $scope.events = [
            {
                date: tomorrow,
                status: 'full'
            },
            {
                date: afterTomorrow,
                status: 'partially'
            }
        ];

        $scope.checkClass = function (u) {
            if (u.status == 0) return 'warning';
            if (u.status == 1) return 'default';
        };

        function getDayClass(data) {
            var date = data.date,
                mode = data.mode;
            if (mode === 'day') {
                var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

                for (var i = 0; i < $scope.events.length; i++) {
                    var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

                    if (dayToCheck === currentDay) {
                        return $scope.events[i].status;
                    }
                }
            }
        }

        /* Excel */

        $scope.excelExport = function () {

            var basTarih = new Date($scope.basTarih);
            basTarih = basTarih.toISOString();
            var bitTarih = new Date($scope.bitTarih);
            bitTarih = bitTarih.toISOString();
            var sortParam = [{ property: "totalPoints", direction: "desc", root: "data", transform: null, sort: null },
            { property: "name", direction: "asc", root: "data", transform: null, sort: null }];
            if (typeof $scope.query == 'undefined') $scope.query = '';
            $scope.excelPromise = $http.get("private/user/exportExcelUserReport.htm?basTar=" + basTarih + "&bitTar=" + bitTarih + '&sort=' + encodeURIComponent(JSON.stringify(sortParam))+"&query=" + $scope.query,{responseType: 'arraybuffer'})
                .then(function (response) {

                     var header = response.headers('Content-disposition')
                     var fileName = header.split("=")[1].replace(/\"/gi,'');
                    
                    var blob = new Blob([response.data],
                        {type : 'application/vnd.ms-excel;charset=utf-8'});
                    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                    var link = angular.element('<a/>');
                    link.attr({
                        href : objectUrl,
                        download : fileName
                    })[0].click();

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        }

        app.filter('suggStatusLabel', function () {
            return function (input) {
                switch (input) {
                    case 0:
                        return 'sugg.status.draft';
                    case 2:
                        return 'sugg.status.submitted';
                    case 4:
                        return 'sugg.status.prereject';
                    case 5:
                        return 'sugg.status.preaccept';
                    case 8:
                        return 'sugg.status.accept';
                    case 9:
                        return 'sugg.status.lastreject';
                    case 10:
                        return 'sugg.status.completed';
                    case 11:
                        return 'sugg.status.firstreject';
                    case 12:
                        return 'sugg.status.editor';
                    default:
                        return 'sugg.status.none';
                }
            };
        });


    }]);
app.controller('reportUserDetailCtrl', function ($uibModalInstance, items, dates, $http, $scope, Notification) {
    var $ctrl = this;
    $scope.user = items;
    $scope.dateValue = dates;
    $scope.showDetail = function (items) {

        if (items.userid) {
            $http({
                method: 'POST',
                url: 'private/user/retrievePointsByTypeAdmin.do',
                data: 'isByUser=' + encodeURIComponent(JSON.stringify(items.userid)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.userDetailData = res.data;
                        getRewards(items);
                    }
                });
        }


    };
    var getRewards = function (a) {
        $http({
            method: 'POST',
            url: 'private/user/retrieveUserRewardsAdmin.do',
            data: 'isByUser=' + encodeURIComponent(JSON.stringify(a.userid)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.userRewardsData = res.data;
                }
            });
    };
    $scope.ok = function () {
        update();
        $uibModalInstance.close($scope.selectedUsers);
    };
    $scope.cancel = function () {
        $uibModalInstance.close();
    };
    $scope.loadConfigPoints = function()
    {
    	$http.get("notsecure/user/retrievePoints.do")
        .then(function(response) {
            $scope.pointsCfg = response.data.data;
            
        }, function(response) {
            //Second function handles error
            Notification.error('hata!');
        });
    };
    $scope.loadConfigPoints();
    $scope.showDetail(items);

});

app.controller('reportContributionsDetailCtrl', function ($uibModalInstance, items, dates, $http, $scope, Notification) {
    var $ctrl = this;
    $scope.user = items;
    $scope.dateValue = dates;
    $scope.pageSizeCont = 10;
    $scope.limitCont = 10;

  
    $scope.pageSizeCont = 10;
    $scope.limitCont = 10;

    $scope.startContSugg = 0;
    $scope.currentPageContSugg = 1;

    $scope.startContSuggComment = 0;
    $scope.currentPageContSuggComment = 1;

    $scope.startContSuggRate = 0;
    $scope.currentPageContSuggRate = 1;

    $scope.startContIdea = 0;
    $scope.currentPageContIdea = 1;

    $scope.startContIdeaComment = 0;
    $scope.currentPageContIdeaComment = 1;

    $scope.startContIdeaRate = 0;
    $scope.currentPageContIdeaRate = 1;
 


   
    $scope.pageChangedContSugg = function () {
        //  $scope.currentPageCont =  $scope.currentPageCont + 1
          $scope.startContSugg = ($scope.currentPageContSugg - 1) * $scope.pageSizeCont;
          $scope.contributionSuggestion(items);
      };
      $scope.pageChangedContSuggComment = function () {
          //  $scope.currentPageCont =  $scope.currentPageCont + 1
            $scope.startContSuggComment = ($scope.currentPageContSuggComment - 1) * $scope.pageSizeCont;
            $scope.contributionSuggestionComment(items);
        };
        $scope.pageChangedContSuggRate = function () {
          //  $scope.currentPageCont =  $scope.currentPageCont + 1
            $scope.startContSuggRate = ($scope.currentPageContSuggRate - 1) * $scope.pageSizeCont;
            $scope.contributionSuggestionRate(items);
        };
  
      $scope.pageChangedContIdea = function () {
          //  $scope.currentPageCont =  $scope.currentPageCont + 1
            $scope.startContIdea = ($scope.currentPageContIdea - 1) * $scope.pageSizeCont;
            $scope.contributionIdea(items);
        };
  
        $scope.pageChangedContIdeaComment = function () {
          //  $scope.currentPageCont =  $scope.currentPageCont + 1
            $scope.startContIdeaComment = ($scope.currentPageContIdeaComment - 1) * $scope.pageSizeCont;
            $scope.contributionIdeaComment(items);
        };
        
        $scope.pageChangedContIdeaRate = function () {
          //  $scope.currentPageCont =  $scope.currentPageCont + 1
            $scope.startContIdeaRate = ($scope.currentPageContIdeaRate - 1) * $scope.pageSizeCont;
            $scope.contributionIdeaRate(items);
        };
        


    $scope.contributionSuggestion = function (items) {

        if (items.userid) {
            $http({
                method: 'POST',
                url: 'private/suggestion/contributionsRetrieve.do',
                data: 'start=' + $scope.startContSugg
                + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
                + '&byUserId=' + encodeURIComponent(JSON.stringify(items.userid)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.suggestionForCont = res.data;
                        $scope.totalItemCountSugg = res.data.total;
                      //  getRewards(items);
                    }
                });
        }
    };
    $scope.contributionSuggestionComment = function () {
        if (items.userid) {
        $http({
            method: 'POST',
            url: 'private/suggestion/contributionsCommentRetrieve.do',
            data: 'start=' + $scope.startContSuggComment
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&byUserId=' + encodeURIComponent(JSON.stringify(items.userid)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.suggestionCommentForCont = res.data;
                    $scope.totalItemCountSuggComment = res.data.total;
                  //  getRewards(items);
                }
            });
        }
    };
    $scope.contributionSuggestionRate = function () {

        if (items.userid) {
        $http({
            method: 'POST',
            url: 'private/suggestion/contributionsRateRetrieve.do',
            data: 'start=' + $scope.startContSuggRate
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&byUserId=' + encodeURIComponent(JSON.stringify(items.userid)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.suggestionRateForCont = res.data;
                    $scope.totalItemCountSuggRate = res.data.total;
                  //  getRewards(items);
                }
            });
        }
    };

    $scope.contributionIdea = function (items) {

        if (items.userid) {
            $http({
                method: 'POST',
                url: 'private/idea/contributionsRetrieve.do',
                data: 'start=' + $scope.startContIdea
                + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
                + '&byUserId=' + encodeURIComponent(JSON.stringify(items.userid)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.IdeaForCont = res.data;
                        $scope.totalItemCountIdea = res.data.total;
                      //  getRewards(items);
                    }
                });
        }


    };
    
    $scope.contributionIdeaComment = function () {      
        if (items.userid) {
        $http({
            method: 'POST',
            url: 'private/idea/contributionsCommentRetrieve.do',
            data: 'start=' + $scope.startContIdeaComment
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&byUserId=' + encodeURIComponent(JSON.stringify(items.userid)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.IdeaCommentForCont = res.data;
                    $scope.totalItemCountIdeaComment = res.data.total;
                  //  getRewards(items);
                }
            });
        }
    };
    $scope.contributionIdeaRate = function () {    
        if (items.userid) {  
        $http({
            method: 'POST',
            url: 'private/idea/contributionsRateRetrieve.do',
            data: 'start=' + $scope.startContIdeaRate
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&byUserId=' + encodeURIComponent(JSON.stringify(items.userid)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.IdeaRateForCont = res.data;
                    $scope.totalItemCountIdeaRate= res.data.total;
                  //  getRewards(items);
                }
            });
        }
    };
    app.filter('suggStatusLabel', function () {
        return function (input) {
            switch (input) {
                case 0:
                    return 'sugg.status.draft';
                case 2:
                    return 'sugg.status.submitted';
                case 4:
                    return 'sugg.status.prereject';
                case 5:
                    return 'sugg.status.preaccept';
                case 8:
                    return 'sugg.status.accept';
                case 9:
                    return 'sugg.status.lastreject';
                case 10:
                    return 'sugg.status.completed';
                case 11:
                    return 'sugg.status.firstreject';
                case 12:
                    return 'sugg.status.editor';
                default:
                    return 'sugg.status.none';
            }
        };
    });
   
    $scope.ok = function () {
        update();
        $uibModalInstance.close($scope.selectedUsers);
    };
    $scope.cancel = function () {
        $uibModalInstance.close();
    };
    $scope.contributionIdea(items);
    $scope.contributionSuggestion(items);
    $scope.contributionSuggestionComment(items);
    $scope.contributionSuggestionRate(items);
    $scope.contributionIdeaComment(items);
    $scope.contributionIdeaRate(items);
   
});