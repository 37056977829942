app.controller("projectViewCtrl", ["$scope", "$sce", "$http", "$location", "$rootScope", "$routeParams", "Notification", "dateFormat", "$translate", "$uibModal", function ($scope,
    $sce, $http, $location, $rootScope, $routeParams, Notification, dateFormat, $translate, $uibModal) {

    $scope.projectId = $routeParams.projectId;
    $scope.project = {};


    /* Functions */

    $scope.getProject = function () {

        $http.get("project/retrieveWithDependencies.do?projectId=" + $scope.projectId).then(
            function (response) {
                var list = response.data.data[0];
                $scope.project = list;
                $scope.getProjectSuggestions();
            });

    }
    $scope.getProjectSuggestions = function () {

        $http.get("project/retrieveProjectSuggestions.do?projectId=" + $scope.projectId).then(
            function (response) {
                var list = response.data.data;
                $scope.projectSuggestion = list;
                $scope.getProjectIdeas();
            });

    }
    $scope.getProjectIdeas = function () {

        $http.get("project/retrieveProjectIdeas.do?projectId=" + $scope.projectId).then(
            function (response) {
                var list = response.data.data;
                $scope.projectIdea = list;
                $scope.getProjectImprovements();
            });

    }
    $scope.getProjectImprovements= function () {

        $http.get("project/retrieveProjectImprovements.do?projectId=" + $scope.projectId).then(
            function (response) {
                var list = response.data.data;
                $scope.projectImprovement = list;
                $scope.getProjectOwners();
            });

    }
    $scope.getProjectOwners = function () {

        $http.get("project/retrieveProjectOwners.do?projectId=" + $scope.projectId).then(
            function (response) {
                var list = response.data.data[0];
                $scope.projectOwner = list;

            });

    }
    

    var regex = /\/project-view\/\d[0-9]*/g;
    //const regexOpen = /\/competition-open\/\d[0-9]*/g;
    if (!regex.test($location.path())) {
        // load open competition
        $scope.getProject();
    } else {
        $scope.getProject();
    }

}]);  