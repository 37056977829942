app.directive("commentEditor", [
    "$http",
    "$translate",
    "Notification",
    "$uibModal",
    "$rootScope",
    "$filter",
    function ($http, $translate, Notification, $uibModal, $rootScope, $filter) {
        return {
            restrict: "E",
            scope: {
                label : "=",
                objectId: "=",
                objectType: "=",
                commands: "=",
                commandType: "=",
                submitType:"=",
                showToggle: "=",
                commentwrite: "=",
                info: "="
            },
            templateUrl: "partial/directive-imp/comment-editor.html",
            controller: function ($scope) {
                $scope.show = false;
                $scope.showlist, $scope.showtext = false;
                $scope.editorComment = {};
                $scope.$watch('objectId', function (newVal) {
                    if (newVal) {
                        $scope.availableCommands = $scope.commands;
                        if (newVal && $scope.availableCommands != null && $scope.availableCommands.length > 0) {
                            if ($scope.availableCommands.includes($scope.commandType)) {
                                $scope.show = true;
                                $scope.showlist = true;
                            }
                            if ($scope.availableCommands.includes($scope.submitType)){
                                if($scope.submitType === 'submit-comment-user'){

                                    if( $scope.commentwrite){  
                                        $scope.showtext = true;
                                    }
                                    else
                                    $scope.showtext = false;
                                }
                                else
                                   $scope.showtext = true;
                            }
                        }

                    }
                });

                /* Functions */
                $scope.editorComment.comment = "";
                $scope.getEditorComments = function () {
                    generateReturnCommand("v");
                    $scope.promise = $http.get("private/improvement-comment/list.do?impId=" + $scope.objectId + "&command=" + $scope.returnCommand
                    ).then(
                        function (response) {
                            $scope.editorComments = response.data.data;
                        },
                        function (response) {
                            //Second function handles error
                            Notification.error("ERROR editor comments");
                        }
                    );
                };
                $scope.submitEditorComment = function () {
                    generateReturnCommand("s");
                    $scope.promise = $http({
                        method: 'POST',
                        url: 'private/improvement-comments/save.do',
                        //data: "txt=" + $scope.editorComment.comment + "&id=" + $scope.objectId +"&command=" + $scope.returnCommand,
                        data: "txt=" + encodeURIComponent($scope.editorComment.comment) + "&id=" + encodeURIComponent($scope.objectId) +"&command=" + encodeURIComponent($scope.returnCommand),
                        headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" }
                    }).then(function (res) {
                        if (res.data.success == true) {
                            $scope.editorComment.comment = "";
                            $scope.getEditorComments();
                            //encodeURIComponent($scope.getEditorComments());
                            $translate("info.comment.submit").then(function (msg) {
                                Notification.success(msg);
                            });
                        } else {
                            $translate("error.comment.submit").then(function (msg) {
                                Notification.error(msg);
                            });
                        }
                    });
                }
                $scope.returnCommand = "";
                var generateReturnCommand = function (c) {
                    
                    switch ($scope.commandType) {
                        case 'view-comment-owner': $scope.returnCommand = c == "s" ? "submit-comment-owner" : "view-comment-owner";

                            break;
                        case 'view-comment-mentor': $scope.returnCommand = c == "s" ? "submit-comment-mentor" : "view-comment-mentor";

                            break;
                        case 'view-comment-expert': $scope.returnCommand = c == "s" ? "submit-comment-expert" : "view-comment-expert";

                            break;
                        case 'view-comment-user': $scope.returnCommand = c == "s" ? "submit-comment-user" : "view-comment-user";

                            break;
                        case 'view-comment-responsible': $scope.returnCommand = c == "s" ? "submit-comment-responsible" : "view-comment-responsible";

                            break;
                        
                        default:$scope.returnCommand = c == "s" ? "submit-comment-user" : "view-comment-user";
                            break;
                    }
                }
                $scope.getEditorComments();
            }
        };
    }
]);


