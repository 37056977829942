app.controller("competitionsCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, Notification, $translate) {
        $scope.authenticated = $rootScope.authenticated;
        
        $scope.loading = true;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        var path = "notsecure/market/retrieveOpenProblemModel.do?start=0&limit=25";
        if ($scope.authenticated)
            path = "private/market/retrieveProblemModel.do?marketState=O&problemState=&page=1&start=0&limit=25";
        $http.get(path)
            .then(function (response) {
                $scope.competitions = response.data.data;
                /*var deadline = null;
                for(var i=0;i<$scope.competitions.length;i++){
                    if($scope.competitions[i].evaluateDate != undefined){
                        deadline = new Date($scope.competitions[i].evaluateDate);
                        deadline.setDate(deadline.getDate()-1); //dd.MM.YYYY 23:59
                        $scope.competitions[i].evaluateDate = deadline;
                    }
                }*/

                $scope.loading = false;
                $scope.isAdmin = $rootScope.currentUser ? $rootScope.currentUser.authorization.hasAdminAccess : false;
            }, function (response) {
                //Second function handles error
                Notification.error('hata!');
            });
        $scope.submitIdeaRedirect = function (index, id) {
            if ($scope.competitions[index].publicMode == 2) {
                $location.path('/submit-idea/' + id);
            } else {
                $scope.sureSubmitIdea($scope,id);
                
            }
        }

        $scope.sureSubmitIdea = function (t,id) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.Ok = function () {
                        $rootScope.loginMsg = "error.login.submit.idea";
                        $rootScope.loginRedirect = "/submit-idea/" + id;
                        $location.path('/login');
                        $uibModalInstance.close();
                    }
                    $scope.OkReg = function () {
                        $location.path('/register');
                        $uibModalInstance.close();
                    }
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    }
                  
                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "sureSubmitIdea.html"
            });
        };

        $scope.excelExport = function () {

            $http.get("private/market/excelExportCompetitions.do?marketState=O&problemState=&page=1&start=0&limit=25", { responseType: 'arraybuffer' })
                .then(function (response) {

                    var header = response.headers('Content-disposition')
                    var fileName = header.split("=")[1].replace(/\"/gi, '');
                  
                    var blob = new Blob([response.data],
                        { type: 'application/vnd.ms-excel;charset=utf-8' });
                    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                    var link = angular.element('<a/>');
                    link.attr({
                        href: objectUrl,
                        download: fileName
                    })[0].click();

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        }
    }
]);

app.filter('to_trusted', ['$sce', function ($sce) {
    return function (text) {
        return $sce.trustAsHtml(text);
    };
}]);