app.controller("reportSuggestionCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate', 'Notification', 'dateFormat',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, Notification, dateFormat) {


        /*Init */
        $scope.lang = $rootScope.lang;
        $scope.$watch('$rootScope', function (newValue, oldValue) {
            $scope.lang = $rootScope.lang;
        });
        $scope.init = function () {
            $scope.getSuggestionReport();
        };
        /*Default Variables*/
        $scope.clearFilter = function () {
            var date = new Date();
            var year = date.getFullYear();
            year = year - 1;
            date.setFullYear(year);
            $scope.basTarih = date;
            $scope.bitTarih = new Date();
        };
        $scope.clearFilter();
       
        /*Functions*/
        $scope.getSuggestionReport = function () {
            var tarih = {};
            tarih.basTarih = dateFormat.formatter($scope.basTarih);
            tarih.bitTarih = dateFormat.formatter($scope.bitTarih);

            $http({
                method: 'POST',
                url: 'private/suggestionReport.do',
                data: 'market=' + encodeURIComponent(JSON.stringify(tarih)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.suggObj = res.data;
                        $scope.suggData = res.data.data;
                        generateCharts();

                    }
                });
        };
        $scope.getLetterAt = function (location) {
            var alphabet = "ABCDEFGHIJKLMNOPRSTUVYZ".split("");
            if (location < alphabet.length) {
                return alphabet[location];
            } else {
                return 'X';
            }
        };
       
        var generateCharts = function () {
            $scope.labelAlan = [];
            $scope.dataAlan = [];
            $scope.optionsAlan = {};
            $scope.labelDurum = [];
            $scope.dataDurum = [];
            $scope.optionsDurum = {};

            $scope.labelUnitAlan = [];
            $scope.dataUnitAlan = [];
            //$scope.optionsUnitAlan = {};
            //$scope.labelUnitDurum = [];
            //$scope.dataUnitDurum = [];
            //$scope.optionsUnitDurum = {};

            if ($scope.suggData) {
                for (var index = 0; index < $scope.suggData.suggestionTypeList.length; index++) {
                    $scope.labelAlan.push($scope.getLetterAt(index) + '-' + ($scope.lang == "en" ? $scope.suggData.suggestionTypeList[index].name_en : $scope.suggData.suggestionTypeList[index].name));
                    $scope.dataAlan.push($scope.suggData.suggestionTypeList[index].total);
                }
               for(var i = 0; i < $scope.suggData.suggUnitReport.length; i++) {
                    $scope.labelUnitAlan.push($scope.suggData.suggUnitReport[i].departmentName);
                    $scope.dataUnitAlan.push($scope.suggData.suggUnitReport[i].total);

                }
                var chartOptions = {responsive: true,scales: {yAxes: [{display: true,ticks: {beginAtZero: true}}]}};
                $scope.optionsAlan = chartOptions;
                $scope.labelDurum = [$translate.instant('sugg.state.2'),$translate.instant('sugg.state.11'),$translate.instant('sugg.state.12'),$translate.instant('sugg.state.5'),$translate.instant('sugg.state.4'),$translate.instant('sugg.state.8'),$translate.instant('sugg.state.9'),$translate.instant('sugg.state.10')];
                //$scope.labelDurum = ["A-Gonderildi","A-Gonderildi","A-Gonderildi","A-Gonderildi","E-Kabul Edildi","A-Gonderildi","A-Gonderildi","A-Gonderildi"];
                $scope.dataDurum = [$scope.suggData.ann, $scope.suggData.firstRej, $scope.suggData.editorAssigned, $scope.suggData.preAcc, $scope.suggData.preRej,$scope.suggData.finAcc,$scope.suggData.finRej,  $scope.suggData.comp];
                $scope.optionsDurum = chartOptions;
            } else {
                $scope.labelAlan.push("Veri yok");
                $scope.dataAlan.push(0);
                 $scope.labelDurum.push("Veri yok");
                $scope.dataDurum.push(0);
            }

        };
        var formatDate = function (a) {

            if (typeof (a) != 'string') {
                var dd = a.getDate();
                var mm = a.getMonth() + 1; //January is 0!

                var yyyy = a.getFullYear();
                if (dd < 10) {
                    dd = '0' + dd;
                }
                if (mm < 10) {
                    mm = '0' + mm;
                }
                var date = mm + '/' + dd + '/' + yyyy;
                return date;
            }
            return a;
        };
        /* DatePicker Setup*/
        $scope.open1 = function () {
            $scope.popup1.opened = true;
        };
        $scope.popup1 = {
            opened: false
        };
        $scope.open2 = function () {
            $scope.popup2.opened = true;
        };
        $scope.popup2 = {
            opened: false
        };
        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[3];
        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2035, 5, 22),
             startingDay: 1
        };


    }]);