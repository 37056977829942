app.directive("viewHistory", [
    "$http",
    "$translate",
    "Notification",
    "$uibModal",
    "$rootScope",
    "$filter",
    function ($http, $translate, Notification, $uibModal, $rootScope,$filter) {
      return {
        restrict: "E",
        scope: {
          impId: "=",
          fieldId: "=",
          show: "=",
          commands: "=",
          commandType: "=",
          impOwners:"=",
          label: "=",
          showToggle: "="
        },
        templateUrl: "partial/directive-imp/view-history.html",
        controller: function ($scope) {
         
       
          $scope.isLoading = false;
          $scope.$watch('commands', function (newVal) {
            if (newVal) {
              $scope.show=false;
              $scope.availableCommands = $scope.commands;
              for(var i =0; i < $scope.availableCommands.length ; i++){
                if( $scope.availableCommands[i] === $scope.commandType ){
                  $scope.show=true;
                  $scope.selectedFieldId = $scope.fieldId;
                  $scope.getImpAudits();
                }
              }
               
            }
          });
          $scope.getImpAudits = function () {
            var path = "private/improvement-audit/list.do?impId=" + $scope.impId;
            $http.get(path).then(
              function (response) {
                $scope.improvementAudits = response.data.data;
                $scope.total = response.data.total;
                $scope.isLoading = true;
              },
              function (response) {
                //Second function handles error
                Notification.error("ERROR improvement Audits");
              }
            );
          };

          $scope.isShowAuditFullName = function(ia){
            for(var i = 0 ; i<$scope.impOwners.length ; i++){
              if($scope.impOwners[i].ownerId == ia.ownerId)
                  return $translate.instant("label.improvement.owner"); 
            }
            return ia.fullName;
          }

  

        }

      };
    }
  ]);
  
 
