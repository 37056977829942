app.controller("competitionEditCtrl", ["$scope", "$sce", "$http", "$location", "$rootScope", "$routeParams", "Notification", "dateFormat", "$translate", "$uibModal", "$cookies", function ($scope,
  $sce, $http, $location, $rootScope, $routeParams, Notification, dateFormat, $translate, $uibModal, $cookies) {

  $scope.init = function () {
    loadInnovationtypes();

  }
  $scope.lang = $rootScope.lang;
  $scope.$watch('$rootScope', function (newValue, oldValue) {
    $scope.lang = $rootScope.lang;
  });

  $scope.problemAcceptanceCriteria = [];
  $scope.competition = {};
  $scope.competition.innovationType = 2;
  $scope.competition.points = 20;
  $scope.reward = [];
  $scope.jurryList = [];
  $scope.selectedJurry = [];
  $scope.problemUser = [];
  $scope.newCriteria = {};
  $scope.newCriteria.priority = 5;
  $scope.newReward = {};
  $scope.fileList = [];
  $scope.uploadedFiles = [];
  $scope.fileProgress = 0;
  $scope.criteriaStatus = "S";
  $scope.rewardStatus = "S";
  $scope.competitionId = 0;
  $scope.priorities = [1, 2, 3, 4, 5];

  $scope.problemFieldList = [];
  $scope.newProblemField = {};
  $scope.newProblemFieldStatus = "S";

  $scope.newCategory = {};
  $scope.newCategory.parent = {
    id: -1,
    "name": "--" + $translate.instant("label.category.parent.select") + "--"
  };
  $scope.problemCategory = [];
  $scope.categoryStatus = "S";
  $scope.problemCategoryParents = [];
  $scope.problemCategoryParents.push($scope.newCategory.parent);
  $scope.uploadedIcons = [];
  $scope.iconList = [];
  $scope.iconProgress = 0;
  $scope.icon = {};
  $scope.icon.url = "notsecure/image.htm?id=problem-" + $scope.competitionId + "-problem.png";
  var NextDate = function (date) {
    if (date instanceof Date) {
      var today = angular.copy(date);
      today.setDate(today.getDate() + 1);
      return today;
    }
  }
  var BeforeDate = function (date) {
    if (date instanceof Date) {
      var today = angular.copy(date);
      today.setDate(today.getDate() - 1);
      return today;
    }
  }
  var loadInnovationtypes = function () {
    $http.get("private/problem/retrieveinnovationtypes.do?start=0&limit=0")
      .then(function (response) {
        $scope.innovationTypes = response.data.data;
      }, function (response) {
        //Second function handles error
        Notification.error('Error: competition');
      });
  }

  $scope.changeUserandEvalView = function (v) {
    switch (v) {
      case 1:
        $scope.competition.evalView = 0;
        $scope.competition.ideaUserView = 0;
        if ($scope.competition.evalComment == 1 || $scope.competition.evalComment == undefined) {
          $scope.competition.evalComment = 0;
        }
        if ($scope.competition.ideaUserComment == 1 || $scope.competition.ideaUserComment == undefined) {
          $scope.competition.ideaUserComment = 0;
        }
        $scope.competition.evalComment = 0;
        break;
      case 2:
        $scope.competition.evalView = 1;
        $scope.competition.ideaUserView = 0;
        if ($scope.competition.ideaUserComment == 1 || $scope.competition.ideaUserComment == undefined) {
          $scope.competition.ideaUserComment = 0;
        }
        break;
      case 3:
        $scope.competition.evalView = 1;
        $scope.competition.ideaUserView = 1;
        break;
    }
  }
  $scope.changeCommentView = function (v) {
    switch (v) {
      case 1:
        if ($scope.competition.ideaUserComment == 0 || $scope.competition.ideaUserComment == undefined) {
          $scope.competition.ideaUserComment = 1;
        } else if ($scope.competition.ideaUserComment == 1 || $scope.competition.ideaUserComment == undefined) {
          $scope.competition.ideaUserComment = 0;
        }
        break;
      case 2:
        if ($scope.competition.evalComment == 0 || $scope.competition.evalComment == undefined) {
          $scope.competition.evalComment = 1;
        } else if ($scope.competition.evalComment == 1 || $scope.competition.evalComment == undefined) {
          $scope.competition.evalComment = 0;
        }
        break;
    }
  }
  $scope.changePublicMode = function (v) {
    switch (v) {
      case 1:
        $scope.competition.publicMode = 0;
        break;
      case 2:
        $scope.competition.publicMode = 1;
        break;
      case 3:
        $scope.competition.publicMode = 2;
        break;
    }
  }
  $scope.addJury = function () {
    for (var i = 0; i < $scope.selectedJurry.length; i++) {
      var isExist = false;
      var puser_ = {
        fullname: $scope.selectedJurry[i].name + " " + $scope.selectedJurry[i].surname,
        id: $scope.selectedJurry[i].id,
        problemId: $scope.competitionId == 0 ? 0 : $scope.competitionId
      }
      for (var j = 0; j < $scope.problemUser.length; j++) {
        if ($scope.problemUser[j].id == $scope.selectedJurry[i].id) {
       //   Notification.warning($translate.instant("warning.field.same.value"));
          isExist = true;
        }
      }
      if(!isExist) $scope.problemUser.push(puser_);
    }


  }
  $scope.removeSelected = function (i) {
    $scope.problemUser.splice(i, 1);
  }
  /* Problem Fields */
  $scope.addProblemField = function () {
    if ($scope.newProblemField.title == undefined || $scope.newProblemField.title == "") {
      Notification.warning($translate.instant('warning.field.cannot.empty'));
      return;
    }
    if ($scope.newProblemFieldStatus == "S") {
      for (var i = 0; i < $scope.problemFieldList.length; i++) {
        if ($scope.problemFieldList[i].title == $scope.newProblemField.title) {
          Notification.warning($translate.instant('warning.field.same.value') + ":" + $scope.newProblemField.title);
          return;
        }
      }
      $scope.problemFieldList.push({
        id: 0,
        title: $scope.newProblemField.title,
        flowIndex: $scope.problemFieldList.length + 1
      });
    } else {
      for (var i = 0; i < $scope.problemFieldList.length; i++) {
        if ($scope.problemFieldList[i].flowIndex == $scope.newProblemField.flowIndex) {
          $scope.problemFieldList[i].title = $scope.newProblemField.title;
        }
      }
    }
    $scope.newProblemField = {};
    $scope.newProblemFieldStatus = "S";
  }
  $scope.editProblemField = function (problemField) {
    $scope.newProblemFieldStatus = "E";
    $scope.newProblemField.title = problemField.title;
    $scope.newProblemField.flowIndex = problemField.flowIndex;
  }
  $scope.deleteProblemField = function (i) {
    $scope.problemFieldList.splice(i, 1);
    for (var i = 0; i < $scope.problemFieldList.length; i++) {
      $scope.problemFieldList[i].flowIndex = i + 1;
    }
  }
  /* Category */
  $scope.addCategory = function (a) {
    $scope.newCategory.problemId = $scope.competitionId;
    $scope.newCategory.hasChild = 0;
    if ($scope.newCategory.name == undefined || $scope.newCategory.name == "") {
      Notification.warning($translate.instant('warning.field.cannot.empty') + ":" + $translate.instant('label.category.new'));
      return;
    }
    if (a == "S") {
      if ($scope.problemCategory.length < 20) {
        for (var i = 0; i < $scope.problemCategory.length; i++) {
          if ($scope.problemCategory[i] != null && $scope.problemCategory[i].parent == null && $scope.problemCategory[i].name == $scope.newCategory.name) {
            Notification.warning($translate.instant('warning.field.same.value') + ":" + $scope.newCategory.name);
            return;
          }
        }
        $scope.newCategory.status = 1;
        if ($scope.newCategory.parent != undefined && $scope.newCategory.parent.id === -1) {
          $scope.newCategory.parent = undefined;
        }
        if ($scope.newCategory.parent == undefined || $scope.newCategory.parent.name == undefined) {
          $scope.problemCategoryParents.push($scope.newCategory);
        }
        $scope.problemCategory.push($scope.newCategory);
        $scope.newCategory = {};
        reOrderProblemCategory();
      } else {
        Notification.warning($translate.instant('warning.competition.max.criteria'));
        $scope.newCategory = {};
        $scope.newCategory.parent = 0;

      }

    } else {
      if ($scope.newCategory != null && $scope.newCategory.parent != null && $scope.problemCategory[$scope.selectedindex].hasChild === 1 && $scope.newCategory.parent.name != "--" + $translate.instant("label.category.parent.select") + "--") {
        Notification.warning($translate.instant('label.category.parent.not.editable.warning') + ":" + $scope.newCategory.name);
        return;
      }
      if ($scope.newCategory != null && $scope.newCategory.parent != null && $scope.newCategory.parent.id === -1) {
        $scope.newCategory.parent = null;
      }
      if ($scope.problemCategory[$scope.selectedindex].parent == null) {
        for (var i = 0; i < $scope.problemCategoryParents.length; i++) {
          if ($scope.problemCategoryParents[i].id != undefined && $scope.problemCategoryParents[i].id != 0 && $scope.problemCategoryParents[i].id === $scope.problemCategory[$scope.selectedindex].id) {
            $scope.problemCategoryParents[i] = angular.copy($scope.newCategory);
          } else if ($scope.problemCategoryParents[i].name === $scope.problemCategory[$scope.selectedindex].name) {
            $scope.problemCategoryParents[i] = angular.copy($scope.newCategory);
          }
        }
      }
      if ($scope.problemCategory[$scope.selectedindex].parent == null && $scope.problemCategory[$scope.selectedindex].hasChild === 1) {
        for (var i = 0; i < $scope.problemCategory.length; i++) {
          if ($scope.problemCategory[i].parent != null && $scope.problemCategory[i].parent.id != undefined && $scope.problemCategory[i].parent.id > 0 && $scope.problemCategory[i].parent.id === $scope.problemCategory[$scope.selectedindex].id) {
            $scope.problemCategory[i].parent = angular.copy($scope.newCategory);
          } else if ($scope.problemCategory[i].parent != null && $scope.problemCategory[i].parent.name === $scope.problemCategory[$scope.selectedindex].name) {
            $scope.problemCategory[i].parent = angular.copy($scope.newCategory);
          }
        }
      }
      $scope.problemCategory[$scope.selectedindex] = angular.copy($scope.newCategory);
      $scope.categoryStatus = "S";
      $scope.newCategory = {};
      reOrderProblemCategory();
    }

  }
  $scope.editCategory = function (a, i) {
    $scope.selectedindex = i;
    $scope.categoryStatus = "E";
    $scope.newCategory.name = a.name;
    $scope.newCategory.parent = a.parent;
    $scope.newCategory.parentId = a.parentId;
    $scope.newCategory.status = a.status;
    $scope.newCategory.hasChild = a.hasChild;
    $scope.newCategory.problemId = a.problemId;
    $scope.newCategory.id = a.id;
    $scope.newCategory.path = a.path;
    if (a.parent == null) {
      $scope.newCategory.parent = {
        id: -1,
        "name": "--" + $translate.instant("label.category.parent.select") + "--"
      };
    }

  }
  $scope.deleteCategory = function (a) {
    var willDeleteObj = $scope.problemCategory[a];
    $scope.problemCategory.splice(a, 1);
    var parentIndex = $scope.problemCategoryParents.indexOf(willDeleteObj);
    if (parentIndex != -1) {
      $scope.problemCategoryParents.splice(parentIndex, 1);
      for (var j = 0; j < $scope.problemCategory.length; j++) {
        var parent = $scope.problemCategory[j].parent;
        if (parent != null && parent.id === willDeleteObj.id && parent.name === willDeleteObj.name && parent.hasChild === willDeleteObj.hasChild) {
          $scope.problemCategory[j] = null;
        }
      }
    } else {
      if (willDeleteObj.parent != undefined && willDeleteObj.parent != null) {
        for (var i = 0; i < $scope.problemCategory.length; i++) {
          if ($scope.problemCategory[i].parent != undefined && $scope.problemCategory[i].parent === willDeleteObj.parent) {
            willDeleteObj.parent.hasChild = 1;
            break;
          }
          willDeleteObj.parent.hasChild = 0;
        }
      }
    }

    reOrderProblemCategory();
  }
  var reOrderProblemCategory = function () {
    if ($scope.problemCategoryParents.length > 0) {
      var reOrderedProblemCategory = [];
      for (var i = 0; i < $scope.problemCategoryParents.length; i++) {
        if ($scope.problemCategoryParents[i] != undefined && $scope.problemCategoryParents[i] != null) {
          if ($scope.problemCategoryParents[i].id != -1) {
            reOrderedProblemCategory.push($scope.problemCategoryParents[i]);
          }
        }
        for (var j = 0; j < $scope.problemCategory.length; j++) {
          if (checkCategoryObject(i, j)) {
            reOrderedProblemCategory.push($scope.problemCategory[j]);
            $scope.problemCategoryParents[i].hasChild = 1;
          }
        }
      }
      $scope.problemCategory = [];
      $scope.problemCategory = reOrderedProblemCategory;
    } else {
      $scope.problemCategory = [];
      $scope.problemCategoryParents = [];
    }
  };

  var checkCategoryObject = function (i, j) {
    if ($scope.problemCategory[j] != undefined && $scope.problemCategory[j] != null) {
      if ($scope.problemCategory[j].parent === $scope.problemCategoryParents[i]) {
        return true;
      } else if ($scope.problemCategory[j].parent != undefined && $scope.problemCategory[j].parent != null && $scope.problemCategory[j].parent.name === $scope.problemCategoryParents[i].name && $scope.problemCategory[j].parent.problemId === $scope.problemCategoryParents[i].problemId) {
        return true;
      }
    }
    return false;
  };

  $scope.deleteModal = function (t, a) {
    $rootScope.modalInstance = $uibModal.open({
      animation: true,
      backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
        //  $scope.value = i;
        $scope.deleteModalOk = function () {
          t.deleteCategory(a);
          $uibModalInstance.close();
        }

        $scope.deleteModalClose = function () {
          $rootScope.modalInstance.close();
        }
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "sureModalDelete.html"
    });
  };
  /* Criteria */
  $scope.addCriteria = function (a) {
    $scope.newCriteria.problemId = $scope.competitionId;
    if ($scope.newCriteria.criteria == undefined || $scope.newCriteria.criteria == "") {
      Notification.warning($translate.instant('warning.field.cannot.empty') + ":" + $translate.instant('label.criteria.new'));
      return;
    }
    if (a == "S") {
      if ($scope.problemAcceptanceCriteria.length < 20) {
        for (var i = 0; i < $scope.problemAcceptanceCriteria.length; i++) {
          if ($scope.problemAcceptanceCriteria[i].criteria == $scope.newCriteria.criteria) {
            Notification.warning($translate.instant('warning.field.same.value') + ":" + $scope.newCriteria.criteria);
            return;
          }
        }
        $scope.newCriteria.status = 1;
        $scope.problemAcceptanceCriteria.push($scope.newCriteria);
        $scope.newCriteria = {};
        $scope.newCriteria.priority = $scope.priorities[4];
      } else {
        Notification.warning($translate.instant('warning.competition.max.criteria'));
        $scope.newCriteria = {};
        $scope.newCriteria.priority = $scope.priorities[4];

      }

    } else {
      $scope.problemAcceptanceCriteria[$scope.selectedindex] = angular.copy($scope.newCriteria);
      $scope.criteriaStatus = "S";
      $scope.newCriteria = {};
      $scope.newCriteria.priority = $scope.priorities[4];
    }

  }
  $scope.editCriteria = function (a, i) {
    $scope.selectedindex = i;
    $scope.criteriaStatus = "E";
    $scope.newCriteria.status = 1;
    $scope.newCriteria.criteria = a.criteria;
    $scope.newCriteria.priority = a.priority;
  }
  $scope.deleteCriteria = function (a) {
    $scope.problemAcceptanceCriteria.splice(a, 1);

  }
  /* Reward */
  $scope.addReward = function (a) {
    if ($scope.newReward.rewards == undefined || $scope.newReward.rewards == "") {
      Notification.warning($translate.instant('warning.field.cannot.empty') + ":" + $translate.instant('label.reward.new'));
      return;
    }
    if (a == "S") {
      if ($scope.reward.length < 10) {
        for (var i = 0; i < $scope.reward.length; i++) {
          if ($scope.reward[i].rewards == $scope.newReward.rewards) {
            Notification.warning($translate.instant('warning.field.same.value') + ":" + $scope.newReward.rewards);
            return;
          }
        }
        $scope.newReward.problemId = $scope.competition.id;
        // $scope.newReward.orderx = $scope.reward.length + 1;
        $scope.newReward.order = $scope.reward.length + 1;
        $scope.newReward.status = 1;
        $scope.reward.push($scope.newReward);
        $scope.newReward = {};
      } else {
        Notification.warning($translate.instant('warning.competition.max.rewards'));
        $scope.newReward = {};

      }

    } else {
      $scope.reward[$scope.selectedRindex].rewards = angular.copy($scope.newReward.rewards);
      $scope.rewardStatus = "S";
      $scope.newReward = {};
    }
  }
  $scope.editReward = function (a, i) {
    $scope.selectedRindex = i;
    $scope.rewardStatus = "E";
    $scope.newReward.rewards = a.rewards;
  }
  $scope.deleteReward = function (a) {
    $scope.reward.splice(a, 1);

  }
  /* date options */
  $scope.dateOptions = {
    formatYear: "yy",
    maxDate: new Date(2023, 4, 22),
    minDate: NextDate(new Date()),
    startingDay: 1
  };
  $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
  $scope.format = $translate.instant('date.format.shortDate'); //$scope.formats[3];
  $scope.altInputFormats = $scope.format; //['M!/d!/yyyy'];

  // Disable weekend selection
  function disabled(data) {
    var date = data.date,
      mode = data.mode;
    return mode === "day" && (date.getDay() === 0 || date.getDay() === 6);
  }

  $scope.$watch('dateAnnouncement', function (newValue, oldValue) {
    if (newValue == undefined && oldValue == undefined)
      return;
    if (newValue != undefined)
      $scope.dateOptions.minDate = angular.copy(NextDate(newValue));
    if (newValue == undefined && oldValue != undefined)
      $scope.dateOptions.minDate = NextDate(new Date());
    // if(newValue > $scope.dateEvalStart)$scope.dateEvalStart ==undefined;
    // if(newValue > $scope.dateEvalDue)$scope.dateEvalDue ==undefined;

  });

  $scope.$watch('dateEvalStart', function (newValue, oldValue) {
    if (newValue == undefined && oldValue == undefined)
      return;
    if (newValue == undefined && oldValue != undefined)
      $scope.dateOptions.minDate = angular.copy($scope.dateAnnouncement == undefined ? NextDate(new Date()) : NextDate($scope.dateAnnouncement));
    if (newValue > $scope.dateEvalDue)
      $scope.dateEvalDue = undefined;
  });

  $scope.$watch('dateEvalDue', function (newValue, oldValue) {
    if (newValue == undefined && oldValue == undefined)
      return;
    if (newValue != undefined && $scope.dateEvalStart == undefined)
      $scope.dateOptions.maxDate = BeforeDate(newValue);
    if (newValue < $scope.dateEvalStart)
      $scope.dateEvalStart = undefined;
    if (newValue < $scope.dateAnnouncement)
      $scope.dateAnnouncement = undefined;
    if (newValue == undefined && oldValue != undefined)
      $scope.dateOptions.maxDate = new Date(2023, 4, 22);
  });
  $scope.dpAnnouncement = {
    opened: false
  };
  $scope.openAnnouncement = function () {
    if ($scope.dateEvalStart != undefined) {
      $scope.dateOptions.minDate = NextDate(new Date());
      $scope.dateOptions.maxDate = BeforeDate($scope.dateEvalStart);
    }
    $scope.dpAnnouncement.opened = true;
  };
  $scope.dpEvalStart = {
    opened: false
  };
  $scope.openEvalStart = function () {

    $scope.dateOptions.minDate = $scope.dateAnnouncement == undefined ? $scope.dateOptions.minDate : NextDate($scope.dateAnnouncement);
    $scope.dateOptions.maxDate = $scope.dateEvalDue == undefined ? $scope.dateOptions.maxDate : BeforeDate($scope.dateEvalDue);

    $scope.dpEvalStart.opened = true;
  };
  $scope.dpEvalDue = {
    opened: false
  };
  $scope.openEvalDue = function () {
    if ($scope.dateEvalStart != undefined) {
      $scope.dateOptions.minDate = NextDate($scope.dateEvalStart);
      $scope.dateOptions.maxDate = new Date(2023, 4, 22);
    }

    $scope.dpEvalDue.opened = true;
  };
  var formatStringToDate = function (a) {
    var date = new Date(a);
    return date.toString($scope.formats[3]);
  }
  // Agac
  $scope.treeOptions = {};
  $scope.getAgac = function (node) {
    $http.get("private/market/agac.do?node=" + node + "&problemId=" + $scope.competitionId).then(
      function (response) {
        $scope.children = response.data.data;
      },
      function (response) {
        //Second function handles error
        Notification.error("hata!");
      }
    );
  };

  $scope.toggleGroupSelection = function (item) {
    item.checked = !item.checked;
    $scope.toggleChildSelection(item, item.checked);
    $scope.toggleParentSelection(item);
  };
  $scope.toggleChildren = function (scope) {
    if (!scope.$nodeScope.$modelValue.children) {
      $http.get("private/market/agac.do?node=" + scope.$nodeScope.$modelValue.id + "&problemId=" + $scope.competitionId).then(function (response) {
        scope.$nodeScope.$modelValue.children = response.data.data;
        if (scope.$nodeScope.$modelValue.children.length > 0) {
          for (var i = 0; i < scope.$nodeScope.$modelValue.children.length; i++) {
            scope.$nodeScope.$modelValue.children[i].parentId = scope.$nodeScope.$modelValue.id;
          }

          if (scope.$nodeScope.$modelValue.checked) {
            for (var i = 0; i < scope.$nodeScope.$modelValue.children.length; i++) {
              scope.$nodeScope.$modelValue.children[i].checked = true;
            }

          }
          scope.toggle();
        }
      });
    } else {
      if (scope.$nodeScope.$modelValue.children.length > 0) {
        scope.toggle();
      }
    }
  };


  $scope.findSelectionById = function (id, children) {
    for (var i = 0; i < children.length; i++) {
      if (children[i].id == id) return children[i];
      else if (children[i].children) return $scope.findSelectionById(id, children[i].children);
    }
  }

  $scope.toggleChildSelection = function (item, checked) {
    item.checked = checked;
    if (item.children != null) {
      for (var i = 0; i < item.children.length; i++) {
        $scope.toggleChildSelection(item.children[i], checked);
      }
    }
  }

  $scope.toggleParentSelection = function (item) {
    if (item.parentId) {
      var parent = $scope.findSelectionById(item.parentId, $scope.children);
      var checkedCount = 0;
      var childCount = 0;
      for (var i = 0; i < parent.children.length; i++) {
        childCount++;
        if (parent.children[i].checked) checkedCount++;
      }
      parent.checked = (childCount == checkedCount);
      $scope.toggleParentSelection(parent);
    }
  }
  // competiton
  $scope.loadCompetition = function () {
    $http.get("private/market/retrieveProblemModelSpecific.do?problemId=" + $scope.competitionId + "&start=0&limit=1")
      .then(function (response) {
        if (response.data.success) {
          $scope.competition = response.data.data[0];
          //$scope.dateAnnouncement = new Date($scope.competition.announceDate);        


         /* if ($scope.competition.evaluateDate != undefined) {
            var deadline = new Date($scope.competition.evaluateDate);
            deadline.setDate(deadline.getDate() - 1); //dd.MM.YYYY 23:59
            $scope.competition.evaluateDate = deadline;
          } */

          if ($scope.competition.evaluateDate != null) {
            $scope.dateEvalStart = new Date($scope.competition.evaluateDate);
            $scope.dateEvalDue = new Date($scope.competition.closeDate);
          }

          if ($scope.competition.announceDate != null) {
            $scope.dateAnnouncement = new Date($scope.competition.announceDate);
          }

          // $scope.dateEvalStart = new Date($scope.competition.evaluateDate);

          // $scope.dateEvalDue = new Date($scope.competition.closeDate);
          $scope.problemAcceptanceCriteria = $scope.competition.acceptanceCriteria;
          $scope.problemFieldList = $scope.competition.problemFields;
          $scope.reward = angular.copy($scope.competition.rewards);
          $scope.problemUser = formatProblemUser($scope.competition.problemUser);
          $scope.problemCategory = $scope.competition.problemCategories;
          for (var i = 0; i < $scope.problemCategory.length; i++) {
            if ($scope.problemCategory[i].parent == undefined || $scope.problemCategory[i].parent.name == undefined) {
              $scope.problemCategoryParents.push($scope.problemCategory[i]);
            }
          }
          $scope.newCategory.status = 1;
          $scope.getUploadedFiles();
        } else {
          //Second function handles error
          Notification.error(response.data.message);
          $location.path('/competitions');
        }

      }, function (response) {
        //Second function handles error
        Notification.error(response);
        $location.path('/competitions');
      });
  };
  var formatProblemUser = function (userList) {
    var arr = [];

    for (var i = 0; i < userList.length; i++) {
      var obj = {};
      obj.id = userList[i].userId;
      obj.fullname = userList[i].fullname;
      obj.problemId = userList[i].problemId;
      arr.push(obj);
    }
    return arr;
  }
  $scope.gruplist = [];


  $scope.getSelectedDepartment = function () {
    $http.get("private/problem/listProblemSelectedDepartment.do?id=" + $scope.competitionId).then(
      function (response) {
        $scope.selectedDepartment = response.data.data;
      },
      function (response) {
        //Second function handles error
        Notification.error("hata!");
      }
    );
  };
  $scope.SetChildrenToGroupList = function (children) {
    if (children) {
      for (var i = 0; i < children.length; i++) {
        var c = children[i];
        if (c.checked) {
          $scope.gruplist.push({
            checked: c.checked,
            hasChild: c.hasChild,
            id: c.id,
            idTree: c.idTree,
            leaf: c.leaf,
            name: c.name
          });
        } else if (children[i].children) {
          $scope.SetChildrenToGroupList(children[i].children);
        }
      }
    }
  }
  $scope.saveCompetition = function (a) {
    //if (a == 0 && $scope.competitionId != 0) {
    //$scope.submitCompetition();
    //}
    //else {
    $scope.gruplist = [];
    $scope.SetChildrenToGroupList($scope.children);
    if (checkFormIsValid(a)) {

      reOrderRewards();
      var data_ = validateCompetetionData();
      var url = "private/editProblem.do";
      var data = 'market=' + encodeURIComponent(JSON.stringify(data_)) +
        '&criteriaList=' + encodeURIComponent(JSON.stringify($scope.problemAcceptanceCriteria)) +
        '&rewardList=' + encodeURIComponent(JSON.stringify($scope.reward)) +
        '&groupList=' + encodeURIComponent(JSON.stringify($scope.gruplist)) +
        '&problemFieldList=' + encodeURIComponent(JSON.stringify($scope.problemFieldList)) +
        '&evaluatorList=' + encodeURIComponent(JSON.stringify($scope.problemUser)) +
        '&categoryList=' + encodeURIComponent(JSON.stringify($scope.problemCategory));
      $http({
          method: 'POST',
          url: url,
          data: data,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
          }
        })
        .then(function (res) {
          if (res.data.success == true) {
            $scope.competition = res.data.data[0];
            $scope.competitionId = $scope.competition.id;
            if (a == 0) $scope.submitCompetition();
            if (a != 0) {
              if ($scope.competitionId == 0)
                Notification.success($translate.instant("success.competition.defined"));
              else
                Notification.success($translate.instant("success.competition.edited"));
            }
            $scope.getSelectedDepartment();
          } else {
            Notification.error($translate.instant("error.generic"));
          }
        });
    }
    //}

  }
  var reOrderRewards = function () {
    for (var i = 0; i < $scope.reward.length; i++) {
      $scope.reward[i].order = i + 1;
    }
  }
  $scope.submitCompetition = function () {

    if (checkFormIsValid(0)) {
      var action = "S";
      var path = "private/marketAction.do?problemId=" + $scope.competition.id + "&action=" + action;
      $http.get(path)
        .then(function (response) {
          if (response.data.success == true) {
            Notification.success($translate.instant("success.competition.submited"));
            $scope.sureModalClose();
            $location.path('/competitions');
          } else {
            Notification.error($translate.instant("error.generic"));
          }

        }, function (response) {
          Notification.error($translate.instant("error.generic"));
        });

    }
  }
  var validateCompetetionData = function () {
    var obj = {};
    obj.id = angular.copy($scope.competition.id);
    obj.status = angular.copy($scope.competition.status);
    obj.name = angular.copy($scope.competition.name);
    obj.innovationType = angular.copy($scope.competition.innovationType);
    obj.tag = angular.copy($scope.competition.tag);
    var deadline = new Date($scope.dateAnnouncement);
    //deadline.setDate(deadline.getDate() + 1); //dd.MM.YYYY 23:59
    $scope.dateAnnouncement = deadline;
    obj.announceDateStr = angular.copy(dateFormat.formatter($scope.dateAnnouncement));
    deadline = new Date($scope.dateEvalStart);
    //deadline.setDate(deadline.getDate() + 1); //dd.MM.YYYY 23:59
    $scope.dateEvalStart = deadline;
    obj.evaluateDateStr = angular.copy(dateFormat.formatter($scope.dateEvalStart));
    deadline = new Date($scope.dateEvalDue);
    //deadline.setDate(deadline.getDate() + 1); //dd.MM.YYYY 23:59
    $scope.dateEvalDue = deadline;
    obj.closeDateStr = angular.copy(dateFormat.formatter($scope.dateEvalDue));
    obj.problemState = angular.copy($scope.competition.problemState);
    obj.ideaViewState = angular.copy($scope.competition.ideaViewState);
    obj.publicMode = angular.copy($scope.competition.publicMode);
    obj.code = angular.copy($scope.competition.code);
    obj.description = angular.copy($scope.competition.description);
    obj.evalView = angular.copy($scope.competition.evalView);
    obj.ideaUserComment = angular.copy($scope.competition.ideaUserComment);
    obj.evalComment = angular.copy($scope.competition.evalComment);
    obj.ideaUserView = angular.copy($scope.competition.ideaUserView);
    obj.points = angular.copy($scope.competition.points);
    var compArr = [];
    compArr.push(obj);
    return compArr;

  }
  var checkFormIsValid = function (a) {
    if (a == 0) {
      var checked = false;
      // $scope.children.forEach(element => {
      //   if (element.checked)
      //     checked = true;
      //   if (element.children != null) {
      //     element.children.forEach(item => {
      //       if (item.checked)
      //         checked = true;
      //     });
      //   }
      // });

      for (var i = 0; i < $scope.children.length; i++) {
        if ($scope.children[i].checked)
          checked = true;
        if ($scope.children[i].children != null) {
          for (var j = 0; j < $scope.children[i].children.length; j++) {
            if ($scope.children[i].children[j].checked)
              checked = true;
          }
        }
      }

      if (!checked) {
        Notification.warning($translate.instant("info.select.user"));
        return checked;
      }
      if (!ValidateDateAndInputs()) return false;
      $scope.competition.problemState = 'A';
    } else if (a == 1) {
      if (!ValidateDateAndInputs()) return false;
      $scope.competition.problemState = 'D';
    } else if (a == 2) {
      if (!ValidateDateAndInputs()) return false;
    }
    return true;
  }
  var ValidateDateAndInputs = function () {
    if ($scope.competition.problemState == 'S' || $scope.competition.problemState == 'D') {
      if ($scope.dateAnnouncement <= new Date()) {
        Notification.warning($translate.instant("warning.competition.date1"));
        return false;
      }
      if ($scope.dateEvalStart <= new Date() || $scope.dateEvalStart <= $scope.dateAnnouncement) {
        Notification.warning($translate.instant("warning.competition.date2"));
        return false;
      }
      if ($scope.dateEvalDue <= new Date() || $scope.dateEvalDue <= $scope.dateEvalStart) {
        Notification.warning($translate.instant("warning.competition.date3"));
        return false;
      }

    }

    if ($scope.competition.problemState == 'A') {
      if ($scope.dateEvalStart <= new Date()) {
        Notification.warning($translate.instant("warning.competition.date5"));
        return false;
      }
      if ($scope.dateEvalDue <= new Date()) {
        Notification.warning($translate.instant("warning.competition.date6"));
        return false;
      }
      if ($scope.dateEvalStart <= new Date() || $scope.dateEvalStart <= $scope.dateAnnouncement) {
        Notification.warning($translate.instant("warning.competition.date2"));
        return false;
      }
      if ($scope.dateEvalDue <= new Date() || $scope.dateEvalDue <= $scope.dateEvalStart) {
        Notification.warning($translate.instant("warning.competition.date3"));
        return false;
      }
    }
    if ($scope.competition.problemState == 'E') {

      if ($scope.dateEvalDue <= new Date()) {
        Notification.warning($translate.instant("warning.competition.date6"));
        return false;
      }
      if ($scope.dateEvalDue <= new Date() || $scope.dateEvalDue <= $scope.dateEvalStart) {
        Notification.warning($translate.instant("warning.competition.date3"));
        return false;
      }
    }
    if ($scope.competition.points > 9999) {
      Notification.warning($translate.instant("warning.idea.point.bigger"));
      return false;
    }
    if ($scope.competition.points < 0) {
      Notification.warning($translate.instant("warning.idea.point.smaller"));
      return false;
    }
    return true;
  }



  $scope.sureModal = function (t, i) {
    $rootScope.modalInstance = $uibModal.open({
      animation: true,
      backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
        $scope.value = i;
        $scope.sureModalOk = function () {
          if (i == 0) t.saveCompetition(0);
          if (i == 2) t.saveCompetition(2);
          $uibModalInstance.close();
        }

        $scope.sureModalClose = function () {
          $uibModalInstance.close();
        };
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "sureModal.html"
    });
  };
  $scope.sureModalClose = function () {
    $rootScope.modalInstance.close();
  };
  // file
  $scope.getUploadedFiles = function () {

    var path = "private/market/retrieveProblemFiles.do?";

    $http.get(path + "problemId=" + $scope.competitionId + "&start=0&limit=25")
      .then(function (response) {
        $scope.uploadedFiles = response.data.data;
      }, function (response) {
        //Second function handles error
        Notification.error('ERROR suggestion files');
      });
  };
  $scope.removeFile = function (i) {
    $scope.fileList.splice(i, 1);
  };
  $scope.updateFiles = function (newFiles) {

    if (newFiles.length > 0) {
      if (($scope.fileList.length + newFiles.length + $scope.uploadedFiles.length) > 3) {
        Notification.error($translate.instant('error.files.max3'));
      } else {

        for (var i = 0; i < newFiles.length; i++) {
          $scope.fileList.push(newFiles[i]);
        }

        // newFiles.forEach(function (f) {
        //   $scope.fileList.push(f);
        // });

        $scope.uploadEnabled = true;
        Notification.info($translate.instant('info.files.added'));
      }
    }
  };
  $scope.deleteFile = function (id) {
    var url = "private/market/deleteFileById.do";
    if ($rootScope.authenticated) {
      url = "private/market/deleteFileById.do";
    }
    var payload = new FormData();

    payload.append('problemId', $scope.competitionId);
    payload.append('fileId', id);
    $http({
        url: url,
        method: 'POST',
        data: payload,
        //assign content-type as undefined, the browser
        //will assign the correct boundary for us
        headers: {
          'Content-Type': undefined
        },
        //prevents serializing payload.  don't do it.
        transformRequest: angular.identity
      })
      .then(function (res) {
        $scope.getUploadedFiles();
        Notification.info($translate.instant('info.file.deleted'));
      });
  };
  $scope.uploadFiles = function () {
    if (($scope.fileList.length + $scope.uploadedFiles.length) > 3) {
      Notification.error($translate.instant('error.files.max3'));
      return;
    }
    if ($scope.fileList && $scope.fileList.length < 4 && $scope.fileList.length > 0) {
      handleMsg(MessageTypes.LOADING, 'info.files.uploading');
      for (var i = 0; i < $scope.fileList.length; i++) {
        var payload = new FormData();

        payload.append('problemId', $scope.competitionId);
        payload.append('file', $scope.fileList[i]);
        payload.append('authenticated', false);
        var url = "private/uploadFile.htm";
        if ($rootScope.authenticated) {
          // url = 'private/uploadFile.htm';
          payload.append('authenticated', true);
        }
        $http({
          url: url,
          method: 'POST',
          ignoreLoadingBar: true,
          data: payload,
          //assign content-type as undefined, the browser
          //will assign the correct boundary for us
          headers: {
            'Content-Type': undefined
          },
          //prevents serializing payload.  don't do it.
          transformRequest: angular.identity
        }).then(function (response) {
          if (response.data.success) {
            $scope.fileList = [];
            Notification.info($translate.instant("info.files.uploaded"));
            $scope.fileList = [];
            $scope.getUploadedFiles();
          } else {
            Notification.error($translate.instant(response.data.message));
          }


        }, function (response) {
          if (response.status > 0) {
            Notification.error(response.status + ': ' + response.data);
          }
        }, function (evt) {
          $scope.fileProgress =
            Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
          if (evt.loaded == evt.total) {}
        });
      }
    } else {
      Notification.error($translate.instant('error.files.max3'));
    }
  };

  // icon
  $scope.updateIcon = function (newIcon) {
    if (newIcon.length > 0) {
      $scope.iconList = [];
      var i = 0;
      $scope.iconList.push(newIcon[i]);
      $scope.uploadEnabled = true;
      //Notification.info($translate.instant('info.files.added'));
      $scope.uploadIcons();
    }
  };
  $scope.uploadIcons = function () {
    if ($scope.iconList.length > 0) {
      handleMsg(MessageTypes.LOADING, 'info.files.uploading');
      var i = 0;
      var payload = new FormData();
      payload.append('problemId', $scope.competitionId);
      payload.append('file', $scope.iconList[i]);
      payload.append('authenticated', false);
      var url = "private/uploadIcon.htm";
      if ($rootScope.authenticated) {
        payload.append('authenticated', true);
      }
      $scope.loadIconPromise = $http({
        url: url,
        method: 'POST',
        ignoreLoadingBar: true,
        data: payload,
        headers: {
          'Content-Type': undefined
        },
        transformRequest: angular.identity
      }).then(function (response) {
        if (response.data.success) {
          //Notification.info($translate.instant("info.files.uploaded"));
          $scope.iconList = [];
          $scope.icon.url = "notsecure/image.htm?id=problem-" + $scope.competitionId + "-problem.png" + "-" + $scope.icon.name;
        } else {
          Notification.error($translate.instant(response.data.message));
        }

      }, function (response) {
        if (response.status > 0) {
          Notification.error(response.status + ': ' + response.data);
        }
      }, function (evt) {
        $scope.iconProgress =
          Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        if (evt.loaded == evt.total) {}
      });
    } else {
      Notification.error($translate.instant('error.files.max3'));
    }
  };

  function handleMsg(type, msg) {
    if (type == MessageTypes.ERROR) {
      $scope.showError = true;
      $scope.showMessage = false;
      $scope.showMessageSpinner = false;
      $scope.errorMsg = msg;
    } else if (type == MessageTypes.INFO) {
      $scope.showError = false;
      $scope.showMessage = true;
      $scope.showMessageSpinner = false;
      $scope.result = msg;
    } else if (type == MessageTypes.LOADING) {
      $scope.showError = false;
      $scope.showMessage = true;
      $scope.showMessageSpinner = true;
      $scope.result = msg;
    }
  }
  var MessageTypes = {
    ERROR: 1,
    INFO: 2

  };
  // init
  if ($routeParams.competitionId) {
    $scope.competitionId = $routeParams.competitionId;
    $scope.icon.url = "notsecure/image.htm?id=problem-" + $scope.competitionId + "-problem.png";
    $scope.loadCompetition();
  }


  $scope.getAgac("root");
  $scope.getSelectedDepartment();

}]);