app.controller("improvementSettingsCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', 'Notification', '$translate', '$uibModal',
    function ($scope, $sce, $http, $location, $rootScope, Notification, $translate, $uibModal) {



        $scope.pageSizeCategory = 50;
        $scope.pagingMaxSize = 4;
        $scope.currentIndexCategory = 0;
        $scope.calcindexCategory = 0;
        $scope.pageSize = 50;
        $scope.edittingAllowCategory = true;
        $scope.currentIndex = 0;
        $scope.calcindex = 0;
        $scope.currentPageCategory = 1;
        $scope.pagination = { currentPage: 1 };
        $scope.edittingAllow = true;

        $scope.selectableStyle = { "cursor": "pointer" };
        $scope.noneSelectableStyle = { "cursor": "not-allowed" };
        $scope.impCategoryData = {
            code: '',
            name: '',
            nameEn: "",
            active: 1
        };

        $scope.impFieldData = {
            titlePrimaryLang: '',
            titleSecondaryLang: '',
            requiresManagerConfirmation: "true",
            active: 1
        };

        $scope.impFieldUserCommitteeData = {
            userFullName: '',
            userTitle: '',
            authType: "8",
            active: 1
        };

        $scope.impFieldUsers = [];

        $scope.impFieldUserList = [];

        $scope.init = function (a) {
            $scope.loading = true;
            if (a == 'j') $scope.getImprovementCategoryList();
            if (a == 'i') $scope.getImprovementFieldList();
            if (a == 'k') $rootScope.getImprovementFieldUserList();
        };

        // IMPROVEMENT CATEGORY LIST
        $scope.getTemplateImpCategory = function (i) {
            if (i.id === $scope.impCategoryData.id && $scope.edittingAllowCategory) return 'editImpCategory';
            else return 'displayImpCategory';
        };

        $scope.getImprovementCategoryList = function () {
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            var page = $location.search().page || 1;
            $scope.currentIndexCategory = (page - 1) * $scope.pageSizeCategory;
            $scope.myPromise = $http.get("private/improvement-category/list.do?start=" + $scope.currentIndexCategory + "&limit=" + $scope.pageSizeCategory)
                .then(function (response) {
                    $scope.impCategory = response.data.data;

                }, function (response) {
                    //Second function handles error
                    Notification.error(response.data.message);
                });
        };

        $scope.sureModalClose = function () {
            $rootScope.modalInstance.close();
        };

        $scope.cancelImprovementCategory = function (i) {
            $scope.impCategoryData = {};
            $scope.updatestate = false;

        };

        $scope.updateImprovementCategory = function (i) {

            if (i) {
                $scope.impCategoryData = angular.copy(i);
                $scope.impCategoryData.active = 1;
                $scope.edittingAllowCategory = true;
            }
        };
        $scope.deleteImprovementCategory = function (i) {

            if (i) {
                $scope.impCategoryData = angular.copy(i);
                $scope.impCategoryData.active = 0;
                $scope.edittingAllowCategory = false;
            }
            $scope.saveUpdateImpCategory();
        };
        $scope.activateImprovementCategory = function (i) {

            if (i) {
                $scope.impCategoryData = angular.copy(i);
                $scope.impCategoryData.active = 1;
                $scope.edittingAllowCategory = false;
            }
            $scope.saveUpdateImpCategory();
        };

        /************************************************************************************************/


        /*************************************************************************************************/

        $scope.saveUpdateImpCategory = function () {
            $scope.dataLoading = true;
            $scope.hasError = false;

            $http({
                method: 'POST',
                url: 'private/improvement-category/save.do',
                data: 'category=' + encodeURIComponent(JSON.stringify($scope.impCategoryData)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.impCategoryData = {};
                    $scope.dataLoading = false;
                    $scope.msg = res.data.message;
                    $scope.edittingAllowCategory = true;
                    if (res.data.success == true) {
                        Notification.success($translate.instant("info.improvement.category.saved"));
                        $scope.getImprovementCategoryList();
                    } else {
                        $scope.hasError = true;
                        Notification.warning($scope.msg);
                        $scope.dataLoading = false;
                    }

                });
        };


        $scope.stringToDate = function (d) {
            $scope.date = new Date(d);
            return $scope.date;
        };
        $scope.openNewImprovementCategoryModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true, backdrop: 'static', backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {

                        if ($scope.impCategoryData == "" || $scope.impCategoryData == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.impCategoryData.code == "" || $scope.impCategoryData.code == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.impCategoryData.name == "" || $scope.impCategoryData.name == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        }
                        if ($scope.impCategoryData.nameEn == "" || $scope.impCategoryData.nameEn == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        }

                        $scope.saveNewImpCategory(t);
                        $scope.Close();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };
                    $scope.saveNewImpCategory = function (t) {
                        $scope.dataLoading = true;
                        $scope.hasError = false;
                        if ($scope.impCategoryData.requiresManagerConfirmation === "1") {
                            $scope.impCategoryData.requiresManagerConfirmation = true;
                        } else {
                            $scope.impCategoryData.requiresManagerConfirmation = false;
                        }
                        $http({
                            method: 'POST',
                            url: 'private/improvement-category/save.do',
                            data: 'category=' + encodeURIComponent(JSON.stringify($scope.impCategoryData)),
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                if (res.data.success == true) {
                                    Notification.success($translate.instant("info.improvement.category.saved"));
                                    t.getImprovementCategoryList();
                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }

                            });
                    };
                },
                size: 'lg',
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "newImprovementCategory.html"
            });
        };

        // IMPROVEMENT FIELD LIST
        $scope.getTemplateImpField = function (i) {
            if (i.id === $scope.impFieldData.id && $scope.edittingAllow) return 'editImpField';
            else return 'displayImpField';
        };


        $scope.getImprovementFieldList = function () {
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $scope.myPromise = $http.get("private/improvement-field/list.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                .then(function (response) {
                    $scope.impFields = response.data.data;
                    for (var i = 0; i < $scope.impFields.length; i++) {
                        if ($scope.impFields[i].requiresManagerConfirmation != null) {
                            $scope.impFields[i].requiresManagerConfirmation = $scope.impFields[i].requiresManagerConfirmation + "";
                        }
                    }
                    $scope.impFieldsTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.currentPage = page;
                    var rangeEnd = $scope.currentIndex + $scope.pageSize;
                    $scope.displayRangeEnd = rangeEnd > $scope.impFieldsTotal ? $scope.impFieldsTotal : rangeEnd;
                    $scope.loading = false;
                    $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
                }, function (response) {
                    //Second function handles error
                    Notification.error(response.data.message);
                });
        };


        $scope.updateImprovementField = function (i) {

            if (i) {
                //  $scope.impFieldData = angular.copy(i);
                $scope.openEditImprovementFieldModal(this, i);
                //   $scope.impFieldData.active = 1;
                //   $scope.edittingAllow = true;
            }
        };

        $scope.deleteImprovementField = function (i) {

            if (i) {
                $scope.impFieldData = angular.copy(i);
                $scope.impFieldData.active = 0;
                $scope.edittingAllow = false;
            }
            $scope.saveUpdateImpField();
        };
        $scope.activateImprovementField = function (i) {

            if (i) {
                $scope.impFieldData = angular.copy(i);
                $scope.impFieldData.active = 1;
                $scope.edittingAllow = false;
            }
            $scope.saveUpdateImpField();
        };


        $scope.saveUpdateImpField = function () {
            $scope.dataLoading = true;
            $scope.hasError = false;
            if ($scope.impFieldData.requiresManagerConfirmation === "true") {
                $scope.impFieldData.requiresManagerConfirmation = true;
            } else {
                $scope.impFieldData.requiresManagerConfirmation = false;
            }
            $http({
                method: 'POST',
                url: 'private/improvement-field/save.do',
                data: 'field=' + encodeURIComponent(JSON.stringify($scope.impFieldData)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.impFieldData = {};
                    $scope.dataLoading = false;
                    $scope.msg = res.data.message;
                    $scope.edittingAllow = true;
                    if (res.data.success == true) {
                        Notification.success($translate.instant("info.improvement.field.saved"));
                        $scope.getImprovementFieldList();
                    } else {
                        $scope.hasError = true;
                        Notification.warning($scope.msg);
                        $scope.dataLoading = false;
                    }

                });
        };
        $scope.cancelImprovementField = function (i) {
            $scope.impFieldData = {};
            $scope.updatestate = false;

        };

        $scope.openImpFieldUserList = function (t, i) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true, backdrop: 'static', backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.myPromise = $http.get("private/improvement-field-user/list.do?fieldId=" + i.id)
                        .then(function (response) {
                            $scope.impFieldUsers = response.data.data;
                            $scope.loading = true;
                            $scope.users = [];
                            $scope.pagination = { currentPage: 1 };
                            $scope.pageSize = 10;
                            $scope.currentIndex = 0;
                            $scope.currentPage = 0;
                            $scope.query = "";
                            $scope.modalFieldName = i.titlePrimaryLang;

                            $scope.impFieldAuthTypeOptions = [
                                { value: 1, label: "imp.field.auth.auditor" },
                                { value: 2, label: "imp.field.auth.manager" },
                                { value: 3, label: "imp.field.auth.responsible" },
                                { value: 4, label: "imp.field.auth.mentor" },
                                // { value: 5, label: "imp.field.auth.expert" },
                                // { value: 6, label: "imp.field.auth.implementer" },
                            ];
                            $scope.selecteImpFieldAuthType = {};
                        }, function (response) {
                            //Second function handles error
                            Notification.error(response.data.message);
                        });
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };
                    $scope.pageChanged = function () {
                        $scope.currentIndex = ($scope.pagination.currentPage - 1) * $scope.pageSize;
                        $scope.getUserList();
                    };
                    $scope.clearSearch = function () {
                        $scope.query = "";
                        $scope.getUserList();
                    };

                    $scope.saveImpFieldUser = function (user, authType) {
                        $scope.dataLoading = true;
                        $scope.hasError = false;
                        var impFieldUserData = {
                            "userId": user.id,
                            "fieldId": i.id,
                            "authType": authType.value
                        };
                        $http({
                            method: 'POST',
                            url: 'private/improvement-field-user/save.do',
                            data: 'fieldUser=' + encodeURIComponent(JSON.stringify(impFieldUserData)),
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.impFieldData = {};
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                $scope.edittingAllow = true;
                                $scope.loading = false;
                                if (res.data.success == true) {
                                    Notification.success($translate.instant("info.improvement.field.user.saved"));
                                    $scope.myPromise = $http.get("private/improvement-field-user/list.do?fieldId=" + i.id)
                                        .then(function (response) {
                                            $scope.impFieldUsers = response.data.data;
                                            $scope.loading = true;
                                        }, function (response) {
                                            //Second function handles error
                                            Notification.error(response.data.message);
                                        });
                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }
                            });
                    };

                    $scope.approveDelete = function (impFieldUser) {
                        $scope.dataLoading = true;
                        $scope.hasError = false;
                        $http({
                            method: 'POST',
                            url: 'private/improvement-field-user/approveDelete.do?id=' + impFieldUser.id,
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.impFieldData = {};
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                $scope.edittingAllow = true;
                                $scope.loading = false;
                                if (res.data.success == true) {
                                    Notification.success($translate.instant("info.improvement.field.user.deleted"));
                                    $scope.myPromise = $http.get("private/improvement-field-user/list.do?fieldId=" + i.id)
                                        .then(function (response) {
                                            $scope.impFieldUsers = response.data.data;
                                            $scope.loading = true;
                                        }, function (response) {
                                            //Second function handles error
                                            Notification.error(response.data.message);
                                        });
                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }
                            });
                    };

                    $scope.impUserRelationView = function (t, i, start, limit, impFieldUser) {
                        $rootScope.modalInstance = $uibModal.open({
                            animation: true, backdrop: 'static', backdrop: 'static',
                            controller: function ($scope, $uibModalInstance) {

                                $scope.impReview = i.data;
                                $scope.impTotal = i.total;
                                $scope.start = start;
                                $scope.limit = limit;
                                $scope.impFieldUser = impFieldUser;

                                $scope.Close = function () {
                                    $uibModalInstance.close();
                                };
                                $scope.deleteUser = function () {

                                    t.approveDelete($scope.impFieldUser);
                                    $uibModalInstance.close();

                                };

                            },
                            size: 'lg',
                            ariaLabelledBy: "modal-title",
                            ariaDescribedBy: "modal-body",
                            templateUrl: "impUserRelationView.html"
                        });
                    };
                    $scope.deleteImpFieldUser = function (impFieldUser) {
                        $scope.dataLoading = true;
                        $scope.hasError = false;
                        $http({
                            method: 'POST',
                            url: 'private/improvement-field-user/delete.do?id=' + impFieldUser.userId + "&fieldId=" + impFieldUser.fieldId + "&start=0&limit=500",
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.impFieldData = {};
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                $scope.edittingAllow = true;
                                $scope.loading = false;
                                if (res.data.success == true) {
                                    //  Notification.success($translate.instant("info.improvement.field.user.deleted"));
                                    $scope.impUserRelationView($scope, res.data, 0, 500, impFieldUser);

                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }
                            });
                    };
                    $scope.getUserList = function () {
                        var _url = "private/user/retrieveAllUsers.do";
                        var data = 'query=' + $scope.query + "&page=" + $scope.currentPage + "&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize;
                        $scope.myPromise = $http({
                            method: 'POST',
                            url: _url,
                            data: data,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                            }
                        })
                            .then(function (response) {
                                $scope.users = response.data.data;
                                $scope.loading = false;
                                $scope.showMaxUserInfo = false;
                                $scope.usersTotal = response.data.total;
                                $scope.displayRangeStart = $scope.currentIndex + 1;
                                var rangeEnd = $scope.currentIndex + $scope.pageSize;
                                $scope.displayRangeEnd = rangeEnd > $scope.usersTotal ? $scope.usersTotal : rangeEnd;

                            }, function (response) {
                                //Second function handles error
                                Notification.error(response.data.message);
                                $scope.loading = false;
                            });
                    };
                },
                size: 'lg',
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "impFieldUserModal.html"
            });
        };

        $scope.stringToDate = function (d) {
            $scope.date = new Date(d);
            return $scope.date;
        };
        $scope.openNewImprovementFieldModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true, backdrop: 'static', backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.departmentId = 0;
                    $scope.getAllDepartments = function () {
                        $http({
                            method: 'GET',
                            url: 'private/improvement/allDepartments.do',
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                if (res.data.success == true) {
                                    $scope.departments = res.data.data;
                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }

                            });
                    }

                    $scope.getAllDepartments();
                    $scope.clearDepartmentsSelected = function () {

                        for (var i = 0; i < $scope.departments.length; i++)
                            if ($scope.departments[i].departmentId == $scope.departmentId) {
                                $scope.departments[i].selected = false;
                                return;
                            }
                    }

                    $scope.selectDepartment = function (department) {

                        $scope.impFieldData.departmentName = department.userDepartmant;

                        if (department.departmentId != $scope.departmentId)
                            $scope.clearDepartmentsSelected();

                        department.selected ? department.selected = false : department.selected = true;
                        department.selected ? $scope.departmentId = department.departmentId : $scope.departmentId = 0;

                    };

                    $scope.Ok = function () {
                        $scope.impFieldData.departmentId = $scope.departmentId;
                        if ($scope.impFieldData == "" || $scope.impFieldData == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.impFieldData.titlePrimaryLang == "" || $scope.impFieldData.titlePrimaryLang == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.impFieldData.titleSecondaryLang == "" || $scope.impFieldData.titleSecondaryLang == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        }
                        /*
                        if ($scope.impFieldData.requiresManagerConfirmation == "" || $scope.impFieldData.requiresManagerConfirmation == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } */

                        $scope.saveNewImpField(t);
                        $scope.Close();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };
                    $scope.saveNewImpField = function (t) {
                        $scope.dataLoading = true;
                        $scope.hasError = false;
                        if ($scope.impFieldData.requiresManagerConfirmation === "1") {
                            $scope.impFieldData.requiresManagerConfirmation = true;
                        } else {
                            $scope.impFieldData.requiresManagerConfirmation = false;
                        }
                        $http({
                            method: 'POST',
                            url: 'private/improvement-field/save.do',
                            data: 'field=' + encodeURIComponent(JSON.stringify($scope.impFieldData)),
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                if (res.data.success == true) {
                                    Notification.success($translate.instant("info.improvement.field.saved"));
                                    t.getImprovementFieldList();
                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }

                            });
                    };
                },
                size: 'lg',
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "newImprovementField.html"
            });
        };
        $scope.openEditImprovementFieldModal = function (t, impFieldDatas) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true, backdrop: 'static', backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.impFieldData = angular.copy(impFieldDatas);
                    $scope.departmentId = impFieldDatas.departmentId;
                    $scope.getAllDepartments = function () {
                        $http({
                            method: 'GET',
                            url: 'private/improvement/allDepartments.do',
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                if (res.data.success == true) {
                                    $scope.departments = res.data.data;
                                    for (var i = 0; i < $scope.departments.length; i++) {
                                        if ($scope.departments[i].departmentId == $scope.impFieldData.departmentId) {
                                            $scope.departments[i].selected = true;
                                            return;
                                        }
                                    }
                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }

                            });
                    }

                    $scope.getAllDepartments();
                    $scope.clearDepartmentsSelected = function () {

                        for (var i = 0; i < $scope.departments.length; i++)
                            if ($scope.departments[i].departmentId == $scope.departmentId) {
                                $scope.departments[i].selected = false;
                                return;
                            }
                    }

                    $scope.selectDepartment = function (department) {
                        $scope.impFieldData.departmentName = department.userDepartmant;
                        if (department.departmentId != $scope.departmentId)
                            $scope.clearDepartmentsSelected();

                        department.selected ? department.selected = false : department.selected = true;
                        department.selected ? $scope.departmentId = department.departmentId : $scope.departmentId = 0;
                    };

                    $scope.Ok = function () {
                        $scope.impFieldData.departmentId = $scope.departmentId;
                        if ($scope.impFieldData == "" || $scope.impFieldData == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.impFieldData.titlePrimaryLang == "" || $scope.impFieldData.titlePrimaryLang == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.impFieldData.titleSecondaryLang == "" || $scope.impFieldData.titleSecondaryLang == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        }
                        /*
                        if ($scope.impFieldData.requiresManagerConfirmation == "" || $scope.impFieldData.requiresManagerConfirmation == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } */

                        $scope.saveNewImpField(t);
                        $scope.Close();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };
                    $scope.saveNewImpField = function (t) {
                        $scope.dataLoading = true;
                        $scope.hasError = false;
                        if ($scope.impFieldData.requiresManagerConfirmation === "1") {
                            $scope.impFieldData.requiresManagerConfirmation = true;
                        } else {
                            $scope.impFieldData.requiresManagerConfirmation = false;
                        }
                        $http({
                            method: 'POST',
                            url: 'private/improvement-field/save.do',
                            data: 'field=' + encodeURIComponent(JSON.stringify($scope.impFieldData)),
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                if (res.data.success == true) {
                                    Notification.success($translate.instant("info.improvement.field.saved"));
                                    t.getImprovementFieldList();
                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }

                            });
                    };
                },
                size: 'lg',
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "newImprovementField.html"
            });
        };


        // IMPROVEMENT FIELD USER LIST
        $scope.getTemplateImpFieldUser = function (i) {
            return 'displayImpFieldUser';
        };
        $scope.findFieldName = function (fieldId) {

            if (fieldId == 0)
                return $translate.instant("label.improvement.all.field");

            console.log(fieldId);
            for (var i = 0; i < $scope.impFields.length; i++) {
                if ($scope.impFields[i].id == fieldId)
                    return $scope.impFields[i].titlePrimaryLang;
            }

        }

        $scope.deleteImpFieldUser = function (impFieldUser) {
            $scope.dataLoading = true;
            $scope.hasError = false;
            $http({
                method: 'POST',
                url: 'private/improvement-field-user-no-field-relation/delete.do?id=' + impFieldUser.id,
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.impFieldData = {};
                    $scope.dataLoading = false;
                    $scope.msg = res.data.message;
                    $scope.edittingAllow = true;
                    $scope.loading = false;
                    if (res.data.success == true) {
                        Notification.success($translate.instant("info.improvement.field.user.deleted"));
                        $scope.myPromise = $http.get("private/improvement-field-user/list-by-type-no-field.do?authType=8" + "&page=" + $scope.currentPage + "&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                            .then(function (response) {
                                $scope.impFieldUserList = response.data.data;
                                $scope.loading = true;
                            }, function (response) {
                                //Second function handles error
                                Notification.error(response.data.message);
                            });
                    } else {
                        $scope.hasError = true;
                        Notification.warning($scope.msg);
                        $scope.dataLoading = false;
                    }
                });
        };

        $scope.deleteImprovementFieldUser = function (i) {
            if (i) {
                $scope.impFieldUserCommitteeData = angular.copy(i);
                $scope.impFieldUserCommitteeData.active = 0;
                $scope.deleteImpFieldUser($scope.impFieldUserCommitteeData);
            }
        };
        $scope.deleteFieldSure = function (t, fu) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true, backdrop: 'static', backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {
                        t.deleteImprovementField(fu);
                        $scope.Close();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "deleteFieldSureModal.html"
            });
        };


        $rootScope.getImprovementFieldUserList = function () {
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $scope.myPromise = $http.get("private/improvement-field-user/list-by-type-no-field.do?authType=8" + "&page=" + $scope.currentPage + "&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                .then(function (response) {
                    $scope.impFieldUserList = response.data.data;
                    console.log($scope.impFieldUserList);
                    $scope.impFieldUserListTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.currentPage = page;
                    var rangeEnd = $scope.currentIndex + $scope.pageSize;
                    $scope.displayRangeEnd = rangeEnd > $scope.impFieldUserListTotal ? $scope.impFieldUserListTotal : rangeEnd;
                    $scope.loading = false;
                    $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
                }, function (response) {
                    //Second function handles error
                    Notification.error(response.data.message);
                });

        };

        $scope.openNewImprovementFieldUserModal = function (t, impFieldList, impFieldUserList, impFields, impFieldUser) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true, backdrop: 'static', backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.impFields = impFields;
                    $scope.impFieldUser = impFieldUser;
                    $rootScope.impFieldUserList = impFieldUserList;
                    $scope.loading = true;
                    $scope.users = [];
                    $scope.pagination = { currentPage: 1 };
                    $scope.pageSize = 10;
                    $scope.currentIndex = 0;
                    $scope.currentPage = 0;
                    $scope.query = "";
                    console.log("----:" + impFieldList);
                    $scope.impFieldList = impFieldList;
                    console.log("----++++:" + $scope.impFieldList);
                    $scope.openAssigneeTykModal = function (t, user, authType, impFieldList) {
                        $rootScope.modalInstance = $uibModal.open({
                            animation: true, backdrop: 'static', backdrop: 'static',
                            controller: function ($scope, $uibModalInstance) {
                                $scope.pageSizeCategory = 50;
                                $scope.pagingMaxSize = 4;
                                $scope.currentIndexCategory = 0;
                                $scope.calcindexCategory = 0;
                                $scope.pageSize = 50;
                                $scope.edittingAllowCategory = true;
                                $scope.currentIndex = 0;
                                $scope.calcindex = 0;
                                $scope.currentPageCategory = 1;
                                $scope.pagination = { currentPage: 1 };
                                $scope.edittingAllow = true;
                                $scope.impFieldList = impFieldList;
                                $scope.impFieldUserData = {
                                    "userId": user.id,
                                    "fieldId": 0,
                                    "authType": authType
                                };

                                $scope.Ok = function () {
                                    //////////////////////////////////////////////////

                                    $scope.dataLoading = true;
                                    $scope.hasError = false;

                                    $http({
                                        method: 'POST',
                                        url: 'private/improvement-field-user/save.do',
                                        data: 'fieldUser=' + encodeURIComponent(JSON.stringify($scope.impFieldUserData)),
                                        headers: {
                                            "content-type": "application/x-www-form-urlencoded"
                                        }
                                    })
                                        .then(function (res) {
                                            $scope.impFieldUserCommitteeData = {};
                                            $scope.dataLoading = false;
                                            $scope.msg = res.data.message;
                                            $scope.edittingAllow = true;
                                            $scope.loading = false;
                                            if (res.data.success == true) {
                                                Notification.success($translate.instant("info.improvement.field.user.committee.saved"));
                                                var page = $location.search().page || 1;
                                                $scope.currentIndex = (page - 1) * $scope.pageSize;
                                                $scope.myPromise = $http.get("private/improvement-field-user/list-by-type-no-field.do?authType=8" + "&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                                                    .then(function (response) {
                                                        $rootScope.impFieldUserList = response.data.data;
                                                        console.log($scope.impFieldUserList);
                                                        $scope.impFieldUserListTotal = response.data.total;
                                                        $scope.displayRangeStart = $scope.currentIndex + 1;
                                                        $scope.currentPage = page;
                                                        var rangeEnd = $scope.currentIndex + $scope.pageSize;
                                                        $scope.displayRangeEnd = rangeEnd > $scope.impFieldUserListTotal ? $scope.impFieldUserListTotal : rangeEnd;
                                                        $scope.loading = true;
                                                        $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
                                                    },
                                                        function (response) {
                                                            Notification.error(response.data.message);
                                                        });
                                            } else {
                                                $scope.hasError = true;
                                                Notification.warning($scope.msg);
                                                $scope.dataLoading = false;
                                            }
                                            $scope.Close();
                                        });
                                    //////////////////////////////////////////////////

                                };
                                $scope.Close = function () {
                                    $uibModalInstance.close();
                                };

                            },
                            ariaLabelledBy: "modal-title",
                            ariaDescribedBy: "modal-body",
                            templateUrl: "addTyk.html"
                        });
                    };

                    $scope.Close = function () {
                        t.getImprovementFieldUserList();
                        $uibModalInstance.close();
                    };
                    $scope.pageChanged = function () {
                        $scope.currentIndex = ($scope.pagination.currentPage - 1) * $scope.pageSize;
                        $scope.getUserList();
                    };
                    $scope.clearSearch = function () {
                        $scope.query = "";
                        $scope.getUserList();
                    };


                    $scope.saveImpFieldUser = function (user, authType) {
                        $scope.openAssigneeTykModal(this, user, authType, $scope.impFieldList);
                    };

                    $scope.getUserList = function () {
                        var _url = "private/user/retrieveAllUsers.do";
                        var data = 'query=' + $scope.query + "&page=" + $scope.currentPage + "&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize;
                        $scope.myPromise = $http({
                            method: 'POST',
                            url: _url,
                            data: data,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                            }
                        })
                            .then(function (response) {
                                $scope.users = response.data.data;
                                $scope.loading = false;
                                $scope.showMaxUserInfo = false;
                                $scope.usersTotal = response.data.total;
                                $scope.displayRangeStart = $scope.currentIndex + 1;
                                var rangeEnd = $scope.currentIndex + $scope.pageSize;
                                $scope.displayRangeEnd = rangeEnd > $scope.usersTotal ? $scope.usersTotal : rangeEnd;

                            }, function (response) {
                                Notification.error(response.data.message);
                                $scope.loading = false;
                            });
                    };

                    $scope.deleteImpFieldUser = function (impFieldUser) {
                        $scope.dataLoading = true;
                        $scope.hasError = false;
                        $http({
                            method: 'POST',
                            url: 'private/improvement-field-user-no-field-relation/delete.do?id=' + impFieldUser.id,
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.impFieldData = {};
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                $scope.edittingAllow = true;
                                $scope.loading = false;
                                if (res.data.success == true) {
                                    Notification.success($translate.instant("info.improvement.field.user.deleted"));
                                    $scope.myPromise = $http.get("private/improvement-field-user/list-by-type-no-field.do?authType=8" + "&page=" + $scope.currentPage + "&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                                        .then(function (response) {
                                            $rootScope.impFieldUserList = response.data.data;
                                            $scope.loading = true;
                                        }, function (response) {
                                            //Second function handles error
                                            Notification.error(response.data.message);
                                        });
                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }
                            });
                    };

                    $scope.deleteImprovementFieldUser = function (i) {
                        if (i) {
                            $scope.impFieldUserCommitteeData = angular.copy(i);
                            $scope.impFieldUserCommitteeData.active = 0;
                            $scope.deleteImpFieldUser($scope.impFieldUserCommitteeData);
                        }
                    };

                    $scope.getTemplateImpFieldUser = function (i) {
                        return 'displayImpFieldUser';
                    };
                    $scope.findFieldName = function (fieldId) {

                        if (fieldId == 0)
                            return $translate.instant("label.improvement.all.field");

                        console.log(fieldId);
                        for (var i = 0; i < $scope.impFields.length; i++) {
                            if ($scope.impFields[i].id == fieldId)
                                return $scope.impFields[i].titlePrimaryLang;
                        }

                    }
                },
                size: 'lg',
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "impFieldUserNewModal.html"
            });
        };

    }
]);

app.filter('impFieldAuthType', function () {
    return function (input) {
        switch (input) {
            case 1:
                return 'imp.field.auth.auditor';
            case 2:
                return 'imp.field.auth.manager';
            case 3:
                return 'imp.field.auth.responsible';
            case 4:
                return 'imp.field.auth.mentor';
            case 5:
                return 'imp.field.auth.expert';
            case 6:
                return 'imp.field.auth.implementer';
            default:
                return 'imp.field.auth.none';
        }
    };
});

