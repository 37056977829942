app.controller("reportImprovementUnitCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate', 'Notification', '$uibModal', 'dateFormat',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, Notification, $uibModal, dateFormat) {


        /*Init */
        $scope.isDetail = false;
        $scope.loading = false;
        $scope.pageSize = 50;
        $scope.pagingMaxSize= 4;
        $scope.clearFilter = function () {
            var date = new Date();
            var year = date.getFullYear();
            year = year - 1;
            date.setFullYear(year);
            $scope.basTarih = date;
            $scope.bitTarih = new Date();
            $scope.query = '';
        };
        $scope.clearFilter();
        $scope.init = function () {
            $scope.loading = true;
            $scope.getUnitReport();
        };
        $scope.dates = {};
        
     
        $scope.generateChar = function(){
            $scope.charData = [];
            $scope.charLabel = [];
            if( $scope.unitData.length>0){
                $scope.optionsValue = {responsive: true,colors : ['#4D5360']};
                $scope.colors = ['#7FDBFF', '#3498DB', '#717984', '#F1C40F','#72C02C', '#3498DB', '#FF4136', '#F1C40F','#3498DB', '#717984', '#F1C40F','#72C02C', '#3498DB', '#717984', '#F1C40F'];
            }
            for(var i = 0 ;  i<$scope.unitData.length ; i++){
                $scope.charData.push(" "+$scope.unitData[i].improvementCount);
                $scope.charLabel.push($scope.unitData[i].userDepartmant);
            }          
        }

        /*Functions*/
        $scope.getUnitReport = function () {
            var basTarih = dateFormat.formatter($scope.basTarih);
            var bitTarih = dateFormat.formatter($scope.bitTarih);
            $scope.dates.basTarih = $scope.basTarih;
            $scope.dates.bitTarih = $scope.bitTarih;
            if (typeof $scope.query == 'undefined') $scope.query = '';
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
           $scope.myPromise = $http.get("private/improvementUnitReport.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize + "&problemState=&basTar=" + basTarih + "&bitTar=" + bitTarih+ "&query=" + $scope.query)
                .then(function (response) {
                    $scope.unitData = response.data.data;
                    $scope.unitTotal = response.data.total;
                    $scope.loading = false;
                    $scope.generateChar();
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.pageChanged = function () {

            $scope.currentIndex = ($scope.currentPage - 1) * $scope.pageSize;
            $location.path('/report-improvement-unit').search('page', $scope.currentPage);

        };

        /* DatePicker Setup*/

        $scope.clear = function () {
            $scope.dt = null;
        };

        $scope.inlineOptions = {
            customClass: getDayClass,
            minDate: new Date(),
            showWeeks: true
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2035, 5, 22),
            startingDay: 1
        };

        $scope.toggleMin = function () {
            $scope.inlineOptions.minDate = $scope.inlineOptions.minDate ? null : new Date();
            $scope.dateOptions.minDate = $scope.inlineOptions.minDate;
        };

        $scope.toggleMin();

        $scope.open1 = function () {
            $scope.popup1.opened = true;
        };

        $scope.open2 = function () {
            $scope.popup2.opened = true;
        };

        $scope.setDate = function (year, month, day) {
            $scope.dt = new Date(year, month, day);
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[3];
        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.popup1 = {
            opened: false
        };

        $scope.popup2 = {
            opened: false
        };

        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var afterTomorrow = new Date();
        afterTomorrow.setDate(tomorrow.getDate() + 1);
        $scope.events = [
            {
                date: tomorrow,
                status: 'full'
            },
            {
                date: afterTomorrow,
                status: 'partially'
            }
        ];

        function getDayClass(data) {
            var date = data.date,
                mode = data.mode;
            if (mode === 'day') {
                var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

                for (var i = 0; i < $scope.events.length; i++) {
                    var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

                    if (dayToCheck === currentDay) {
                        return $scope.events[i].status;
                    }
                }
            }
        }

        /* Excel */

        $scope.excelExport = function () {

            var basTarih = new Date($scope.basTarih);
            basTarih = basTarih.toISOString();
            var bitTarih = new Date($scope.bitTarih);
            bitTarih = bitTarih.toISOString();
            var sortParam = [{ root: "data", transform: null, sort: null },
            { property: "name", root: "data", transform: null, sort: null }];
            if (typeof $scope.query == 'undefined') $scope.query = '';
            $scope.excelPromise = $http.get("private/exportExcelImpUnitReport.htm?basTar=" + basTarih + "&bitTar=" + bitTarih + '&sort=' + encodeURIComponent(JSON.stringify(sortParam))+"&query=" + $scope.query,{responseType: 'arraybuffer'})
                .then(function (response) {

                     var header = response.headers('Content-disposition')
                     var fileName = header.split("=")[1].replace(/\"/gi,'');
                   
                    var blob = new Blob([response.data],
                        {type : 'application/vnd.ms-excel;charset=utf-8'});
                    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                    var link = angular.element('<a/>');
                    link.attr({
                        href : objectUrl,
                        download : fileName
                    })[0].click();

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        }

    }]);
  
    //$scope.pageSizeCont = 10;
   // $scope.limitCont = 10;