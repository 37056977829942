app.controller("reportPortfolioCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate', 'Notification', 'dateFormat',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, Notification, dateFormat) {


        /*Init */
        $scope.lang = $rootScope.lang;
        $scope.$watch('$rootScope', function (newValue, oldValue) {
            $scope.lang = $rootScope.lang;
        });
        $scope.init = function () {
            $scope.getSuggestionReport();
        };
        /*Default Variables*/
        $scope.clearFilter = function () {
            var date = new Date();
            var year = date.getFullYear();
            year = year - 1;
            date.setFullYear(year);
            $scope.basTarih = date;
            $scope.bitTarih = new Date();
        };
        $scope.clearFilter();
       
        /*Functions*/
        $scope.getSuggestionReport = function () {
            var tarih = {};
            tarih.basTarih = dateFormat.formatter($scope.basTarih);
            tarih.bitTarih = dateFormat.formatter($scope.bitTarih);

            $http({
                method: 'POST',
                url: 'private/projectPortfolioReport.do',
                data: 'market=' + encodeURIComponent(JSON.stringify(tarih)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.suggObj = res.data;
                        $scope.projectData = res.data.data;
                        generateCharts();

                    }
                });
        };
        $scope.getLetterAt = function (location) {
            var alphabet = "ABCDEFGHIJKLMNOPRSTUVYZ".split("");
            if (location < alphabet.length) {
                return alphabet[location];
            } else {
                return 'X';
            }
        };
       
        var generateCharts = function () {
            $scope.labelValue = [];
            $scope.dataValue = [];
            $scope.optionsValue = {};
            $scope.labelDurum = [];
            $scope.dataDurum = [];
            $scope.optionsDurum = {};
            $scope.series = {};
            $scope.colors = {};
            if ($scope.projectData) {
                var chartOptions = {responsive: true,scales: {yAxes: [{display: true,ticks: {beginAtZero: true}}]},colors : ['#4D5360']};
                $scope.labelDurum = [$translate.instant('project.state.not.started'),$translate.instant('project.state.inprogress'),$translate.instant('project.state.paused'),$translate.instant('project.state.completed'),$translate.instant('project.state.canceled')];
                $scope.dataDurum = [$scope.projectData.p_noStarted, $scope.projectData.p_started, $scope.projectData.p_paused, $scope.projectData.p_completed, $scope.projectData.p_canceled];
                $scope.colors = ['#72C02C', '#3498DB', '#717984', '#F1C40F'];
                $scope.labelValue = [$translate.instant('label.project.budget'),$translate.instant('label.project.benefit')];
                $scope.dataValue = [[$scope.projectData.plannedBudget_0, $scope.projectData.plannedBenefit_0 ], [$scope.projectData.actualBudget_0, $scope.projectData.actualBenefit_0]];
                $scope.series = [$translate.instant('label.project.planned'), $translate.instant('label.project.actual')];
                $scope.optionsValue = chartOptions;
            } else {
                $scope.labelValue.push($translate.instant('label.no.data'));
                $scope.dataValue.push(0);
                 $scope.labelDurum.push($translate.instant('label.no.data'));
                $scope.dataDurum.push(0);
            }

        };
        var formatDate = function (a) {

            if (typeof (a) != 'string') {
                var dd = a.getDate();
                var mm = a.getMonth() + 1; //January is 0!

                var yyyy = a.getFullYear();
                if (dd < 10) {
                    dd = '0' + dd;
                }
                if (mm < 10) {
                    mm = '0' + mm;
                }
                var date = mm + '/' + dd + '/' + yyyy;
                return date;
            }
            return a;
        };

        $scope.formatTLCurrency = function(price){

            var currency_symbol = "₺"
 
            var formattedOutput = new Intl.NumberFormat('tr-TR', {
                style: 'currency',
                currency: 'TRY',
                minimumFractionDigits: 2,
            });
            return formattedOutput.format(price).replace(currency_symbol, '') + currency_symbol;
        }
        
        /* DatePicker Setup*/
        $scope.open1 = function () {
            $scope.popup1.opened = true;
        };
        $scope.popup1 = {
            opened: false
        };
        $scope.open2 = function () {
            $scope.popup2.opened = true;
        };
        $scope.popup2 = {
            opened: false
        };
        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[3];
        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2035, 5, 22),
             startingDay: 1
        };


    }]);