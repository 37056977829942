app.controller("reportImprovementCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate', 'Notification', 'dateFormat',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, Notification, dateFormat) { 


    $scope.loading = false;     
    $scope.filterfieldId = "";

       $scope.sortStr = $location.search().sort || "id desc";

         /*Init */
         $scope.lang = $rootScope.lang;
         $scope.$watch('$rootScope', function (newValue, oldValue) {
             $scope.lang = $rootScope.lang;
         });
         $scope.init = function () {
             $scope.getImprovementReport();
         };

        $scope.fieldFunction = function(fieldId){


            $scope.filterfieldId = fieldId;
            if($scope.filterfieldId == undefined || $scope.filterfieldId == -1){
                $scope.filterfieldId ="";
            }
        }
        function selectFilters(arr, selected) {
            if (arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                    arr[i].selected = false;
                    for (var j = 0; j < selected.length; j++) {
                        if (arr[i].id == selected[j]) {
                            arr[i].selected = true;
                            break;
                        }
                    }
                }
            }
        }
        
       /*Default Variables*/
        $scope.clearFilter = function () {
           var date = new Date();
            var year = date.getFullYear();
            year = year - 1;
            date.setFullYear(year);
            $scope.basTarih = date;
            $scope.bitTarih = new Date();
            $scope.filterfieldId = "";
            $location.url($location.path()); 
            
           //location.reload($location.path());
        };
       $scope.clearFilter();

   /* $scope.dateFunction = function () {
        var date = new Date();
        var year = date.getFullYear();
        year = year - 1;
        date.setFullYear(year);
        $scope.basTarih = date;
        $scope.bitTarih = new Date();
    };

    $scope.dateFunction();*/
       
         /*Functions*/
         $scope.getImprovementReport = function () {

            var tarih = {};
            tarih.basTarih = dateFormat.formatter($scope.basTarih);
            tarih.bitTarih = dateFormat.formatter($scope.bitTarih);

            $http({
                method: 'POST',
                url: 'private/improvementReport.do',
                data: 'market=' + encodeURIComponent(JSON.stringify(tarih)) + '&fieldFilters=' + $scope.filterfieldId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.impData = res.data.data;
                        generateCharts();
                        

                    }
                });

                
        };

        var getTotalCounts = function (){
            $scope.totalForStateDistribution = 0;
            $scope.totalForFiedlDistribution = 0;
            $scope.totalForCategoryDistribution = 0;
            for(var i = 0 ; i< $scope.dataDurum.length ; i++ )
                $scope.totalForStateDistribution += $scope.dataDurum[i];
            
            for(var i = 0 ; i< $scope.dataAlan.length ; i++ )
                $scope.totalForFiedlDistribution += $scope.dataAlan[i];
            
            for(var i = 0 ; i< $scope.dataCategory.length ; i++ )
                $scope.totalForCategoryDistribution += $scope.dataCategory[i]
            

        }

        var generateCharts = function () {
            $scope.labelAlan = [];
            $scope.dataAlan = [];
            $scope.labelCategory = [];
            $scope.dataCategory = [];
            $scope.optionsAlan = {};
            $scope.labelDurum = [];
            $scope.dataDurum = [];
            $scope.labelMainUser = [];
            $scope.dataMainUser = [];
            $scope.optionsDurum = {};
            if ($scope.impData) {
                for (var index = 0; index < $scope.impData.improvementFieldReportList.length; index++) {
                    $scope.labelAlan.push(($scope.lang == "en" ? $scope.impData.improvementFieldReportList[index].titleSecondaryLang : $scope.impData.improvementFieldReportList[index].titlePrimaryLang));
                    $scope.dataAlan.push($scope.impData.improvementFieldReportList[index].total);
                }
                for (var index = 0; index < $scope.impData.improvementCategoryReportList.length; index++) {
                    $scope.labelCategory.push(($scope.lang == "en" ? $scope.impData.improvementCategoryReportList[index].name_en : $scope.impData.improvementCategoryReportList[index].name));
                    $scope.dataCategory.push($scope.impData.improvementCategoryReportList[index].total);
                }
                for (var index = 0; index < $scope.impData.improvementMainUserReportList.length; index++) {
                    $scope.labelMainUser.push($scope.impData.improvementMainUserReportList[index].departmentName);
                    $scope.dataMainUser.push($scope.impData.improvementMainUserReportList[index].total);
                }
                var chartOptions = {responsive: true,scales: {yAxes: [{display: true,ticks: {beginAtZero: true}}]}};
                $scope.optionsAlan = chartOptions;
               
                for (var index = 0; index < $scope.impData.stateCountReports.length; index++) {
           
                    $scope.labelDurum.push($translate.instant('improvement.status.'+  $scope.impData.stateCountReports[index].state));
                    $scope.dataDurum.push($scope.impData.stateCountReports[index].count);
            }



                $scope.optionsDurum = chartOptions;
            } else {
                $scope.labelAlan.push("Veri yok");
                $scope.dataAlan.push(0);
                $scope.labelCategory.push("Veri yok");
                $scope.dataCategory.push(0);
                 $scope.labelDurum.push("Veri yok");
                $scope.dataDurum.push(0);tr
            }
            getTotalCounts();
        };

        $scope.getLetterAt = function (location) {
            var alphabet = "ABCDEFGHIJKLMNOPRSTUVYZ".split("");
            if (location < alphabet.length) {
                return alphabet[location];
            } else {
                return 'X';
            }
        };

         /* DatePicker Setup*/
         $scope.open1 = function () {
            $scope.popup1.opened = true;
        };
        $scope.popup1 = {
            opened: false
        };
        $scope.open2 = function () {
            $scope.popup2.opened = true;
        };
        $scope.popup2 = {
            opened: false
        };
        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[3];
        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2035, 5, 22),
             startingDay: 1
        };

        $scope.getImprovementFilterList = function () {
            $scope.cgImpFilterListResult = $http.get("private/improvement/getFilterListReport.do")
                .then(function (response) {
                    $scope.improvementFilterFieldList = response.data.data.improvementFieldList


                    /*$scope.query = $location.search().query || '';
                    $scope.sortStr = $location.search().sort || 'id desc';*/
                    $scope.selectedFilterField = ($location.search().fieldFilters && $location.search().fieldFilters.split(',')) || [];
                    selectFilters($scope.improvementFilterFieldList, $scope.selectedFilterField);
                }, function (response) {

                    Notification.error('Error: stateList');
                });
        };


        $scope.getImprovementFilterList();
      //  $scope.getImprovementReport();

        $scope.sort = function (key, type) {
            $scope.sortStr = key + ' ' + type;
            $location.search().sort = $scope.sortStr;
            $scope.getImprovementReport();
        };



    }]);