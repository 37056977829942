app.directive("improvementOwners", [
  "$http",
  "$translate",
  "Notification",
  "$uibModal",
  "$rootScope",
  "$filter",
  function ($http, $translate, Notification, $uibModal, $rootScope,$filter) {
    return {
      restrict: "E",
      scope: {
        improvementOwners: "=",
        commands: "=",
        showToggle: "=",
        commandType:"="
      },
      templateUrl: "partial/directive-imp/improvement-owners.html",
      controller: function ($scope) {
     
        $scope.isLoading = false;
        $scope.$watch('commands', function (newVal) {
          if (newVal) {
            $scope.availableCommands = $scope.commands;
            $scope.show=false;
        
            for(var i =0; i < $scope.availableCommands.length ; i++){
              if( $scope.availableCommands[i] === $scope.commandType  ){
                $scope.show=true;
              }
            }
             
          }
        });

      }

    };
  }
]);


