app.controller('protectedCtrl', ['$http', '$scope', '$sce', 'Notification', '$location', "$cookies", function ($http, $scope, $sce, Notification, $location, $cookies) {


    $scope.slideInterval = 10000;
    $scope.noWrapSlides = false;
    $scope.active = 0;
    $scope.activeCamp = 0;
    $scope.leaders = [];
    $scope.showLeadersPanel = false;
    $scope.showPointsPanel = true;
    $scope.showSuggButton = true;
    //$scope.levelName1 = $rootScope.frontendConfig.levelName1;
    //$scope.levelName2 = $rootScope.frontendConfig.levelName2;
    //$scope.levelName3 = $rootScope.frontendConfig.levelName3;

    $scope.getCampaigns = function(){
        //kampanyaları getir
        $http.get("private/campaign/retrieveCampaignsUser.do?start=0&limit=5&objectType=0")
            .then(function (response) {
                var campaignsSlides = response.data.data;

                for(var i = 0 ; i< campaignsSlides.length;i++){
                    var slideObject = {
                        type: "campaign",
                        evaluateDate: new Date(campaignsSlides[i].actualEndDate),
                        code: campaignsSlides[i].code,
                        name: campaignsSlides[i].name,
                        id: campaignsSlides[i].id,
                        objectType: campaignsSlides[i].objectType,
                        reason: campaignsSlides[i].reason,
                        status: campaignsSlides[i].status,
                        description: campaignsSlides[i].subject
                    }
                    $scope.slides.push(slideObject);
                }

              
           //     $scope.slides = [];
            //    $scope.slides = angular.copy($scope.slides2);
    
            }, function (response) {
                Notification.error('hata!');
                
            });
     
    }
    $http.get("private/market/retrieveProblemModel.do?marketState=O&problemState=&page=1&start=0&limit=25")
        .then(function (response) {
            $scope.slides = response.data.data;
            for (var i = 0; i < $scope.slides.length; i++) {
                var deadline = new Date($scope.slides[i].evaluateDate);
                deadline.setDate(deadline.getDate() - 1); //dd.MM.YYYY 23:59
               // $scope.slides[i].evaluateDate = deadline;
                $scope.slides[i].orderDate= deadline.getTime();
                var pid = $scope.slides[i].id;
                $http.get("private/market/retrieveProblemReward.do?problemId=" + pid + "&start=0&limit=25")
                    .then(function (response) {
                        var r = response.data.data;
                        if (r != undefined && r.length > 0) {

                            for (var j = 0; j < $scope.slides.length; j++) {
                                if ($scope.slides[j].id == r[0].problemId) {
                                    $scope.slides[j].rewards = r;
                                    break;
                                }
                            }
                        }
                    }, function (response) {

                    });
            }
            $scope.getCampaigns();
        }, function (response) {
            //Second function handles error

        });

     $scope.getNewsList = function(){
            var path = "private/news/getList.do?start=0&limit=4";
            $http.get(path)
                .then(function (response) {
                    $scope.newsList = response.data.data;
                }, function (response) {
                    //Second function handles error
                  //  Notification.error('hata!');
                });
        };

    $scope.loadSuggestionLeaders = function () {
        $http.get("private/user/retrieveLeaders.do?_dc=1487853345795&type=2&page=1&start=0&limit=25")
            .then(function (response) {
                //$scope.suggestionLeaders = response.data.data;
                if ($scope.frontendConfig.suggestionEnabled) {
                    $scope.leaders[1] = { title: 'label.leaders.suggestion.cut', data: response.data.data };
                    $scope.showLeadersPanel = true;
                } else {
                    $scope.leaders[1] = {};
                }
            }, function (response) {
                //Second function handles error
                //alert('hata!');
            });
    };
    $scope.loadPointLeaders = function () {
        $http.get("private/user/retrieveLeaders.do?_dc=1487853345795&type=5&page=1&start=0&limit=25")
            .then(function (response) {
                //$scope.pointLeaders = response.data.data;
                $scope.leaders[0] = { title: 'label.leaders.point', data: response.data.data };
                $scope.showLeadersPanel = true;
            }, function (response) {
                //Second function handles error
                //alert('hata!');
            });
    };
    $scope.loadCommentLeaders = function () {
        $http.get("private/user/retrieveLeaders.do?_dc=1487853345795&type=3&page=1&start=0&limit=25")
            .then(function (response) {
                //$scope.commentLeaders = response.data.data;
                $scope.leaders[4] = { title: 'label.leaders.comment', data: response.data.data };
                $scope.showLeadersPanel = true;
            }, function (response) {
                //Second function handles error
                //alert('hata!');
            });
    };
    $scope.loadIdeaLeaders = function () {
        $http.get("private/user/retrieveLeaders.do?_dc=1487853345795&type=1&page=1&start=0&limit=25")
            .then(function (response) {
                //$scope.ideaLeaders = response.data.data;
                if ($scope.frontendConfig.competitionEnabled) {
                    $scope.leaders[3] = { title: 'label.leaders.idea', data: response.data.data };
                    $scope.showLeadersPanel = true;
                } else {
                    $scope.leaders[3] = {};
                }
            }, function (response) {
                //Second function handles error
                //alert('hata!');
            });
    };
    $scope.loadImprovementLeaders = function () {
        $http.get("private/user/retrieveLeaders.do?_dc=1487853345795&type=6&page=1&start=0&limit=25")
            .then(function (response) {
                //$scope.ideaLeaders = response.data.data;
                if ($scope.frontendConfig.improvementEnabled) {
                    $scope.leaders[2] = { title: 'label.improvements.cut', data: response.data.data };
                    $scope.showLeadersPanel = true;
                } else {
                    $scope.leaders[2] = {};
                }
            }, function (response) {
                //Second function handles error
                //alert('hata!');
            });
    };
    $scope.getSuggestions = function () {
        if (typeof $scope.query == 'undefined') $scope.query = '';
        $http.get("private/suggestion/retrieveModelByUser.do?query=&suggType=&suggClass=&suggState=&page=1&start=0&limit=5&sortState=&suggClass=&roleFilters=")
            .then(function (response) {
                $scope.suggestions = response.data.data;
                $scope.suggTotal = response.data.total;
            }, function (response) {
                //Second function handles error
                //alert('hata!');
            });
    };
    $scope.getImprovements = function () {
        $scope.sortStr = 'id desc';
        $http.get("private/improvement/list.do?start=0&limit=5&roleFilters=" + "&stateFilters=&categoryFilters=&campaignFilters=&fieldFilters=&query=&sort=" + $scope.sortStr)
            .then(function (response) {
                $scope.improvements = response.data.data;
                $scope.impTotal = response.data.total;
            }, function (response) {
                //Second function handles error
                //alert('hata!');
            });
    };
    // 3: Comment leaders
    // 5: point
    $scope.scoreChartData = [
        [],
        []
    ];
    $scope.scoreChartLabels = ['Suggestion', 'Suggestion Comment', 'Suggestion Rating', 'Idea', 'Idea Comment', 'Idea Rating'];
    $scope.scoreChartOptions = { legend: { display: true } };
    $scope.scoreChartLegend = '';
    $scope.$on('chart-create', function (event, chart) {
        var legend = chart.generateLegend();
        $scope.scoreChartLegend = $sce.trustAsHtml(legend);
   
    });
    $scope.loadPoints = function () {
        //private/user/retrievePoints.do?_dc=1488270205528&isByUser=1&page=1&start=0&limit=25
        $http.get("private/user/retrievePoints.do?isByUser=1&page=1&start=0&limit=25&userIdObj=0")
            .then(function (response) {
                if (response.data.data)
                    $scope.points = response.data.data[0];
                else $scope.points = {};
                var p = $scope.points;
                //if(p != null && p.totalPoints != null){
                //    $scope.showPointsPanel = true;
                //}
                if ($scope.frontendConfig.competitionEnabled === false) {
                    p.totalPoints -= p.ideaPoints;
                    p.totalComments -= p.ideaCount;
                }
                if ($scope.frontendConfig.suggestionEnabled === false) {
                    p.totalPoints -= p.suggestionPoints;

                    p.totalPoints -= p.suggestionEditorPoints;
                    p.totalPoints -= p.suggestionExpertPoints;

                    p.totalComments -= p.suggestionCount;

                    p.totalComments -= p.completedSuggCount;
                    p.totalComments -= p.finalAcceptedSuggCount;
                    p.totalComments -= p.firstRejectedSuggCount;
                    p.totalComments -= p.lastRejectedSuggCount;
                    p.totalComments -= p.preAcceptedSuggCount;
                    p.totalComments -= p.preRejectedSuggCount;
                    p.totalComments -= p.totalSuggestionCount;
                }
                if($scope.frontendConfig.improvementEnabled === false){
                    p.totalPoints -= p.suggestionPoints;
                }
                $scope.scoreChartCountData = [
                    p.suggestionCount,
                    p.suggCommentCount,
                    p.suggRatingCount,
                    p.ideaCount,
                    p.commentCount,
                    p.ratingCount
                ];
                $scope.scoreChartPointData = [
                    p.suggestionPoints,
                    p.suggestionCommentPoints,
                    p.suggRatingPoints,
                    p.ideaPoints,
                    p.commentPoints,
                    p.ideaRatingPoints
                ];

            }, function (response) {
                //Second function handles error
                //alert('hata!');
            });
    };
    $scope.loadPointsByType = function () {
        //private/user/retrievePointsByType.do?_dc=1488270205530&isByUser=1&page=1&start=0&limit=25
        $http.get("private/user/retrievePointsByType.do?isByUser=1&page=1&start=0&limit=25")
            .then(function (response) {
                $scope.pointsByType = response.data.data;
            }, function (response) {
                //Second function handles error
                //alert('hata!');
            });
    };
    $scope.loadReward = function () {
        //private/user/retrieveUserRewards.do?_dc=1488270205531&isByUser=1&page=1&start=0&limit=25
        $http.get("private/user/retrieveUserRewards.do?isByUser=1&page=1&start=0&limit=25")
            .then(function (response) {
                $scope.rewards = response.data.data;
            }, function (response) {
                //Second function handles error
                //alert('hata!');
            });
    };
 
    $scope.loadPointLeaders();
    $scope.loadSuggestionLeaders();
    $scope.loadImprovementLeaders();
    $scope.loadCommentLeaders();
    $scope.loadIdeaLeaders();
    $scope.getSuggestions();
    $scope.getImprovements();
    $scope.loadPoints();
    $scope.loadPointsByType();
    $scope.loadReward();
    $scope.getNewsList();
    
}]);

app.filter('replaceMonth', ["$rootScope",function ($rootScope) {
    return function (input) {
        var str =$rootScope.frontendConfig.leadersMonthParam.toString();
    if(str == 0){
        var ret = "Tablodaki değerler mevcut takvim yılına aittir.";
    }else{
       var ret = input.replace("[[month]]",str);
    }

       return ret;
    };
}]);
app.filter('levelUp', ["$rootScope",function ($rootScope) {
    return function (input) {
        var str1 =parseInt($rootScope.frontendConfig.levelUserPoint1);
        var str2 =parseInt($rootScope.frontendConfig.levelUserPoint2);
        var str3 =parseInt($rootScope.frontendConfig.levelUserPoint3);
        var str4 =parseInt($rootScope.frontendConfig.levelUserPoint4);
        var str5 =parseInt($rootScope.frontendConfig.levelUserPoint5);
        
    if(input > str5 ){

        var ret = "glyphicon glyphicon-flash";
    }
    else if(input < str5   && input > str4){
        var ret = "glyphicon glyphicon-fire";
    }
    else if(input < str4   && input > str3){
        var ret = "glyphicon glyphicon-certificate";
    }
    else if(input < str3   && input > str2){
        var ret = "glyphicon glyphicon-flag";
    }
    else if(input < str2   && input > str1){
        var ret = "glyphicon glyphicon-sunglasses";
    }
       return ret;
    };
}]);
