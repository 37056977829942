app.controller("usersCtrl", ['$scope', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate', '$uibModal',
    function ($scope, $http, $location, $rootScope, $routeParams, Notification, $translate, $uibModal) {

        $scope.pageSize = 50;
        $scope.pagingMaxSize = 4;
        $scope.currentIndex = 0;
        $scope.calcindex = 0;
        $scope.pagination = { currentPage: 1 };
        $scope.data = { query: '' };
        $scope.data.query = $location.search().query || '';
        $scope.activeForm = $scope.data.query != '' ? 1 : 0;
        $scope.dataTykUsers = [];
        $scope.selectedBoard = [];
        $scope.selectedAdmin = [];
        $scope.showTerminateButton = false;
        $scope.showAdminTerminateButton = false;
        $scope.selectableStyle = { "cursor": "pointer" };
        $scope.noneSelectableStyle = { "cursor": "not-allowed" };
        $scope.model = {};
        $scope.model.allItemsSelected = false;
        $scope.eduOptions = [
            {value:0, label:"label.edu.primary"},
            {value:1, label:"label.edu.secondary"},
            {value:2, label:"label.edu.high"},
            {value:3, label:"label.edu.college"},
            {value:4, label:"label.edu.undergraduate"},
            {value:5, label:"label.edu.graduate"},
            {value:6, label:"label.edu.phd"},
        ];

        $scope.init = function (a) {
            $scope.loading = true;
            $scope.stateOfUser = a === 'e' ? "external" : (a === 'i' ? "internal" : (a === 'u' ? 'unregistered' : 'internal'));
            if (a == 'i' && $scope.data.query != '' && $scope.activeForm == 1) {
                $scope.stateOfUser = $scope.data.query != '' ? 'external' : 'internal';
                if (!$scope.data.query) $scope.data = { query: '' };
            } else if ($scope.data.query != '' && $scope.activeForm != 1) {
                $location.search('query', null);
            }
            resetPagination();
            if (a == 'i' || a == 'e') $scope.getUsersSpecific();
            if (a === 't') $scope.getTykUsers();
            if (a === 'y') $scope.getAdminUsers();
        };
        var resetPagination = function () {
            $scope.pageSize = 50;
            $scope.pagingMaxSize = 4;
            $scope.currentIndex = 0;
            $scope.pagination = { currentPage: 1 };
        }

        $scope.getUsersSpecific = function () {
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            $scope.myPromise = $http.get("private/user/retrieveUserSpecific.do?start=" + $scope.currentIndex + "&query=" + $scope.data.query + "&limit=" + $scope.pageSize + "&external=" + $scope.stateOfUser)
                .then(function (response) {
                    $scope.user = response.data.data;
                    $scope.usersTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    var rangeEnd = $scope.currentIndex + $scope.pageSize;
                    $scope.displayRangeEnd = rangeEnd > $scope.usersTotal ? $scope.usersTotal : rangeEnd;
                    $scope.loading = false;
                    $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: getUsersSpecific');
                });
        };

        $scope.getTykUsers = function () {
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            $scope.myPromise = $http.get("private/user/retrieveTyk.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                .then(function (response) {
                    $scope.model.userTyk = response.data.data;
                    $scope.usersTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    var rangeEnd = $scope.currentIndex + $scope.pageSize;
                    $scope.displayRangeEnd = rangeEnd > $scope.usersTotal ? $scope.usersTotal : rangeEnd;
                    $scope.loading = false;
                    $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };

        $scope.getAdminUsers = function () {
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            $scope.myPromise = $http.get("private/user/retrieveAdmins.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                .then(function (response) {
                    $scope.model.userAdmin = response.data.data;
                    $scope.usersTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    var rangeEnd = $scope.currentIndex + $scope.pageSize;
                    $scope.displayRangeEnd = rangeEnd > $scope.usersTotal ? $scope.usersTotal : rangeEnd;
                    $scope.loading = false;
                    $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };

        $scope.activateUser = function (u, s, m) {

            if ((u.status == 1 || u.status == 0) && s === 'd') {
                $http.get("private/user/activateUser.do?userId=" + u.id)
                    .then(function (response) {
                        $scope.sureModalClose();
                        $scope.getUsersSpecific();
                    }, function (response) {
                        //Second function handles error
                        Notification.error('Error: competition');
                    });

            }
            if (u.status == 2 && s === 'a') {
                $scope.myPromise = $http.get("private/user/approveUser.do?userId=" + u.id + "&orgId=" + u.orgId)
                    .then(function (response) {
                        $scope.sureModalClose();
                        $scope.getUsersSpecific();
                    }, function (response) {
                        //Second function handles error
                        Notification.error('Error: competition');
                    });

            } if (u.status == 2 && s === 'r') {
                if (m === '' || m == undefined) {
                    Notification.warning($translate.instant("label.header.sure.reject.message"));
                    return;
                }
                $http.get("private/user/rejectUser.do?userId=" + u.id + "&msg=" + m)
                    .then(function (response) {
                        $scope.sureModalClose();
                        $scope.getUsersSpecific();
                    }, function (response) {
                        //Second function handles error
                        Notification.error('Error: competition');
                    });

            }

        };

        $scope.removeFromTyk = function () {

            for (var i = 0; i < $scope.model.userTyk.length; i++) {
                if ($scope.model.userTyk[i].selected) {
                    var obj = { id: $scope.model.userTyk[i].id }
                    $scope.dataTykUsers.push(obj);
                }
            }
            if ($scope.dataTykUsers.length < 1) {
                Notification.warning($translate.instant("label.header.user.select.user"));
                return;
            }
            $scope.myPromise = $http({
                method: 'POST',
                url: 'private/user/removeFromTyk.do',
                data: 'data=' + encodeURIComponent(JSON.stringify($scope.dataTykUsers)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.getTykUsers();
                    $scope.dataTykUsers = [];
                    $scope.sureModalClose();
                    if (res.data.success == true) {
                        Notification.success($translate.instant("sugg.status.completed"));
                    } else {
                        $scope.hasError = true;
                    }

                });

        };

        $scope.boardAssignee = function () {

            var arr = [];
            var user = {};




            for (var j = 0; j < $scope.selectedBoard.length; j++) {

                for (var i = 0; i < $scope.model.userTyk.length; i++) {

                    if ($scope.model.userTyk[i].selected && $scope.model.userTyk[i].exitDate == null) {

                        if ($scope.model.userTyk[i].userId == $scope.selectedBoard[j].id) {
                            Notification.warning($translate.instant("warning.field.same.value") + ":" + $scope.model.userTyk[i].firstName + " " + $scope.model.userTyk[i].lastName);
                            var user = {};
                            return;
                        }

                    }
                }
                user = { userId: $scope.selectedBoard[j].id }
                arr.push(user);
            }



            $scope.myPromise = $http({
                method: 'POST',
                url: 'private/user/saveTyk.do',
                data: 'data=' + encodeURIComponent(JSON.stringify(arr)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.selectedBoard = [];
                    $scope.dataTykUsers = [];
                    $scope.getTykUsers();
                    $scope.sureModalClose();
                    if (res.data.success == true) {
                        Notification.success($translate.instant("sugg.status.completed"));
                    } else {
                        $scope.hasError = true;
                    }

                });

        };

        $scope.adminAssignee = function (a) {

            var typeid = a;
            var userid = 0;
            var role = "ROLE_ADMIN";
            if (a == 1)
                userid = $scope.selectedAdmin[0].id;
            else {
                for (var i = 0; i < $scope.model.userAdmin.length; i++) {

                    if ($scope.model.userAdmin[i].selected) {
                        userid = $scope.model.userAdmin[i].id;
                    }

                }
            }
            if (userid != 0) {
                $scope.myPromise = $http({
                    method: 'POST',
                    url: 'private/user/manageRole.do',
                    data: 'userId=' + encodeURIComponent(JSON.stringify(userid)) + '&role=' + role + '&type=' + encodeURIComponent(JSON.stringify(typeid)),
                    headers: {
                        "content-type": "application/x-www-form-urlencoded"
                    }
                })
                    .then(function (res) {
                        $scope.selectedAdmin = [];
                        $scope.getAdminUsers();
                        $scope.sureModalClose();
                        if (res.data.success == true) {
                            Notification.success($translate.instant("sugg.status.completed"));
                        } else {
                            Notification.warning(res.data.message);
                        }

                    });
            }


        };

        $scope.selectAll = function () {
            // Loop through all the entities and set their isChecked property
            for (var i = 0; i < $scope.model.userTyk.length; i++) {
                if ($scope.model.userTyk[i].exitDate == null) {
                    $scope.model.userTyk[i].selected = $scope.model.allItemsSelected;
                }
            }
            $scope.showTerminateButton = $scope.model.allItemsSelected;
        };
        $scope.selectUserTyk = function (c) {
            $scope.showTerminateButton = false;
            if (c.exitDate == null) {
                c.selected = c.selected ? false : true;
            }
            for (var i = 0; i < $scope.model.userTyk.length; i++) {

                if ($scope.model.userTyk[i].selected && $scope.model.userTyk[i].exitDate == null) {
                    $scope.showTerminateButton = true;
                }
            }
            for (var i = 0; i < $scope.model.userTyk.length; i++) {

                if (!$scope.model.userTyk[i].selected && $scope.model.userTyk[i].exitDate == null) {
                    $scope.model.allItemsSelected = false;
                    return;
                }
            }

            $scope.model.allItemsSelected = true;
        };
        $scope.setTykClass = function (c) {

            if (c.exitDate) return 'danger';
            if (c.selected != undefined && c.selected) return 'active';
            if (c.selected != undefined && !c.selected) return 'default';

        };
        $scope.selectUserAdmin = function (c) {

            for (var i = 0; i < $scope.model.userAdmin.length; i++) {

                if ($scope.model.userAdmin[i].userName == c.userName) {
                    $scope.model.userAdmin[i].selected = $scope.model.userAdmin[i].selected ? false : true;
                } else {
                    $scope.model.userAdmin[i].selected = false;
                }

            }

            for (var i = 0; i < $scope.model.userAdmin.length; i++) {

                if ($scope.model.userAdmin[i].selected) {
                    $scope.showAdminTerminateButton = true;
                    return;
                }

            }
            $scope.showAdminTerminateButton = false;

        };
        $scope.setAdminClass = function (c) {

            if (c.selected != undefined && c.selected) return 'active';
            if (c.selected != undefined && !c.selected) return 'default';

        };
        $scope.getUsersSpecificDetail = function (t, i) {
            $scope.myPromise = $http.get("private/user/retrieveUserSpecificDetail.do?userid=" + i.id)
                .then(function (response) {
                    $scope.userDetail = response.data.data[0];
                    $scope.openDetail(t, i);
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: getUsersSpecificDetail');
                });
        };

        $scope.openSureActivateModal = function (t, i, s) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.status = i.status;
                    $scope.state = s;
                    $scope.headerMessage = "";
                    $scope.contentmessage = "";
                    if ($scope.status == 0) {
                        $scope.contentmessage = "label.header.sure.activate";
                        $scope.headerMessage = "label.header.activate";
                    }
                    if ($scope.status == 1) {
                        $scope.contentmessage = "label.header.sure.deactivate";
                        $scope.headerMessage = "label.header.deactivate";
                    }
                    if ($scope.status == 2 && s === 'a') {
                        $scope.contentmessage = "label.header.sure.approve";
                        $scope.headerMessage = "label.header.approve";
                    }
                    if ($scope.status == 2 && s === 'r') {
                        $scope.contentmessage = "label.header.sure.reject";
                        $scope.headerMessage = "label.header.reject";
                    }

                    $scope.openmessageinput = s === 'r' ? true : false;
                    $scope.Ok = function () {
                        t.activateUser(i, s, $scope.rejectmessage);
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "sureActivate.html"
            });
        };
        $scope.openTykModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {
                        t.removeFromTyk();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "sureTyk.html"
            });
        };
        $scope.openAssigneeTykModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {
                        t.boardAssignee();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "addTyk.html"
            });
        };
        $scope.openAdminModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {
                        var a = 0;
                        t.adminAssignee(a);
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "sureAdmin.html"
            });
        };
        $scope.openAssigneeAdminModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {
                        var a = 1;
                        t.adminAssignee(a);
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "addAdmin.html"
            });
        };
        $scope.sureModalClose = function () {
            $rootScope.modalInstance.close();
        };
        $scope.checkClass = function (u) {
            if (u.status == 0) return 'warning';
            if (u.status == 1) return 'default';
            if (u.status == 2) return 'info';
            if (u.status == 3) return 'danger';
            if (u.status == 4) return 'info';

        };
        $scope.checkText = function (u) {
            if (u.status == 0) return 'user.status.0';
            if (u.status == 1) return 'user.status';
            if (u.status == 2) return 'user.status.2';
            if (u.status == 3) return 'user.status.3';
            if (u.status == 4) return 'user.status.2';

        };
        $scope.checkButton = function (u) {
            if (u.status == 0) return 'label.header.activate';
            if (u.status == 1) return 'label.header.deactivate';

        };
        $scope.pageChanged = function () {
            $scope.currentIndex = ($scope.pagination.currentPage - 1) * $scope.pageSize;
            if ($scope.activeForm == 0 || $scope.activeForm == 1) $scope.getUsersSpecific();
            if ($scope.activeForm == 2) $scope.getTykUsers();
            if ($scope.activeForm == 3) $scope.getAdminUsers();
        };
        $scope.stringToDate = function (d) {
             var date;
             if(d != '-')
             {
                date = new Date(d);
                return date;
             }
             return d;
        };

        $scope.openDetail = function (t, i) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.dates = {};
                    
                    $scope.contribution = '#/contributions/' + i.id + '/' + i.name + ' ' + i.surname;
                    $scope.scorePage = '#/score/' + i.id;
                    $scope.userDetail = t.userDetail;
                    $scope.datedd = t.stringToDate($scope.userDetail.lastlogin);
                   
                    $scope.meduOptions = t.eduOptions;
                    $scope.openRewardDetail = function (a, b, c) {
                        t.openRewardDetail(a, b, c);
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "userDetailModal.html"
            });
        };
        $scope.openRewardDetail = function (size, user, dates) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'userRewardDetailModal.html',
                controller: function ($scope, $uibModalInstance, user, dates) {
                    $scope.username = user.username + ' ' + user.surname;
                    $scope.showDetail = function () {

                        if (user.id) {
                            $http({
                                method: 'POST',
                                url: 'private/user/retrievePointsByTypeAdmin.do',
                                data: 'isByUser=' + encodeURIComponent(JSON.stringify(user.id)),
                                headers: {
                                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                                }
                            })
                                .then(function (res) {
                                    if (res.data.success) {
                                        $scope.userDetailData = res.data;
                                        getRewards(user);
                                    }
                                });
                        }


                    };
                    $scope.loadPoints = function () {
                        //private/user/retrievePoints.do?_dc=1488270205528&isByUser=1&page=1&start=0&limit=25
                        $http.get("private/user/retrievePoints.do?isByUser=0&page=1&start=0&limit=25&userIdObj="+user.id)
                            .then(function (response) {
                                $scope.user = response.data.data[0];

                            }, function (response) {
                                //Second function handles error
                                Notification.error('hata!');
                            });
                    };
                    var getRewards = function (a) {
                        $http({
                            method: 'POST',
                            url: 'private/user/retrieveUserRewardsAdmin.do',
                            data: 'isByUser=' + encodeURIComponent(JSON.stringify(a.id)),
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                            }
                        })
                            .then(function (res) {
                                if (res.data.success) {
                                    $scope.userRewardsData = res.data;
                                }
                            });
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };
                    $scope.showDetail();
                    $scope.loadPoints();

                },
                size: size,
                resolve: {
                    user: function () {
                        return user;
                    },
                    dates: function () {
                        return dates;
                    }
                }
            });

        };


    }]);