app.directive("evalReport", [
    "$http",
    "$translate",
    "Notification",
    function ($http, $translate, Notification) {
      return {
        restrict: "E",
        scope: {
          evalId: "=",
          evalType: "=",
          evalTitle: "=",
          commands: "=",
          commandType:"=",
          objectId: "=",
          objectType: "=",
          reportType: "=",
          showToggle: "=",
          userAuthList: "="
        },
        templateUrl: "partial/directive-imp/eval-report.html",
        controller: function ($scope) {
          $scope.isLoading = true;
          $scope.isVisible = false;
          $scope.objectCode = "";
          $scope.objectTitle = "";
          $scope.$watch('commands', function (newVal) {
              if (newVal) {
                $scope.isVisible = false;
                $scope.availableCommands = $scope.commands;
                if($scope.availableCommands != null && $scope.availableCommands.length > 0){
                  for(var i =0; i < $scope.availableCommands.length ; i++){
                    if( $scope.availableCommands[i] === $scope.commandType  ){
                   //   $scope.isVisible=true;
                      $scope.loadEvalReport();
                    }
                  }
                }
              }
            });

          var path = "private/eval/retrieveEvalReport.do?evalId=" + $scope.evalId;
          $scope.allList = false;
    
         // for(var i = 0 ; i< $scope.userAuthList.length ; i++){
          //  if(  $scope.userAuthList[i] == 4 || $scope.userAuthList[i] == 1 )
          //      $scope.allList = true;
         // }
         
         if($scope.commandType == 'report-imp-eval-pre')
            $scope.allList = true;

         if($scope.commandType == 'report-imp-eval-board')
            $scope.allList = true;
  
          if ($scope.evalId == 0) {
            if ($scope.objectType == 2) // improvement
            {
              path = "private/eval/retrieveImprovementEvalReport.do?impId=" + $scope.objectId + "&evalType=" + $scope.evalType+"&objectType=" + $scope.objectType +"&allList=" + $scope.allList;
            }
          }
          $scope.loadEvalReport = function () {
         
            $http
              .get(path)
              .then(
              function (response) {
                if (response.data.success == true) {
                  if (response.data.data) {
                    $scope.report = response.data.data;
                    $scope.isLoading = false;
     
                    if($scope.report.evaluatorList.length >0)
                    {
                      var oid = $scope.report.eval.objectId;
                   //   $scope.loadImprovement(oid);
                      $scope.isVisible = true;
                    }
                    else{
                      $scope.isVisible=false;
                    }
                    
                  }
                }
              },
              function (response) {
                $translate("error.eval.load").then(function (msg) {
                 // Notification.error(msg);
                 $scope.isVisible=false;
                });
                $scope.isLoading = false;
              }
              );
          };

          $scope.loadImprovement = function (id) {
            $http.get("private/improvement/get.do?id=" + id)
              .then(function (response) {
                var obj = response.data.data;
                $scope.objectCode = obj.code;
                $scope.objectTitle = obj.title;
  
              }, function (response) {
                Notification.error('Error: improvement');
              });
          };
 
        }
      };
    }
  ]);
  