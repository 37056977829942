app.directive("benefitForm", [
    "$http",
    "$translate",
    "Notification",
    function ($http, $translate, Notification) {
      return {
        restrict: "E",
        scope: {
          type: "=",
          show:"=",
          label:"=",
          desc:"=",
          id:"="
        },
        templateUrl: "partial/directive/benefit-form.html",
        controller: function ($scope) {
          $scope.isLoading = true;
          
          
          $scope.bnfFinancialTypes = [
            { value: 0, label: "label.bnfFinancialType0" },
            { value: 1, label: "label.bnfFinancialType1" },
            { value: 2, label: "label.bnfFinancialType2" },
            { value: 3, label: "label.bnfFinancialType3" },
            { value: 4, label: "label.bnfFinancialType4" }
          ];
          $scope.bnfSelectedFinancialType =  $scope.bnfFinancialTypes[0];
          $scope.benefit = {
            bnfFinancialType:0,
            bnfFinancialValue:0,
            bnfCostInvesment:0,
            bnfCostOperational:0,
            bnfExplanation:''
          };
          $scope.getBenefit = function() {
            $http
              .get(
                "private/suggestion/retrieveBenefitBySuggestionIdAndType.do?suggestionId=" +
                  $scope.id +
                  "&type=" +
                  $scope.type
              )
              .then(
                function(response) {
                  
                    $scope.benefit = response.data.data ? response.data.data[0] : $scope.benefit;
                    $scope.bnfSelectedFinancialType = $scope.bnfFinancialTypes[$scope.benefit.bnfFinancialType];
                  
                },
                function(response) {
                  //Second function handles error
                  Notification.error("ERROR suggestion benefit");
                }
              );
          };
          $scope.saveBenefit = function() {
            
            $scope.benefit.bnfFinancialType = $scope.bnfSelectedFinancialType.value;
            
              $scope.benefit.bnfType = $scope.type;
              $scope.benefit.bnfSuggestionId = $scope.id;
            //delete bnf.bnfId;
            delete $scope.benefit.bnfUpdateDateStr;
            delete $scope.benefit.bnfUpdateDate;
            delete $scope.benefit.bnfUserId;
            $http({
              method: "POST",
              url: "private/suggestion/saveBenefitNew.do",
              data: "data=" + encodeURIComponent(JSON.stringify($scope.benefit)),
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
              }
            }).then(function(res) {
              $scope.getBenefit();
              if (res.data.success == true) {
                $translate("info.benfit.submit").then(function(msg) {
                  Notification.success(msg);
                });
              } else {
                $translate("error.benefit.submit").then(function(msg) {
                  Notification.error(msg);
                });
              }
            });
          };
          $scope.getBenefit();
        }
      };
    }
  ]);
  