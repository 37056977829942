app.controller("improvementFieldCtrl", ['$scope', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate', '$uibModal',
    function ($scope, $http, $location, $rootScope, $routeParams, Notification, $translate, $uibModal) {

        $scope.pageSize = 50;
        $scope.pagingMaxSize = 4;
        $scope.currentIndex = 0;
        $scope.calcindex = 0;
        $scope.pagination = { currentPage: 1 };
        $scope.edittingAllow = true;
        $scope.selectableStyle = { "cursor": "pointer" };
        $scope.noneSelectableStyle = { "cursor": "not-allowed" };
        $scope.impFieldData = {
            titlePrimaryLang: '',
            titleSecondaryLang: '',
            requiresManagerConfirmation : "0",
            active : 1
        };
        $scope.impFieldUsers = [];
        $scope.init = function (a) {
            $scope.loading = true;
            if (a == 'i' || a == 'e') $scope.getImprovementFieldList();
        };

        $scope.getTemplateImpField = function (i) {
            if (i.id === $scope.impFieldData.id && $scope.edittingAllow) return 'editImpField';
            else return 'displayImpField';
        };

        $scope.getImprovementFieldList = function ()  {          
        if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
        var page = $location.search().page || 1;
        $scope.currentIndex = (page - 1) * $scope.pageSize;
            $scope.myPromise = $http.get("private/improvement-field/list.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                .then(function (response) {
                    $scope.impFields = response.data.data;
                    for(var i=0;i<$scope.impFields.length;i++){
                        if($scope.impFields[i].requiresManagerConfirmation != null){
                            $scope.impFields[i].requiresManagerConfirmation = $scope.impFields[i].requiresManagerConfirmation+"";
                        }
                    }
                    $scope.impFieldsTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.currentPage = page;
                    var rangeEnd = $scope.currentIndex + $scope.pageSize;
                    $scope.displayRangeEnd = rangeEnd > $scope.impFieldsTotal ? $scope.impFieldsTotal : rangeEnd;
                    $scope.loading = false;
                    $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: getImprovementFieldList');
                });
        };

        $scope.sureModalClose = function () {
            $rootScope.modalInstance.close();
        };

        $scope.updateImprovementField = function (i) {

            if (i) {
                $scope.impFieldData = angular.copy(i);
                $scope.impFieldData.active = 1;
                $scope.edittingAllow = true;
            }
        };
        $scope.deleteImprovementField  = function (i) {

            if (i) {
                $scope.impFieldData = angular.copy(i);
                $scope.impFieldData.active = 0;
                $scope.edittingAllow = false;
            }
            $scope.saveUpdateImpField();
        };
        $scope.activateImprovementField  = function (i) {

            if (i) {
                $scope.impFieldData = angular.copy(i);
                $scope.impFieldData.active = 1;
                $scope.edittingAllow = false;
            }
           $scope.saveUpdateImpField();
        };

        $scope.saveUpdateImpField = function () {
            $scope.dataLoading = true;
            $scope.hasError = false;
            if($scope.impFieldData.requiresManagerConfirmation === "true"){
                $scope.impFieldData.requiresManagerConfirmation = true;
            }else{
                $scope.impFieldData.requiresManagerConfirmation = false;
            }
            $http({
                method: 'POST',
                url: 'private/improvement-field/save.do',
                data: 'field=' + encodeURIComponent(JSON.stringify($scope.impFieldData)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.impFieldData = {};
                    $scope.dataLoading = false;
                    $scope.msg = res.data.message;
                    $scope.edittingAllow = true;
                    if (res.data.success == true) {
                        Notification.success($translate.instant("info.improvement.field.saved"));
                        $scope.getImprovementFieldList();
                    } else {
                        $scope.hasError = true;
                        Notification.warning($scope.msg);
                        $scope.dataLoading = false;
                    }
                    
                });
        };
        $scope.cancelImprovementField = function (i) {
            $scope.impFieldData = {};
            $scope.updatestate = false;

        };

        $scope.openImpFieldUserList = function (t,i) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.myPromise = $http.get("private/improvement-field-user/list.do?fieldId=" + i.id)
                    .then(function (response) {
                      $scope.impFieldUsers = response.data.data;
                      $scope.loading = true;
                      $scope.users = [];
                      $scope.pagination = { currentPage: 1 };
                      $scope.pageSize = 10;   
                      $scope.currentIndex = 0;
                      $scope.currentPage = 0;
                      $scope.query = "";

                      $scope.impFieldAuthTypeOptions = [
                        { value: 1, label: "imp.field.auth.auditor" },
                        { value: 2, label: "imp.field.auth.manager" },
                        { value: 3, label: "imp.field.auth.responsible" },
                        { value: 4, label: "imp.field.auth.mentor" },
                        { value: 5, label: "imp.field.auth.expert" },
                      ];
                       $scope.selecteImpFieldAuthType = {};
                      $scope.getUserList();
                      //var _url = "private/user/retrieveAllStaff.do";
                      //if($scope.userType == "ALL")
                    }, function (response) {
                        //Second function handles error
                        Notification.error('Error: getImprovementFieldUser');
                    });
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };
                    $scope.pageChanged = function () {
                        $scope.currentIndex = ($scope.pagination.currentPage - 1) * $scope.pageSize;
                        $scope.getUserList();
                    };
                    $scope.clearSearch = function () {
                        $scope.query = "";
                        $scope.getUserList();
                    };
                    
                    $scope.saveImpFieldUser = function (user,authType) {
                        $scope.dataLoading = true;
                        $scope.hasError = false;
                        var impFieldUserData = {
                            "userId" : user.id,
                            "fieldId" : i.id,
                            "authType" : authType.value
                        };
                        $http({
                            method: 'POST',
                            url: 'private/improvement-field-user/save.do',
                            data: 'fieldUser=' + encodeURIComponent(JSON.stringify(impFieldUserData)),
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.impFieldData = {};
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                $scope.edittingAllow = true;
                                $scope.loading = false;
                                if (res.data.success == true) {
                                    Notification.success($translate.instant("info.improvement.field.user.saved"));
                                    $scope.myPromise = $http.get("private/improvement-field-user/list.do?fieldId=" + i.id)
                                    .then(function (response) {
                                      $scope.impFieldUsers = response.data.data;
                                      $scope.loading = true;
                                    }, function (response) {
                                        //Second function handles error
                                        Notification.error('Error: getImprovementFieldUser');
                                    });
                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }
                            });
                    };
                    $scope.getUserList = function () {
                        var _url = "private/user/retrieveAllUsers.do";
                        var data = 'query=' + $scope.query+"&page="+$scope.currentPage+"&start="+$scope.currentIndex+"&limit="+$scope.pageSize;
                        $scope.myPromise = $http({
                            method: 'POST',
                            url:_url,
                            data: data,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                            }
                        })
                        .then(function (response) {
                            $scope.users = response.data.data;
                            $scope.loading = false;
                            $scope.showMaxUserInfo = false;
                            $scope.usersTotal = response.data.total;
                            $scope.displayRangeStart = $scope.currentIndex +1;
                            var rangeEnd = $scope.currentIndex + $scope.pageSize;
                            $scope.displayRangeEnd = rangeEnd > $scope.usersTotal ? $scope.usersTotal : rangeEnd;
                            
                        }, function (response) {
                            //Second function handles error
                            Notification.error('ERROR editor');
                            $scope.loading = false;
                        });
                    };
                },
                size: 'lg',
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "impFieldUserModal.html"
            });
        };

        $scope.stringToDate = function (d) {
            $scope.date = new Date(d);
            return $scope.date;
        };
        $scope.openNewImprovementFieldModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {

                        if ($scope.impFieldData == "" || $scope.impFieldData == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.impFieldData.titlePrimaryLang == "" || $scope.impFieldData.titlePrimaryLang == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.impFieldData.titleSecondaryLang == "" || $scope.impFieldData.titleSecondaryLang == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        }
                        if ($scope.impFieldData.requiresManagerConfirmation == "" || $scope.impFieldData.requiresManagerConfirmation == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        }

                        $scope.saveNewImpField(t);
                        $scope.Close();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };
                    $scope.saveNewImpField = function (t) {
                        $scope.dataLoading = true;
                        $scope.hasError = false;
                        if($scope.impFieldData.requiresManagerConfirmation === "1"){
                            $scope.impFieldData.requiresManagerConfirmation = true;
                        }else{
                            $scope.impFieldData.requiresManagerConfirmation = false;
                        }
                        $http({
                            method: 'POST',
                            url: 'private/improvement-field/save.do',
                            data: 'field=' + encodeURIComponent(JSON.stringify($scope.impFieldData)),
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (res) {
                                $scope.dataLoading = false;
                                $scope.msg = res.data.message;
                                if (res.data.success == true) {
                                    Notification.success($translate.instant("info.improvement.field.saved"));
                                    t.getImprovementFieldList();
                                } else {
                                    $scope.hasError = true;
                                    Notification.warning($scope.msg);
                                    $scope.dataLoading = false;
                                }
                                
                            });
                    };
                },
                size: 'lg',
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "newImprovementField.html"
            });
        };

    }]);
    app.filter('impFieldAuthType', function () {
        return function (input) {
            switch (input) {
                case 1:
                    return 'imp.field.auth.auditor';
                case 2:
                    return 'imp.field.auth.manager';
                case 3:
                    return 'imp.field.auth.responsible';
                case 4:
                    return 'imp.field.auth.mentor';
                case 5:
                    return 'imp.field.auth.expert';
                case 6:
                    return 'imp.field.auth.implementer';
                default:
                    return 'imp.field.auth.none';
            }
        };
    });
