app.directive("fieldChange", [
    "$http",
    "$translate",
    "Notification",
    "$uibModal",
    "$rootScope",
    "$filter",
    '$location',
    function ($http, $translate, Notification, $uibModal, $rootScope,$filter,$location) {
      return {
        restrict: "E",
        scope: {
          impId: "=",
          impState:"=",
          fieldId: "=",
          fieldUserId: "=",
          show: "=",
          reloadImp:"&",
          commands: "=",
          commandType:"=",
          label: "=",
          showToggle: "="
        },
        templateUrl: "partial/directive-imp/field-change.html",
        controller: function ($scope) {
          $scope.STATUS_FIRSTACCEPT = 50;
          
          $scope.selectedFieldId = $scope.fieldId;
          $scope.ImpUser = {
            "userId" :$scope.fieldUserId,
            "improvementId" :$scope.impId,
            "authType":3
          };

          $scope.isLoading = false;

          $scope.refreshImp = function () {
            $scope.reloadImp();
          };

          $scope.$watch('commands', function (newVal) {
            if (newVal) {
              $scope.availableCommands = $scope.commands;
              $scope.show=false;
              for(var i =0; i < $scope.availableCommands.length ; i++){
                if( $scope.availableCommands[i] === $scope.commandType  ){
                  $scope.show=true;
                  $scope.selectedFieldId = $scope.fieldId;
                  $scope.getImpFields();
                  $scope.getImpFieldUsers($scope.selectedFieldId);
                }
              }
               
            }
          });

          $scope.getImpFields = function () {
            var path = "private/improvement-field/availableList.do?start=0&limit=100";
            $http.get(path).then(
              function (response) {
                
                $scope.improvementFields = response.data.data;
                $scope.isLoading = true;
              },
              function (response) {
                //Second function handles error
                Notification.error("ERROR improvement fields");
              }
            );
          };

          $scope.getImpFieldUsers = function (id) {
            $scope.ImpUser.fieldId = id;

            var path = "private/improvement-field-user/list-by-type.do?fieldId="+ id + "&authType=3&start=0&limit=100&query=";
            $http.get(path).then(
              function (response) {
               
                $scope.isLoading = true;
                $scope.improvementFieldUsers = response.data.data;


                if($scope.fieldId != id)
                  $scope.ImpUser.userId =$scope.improvementFieldUsers[0].userId;
                 else
                  $scope.ImpUser.userId = $scope.fieldUserId;
              },
              function (response) {
                //Second function handles error
                Notification.error("ERROR improvement field users");
              }
            );
          };
          $scope.changeFieldUser = function (id) {
            $scope.ImpUser.userId = id;
          };
  
          $scope.saveImpField = function(){

            $http({
              method: "POST",
              url: "private/improvement-user/responsibleUpdate.do",
              data:
              "user=" + encodeURIComponent(JSON.stringify(new Array($scope.ImpUser))) 
              +"&impFieldId="+$scope.ImpUser.fieldId,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
              }
            }).then(function (res) {
              if (res.data.success == true) {
                Notification.success($translate.instant("label.improvement.user.change"));
                $location.path('/improvements')
             //   $scope.refreshImp();
              } else {
                $translate("error.generic").then(function (msg) {
                  Notification.error(msg);
                });
              }
            });

            
          };

          
        $scope.fieldChangeSureApprove = function (t) {
          $rootScope.modalInstance = $uibModal.open({
              animation: true,backdrop: 'static',
              controller: function ($scope, $uibModalInstance) {
  
                  $scope.fcSureModalOk = function () {
                    t.saveImpField();
                    $uibModalInstance.close();
                  }
                  $scope.fcSureModalClose = function () {
                    $uibModalInstance.close();
                  }
              },
              ariaLabelledBy: "modal-title",
              ariaDescribedBy: "modal-body",
              templateUrl: "fieldChangeSureApprove.html"
          });
      };

        }

      };
    }
  ]);
  
 
