app.controller("newUserCtrl", ['$scope', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate', '$uibModal',
    function ($scope, $http, $location, $rootScope, $routeParams, Notification, $translate, $uibModal) {

        $scope.userid = $routeParams.userid ? $routeParams.userid : 0;
        $scope.senderPage = $routeParams.sender ? $routeParams.sender : 0;
        $scope.dataLoading = false;
        $scope.isExternal = false;
        $scope.termsChecked = false;
        $scope.userSettings = { lang : 'tr'}
        $scope.departmentId='102';
        $scope.departmentIdIn=0;
        $scope.userData = {
            firstName: '',
            surname: '',
            email: ''
        };
        $scope.extData = {
            company: '',
            idno: 0,
            education: 3,
            job: '',
            phone: ''
        };
        $scope.init = function () {
            $scope.getDepartments();
            if ($scope.userid > 0) $scope.getUserDetails();
        }
        $scope.checkExternal = function () {
            if ($scope.userData.email.trim()) {
                $http({
                    method: "POST",
                    url: "notsecure/user/checkExternalUser.do",

                    data:
                        "email=" +
                        $scope.userData.email +
                        "&firstName=" +
                        $scope.userData.firstName +
                        "&surname=" +
                        $scope.userData.surname,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                    }
                }).then(function (res) {
                    if (res.data.checkWarning) {
                        $scope.isExternal = true;
                        $translate("error.idea.isExternal").then(function (msg) {
                            Notification.error(msg);
                        });
                    } else $scope.isExternal = false;
                });
            }
        };
        $scope.eduOptions = [
            {value:0, label:"label.edu.primary"},
            {value:1, label:"label.edu.secondary"},
            {value:2, label:"label.edu.high"},
            {value:3, label:"label.edu.college"},
            {value:4, label:"label.edu.undergraduate"},
            {value:5, label:"label.edu.graduate"},
            {value:6, label:"label.edu.phd"},
        ];
        
        $scope.selectedEdu = $scope.eduOptions[4];


        $scope.register = function () {
            $scope.dataLoading = true;
            $scope.hasError = false;
            $scope.extData.education = $scope.selectedEdu.value;
            /* $scope.departmentId */
            var depid = $scope.senderPage == 0 ? 102 : $scope.selectedDepId;
            $http({
                method: 'POST',
                url: 'private/createNewUser.do',
                data: 'userData=' + encodeURIComponent(JSON.stringify($scope.userData)) + '&extData=' + encodeURIComponent(JSON.stringify($scope.extData)) + '&departmentId=' + depid +'&userSettings='+encodeURIComponent(JSON.stringify($scope.userSettings)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.dataLoading = false;
                    $scope.msg = res.data.message;
                    if (res.data.success == true) {
                        Notification.success(res.data.data);
                        if($scope.senderPage==0)
                        $location.path("/users").search('query', $scope.userData.firstName + ' ' + $scope.userData.surname);
                    } else {
                        $scope.hasError = true;
                        Notification.warning($scope.msg);
                    }
                    
                });
        };
        $scope.getDepartments = function (u) {

            $http.get("private/user/agac2.do?node=root")
                .then(function (response) {
                    $scope.agac = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        };
      
        $scope.getUserDetails = function () {
            path = "private/user/retrieveUserDetails.do?userId=" + $scope.userid;
            $http.get(path)
                .then(function (response) {
                    var data = response.data;
                    if (Array.isArray(data)) {
                        var userdata = response.data[0];
                        $scope.userData.userName = userdata.username;
                        $scope.userData.firstName = userdata.name;
                        $scope.userData.surname = userdata.surname;
                        $scope.userData.email = userdata.email;
                        $scope.userData.sicil = userdata.sicil;
                        $scope.userData.title = userdata.title;
                        $scope.extData = response.data[1];
                        $scope.userStatus = $scope.userData.isExternal == 0 ? $translate.instant('label.personel') : $translate.instant('label.external.user');
                    }

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error in user details');
                });
        };


    }]);