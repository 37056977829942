app.controller('scoreCtrl',['$http', '$scope', '$sce','$routeParams','Notification', function($http, $scope, $sce,$routeParams,Notification) {
    var _this = this;
    $scope.userId = $routeParams.userid ? $routeParams.userid : 1;

    // 3: Comment leaders
    // 5: point
    $scope.pagingMaxSize= 4;
    $scope.startContImprovementRate = 0;
    $scope.currentPageContImprovementRate = 1;
    $scope.currentPageUserPoints = 1;
    $scope.startPageUserPoints = 0;
    $scope.pageSizeCont = 10;
    $scope.limitCont = 10;

    $scope.pageChangedUserPointDetail = function () {
        //  $scope.currentPageCont =  $scope.currentPageCont + 1
          $scope.startPageUserPoints = ($scope.currentPageUserPoints - 1) * $scope.pageSizeCont;
          $scope.getUserPointDetailList();
      };
    
     $scope.getUserPointDetailList = function () {      
        $http({
            method: 'POST',
            url: 'private/user/getUserPoints.do?isByUser='+ $scope.userId ,
            data: '&start=' + $scope.startPageUserPoints
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont )) ,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.userPointDetailList = res.data.data;
                    $scope.userPointDetailListTotal= res.data.total;
                  //  getRewards(items);
                }
            });
    };

    $scope.loadPoints = function()
    {
    	//private/user/retrievePoints.do?_dc=1488270205528&isByUser=1&page=1&start=0&limit=25
    	$http.get("private/user/retrievePoints.do?isByUser="+ $scope.userId + "&page=1&start=0&limit=25&userIdObj=0")
        .then(function(response) {
            $scope.points = response.data.data[0];
            $scope.userName = $scope.userId > 1 ? $scope.points.name : null;
            
        }, function(response) {
            //Second function handles error
            Notification.error('hata!');
        });
    };
    $scope.loadConfigPoints = function()
    {
    	$http.get("notsecure/user/retrievePoints.do")
        .then(function(response) {
            $scope.pointsCfg = response.data.data;
            
        }, function(response) {
            //Second function handles error
            Notification.error('hata!');
        });
    };
    $scope.loadPointsByType = function()
    {
    	//private/user/retrievePointsByType.do?_dc=1488270205530&isByUser=1&page=1&start=0&limit=25
    	$http.get("private/user/retrievePointsByType.do?isByUser="+ $scope.userId + "&page=1&start=0&limit=25")
        .then(function(response) {
        	$scope.pointsByType = response.data.data;
        }, function(response) {
            //Second function handles error
            Notification.error('hata!');
        });
    };
    $scope.loadReward = function()
    {
    	//private/user/retrieveUserRewards.do?_dc=1488270205531&isByUser=1&page=1&start=0&limit=25
    	$http.get("private/user/retrieveUserRewards.do?isByUser="+ $scope.userId + "&page=1&start=0&limit=25")
        .then(function(response) {
        	$scope.rewards = response.data.data;
        }, function(response) {
            //Second function handles error
            Notification.error('hata!');
        });
    };
    $scope.loadPoints();
    $scope.loadPointsByType();
    $scope.loadReward();
    $scope.loadConfigPoints();
    $scope.getUserPointDetailList();

 
    
}]);

app.filter('userPointTypeFilter', function () {
    return function (input) {
        return 'label.user.point.type.'+input;
    };
});