app.directive("reportEvalDir", [
  "$http",
  "$translate",
  "Notification",
  function ($http, $translate, Notification) {
    return {
      restrict: "AEC",
      scope: {
        evalId: "=",
        evalType: "=",
        objectId: "=",
        objectType: "=",
        reportType: "="
      },
      templateUrl: "partial/directive/eval-report-dir.html",
      controller: function ($scope) {
        $scope.isLoading = true;
        $scope.isVisible = false;
        $scope.objectLink = "";
        $scope.objectCode = "";
        $scope.objectTitle = "";
        var path = "private/eval/retrieveEvalReport.do?evalId=" + $scope.evalId;

        if ($scope.evalId == 0) {
          if ($scope.objectType == 0) // suggestion
          {
            path = "private/eval/retrieveSuggEvalReport.do?suggId=" + $scope.objectId + "&evalType=" + $scope.evalType;
          }

        }
        $scope.loadEvalReport = function () {
       
          $http
            .get(path)
            .then(
            function (response) {
              if (response.data.success == true) {
                if (response.data.data) {
                  $scope.report = response.data.data;
                  $scope.isLoading = false;
                  $scope.isVisible = true;
                  
                  var oid = $scope.report.eval.objectId;
                  if ($scope.report.eval.objectType == 1 && $scope.reportType == 1) {
                    $scope.objectLink = "#/idea/" + oid;
                    $scope.loadIdea(oid);
                    $scope.evalTypeIdea=$scope.report.eval.evalType;
                  } else {
                    $scope.objectLink = "#/suggestion/" + oid;
                    $scope.loadSuggestion(oid);
                  }
                }
              }
            },
            function (response) {
              $translate("error.eval.load").then(function (msg) {
               // Notification.error(msg);
              });
              $scope.isLoading = false;
            }
            );
        };
        $scope.loadIdea = function (id) {
          $http.get("private/idea/retrieveSpecific.do?ideaId=" + id + "&start=0&limit=25")
            .then(function (response) {
              var obj = response.data.data[0];
              $scope.objectCode = obj.name;
              $scope.objectTitle = obj.showCase;

            }, function (response) {
              Notification.error('Error: idea');
            });
        };
        $scope.loadSuggestion = function (id) {
          $http.get("private/suggestion/retrieveModelByUser.do?query=&suggType=&suggClass=&suggState=&page=1&start=0&limit=5&sortState=&suggClass=&roleFilters=&ids=" + id)
            .then(function (response) {
              var obj = response.data.data[0];
              $scope.objectCode = obj.codeDisplay;
              $scope.objectTitle = obj.title;

            }, function (response) {
              Notification.error('Error: suggestion');
            });
        };
        $scope.loadEvalReport();
      }
    };
  }
]);
