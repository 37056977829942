app.directive("approveForm", [
  "$http",
  "$translate",
  "Notification",
  "$uibModal",
  "$rootScope",
  function ($http, $translate, Notification, $uibModal, $rootScope) {
    return {
      restrict: "E",
      scope: {
        suggId: "=",
        rejectTarget: "=",
        nextTarget: "=",
        approveTarget: "=",
        show: "=",
        expLabel: "=",
        rejectLabel: "=",
        nextLabel: "=",
        isExternal: "=",
        refresh: "&",
        owners: "=",
        isEditor: "=",
        evalScore: "=",
        suggestionStatus: "=",

      },
      templateUrl: "partial/directive/approve-form.html",
      controller: function ($scope) {
        $scope.isLoading = false;
        $scope.userScore = 0;
        $scope.exp = '';
        $scope.reward = '';
        $scope.rewardPoint = 0;
        $scope.rewardList = [];
        $scope.emailSend = false;
        $scope.noRewarsMessage = "";
        //if(!$scope.show) return;
        $scope.$watch('owners', function (newVal) {
          $scope.rewardList = [];
          if (newVal) {
            for (var i = 0; i < newVal.length; i++) {
              var u = newVal[i];
              $scope.rewardList.push({
                ownerId: u.ownerId,
                fullName: u.fullName,
                share: u.share,
                isCorresponder: u.isCorresponder,
                reward: '',
                rewardPoint: 0
              });
            }
          }
        });
        $scope.accept = function () {
          $scope.noRewarsMessage = "";
          if ($scope.rewardList[0].reward == "" && ($scope.rewardList[0].rewardPoint == 0 || $scope.rewardList[0].rewardPoint == null)) {

            $scope.noRewarsMessage = $translate.instant('info.approve.sugg.reward.confirm');
            //if (!confirm($translate.instant('info.approve.sugg.reward.confirm'))) { return };
          }


          if ($scope.rejectTarget == 4)
            ValidateData("accept", 'info.approve.sugg.confirm');
          //$scope.postData($scope.approveTarget, $scope.rewardList, 'info.approve.sugg.confirm');
          else
            ValidateData("accept", 'info.approve.sugg.confirm.preaccepted');
          //$scope.postData($scope.approveTarget, $scope.rewardList, 'info.approve.sugg.confirm.preaccepted');

        };
        $scope.nextStep = function () {

          if ($scope.rewardList[0].reward == "" && ($scope.rewardList[0].rewardPoint == 0 || $scope.rewardList[0].rewardPoint == null))
            ValidateData("nextstep", 'info.next.sugg.confirm');
          //$scope.postData($scope.nextTarget, [], 'info.next.sugg.confirm');
          else
            ValidateData("nextstep", 'info.next.sugg.confirm.preaccept');
          //$scope.postData($scope.nextTarget, [], 'info.next.sugg.confirm.preaccept');

        };
        $scope.reject = function () {

   
            if ($scope.rewardList.length > 0 && $scope.rewardList[0].reward == "" && ($scope.rewardList[0].rewardPoint == 0 || $scope.rewardList[0].rewardPoint == null))
              ValidateData("reject", 'info.reject.sugg.confirm');
            else
              ValidateData("reject", 'info.reject.sugg.confirm.reward');
          

          //$scope.postData($scope.rejectTarget, [], 'info.reject.sugg.confirm.reward');

        };

        var ValidateData = function (s, m) {
          $scope.messageArr = [];
          if ($scope.noRewarsMessage) $scope.noRewarsMessage.length > 0 ? $scope.messageArr.push($scope.noRewarsMessage) : false;


          if ($scope.evalScore == 0) {
            if ($scope.suggestionStatus == 5) {
              $scope.messageArr.push($translate.instant("sugg.pre.evaluation.board.confirm"));
              //message += message.length > 0 ? '\n'+ $translate.instant("sugg.pre.evaluation.board.confirm") : $translate.instant("sugg.pre.evaluation.board.confirm");
              //if (!confirm($translate.instant("sugg.pre.evaluation.board.confirm"))) return;
            }
            else if ($scope.suggestionStatus == 2) {
              $scope.messageArr.push($translate.instant("info.reject.sugg.confirm"));
            }
            else {
              $scope.messageArr.push($translate.instant("sugg.pre.evaluation.confirm"));
              //message += message.length > 0 ? '\n\r' + $translate.instant("sugg.pre.evaluation.confirm") :$translate.instant("sugg.pre.evaluation.confirm");
              //if(!confirm($translate.instant("sugg.pre.evaluation.confirm"))) return;
            }
          }


          if ($scope.suggestionStatus == 5) {
            $scope.messageArr.push($translate.instant("label.pre.board.point") + " " + $scope.evalScore + "/100 . " + $translate.instant(m));
            //message += message.length > 0 ? '\n\r' + $translate.instant("label.pre.board.point") + " " + $scope.evalScore + "/100 . " + $translate.instant(m) : $translate.instant("label.pre.board.point") + " " + $scope.evalScore + "/100 . " + $translate.instant(m);
            //if(!confirm($translate.instant("label.pre.board.point")+" " + $scope.evalScore +"/100 . "  + $translate.instant(message))) return;
          }
          else if ($scope.suggestionStatus == 2) {

          }
          else {
            $scope.messageArr.push($translate.instant("label.pre.evaluation.point") + " " + $scope.evalScore + "/100 . " + $translate.instant(m));
            //message += message.length > 0 ? '\n' + $translate.instant("label.pre.evaluation.point") + " " + $scope.evalScore + "/100 . " + $translate.instant(m) :$translate.instant("label.pre.evaluation.point") + " " + $scope.evalScore + "/100 . " + $translate.instant(m);
            //if(!confirm($translate.instant("label.pre.evaluation.point")+" " + $scope.evalScore +"/100 . "  + $translate.instant(message))) return;
          }

          $scope.sureModal($scope, $scope.messageArr, s);
        };

        $scope.postData = function (state, rewards) {
          $scope.isLoading = true;
          var exp = [];
          exp.push($scope.exp);
          $http({
            method: 'POST',
            url: 'private/suggestion/approve.do',
            data: 'id=' + $scope.suggId + '&state=' + state + '&rewards=' + encodeURIComponent(JSON.stringify(rewards)) + '&users=&pexplanation=&evalDate=0&explanation=' + encodeURIComponent(JSON.stringify(exp)) + '&sendtykmail=' + $scope.emailSend,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
          })
            .then(function (res) {
              if (res.data.success == true) {
                $scope.refresh();

              } else {
                $translate('error.generic').then(function (msg) {
                  Notification.error(msg);
                });
              }
              $scope.isLoading = false;
            });
        };

        $scope.sureModal = function (t, m, s) {
          $rootScope.modalInstance = $uibModal.open({
            animation: true,backdrop: 'static',
            controller: function ($scope, $uibModalInstance) {
              $scope.sureModalContentMessage = m;
              $scope.sureModalOk = function (a) {

                $uibModalInstance.close();
                if (s === "nextstep") {
                  var arrMsg = [];
                  arrMsg.push($translate.instant('label.suggestion.preaccept.mail'));
                  t.sureModal(t, arrMsg, "postdata");

                } else if (s === "postdata") {
                  t.emailSend = true;
                  t.postData(t.nextTarget, []);
                } else if (s === "accept") {
                  t.postData(t.approveTarget, t.rewardList);
                } else if (s === "reject") {
                  t.postData(t.rejectTarget, []);
                }

              }

              $scope.sureModalClose = function () {
                if (s === "postdata") {
                  t.emailSend = false;
                  t.postData(t.nextTarget, []);
                }
                t.noRewarsMessage = "";
                $uibModalInstance.close();
              };
            },
            ariaLabelledBy: "modal-title",
            ariaDescribedBy: "modal-body",
            templateUrl: "sureModalApprove.html"
          });
        };
        $scope.sureModalClose = function () {
          $scope.noRewarsMessage = "";
          $rootScope.modalInstance.close();
        };

      }
    };
  }
]);
