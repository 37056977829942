app.directive("actionForm", [
    "$http",
    "$translate",
    "Notification",
    "$uibModal",
    "$rootScope",
    "$filter",
    "$location",
    function ($http, $translate, Notification, $uibModal, $rootScope,$filter,$location) {
      return {
        restrict: "E",
        scope: {
          impId: "=",
          actions: "=",
          label:"=",
          reloadImp:"&",
          showToggle: "="
        },
        templateUrl: "partial/directive-imp/action-form.html",
        controller: function ($scope) {
          $scope.show = false;
          $scope.isLoading = false;
          $scope.$watch('actions', function (newVal) {
            if (newVal) {
              $scope.availableActions = $scope.actions;
              if($scope.availableActions.length > 0){
                  $scope.show = true;
              }   
            }
          });

          $scope.refreshImp = function () {
            $scope.sureModalClose();
            $scope.reloadImp();
          };

          $scope.action = function (actionType,exp) {
           // if(exp == null | exp == undefined )
           //   exp = "-";
            var message = $translate.instant($filter('actionLabel')(actionType))+$translate.instant("label.improvement.confirm");
            $scope.sureModal($scope,message,actionType,exp);
          };
         $scope.postData = function(actionType,exp){
            $http({
              method: 'POST',
              url: 'private/improvement/action.do',
              data: 'id=' +$scope.impId+"&action="+actionType+"&message="+encodeURIComponent(exp ? JSON.stringify(exp):''),
              headers: {
                  "content-type": "application/x-www-form-urlencoded"
              }
          })
              .then(function (res) {
                $scope.sureModalClose();
              if (res.data.success == true) {
                $scope.isLoading = true;
              if(actionType == "first-accept-request")
                   $scope.refreshImp();
              else if(actionType =="uygulayiciya.atandi")
                   $scope.refreshImp();
              else 
                  // $scope.refreshImp();
                 $location.path('/improvements');
                //  window.location.reload(true);
              }else{
                mesaj = res.data.message;
                mesajList = mesaj.split(":");
                Notification.error(mesajList[1]);
                //Notification.error(res.data.message);
              }
            },
            function(res) {
              $scope.sureModalClose();
              mesaj = res.data.message;
              mesajList = mesaj.split(":");
              Notification.error(mesajList[1]);
             // Notification.error(res.data.message);
              $scope.isLoading = false;
            });
          };

          $scope.sureModal = function (t,m,actionType,exp) {
            $rootScope.modalInstance = $uibModal.open({
              animation: true,backdrop: 'static',
              controller: function ($scope, $uibModalInstance) {
                $scope.sureModalContentMessage = m;
                $scope.sureModalOk = function (a) {
                  t.postData(actionType,exp);
                }
  
                $scope.sureModalClose = function () {

                  $uibModalInstance.close();
                };
              },
              ariaLabelledBy: "modal-title",
              ariaDescribedBy: "modal-body",
              templateUrl: "sureModalApprove.html"
            });
          };
          $scope.sureModalClose = function () {
            $scope.noRewarsMessage = "";
            $rootScope.modalInstance.close();
          };
  
        }
      };
    }
  ]);
  
  app.filter('actionLabel', function () {
    return function (input) {
      return "label.improvement.action."+input;
    };
});

app.filter('actionStatusStyle', function () {
  return function (input) {
    return 'btn-default';
  };
});
