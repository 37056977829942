app.controller("improvementSubmitCtrl", ["$scope", "$sce", "$http", "$location", "$rootScope", "$routeParams", "$translate", "$uibModal", 
"Notification",
 function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, $uibModal, Notification) {

    $rootScope.selectedFilterCampId = Number($location.search().campid);
    $scope.isLoading = false;
   // $scope.isNew = true;
    $scope.showSaveDraft = $rootScope.authenticated;
    $scope.showSubmit = true;
    $scope.id;
    $scope.improvement = {};
    $scope.responsible = {};
    $scope.showError = false;
    $scope.showMessage = false;
    $scope.improvementFields = [];
    $scope.improvementCategories = [];
    $scope.improvementFieldUsers = [];
    $scope.selectedCategory = [];
    $scope.selectedSuggestionOwners = [];
    $scope.suggestionOwners = [];
    $scope.termsChecked = true;
    $scope.isCorresponder = false;
    $scope.isUpdateImprovement = false;
    $scope.responsibleId = 0;
    $scope.impData = {};
    $scope.commandType = "update-improvement";

    $scope.filesMaxCount = $rootScope.frontendConfig.filesMaxCount;
    $scope.filesSizeMaxSugg = $rootScope.frontendConfig.filesSizeMaxSugg;
  
    $scope.multipleCategory = $rootScope.frontendConfig.improvementMultipleCategory;
    $scope.fileQueuSize=0;
    $scope.categoryItem = {
      categoryId:0,
      categoryName:"",
      id:0,
      improvementId:0
    };
    $scope.myInfo = {
      ownerId: $rootScope.currentUser == undefined ? 0 : $rootScope.currentUser.id,
      fullName: $rootScope.currentUser == undefined ? 0 : $rootScope.currentUser.fullName,
      share: 100,
      isCorresponder: true,
      mainOwner:true,
    }
    $scope.remeveSelected = function (ind) {
      var impId = $scope.selectedCategory[ind].id;
      $scope.selectedCategory.splice(ind, 1);
      for (var i = 0; i < $scope.improvementCategories.length; i++) {
        if ($scope.improvementCategories[i].id == impId) {
          $scope.improvementCategories[i].selected = false;
          return;
        }
      }
    };
    $scope.getImpFields = function () {
        var path = "private/improvement-field/availableList.do?start=0&limit=150";
        $http.get(path).then(
          function (response) {
            $scope.improvementFields = response.data.data;
          if(!($scope.improvement.fieldId) )
               $scope.improvement.fieldId = "-1"; 
                       
     
          $scope.getImpFieldUsers();
          },
          function (response) {
            //Second function handles error
            
            
          }
        );
      };


      $scope.getImpFieldUsers = function () {

        if($scope.improvement.fieldId == "" || $scope.improvement.fieldId == -1 || $scope.improvement.fieldId == undefined  )
          return null;

       var _url = "private/improvement-field-user/list-by-type.do";
       var path = _url +'?fieldId='+ $scope.improvement.fieldId+"&authType=3&start=0&limit=100&query=";
        $http.get(path).then(
          function (response) {
            $scope.improvementFieldUsers = response.data.data;
            
            if($scope.impData.state > 10){
              for(var i=0; i<$scope.improvementFieldUsers.length; i++){
                if($scope.improvementFieldUsers[i].userId == $scope.responsibleId){
                $scope.responsibleFullName = $scope.improvementFieldUsers[i].userFullName;
                }
              }
            }
            if($scope.improvementFieldUsers.length > 0){
              if($scope.responsibleId>0 ) 
                $scope.responsible = $scope.responsibleId
              else
                $scope.responsible = $scope.improvementFieldUsers[0].userId;
            }
            else
              Notification.error($translate.instant("label.submit.field.user.warning"));
          },
          function (response) {
            Notification.error($translate.instant("label.submit.field.user.warning"));
          }
        );
      };
      $scope.getImprovement = function(id){
        $scope.id = id;
        var path = "private/improvement/get.do?id=" + (id);
        $http.get(path).then(
          function (response) {
            if(response.data.data){
              var i = response.data.data;
              // şimdilik sadece draft düzenle

          
              if(i.ownerId == $scope.myInfo.ownerId )
                  $scope.isCorresponder = true;

              /*if ($scope.isEditableImprovement(i)) {
                $scope.isUpdateImprovement = true;
              }
              else {
                $scope.isUpdateImprovement = false;
                $location.path("/improvement/" + id);
              }*/
              $scope.impData = i;
              $scope.improvement = {
                id: i.id,
                title: i.title,
                problem: i.problem,
                solution: i.solution,
                ownerId: i.ownerId,
                fieldId: i.fieldId,
                campaignId: i.campaignId
              };    
          

            //  $scope.selectedCategory = i.improvementCategory;

              for(var j = 0 ; j < i.improvementCategoriesModels.length; j++) {
                $scope.selectedCategory.push({
                  id: i.improvementCategoriesModels[j].categoryId,
                  name: i.improvementCategoriesModels[j].categoryName,
                  code: i.improvementCategoriesModels[j].categoryCode,
                  selected:true
                })
              }
              if(!$scope.multipleCategory){
              if($scope.selectedCategory.length>0)
              $scope.improvement.selectedCategoryId = 0;
                $scope.improvement.selectedCategoryId = $scope.selectedCategory[0].id;
              }
              $scope.responsibleId = i.responsibleId;

              for(var j = 0 ; j < i.availableCommands.length; j++) {
                if(i.availableCommands[j].includes($scope.commandType)){
                    $scope.isUpdateImprovement = true;
                    console.log("isUpdate: " + $scope.isUpdateImprovement );
                }
             }
             
              for(var j = 0; j < i.improvementOwnersModels.length; j++){
                if(i.improvementOwnersModels[j].ownerId != $scope.myInfo.ownerId){
                  $scope.suggestionOwners.push(i.improvementOwnersModels[j]);
                }
                $scope.selectedSuggestionOwners.push({
                  id: i.improvementOwnersModels[j].ownerId,
                  name: i.improvementOwnersModels[j].fullName,
                  mainOwner: i.improvementOwnersModels[j].mainOwner,
                  surname: ""
                });
              }
              $scope.validateOwnerShares();
              $scope.getImpFields();
              $scope.getImpCategories();
             // $scope.getImpFieldUsers();
            }
          },
          function (response) {
            //Second function handles error
            Notification.error("ERROR improvement field users");
          }
        );
      }
      //--------------getCampaignList----------------
      $scope.getCampaigns = function () {
        // private/suggestionComment/retrieve.do
        $http
          .get(
            "private/campaign/retrieveCampaignsUser.do?start=0&limit=200&objectType=2"
          )
          .then(
            function (response) {
              $scope.campaigns = response.data.data ? response.data.data : [];
              if($scope.campaigns.length>0){
               if($rootScope.selectedFilterCampId != null){
                $scope.improvement.campaignId = $rootScope.selectedFilterCampId;
               }
               $scope.campaigns.unshift({ id: 0, name: $translate.instant("label.campaign.free") });
              }
              
            },
            function (response) {
              //Second function handles error
              Notification.error("ERROR campaigns");
            }
          );
      };

      $scope.getCampaigns();      
      //--------------getCampaignList----------------
      /*$scope.isEditableImprovement = function (i) {
        if ($scope.isCorresponder) {
          if (i.state == 10 || i.state == 15 || i.state == 50 || i.state == 195 || i.state == 200 || i.state == 210)
            return true;
          else
            return false;
        }
        else
          return false;
      }*/

      $scope.getImpCategories = function () {
        var path = "private/improvement-category/availableList.do?start=0&limit=25";
        $http.get(path).then(
          function (response) {
            $scope.improvementCategories = response.data.data;
            for(var i = 0 ; i<$scope.selectedCategory.length ; i++){
              for(var j = 0 ; j<$scope.improvementCategories.length ; j++){
                if( $scope.selectedCategory[i].id == $scope.improvementCategories[j].id)
                    $scope.improvementCategories[j].selected = true;
              } 
            }  

          },
          function (response) {
            //Second function handles error
            Notification.error("ERROR improvement categories");
          }
        );
      };


      
      //delete improvement
      $scope.deleteImprovement = function (isSubmit) {
        $scope.isLoading = true;

        $http({
          method: "GET",
          url: "private/improvement/delete.do?id=" +$scope.improvement.id,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
          }
        }).then(function (res) {
          $scope.isLoading = false;
          if (res.data.success == true) {
     
              Notification.success($translate.instant("info.improvement.deleted"));
              $location.path("/improvements");
         
           
          }
        });
      };
      ///

    $scope.saveImprovement = function (isSubmit) {
        $scope.isLoading = true;
        
      if($scope.improvement.selectedCategoryId>0){
        $scope.selectedCategory = [];
        for(var i = 0 ; i< $scope.improvementCategories.length ; i++ ){
          if($scope.improvement.selectedCategoryId == $scope.improvementCategories[i].id){
            $scope.selectedCategory.push($scope.improvementCategories[i]);
            break;
          }
        }
      }

       var suggOwners = []
      suggOwners.push(
        {
          ownerId: $scope.myInfo.ownerId,
          share: $scope.myInfo.share,
          corresponder: $scope.myInfo.isCorresponder,
          mainOwner: $scope.myInfo.mainOwner
        }
      );
      for (var i = 0; i < $scope.suggestionOwners.length; i++) {
     
        var so = $scope.suggestionOwners[i];
        suggOwners.push({
          ownerId: so.ownerId,
          share: so.share,
          corresponder: false,
          mainOwner: so.mainOwner
        });
      }

        $http({
          method: "POST",
          url: "private/improvement/save.do",
          data:
            "improvement=" + encodeURIComponent(JSON.stringify(new Array($scope.improvement))) + "&responsible=" +$scope.responsible + "&isSubmit="+isSubmit 
            + "&categories=" + encodeURIComponent(JSON.stringify($scope.selectedCategory )) 
            + "&owners=" + encodeURIComponent(JSON.stringify(suggOwners)) ,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
          }
        }).then(function (res) {
          $scope.isLoading = false;
          if (res.data.success == true) {
            if (isSubmit) {
              Notification.success($translate.instant("info.improvement.saved"));
              $location.path("/improvement/" + res.data.data);
            }
            else {
              Notification.success($translate.instant("info.draft.saved"));
              $location.path("/submit-improvement/" + res.data.data);
            }
          }
        });
      };
      function handleMsg(type, msg) {
        if (type == MessageTypes.ERROR) {
          $scope.showError = true;
          $scope.showMessage = false;
          $scope.errorMsg = msg;
        } else if (type == MessageTypes.INFO) {
          $scope.showError = false;
          $scope.showMessage = true;
          $scope.result = msg;
        }
      }

      var MessageTypes = {
        ERROR: 1,
        INFO: 2
      };

      $scope.updateSuggestionOwners = function (t) {
         $scope.myInfo.mainOwner = true;

        
        var newList = []
        for (var i = 0; i < $scope.selectedSuggestionOwners.length; i++) {
          if ($scope.myInfo.ownerId != $scope.selectedSuggestionOwners[i].id) {
            var so = $scope.selectedSuggestionOwners[i];
            var newSo = {};
            newSo.ownerId = so.id;
            newSo.fullName = so.name + " " + so.surname;
            newSo.share = 1;
            newSo.mainOwner = so.mainOwner;
            for (var j = 0; j < $scope.suggestionOwners.length; j++) {
              if ($scope.suggestionOwners[j].id == so.id) newSo.share = $scope.suggestionOwners[j].share;
            }
            newList.push(newSo);
          }
        }
        $scope.suggestionOwners = newList;
        $scope.validateOwnerShares();
       
      }
      $scope.suggestionOwnerFieldChange = function (ind) {
        if(ind == -1 ){
          if( $scope.suggestionOwners.length == 0 )
            return;

          for (var i = 0; i < $scope.selectedSuggestionOwners.length; i++) {
             $scope.suggestionOwners[i].mainOwner = false;
          }
          $scope.myInfo.mainOwner = true;
        }
        else{
          if( $scope.suggestionOwners[ind].mainOwner ){
            $scope.myInfo.mainOwner = true;
            $scope.suggestionOwners[ind].mainOwner = false;
          }
          else{
            for (var i = 0; i < $scope.selectedSuggestionOwners.length; i++) {
              $scope.suggestionOwners[i].mainOwner = false;
            }
            $scope.myInfo.mainOwner = false;
            $scope.suggestionOwners[ind].mainOwner = true;
          }
        }
      }
      $scope.deleteSuggestionOwner= function (ind) {
        if( $scope.suggestionOwners[ind].mainOwner){
          $scope.myInfo.mainOwner = true;
        }
        $scope.suggestionOwners.splice(ind, 1);
        $scope.selectedSuggestionOwners.splice(ind, 1);
        $scope.validateOwnerShares();
      }

      $scope.validateOwnerShares = function () {
        var total = 0;
        for (var i = 0; i < $scope.suggestionOwners.length; i++) {
          var share = $scope.suggestionOwners[i].share;
          if (share == undefined || share == null) {
            $scope.isOwnerSharesValid = false;
            return;
          }
          total += share;
        }
        if (total > 100) {
          $scope.isOwnerSharesValid = false;
        }
        else {
          $scope.isOwnerSharesValid = true;
          $scope.myInfo.share = 100 - total;
        }
      
      }

      $scope.addOwnerConfirmModal = function (t) {

        $rootScope.modalInstance = $uibModal.open({
          animation: true,backdrop: 'static',
          controller: function ($scope, $uibModalInstance) {
            $scope.Ok = function () {
              t.updateSuggestionOwners(t)
    
              $uibModalInstance.close();
            }
    
            $scope.Close = function () {
              $uibModalInstance.close();
            }
          },
          ariaLabelledBy: "modal-title",
          ariaDescribedBy: "modal-body",
          templateUrl: "addOwnerConfirmModal.html"
        });
      };

      $scope.addCategoryModal = function (t) {
      
        $rootScope.modalInstance = $uibModal.open({
          animation: true,backdrop: 'static',
          controller: function ($scope, $uibModalInstance) {

            $scope.improvementCategories = [];
            $scope.selectedCategory = [];
            angular.copy(t.selectedCategory,  $scope.selectedCategory );
            angular.copy(t.improvementCategories, $scope.improvementCategories );

            $scope.selectCategories = function(category)
            {
                category.selected = true;   
                for(var i = 0; i < $scope.selectedCategory.length; i++)
                {
                    if($scope.selectedCategory[i].id === category.id)
                    {
                      $scope.selectedCategory.splice(i, 1);
                        category.selected = false;
                        return;
                    }
                }
                $scope.selectedCategory.push(category);
              
            };   

            $scope.remeveSelected = function (ind) {
              var impId = $scope.selectedCategory[ind].id;

              $scope.selectedCategory.splice(ind, 1);
              for (var i = 0; i < $scope.improvementCategories.length; i++) {
                if ($scope.improvementCategories[i].id == impId) {
                  $scope.improvementCategories[i].selected = false;
                  return;
                }
              }
            };

            $scope.categoryModalOk = function () {
             $scope.categoryModalApprove();
            };
            
            $scope.categoryModalApprove = function () {
              angular.copy( $scope.improvementCategories, t.improvementCategories );
              angular.copy( $scope.selectedCategory , t.selectedCategory );
              $rootScope.modalInstance.close();
             };

            
            $scope.categoryModalClose = function () {
              $rootScope.modalInstance.close();
            };
    
          },
          ariaLabelledBy: "modal-title",
          ariaDescribedBy: "modal-body",
          templateUrl: "addCategoryModal.html"
        });
      };
      $scope.getISelectedCategories = function () {
        $scope.showPreRejectForm = false;
        $http
          .get("private/improvement-category/improvementCategoriesByImp.do?impId=" + id).then(
            function (response) {
            
              $scope.selectedCategory = response.data.data;
    
            },
            function (response) {
              Notification.error("ERROR improvement-category detail: " + id);
            }
          );
        };
      if ($routeParams.improvementId) {
        $scope.getImprovement($routeParams.improvementId);
      }else{
        // get new id
        $http
          .get("private/improvement/new.do").then(
            function (response) {
              $scope.id = $scope.improvement.id = response.data.data;
              $scope.improvement.campaignId = 0;
            $scope.improvement.solution = $translate.instant("label.improvement.solution.place.holder") ; 
            $scope.improvement.problem = $translate.instant("label.improvement.problem.place.holder") ; 
            },
            function (response) {
              Notification.error("ERROR creating new improvement");
            }
          );
        $scope.getImpFields();
        $scope.getImpCategories();
      }

      $scope.submitSureModal = function (t,isSubmit) {

        $rootScope.modalInstance = $uibModal.open({
          animation: true,backdrop: 'static',
          controller: function ($scope, $uibModalInstance) {
       
            $scope.sureModalOk = function () {
              if(isSubmit == 1 || isSubmit ==2){                            
                t.saveImprovement(isSubmit)              
                $uibModalInstance.close();
                }              
            }
    
            $scope.sureModalClose = function () {
              $uibModalInstance.close();
            };
          },
          ariaLabelledBy: "modal-title",
          ariaDescribedBy: "modal-body",
          templateUrl: "submitSureModal.html"
        });
      };
      $scope.deleteSureModal = function (t,isSubmit) {

        $rootScope.modalInstance = $uibModal.open({
          animation: true,backdrop: 'static',
          controller: function ($scope, $uibModalInstance) {
       
            $scope.sureModalOk = function () {
                t.deleteImprovement();           
                $uibModalInstance.close();
            }
    
            $scope.sureModalClose = function () {
              $uibModalInstance.close();
            };
          },
          ariaLabelledBy: "modal-title",
          ariaDescribedBy: "modal-body",
          templateUrl: "deleteSureModal.html"
        });
      };


}]);
app.filter('impFileTypes', function () {
  return function (input) {
    var ext = '_blank';
    if(~input.lastIndexOf('.'))
      ext = input.substring(input.lastIndexOf(".") + 1);
    else ext = input;
      return ext.toLowerCase()+".png";
  };
});
app.filter('bytes', function() {
	return function(bytes, precision) {
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
		if (typeof precision === 'undefined') precision = 1;
		var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
			number = Math.floor(Math.log(bytes) / Math.log(1024));
		return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
	}
});