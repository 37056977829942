app.controller("alarmSettingsCtrl", ['$scope', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate', '$uibModal',
    function ($scope, $http, $location, $rootScope, $routeParams, Notification, $translate, $uibModal) {

        $scope.taskConfigList = [];
        $scope.suggestionTypes = [];
        $scope.selectedBoard = [];
        $scope.pageSize = 50;
        $scope.pagingMaxSize = 4;
        $scope.innovationType = {};
        $scope.innovationTypeData = {};
        $scope.suggestionType = {};
        $scope.updatestate = false;
        $scope.model = {};
        $scope.model.allItemsSelected = false;
        $scope.data = { query: '' };
        $scope.data.query = $location.search().query || '';
        $scope.activeForm = $scope.data.query != '' ? 1 : 0;
        $scope.taskConfig =  {};
        $scope.taskConfig.id = 0;

        $scope.init = function (a) {
            $scope.innovationTypes = [];
            if (a === 'i') $scope.getTaskConfigList();
           
        };

        $scope.getTaskConfigList = function () {
            //query=&external=internal&page=1&start=0&limit=30
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $http.get("private/task-config/list.do")
                .then(function (response) {
                    $scope.taskConfigList = response.data.data;
                    $scope.typeTotal = response.data.total;
                    $scope.loading = false;
                }, function (response) {
                    Notification.error(response.data.message);
                });
        };
   
      
      
      
        $scope.sureModalClose = function () {
            $rootScope.modalInstance.close();
        };


        $scope.updateTaskConfig = function (i) {

            if (i) {
                $scope.taskConfig = angular.copy(i);
              //  $scope.innovationType.status = 1;
            }
        };
       /* $scope.deleteInnovationType = function (i) {

            if (i) {
                $scope.taskConfig = angular.copy(i);
                $scope.innovationTypeData.status = 0;
            }
            $scope.saveUpdateInnovation();
        }; */
        $scope.activateInnovationType = function (i) {

            if (i) {
                $scope.taskConfig = angular.copy(i);
                $scope.taskConfig.status = 1;
            }
            $scope.saveUpdateInnovation();
        };

   

        $scope.getTemplate = function (i) {
            if (i.id === $scope.taskConfig.id) return 'edit';
            else return 'display';
        };
        
        $scope.cancelEditTaskConfig = function (i) {
            $scope.taskConfig = {};
            $scope.updatestate = false;

        };

       
        $scope.saveTaskConfig = function (i) {

            if(i) $scope.taskConfig = angular.copy(i);
          //  if($scope.innovationType.status) $scope.innovationTypeData = angular.copy($scope.innovationType);
            $http({
                method: 'POST',
                url: 'private/task-config/update.do',
                data: 'data=' + encodeURIComponent(JSON.stringify($scope.taskConfig)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.taskConfig = {};
                    $scope.getTaskConfigList();
                    $scope.updatestate = false;
                    if (res.data.success == true) {
                        Notification.success($translate.instant("sugg.state.10"));
                    } else {
                        $scope.hasError = true;
                        Notification.error(response.data.message);
                    }
                   
                });

        };
      

    }]);