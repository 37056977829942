app.directive("ratingForm", [
    "$http",
    "$translate",
    "Notification",
    "$uibModal",
    "$rootScope",
    "$filter",
    function ($http, $translate, Notification, $uibModal, $rootScope, $filter) {
        return {
            restrict: "E",
            scope: {
                label: "=",
                objectId: "=",
                objectType: "=",
                commands: "=",
                commandType: "=",
                editType: "=",
            },
            templateUrl: "partial/directive-imp/rating.html",
            controller: function ($scope) {

                $scope.show = false;
                $scope.showview, $scope.showedit = false;
                $scope.editorComment = {};
                $scope.improvementRating = {};
                //$scope.improvementRating.rate = 0;

                $scope.$watch('objectId', function (newVal) {
                    if (newVal) {
                        $scope.availableCommands = $scope.commands;
                        if (newVal && $scope.availableCommands != null && $scope.availableCommands.length > 0) {
                            if ($scope.availableCommands.includes($scope.commandType)) {
                                $scope.show = true;
                                $scope.showview = true;
                            }
                            if ($scope.availableCommands.includes($scope.editType)) {
                                $scope.showedit = true;
                            }
                        }

                    }
                });

                $scope.getRating = function () {
                    $scope.promise = $http.get("private/improvement-rating/getUserRating.do?impId=" + $scope.objectId
                    ).then(
                        function (response) {
                           
                            if (response.data.success == true) {
                                var data = response.data.data;
                                $scope.improvementRating.rate = data.rate;
                                $scope.getAverageRating();

                            } else {
                                $translate('error.generic').then(function (msg) {
                                    Notification.error(msg);
                                });
                            }
                        }
                    );
                };

                $scope.getAverageRating = function () {
                    $scope.promise = $http.get("private/improvement-rating/getAverageRating.do?impId=" + $scope.objectId
                    ).then(
                        function (response) {

                            if (response.data.success == true) {
                                $scope.averageRating = response.data.data;

                            } else {
                                $translate('error.generic').then(function (msg) {
                                    Notification.error(msg);
                                });
                            }
                        }
                    );
                };

                $scope.saveRating = function () {
                    if ($scope.improvementRating.rate != 0) {
                        $http({
                            method: 'POST',
                            url: 'private/improvement-rating/saveRating.do',
                            data: 'id=' + $scope.objectId + "&rate=" + $scope.improvementRating.rate,
                            headers: {
                                "content-type": "application/x-www-form-urlencoded"
                            }
                        })
                            .then(function (response) {

                                if (response.data.success == true) {
                                    $scope.getRating();

                                } else {
                                    $translate('error.generic').then(function (msg) {
                                        Notification.error(msg);
                                    });
                                }
                            });
                    } else {
                        $http.get("private/improvement-rating/deleteRating.do?impId=" + $scope.objectId
                        ).then(
                            function (response) {

                                if (response.data.success == true) {
                                    $scope.getRating();
                                    $scope.improvementRating.rate = 0;
                                } else {
                                    $translate('error.generic').then(function (msg) {
                                        Notification.error(msg);
                                    });
                                }
                            }
                        );

                    }

                };

                $scope.getRating();
            }
        }
    }]);