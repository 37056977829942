app.controller("competitionEvalReportCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, Notification) {
        $scope.competitionId = $routeParams.competitionId;
        $scope.loadCompetition = function () {
            var path = "private/market/retrieveProblemModel.do?problemId=" + $scope.competitionId + "&start=0&limit=1&page=1&marketState=O&problemState=";
            $http.get(path)
                .then(function (response) {
                    $scope.competition = response.data.data[0];
                    $scope.competition.description = $sce.trustAsHtml(response.data.data[0].description);
                    $scope.loadReport();

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.loadReport = function () {
            $http.get("private/eval/retrieveEvalForCompetition.do?competitionId=" + $scope.competitionId)
                .then(function (response) {
                    $scope.report = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: report');
                });
        };
        $scope.CompetitionReport = function () {
            $http.get("private/idea/exportHtml.htm?id=" + $scope.competitionId)
                .then(function (response) {
                    $scope.report = response.data;
                     var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
                    winPrint.document.write($scope.report);
                    winPrint.document.close();
                    winPrint.focus();
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: report');
                });
        }

        $scope.excelExport = function () {
            $scope.loading = true;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            if (typeof $scope.query == 'undefined') $scope.query = '';
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
           $scope.myPromise = $http.get("private/eval/retrieveEvalForCompetitionExcel.htm?competitionId=" + $scope.competitionId, { responseType: 'arraybuffer' })
                .then(function (response) {

                    var header = response.headers('Content-disposition')
                    var fileName = header.split("=")[1].replace(/\"/gi, '');
                

                    var blob = new Blob([response.data],
                        { type: 'application/vnd.ms-excel;charset=utf-8' });
                    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                    var link = angular.element('<a/>');
                    link.attr({
                        href: objectUrl,
                        download: fileName
                    })[0].click();

                    $scope.currentPage = page;
                    $scope.loading = false;

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        }

        $scope.loadCompetition();
    }
]);
