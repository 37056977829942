app.controller("adminCampaignsCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, Notification, $translate) {
        $scope.loading = true;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        var path = "private/campaign/retrieveCampaigns.do?start=0&limit=25";
        $http.get(path)
            .then(function (response) {
                $scope.authenticated = $rootScope.authenticated;
                if(!($scope.authenticated && $rootScope.currentUser)) $location.path("/login");
        
                $scope.campaigns = response.data.data;
                $scope.loading = false;
            }, function (response) {
                //Second function handles error
                Notification.error('hata!');
            });

    }
]);
app.filter('campaignStatusStyle', function () {
    return function (input) {
        switch (input) {
            case 0:
                return 'text-danger';
            case 1:
                return 'text-primary';
            case 2:
                return 'text-info';
            case 3:
                return 'text-warning';
            default:
                return 'text-default';
        }
    };
});
app.filter('campaignStatusLabel', function () {
    return function (input) {
        switch (input) {
            case 0:
                return 'campaign.status.deleted';
            case 1:
                return 'campaign.status.draft';
            case 2:
                return 'campaign.status.submitted';
            case 3:
                return 'campaign.status.closed';
            default:
                return 'campaign.status.none';
        }
    };
});