app.directive("assignUser", [
  "$http",
  "$translate",
  "Notification",
  "$uibModal",
  "$rootScope",
  "$filter",
  function ($http, $translate, Notification, $uibModal, $rootScope,$filter) {
    return {
      restrict: "E",
      scope: {
        impId: "=",
        fieldId: "=",
        authType:"=",
        commands: "=",
        commandType: "=",
        label:"=",
        info:"=",
        selectionLimit:"=",
        selected:"=",
        selectorLabel: "=",
        modalTitle:"=",
        showAllUser:"=",
        modalBodyTitle:"=",
        showToggle: "=",
        reloadImp:"&",
      },
      templateUrl: "partial/directive-imp/assign-user.html",
      controller: function ($scope) {
        $scope.isLoading = false;
        $scope.show = false;
        $scope.selectedAssignedUser = [];
        $scope.usersList=[];
        $scope.refreshImp = function () {
          $scope.reloadImp();
        };

        $scope.$watch('commands', function (newVal) {
          if (newVal) {
            $scope.show = false;
            $scope.availableCommands = $scope.commands;
            if($scope.availableCommands != null && $scope.availableCommands.length > 0){
              for(var i =0; i < $scope.availableCommands.length ; i++){
                if( $scope.availableCommands[i] === $scope.commandType  ){
                  $scope.show = true;
                  if($scope.selected){
                    $scope.selectedAssignedUser = $scope.selected;
                  }              
                }
              }
            }
          }
        });

        $scope.$watch('selected', function (newVal) {
          if (newVal) {
            $scope.selectedAssignedUser = $scope.selected;
          }
        });
        $scope.saveSelectedUser = function (users) {
          var list = [];
          $scope.usersList=[];
          if($scope.selectedAssignedUser === undefined && users){
            $scope.selectedAssignedUser = users;
          }
          for (var i = 0; i < $scope.selectedAssignedUser.length; i++) {
            list[i] = {
              "userId" : $scope.selectedAssignedUser[i].id,
              "improvementId":$scope.impId,
              "fieldId" :$scope.fieldId,
              "authType":$scope.authType
            };
          }
          if (list.length == 0) return;
          $http({
            method: "POST",
            url: "private/improvement-users/save.do",
            data:
              "users=" +encodeURIComponent(JSON.stringify(list)) ,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
          }).then(function (res) {
            if (res.data.success == true) {
              if($scope.authType == 4) //mentor
                 Notification.success($translate.instant("label.imp.assign.user.4"));
              else if($scope.authType == 5) //uzman
                 Notification.success($translate.instant("label.imp.assign.user.5"));
              else if($scope.authType == 9) //danışman
                 Notification.success($translate.instant("label.imp.assign.user.9"));
              else
                 Notification.success($translate.instant("label.imp.assign.user.0"));
              $scope.refreshImp();
            } else {
              $translate("error.generic").then(function (msg) {
                Notification.error(msg);
              });
            }
          });
        };

        $scope.update = function (t) {

          $rootScope.modalInstance = $uibModal.open({
            animation: true,backdrop: 'static',
            controller: function ($scope, $uibModalInstance) {
              $scope.modalTitle = t.modalTitle;
              $scope.modalBodyTitle = t.modalBodyTitle;
              $scope.selectorLabel = t.selectorLabel;
              $scope.Ok = function () {
              t.saveSelectedUser(t.selectedAssignedUser)
      
                $uibModalInstance.close();
              }
      
              $scope.Close = function () {
                t.selectedAssignedUser = [];
                for(var i=0; i < t.usersList.length; i++){
                  t.selectedAssignedUser.push({
                    id: t.usersList[i].id,
                    fullName: t.usersList[i].fullname
                  });
                }                             
                $uibModalInstance.close();
              }
            },
            ariaLabelledBy: "modal-title",
            ariaDescribedBy: "modal-body",
            templateUrl: "addConfirmModal.html"
          });
        };
      }
    };
  }
]);


