
app.controller("improvementDetailCtrl", ["$scope", "$sce", "$http", "$location", "$rootScope", "$routeParams", "$translate", "$uibModal", "Notification", function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, $uibModal, Notification) {
  var _this = this;
  var id = $routeParams.improvementId;
  
  $scope.id = id;
  $scope.files = [];
  $scope.evalTotalScore = 0;
  $scope.selectedEditor = [];
  $scope.selectedExperts = [];
  $scope.selectedAdvisor = [];
  $scope.selectedImplementers = [];
  $scope.responsibleUser = {};
  $scope.isCorresponder = false;
  $scope.projectPortfolioState = $rootScope.frontendConfig.projectPortfolioState;
  if ($scope.projectPortfolioState.indexOf(",")) {
    $scope.projectPortfolioStates = [];
    $scope.projectPortfolioStates = $scope.projectPortfolioState.split(",");
  }
  $scope.commandType = "update-improvement";
  $scope.isUpdateImprovement  = false;
  $scope.planningDate = {
    planStartDate: 0,
    planEndDate:0,
    actualStartDate:0,
    actualEndDate:0
  } 
  $scope.isCommentWrite = false;
  $scope.userAuthList = [];
  $scope.isAdmin = $rootScope.currentUser.authorization && $rootScope.currentUser.authorization.hasAdminAccess
  $scope.getImprovement = function () {
      $scope.showPreRejectForm = false;
      var path = "private/improvement/get.do?id=" + (id?id:$scope.id);
      $http
        .get(path).then(
          function (response) {

            if(!response.data.success){
           
              Notification.error( response.data.message );
              return;
            }

            $scope.improvement = response.data.data;
            $rootScope.impOwner = $scope.improvement.improvementOwnersModels;
            console.log( $rootScope.impOwner);
            $scope.getUploadedFiles();
            if($scope.improvement.authorizationList != null && $scope.improvement.authorizationList.length > 0){
              $scope.selectedEditor = [];
              $scope.selectedExperts = [];
              $scope.selectedImplementers = [];
              $scope.selectedAdvisor= [];
              for(var i=0;i<$scope.improvement.authorizationList.length;i++){
                if( $scope.improvement.authorizationList[i].authType === 4){
                  $scope.editor = $scope.improvement.authorizationList[i];
                  if ($scope.editor){
                    $scope.selectedEditor.push({
                      id: $scope.editor.userId,
                      fullName: $scope.editor.userFullName,
                      name: $scope.editor.userFullName
                    });
                  } 
                }
                if( $scope.improvement.authorizationList[i].authType === 3){
                  $scope.responsibleUser = $scope.improvement.authorizationList[i];
                }
                if( $scope.improvement.authorizationList[i].authType === 4){
                  $scope.improvement.userMentor = $scope.improvement.authorizationList[i];
                }
                if( $scope.improvement.authorizationList[i].authType === 5){
                    $scope.selectedExperts.push({
                      id: $scope.improvement.authorizationList[i].userId,
                      fullName: $scope.improvement.authorizationList[i].userFullName,
                      name: $scope.improvement.authorizationList[i].userFullName
                    });
                   
                }
                if( $scope.improvement.authorizationList[i].authType === 6){
                  $scope.improvement.userImplementer = $scope.improvement.authorizationList[i];
                  $scope.selectedImplementers.push({
                    id: $scope.improvement.authorizationList[i].userId,
                    fullName: $scope.improvement.authorizationList[i].userFullName,
                    name: $scope.improvement.authorizationList[i].userFullName
                  });
                 
              }
              
              if( $scope.improvement.authorizationList[i].authType === 9){
                $scope.selectedAdvisor.push({
                  id: $scope.improvement.authorizationList[i].userId,
                  fullName: $scope.improvement.authorizationList[i].userFullName,
                  name: $scope.improvement.authorizationList[i].userFullName
                });
               
            }
             
            if( ($scope.improvement.authorizationList[i].authType === 9)){
                if($scope.improvement.authorizationList[i].userId === $rootScope.currentUser.id){
                   $rootScope.advId = $rootScope.currentUser.id;
                }
            }
     
              if($scope.improvement.authorizationList[i].userId == $rootScope.currentUser.id )
                  $scope.userAuthList.push($scope.improvement.authorizationList[i].authType)
              }

             
              $scope.isShowOwner = $scope.isImprovementOwnersShow([0,1]);
              $scope.isRateShow = $scope.isImprovementOwnersShow([0]);
              $scope.isCommentWrite = $scope.isImprovementOwnersShow([7]);
                 
              $scope.isShowAddProject = $scope.isImprovementOwnersShow([1]);

              $rootScope.isOwner = $scope.isImprovementOwnersShow([0]);
 
            }

            $scope.improvementCategories = $scope.improvement.improvementCategoriesModels;
           
            for(var i = 0 ;  i <   $scope.improvement.improvementApplyCalenderModels.length ; i++){
              if(  $scope.improvement.improvementApplyCalenderModels[i].type == 1 ){
                $scope.planningDate.planStartDate = $scope.improvement.improvementApplyCalenderModels[i].startDate;
                $scope.planningDate.planEndDate = $scope.improvement.improvementApplyCalenderModels[i].endDate;
              }
              if(  $scope.improvement.improvementApplyCalenderModels[i].type == 2 ){
                $scope.planningDate.actualStartDate = $scope.improvement.improvementApplyCalenderModels[i].startDate;
                $scope.planningDate.actualEndDate = $scope.improvement.improvementApplyCalenderModels[i].endDate;
              }
            }
            
            $scope.isUpdateImprovement = $scope.isEditableImprovement();
          },
          function (response) {
            Notification.error("ERROR improvement detail: " + id);
          }
        );
      };

      /*buraya bak */
      $scope.isEditableImprovement = function () {
        for(var i=0 ; i< $scope.improvement.improvementOwnersModels.length ; i++){
          if($scope.improvement.improvementOwnersModels[i].corresponder){
            if($scope.improvement.improvementOwnersModels[i].ownerId == $rootScope.currentUser.id )
                $scope.isCorresponder = true;
          }
        }
        if ($scope.isCorresponder) {
          for(var j = 0 ; j < $scope.improvement.availableCommands.length; j++) {
            if($scope.improvement.availableCommands[j].includes($scope.commandType)){
                return true;
               // console.log("isUpdate: " + $scope.isUpdateImprovement );
            }
         }
        }
        else
          return false;
      }
   
      /****************** LIST PREVIOUSLY UPLOADED FILES ******************/ 
      $scope.getUploadedFiles = function () {
        var path  = "private/improvement-file/list.do?objectType=2" + "&objectId="+$scope.improvement.id + "&impId="+$scope.improvement.id;
        $http
          .get(path)
          .then(
            function (response) {
              $scope.files = response.data.data ? response.data.data : [];
            },
            function (response) {
              //Second function handles error
              Notification.error("ERROR files");
              $scope.isLoading = false;
            }
          );
      };
      $scope.getImprovement();

      $scope.isImprovementOwnersShow = function( showList ){
        var isResult = false;
   
        if(showList == undefined || showList == null || showList.length == 0 )
            return false;

        for(var i = 0 ; i < showList.length ; i++){
          for(var j = 0 ; j < $scope.userAuthList.length ; j++){
            if( showList[i] == $scope.userAuthList[j] )
              {
                isResult= true;
                return true;
              }
          }
        }
     
        return isResult;
      }

        /* Project Portfolio */
$scope.projectPortfolioModal = function (t) {
  $rootScope.modalInstance = $uibModal.open({
    animation: true,backdrop: 'static',
    size: 'lg',
    controller: function ($scope, $uibModalInstance) {
      $scope.project = {};
      $scope.selectedproject = {};
      $scope.openNeworAddContent = function () {
        if ($scope.radioModel == 'Add') {
          $http.get("project/retrieveProjects.do?&start=0&limit=25&states=1&priorities=").then(
            function (response) {
              var list = response.data.data;
              $scope.projects = list;

            });
        }
        else {
          $scope.project.title = t.improvement.title;
          $scope.project.purpose = t.improvement.problem;
          $scope.project.scope = t.improvement.solution;
          $scope.project.status = 1;
        }
      }
      $scope.projectModalSave = function () {

        $http({
          method: 'POST',
          url: 'project/createFromIdeaOrSuggestionOrImprovements.do',

          data: 'project=' + encodeURIComponent(JSON.stringify($scope.project)) + '&suggestionId=0&ideaId=0' + '&improvementId=' + t.id,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
          }
        })
          .then(function (res) {
            if (res.data.success) {
              Notification.success($translate.instant('competition.state.closed'));
              t.getProjectInformation();
              $uibModalInstance.close();
            }
          });

      }
      $scope.assignToProject = function () {
        $http({
          method: 'POST',
          url: 'project/addToCurrentProject.do',

          data: 'projectId=' + $scope.selectedproject.id + '&suggestionId=0&ideaId=0' + '&improvementId=' + t.id,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
          }
        })
          .then(function (res) {
            if (res.data.success) {
              Notification.success($translate.instant('competition.state.closed'));
              t.getProjectInformation();
              $uibModalInstance.close();
            }
          });
      }
      $scope.projectModalClose = function () {
        $uibModalInstance.close();
      }
      $scope.selectProject = function (p) {
        $scope.selectedproject = p;
      }
    },
    ariaLabelledBy: "modal-title",
    ariaDescribedBy: "modal-body",
    templateUrl: "projectPortfolioModal.html"
  });
};
$scope.getProjectInformation = function () {
  $http.get("project/retrieveUsedImprovementByID.do?id=" + $scope.id).then(
    function (response) {
      var datas = response.data;
      $scope.projectsidea = datas.data;
      $scope.isprojectAssinged = datas.success;

    });
}
$scope.getProjectInformation();
        /* Project Portfolio */
}]);
