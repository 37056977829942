app.directive("saveReward", [
    "$http",
    "$translate",
    "Notification",
    "$uibModal",
    "$rootScope",
    "$filter",
    function ($http, $translate, Notification, $uibModal, $rootScope,$filter) {
      return {
        restrict: "E",
        scope: {
          impId: "=",
          reloadImp:"&",
          commands: "=",
          commandType:"=",
          label: "=",
          showToggle: "=",
          show:"="
        },
        templateUrl: "partial/directive-imp/save-reward.html",
        controller: function ($scope) {


          $scope.isLoading = false;
          $scope.warningShow = true;

          $scope.refreshImp = function () {
            $scope.reloadImp();
          };

          $scope.$watch('commands', function (newVal) {
            if (newVal) {
              $scope.availableCommands = $scope.commands;
              $scope.show=false;
          
              for(var i =0; i < $scope.availableCommands.length ; i++){
                if( $scope.availableCommands[i] === $scope.commandType  ){
                  $scope.show=true;
             
                  $scope.getUserAndRecommend();
              //    $scope.getImpFieldUsers($scope.selectedFieldId);
                }
              }
               
            }
          });

          $scope.getUserAndRecommend = function () {
     
            var path = "private/improvement-reward/recommend.do?impId=" + $scope.impId;
            $http.get(path).then(
              function (response) {
                
                $scope.irr = response.data.data;
                $scope.irrUsers = $scope.irr.users
                $scope.isLoading = true;

                $scope.userRewardCombines = [];
                for(var i = 0 ; i <  $scope.irrUsers.length ; i++){
                  var userRewardCombine = {
                    userId: $scope.irrUsers[i].userId,
                    point: $scope.irrUsers[i].userCurrentPoint == -1 ? null : $scope.irrUsers[i].userCurrentPoint,
                    recommended: $scope.irrUsers[i].recommended,
                    fullName: $scope.irrUsers[i].fullName,
                    share: $scope.irrUsers[i].share,
                    sharePercent: $scope.irrUsers[i].share/100,
                    coefficient: $scope.irrUsers[i].coefficient,
                  }
                  console.log("rewardRecommend: " + $rootScope.frontendConfig.rewardRecommend)
                  if($rootScope.frontendConfig.rewardRecommend){
                      userRewardCombine.max = ( ($scope.irr.max*userRewardCombine.sharePercent) -  $scope.irrUsers[i].userTotalPointForImp) > 0 ? (($scope.irr.max*userRewardCombine.sharePercent) - $scope.irrUsers[i].userTotalPointForImp) : 0;
                      userRewardCombine.min = (($scope.irr.min*userRewardCombine.sharePercent)-  $scope.irrUsers[i].userTotalPointForImp) > 0 ? ( ($scope.irr.min*userRewardCombine.sharePercent) - $scope.irrUsers[i].userTotalPointForImp) : 0;
                  }else{
                      userRewardCombine.max = $scope.irr.max;
                      userRewardCombine.min = $scope.irr.min;    
                  }
                  $scope.userRewardCombines.push( userRewardCombine );
                }

              },
              function (response) {
                //Second function handles error
                Notification.error("ERROR improvement recommended");
              }
            );
          };

          $scope.changePoint=function(){
           $scope.warningShow = true;
          }
        
          $scope.saveRewardUser = function(){
            $http({
              method: "POST",
              url: "private/improvement-reward/save.do",
              data:
              "impId="+$scope.impId
             + "&points=" + encodeURIComponent(JSON.stringify( $scope.userRewardCombines)) ,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
              }
            }).then(function (res) {
              if (res.data.success == true) {
                Notification.success($translate.instant("label.improvement.reward.save"));
                $scope.refreshImp();
                $scope.warningShow = false;
              } else {
                $translate("error.generic").then(function (msg) {
                  Notification.error(msg);
                });
              }
            });

            
          };

        }

      };
    }
  ]);
  
 
