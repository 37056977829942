app.directive("benefitAnalysisView", [
  "$http",
  "$translate",
  "Notification",
  "$uibModal",
  "$rootScope",
  "$filter",
  function ($http, $translate, Notification, $uibModal, $rootScope,$filter) {
    return {
      restrict: "E",
      scope: {
        impId: "=",
        commands: "=",
        authType: "=",
        label: "=",
        showToggle: "="
      },
      templateUrl: "partial/directive-imp/benefit-analysis-view.html",
      controller: function ($scope) {
     
        $scope.isLoading = false;
       
      
        $scope.getImpBenefitList = function(){
          var path = "private/improvementBenefit/listByUser.do";
            $http({
              method: 'POST',
              url: path,
              data: 'command=' + encodeURIComponent(JSON.stringify($scope.commands)) + '&impId=' + $scope.impId,
              headers: {
                  "content-type": "application/x-www-form-urlencoded"
              }
          }).then(
              function (response) {
                $scope.isLoading = true;
                $scope.benefitList = response.data.data;
               
                if( $scope.benefitList.length > 0)
                   $scope.show=true; 
             
              },
              function (response) {
                //Second function handles error
                Notification.error("ERROR improvementBenefit/listByUser ");
              }
            );
        };

        $scope.$watch('commands', function (newVal) {
          if (newVal) {
           
            if($scope.commands.length > 0)
                 $scope.getImpBenefitList();
          }
        });

        if($scope.commands.length > 0)
            $scope.getImpBenefitList();
      }

    };
  }
]);


