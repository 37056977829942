app.controller("suggestionsCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', 'Notification', '$translate',
    function ($scope, $sce, $http, $location, $rootScope, Notification, $translate) {
        $scope.pageSize = 10;
        $scope.pagingMaxSize = 4;
        $scope.query = $location.search().query || '';
        $scope.selectedTypes = ($location.search().type && $location.search().type.split(',')) || [];
        $scope.selectedStates = ($location.search().state && $location.search().state.split(',')) || [];
        $scope.selectedRoles = ($location.search().role && $location.search().role.split(',')) || [];
        $scope.selectedCampaigns = ($location.search().campaign && $location.search().campaign.split(',')) || [];
        $scope.sortStr = $location.search().sort || '';
        $scope.isAdmin = false;
        $scope.external = $location.search().external || '-1';
        $scope.loading = false;
        $scope.evalTotalScore = 0;
        $scope.dataToggleExternal = $translate.instant('label.content.terms-suggestion-external');
        $scope.dataToggleInternal = $translate.instant('label.content.terms-suggestion-internal');
        $scope.getSuggestions = function () {
            $scope.loading = true;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            if (typeof $scope.query == 'undefined') $scope.query = '';
            var page = $location.search().page || 1;
            var sort = $location.search().sort ||  $scope.sortStr;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $http.get("private/suggestion/retrieveModelByUser.do?query=" + encodeURIComponent($scope.query) + "&suggType=" + $scope.selectedTypes.join() + "&suggClass=&suggState=" + $scope.selectedStates.join() + "&page=1&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize + "&sortState=" + sort + "&suggClass=&roleFilters=" + $scope.selectedRoles.join() + "&campaign=" + $scope.selectedCampaigns.join() + "&external=" + $scope.external)
                .then(function (response) {
                    $scope.suggestions = response.data.data;
                    $scope.totalSugg = response.data.total;
                    $scope.currentPage = page;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.displayRangeEnd = ($scope.currentIndex + $scope.pageSize) > $scope.totalSugg ? $scope.totalSugg : ($scope.currentIndex + $scope.pageSize);

                    $scope.loading = false;
                    $scope.isAdmin = $rootScope.currentUser ? $rootScope.currentUser.authorization.hasAdminAccess : false;
                }, function (response) {
                    //Second function handles error
                    Notification.error('hata!');
                });
        };
        $scope.sort = function (key, type) {
            $scope.sortStr = key + ' ' + type;
            $scope.getSuggestions();
        };
        $scope.filterSuggestions = function () {
            $scope.currentPage = 1;
            $scope.currentIndex = 0;
            $location.search().page = 1;
            $location.search().type = $scope.selectedTypes.toString();
            $location.search().state = $scope.selectedStates.toString();
            $location.search().role = $scope.selectedRoles.toString();
            $location.search().campaign = $scope.selectedCampaigns.toString();
            $location.search().external = ($scope.external) ? $scope.external : '-1';
            /*
            var tempData = []
            for(var i = 0 ; i < $scope.selectedTypes.length ; i++ )
            {
              for(var s= 0 ; s< $scope.suggestionTypes.length ; s++ ){
                if( $scope.suggestionTypes[s].id  ==  $scope.selectedTypes[i]) 
                  tempData.push( $scope.suggestionTypes[s].name )
              }
            }
            $scope.testData.push({durum: tempData});
            */
            $location.path('/suggestions').search('query', $scope.query);
        };
        $scope.clearFilter = function () {
            $location.url($location.path());
        };
        $scope.getSuggestionTypes = function () {
            $http.get("private/suggestion/retrieveSuggestionTypesAllByLang.do")
                .then(function (response) {
                    $scope.suggestionTypes = response.data.data;
                    selectFilters($scope.suggestionTypes, $scope.selectedTypes);
                }, function (response) {
                    //Second function handles error
                    Notification.error('hata!');
                });
        };
        $scope.generateSuggTypes = function (t) {
            // {{t.status === 0 ? t.name + " (" + "label.header.deactive" | translate + ")" : t.name }}
            var str = "";
            if (t.status === 0) {
                str += t.name;
                str += " (" + $translate.instant('label.header.deactive') + ")";
            }
            else {
                str += t.name;
            }
            return str;
        };
        $scope.getCampaigns = function () {
            $http.get("private/campaign/retrieveCampaignsUser.do?start=0&limit=25&objectType=1")
                .then(function (response) {
                    $scope.campaigns = response.data.data;
                    selectFilters($scope.campaigns, $scope.selectedCampaigns);
                }, function (response) {
                    Notification.error('hata!');
                });
        };
        $scope.checkTykUser = function () {
            $http.get("private/suggestion/isUserBoardMember.do")
                .then(function (res) {
                    if (res.data.success == true) {
                        $scope.showTykFilter = true;
                    } else {
                        $scope.showTykFilter = false;
                    }
                    for (var i = 0; i < $scope.roleFilters.length; i++) {
                        if ($scope.roleFilters[i].id == 3) $scope.roleFilters[i].enabled = $scope.showTykFilter;
                        else if ($scope.roleFilters[i].id == 4) $scope.roleFilters[i].enabled = $rootScope.currentUser.externalState == 0;
                        else if ($scope.roleFilters[i].id == 2) $scope.roleFilters[i].enabled = $rootScope.currentUser.externalState == 0;
                    }
                }, function (response) {
                    Notification.error('hata!');
                });
        };
        function selectFilters(arr, selected) {
            if (arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                    arr[i].selected = false;
                    for (var j = 0; j < selected.length; j++) {
                        if (arr[i].id == selected[j]) {
                            arr[i].selected = true;
                            break;
                        }
                    }
                }
            }

        }
        $scope.$watch('suggestionTypes|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedTypes = nv.map(function (t) {
                    return t.id;
                });
            }
        }, true);
        $scope.$watch('campaigns|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedCampaigns = nv.map(function (t) {
                    return t.id;
                });
            }
        }, true);
        $scope.suggestionStates = window.suggestionStates;

        $scope.$watch('suggestionStates|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedStates = nv.map(function (s) {
                    return s.id;
                });
            }
        }, true);

        $scope.roleFilters = window.roleFilters;

        $scope.$watch('roleFilters|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedRoles = nv.map(function (r) {
                    return r.id;
                });
            }
        }, true);

        $scope.isMine = function (params) {
            if (typeof params === "object") {
                for (var i = 0; i < params.length; i++) {
                    if (Number($rootScope.currentUser.id) == params[i].ownerId) return true;
                }
            }
            else {
                if (Number($rootScope.currentUser.id) == params) return true;
                else return false;
            }

        };
        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function () {

            $scope.currentIndex = ($scope.currentPage - 1) * $scope.pageSize;
            $location.path('/suggestions').search('page', $scope.currentPage).search('sort',$scope.sortStr);

        };

        $scope.go = function (path) {
            $location.path(path);
        };

        $scope.isShowFilterRow = function (filterList) {

            if (filterList == [] || filterList == null || filterList == undefined)
                return false;

            for (var i = 0; i < filterList.length; i++) {
                if (filterList[i].selected)
                    return true
            }


        };
        /* Excel */
        $scope.excelExport = function () {
            $scope.loading = true;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            if (typeof $scope.query == 'undefined') $scope.query = '';
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $http.get("private/suggestion/exportExcel.htm?query=" + encodeURIComponent($scope.query) + "&suggType=" + $scope.selectedTypes.join() + "&suggClass=&suggState=" + $scope.selectedStates.join() + "&page=1&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize + "&sortState=" + $scope.sortStr + "&suggClass=&roleFilters=" + $scope.selectedRoles.join() + "&campaign=" + $scope.selectedCampaigns.join(), { responseType: 'arraybuffer' })
                .then(function (response) {

                    var header = response.headers('Content-disposition')
                    var fileName = header.split("=")[1].replace(/\"/gi, '');

                    var blob = new Blob([response.data],
                        { type: 'application/vnd.ms-excel;charset=utf-8' });
                    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                    var link = angular.element('<a/>');
                    link.attr({
                        href: objectUrl,
                        download: fileName
                    })[0].click();

                    $scope.currentPage = page;
                    $scope.loading = false;

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        }

        $scope.excelExportNoPaging = function () {
            $scope.loading = true;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            if (typeof $scope.query == 'undefined') $scope.query = '';
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $http.get("private/suggestion/exportExcel.htm?query=" + encodeURIComponent($scope.query) + "&suggType=" + $scope.selectedTypes.join() + "&suggClass=&suggState=" + $scope.selectedStates.join() + "&page=1&start=0&limit=" + $scope.totalSugg + "&sortState=" + $scope.sortStr + "&suggClass=&roleFilters=" + $scope.selectedRoles.join() + "&campaign=" + $scope.selectedCampaigns.join(), { responseType: 'arraybuffer' })
                .then(function (response) {

                    var header = response.headers('Content-disposition')
                    var fileName = header.split("=")[1].replace(/\"/gi, '');
               

                    var blob = new Blob([response.data],
                        { type: 'application/vnd.ms-excel;charset=utf-8' });
                    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                    var link = angular.element('<a/>');
                    link.attr({
                        href: objectUrl,
                        download: fileName
                    })[0].click();

                    $scope.currentPage = page;
                    $scope.loading = false;

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        }
        $scope.isFiltersExpanded = true;
        $scope.isSortOpen = false;
        selectFilters(window.suggestionStates, $scope.selectedStates);
        selectFilters(window.roleFilters, $scope.selectedRoles);
        $scope.getSuggestions();
        $scope.getSuggestionTypes();
        $scope.getCampaigns();
        $scope.checkTykUser();
    }
]);
app.filter('suggStatusStyle', function () {
    return function (input) {
        switch (input) {
            case 0:
                return 'text-default';
            case 2:
                return 'text-primary';
            case 4:
                return 'text-danger';
            case 5:
                return 'text-info';
            case 8:
                return 'text-success';
            case 9:
                return 'text-danger';
            case 10:
                return 'text-warning';
            case 11:
                return 'text-danger';
            case 12:
                return 'text-info';
            default:
                return 'text-default';
        }
    };
});
app.filter('suggStatusLabel', function () {
    return function (input) {
        switch (input) {
            case 0:
                return 'sugg.status.draft';
            case 2:
                return 'sugg.status.submitted';
            case 4:
                return 'sugg.status.prereject';
            case 5:
                return 'sugg.status.preaccept';
            case 8:
                return 'sugg.status.accept';
            case 9:
                return 'sugg.status.lastreject';
            case 10:
                return 'sugg.status.completed';
            case 11:
                return 'sugg.status.firstreject';
            case 12:
                return 'sugg.status.editor';
            default:
                return 'sugg.status.none';
        }
    };
});
app.filter('ratingToStar', function ($sce) {
    return function (input) {
        var res = '';
        var fullStarCount = Math.floor(input);
        var odd = input - fullStarCount;
        for (var i = 0; i < fullStarCount; i++) {
            res += ' <span class="text-warning"><i class="fa fa-star" aria-hidden="true"></i></span>';
        }
        if (fullStarCount < 5) {
            if (odd < .25) {
                res += ' <span class="text-warning"><i class="fa fa-star-o" aria-hidden="true"></i></span>';
             //   res += '<span  ng-class='input.state == 12 ? 'text-warning':'text-muted' '><i class="fa fa-star-o" aria-hidden="true"></i></span>';
            } else if (odd < .75) {
                res += ' <span class="text-warning"><i class="fa fa-star-half-o" aria-hidden="true"></i></span>';
             //   res += '<span  ng-class='input.state == 12 ? 'text-warning':'text-muted' '><i class="fa fa-star-half-o" aria-hidden="true"></i></span>';
            } else {
                res += ' <span class="text-warning"><i class="fa fa-star" aria-hidden="true"></i></span>';
              //  res += '<span  ng-class='input.state == 12 ? 'text-warning':'text-muted' '><i class="fa fa-star" aria-hidden="true"></i></span>';
            }
            for (var i = fullStarCount + 1; i < 5; i++) {
                res += ' <span class="text-warning"><i class="fa fa-star-o" aria-hidden="true"></i></span>';
              //  res += '<span  ng-class='input.state == 12 ? 'text-warning':'text-muted' '><i class="fa fa-star-o" aria-hidden="true"></i></span>';
            }
        }    
        return $sce.trustAsHtml(res);
    }
});

app.filter('ratingToStarForImprovement', function ($sce) {
    return function (input) {
    var res = '';
    var fullStarCount = Math.floor(input);
    var odd = input - fullStarCount;
    for (var i = 0; i < fullStarCount; i++) {
        res += ' <span class="text-warning"><i class="fa fa-star" aria-hidden="true"></i></span>';
    }
    if (fullStarCount < 5) {
        if (odd < .25) {
            res += ' <span class="text-warning"><i class="fa fa-star-o" aria-hidden="true"></i></span>';
         //   res += '<span  ng-class='input.state == 12 ? 'text-warning':'text-muted' '><i class="fa fa-star-o" aria-hidden="true"></i></span>';
        } else if (odd < .75) {
            res += ' <span class="text-warning"><i class="fa fa-star-half-o" aria-hidden="true"></i></span>';
         //   res += '<span  ng-class='input.state == 12 ? 'text-warning':'text-muted' '><i class="fa fa-star-half-o" aria-hidden="true"></i></span>';
        } else {
            res += ' <span class="text-warning"><i class="fa fa-star" aria-hidden="true"></i></span>';
          //  res += '<span  ng-class='input.state == 12 ? 'text-warning':'text-muted' '><i class="fa fa-star" aria-hidden="true"></i></span>';
        }
        for (var i = fullStarCount + 1; i < 5; i++) {
            res += ' <span class="text-warning"><i class="fa fa-star-o" aria-hidden="true"></i></span>';
          //  res += '<span  ng-class='input.state == 12 ? 'text-warning':'text-muted' '><i class="fa fa-star-o" aria-hidden="true"></i></span>';
        }
    }    
        return $sce.trustAsHtml(res);
    }
});
app.filter('noTextToDash', function () {
    return function (input) {
        if (input == undefined || input == null || input == '') {
            return '-';
        } else return input;
    }
});
window.suggestionStates = [
    { id: 0, selected: false },
    { id: 2, selected: false },
    { id: 11, selected: false },
    { id: 12, selected: false },
    { id: 4, selected: false },
    { id: 5, selected: false },
    { id: 9, selected: false },
    { id: 8, selected: false },
    { id: 10, selected: false }
];
window.roleFilters = [
    { id: 0, selected: false, enabled: true, name: 'label.role.mysuggestion' },
    { id: 4, selected: false, enabled: false, name: 'label.role.editor' },
    { id: 2, selected: false, enabled: false, name: 'label.role.expert' },
    { id: 3, selected: false, enabled: false, name: 'label.role.board' },
    { id: 1, selected: false, enabled: true, name: 'label.role.discussion' }
];