app.directive("openDiscussionCampaign", [
    "$http",
    "$translate",
    "Notification",
    "$uibModal",
    "$rootScope",
    "$filter",
    function ($http, $translate, Notification, $uibModal, $rootScope,$filter) {
      return {
        restrict: "E",
        scope: {
          impId: "=",
          commands: "=",
          commandType:"=",
          showToggle: "="
        },
        templateUrl: "partial/directive-imp/open-discussion-campaign.html",
        controller: function ($scope) {
          $scope.show = false;
          $scope.isLoading = false;
         
     
      
          $scope.$watch('impId', function (newVal) {
            if (newVal) {  
                if( $scope.impId > 0 ){
                  $scope.getAgac('root');
                  $scope.show = true;
                }
            }
          });


          $scope.treeOptions = {};
          $scope.getAgac = function (node) {
            $http.get("private/campaign/agac.do?node=" + node + "&campaignId=" + $scope.impId).then(
              function (response) {
                $scope.children = response.data.data;
              },
              function (response) {
                //Second function handles error
                Notification.error("hata!");
              }
            );
          };
          $scope.getAgac('root');
          $scope.findSelectionById = function (id, children) {
            for (var i = 0; i < children.length; i++) {
              if (children[i].id == id) return children[i];
              else if (children[i].children) return $scope.findSelectionById(id, children[i].children);
            }
          }
          $scope.toggleParentSelection = function (item) {
            if (item.parentId) {
              var parent = $scope.findSelectionById(item.parentId, $scope.children);
              var checkedCount = 0;
              var childCount = 0;
              for (var i = 0; i < parent.children.length; i++) {
                childCount++;
                if (parent.children[i].checked) checkedCount++;
              }
              parent.checked = (childCount == checkedCount);
              $scope.toggleParentSelection(parent);
            }
          }
          $scope.toggleChildSelection = function (item, checked) {
            item.checked = checked;
            if (item.children != null) {
              for (var i = 0; i < item.children.length; i++) {
                $scope.toggleChildSelection(item.children[i], checked);
              }
            }
          }
          $scope.toggleGroupSelection = function (item) {
            item.checked = !item.checked;
            $scope.toggleChildSelection(item, item.checked);
            $scope.toggleParentSelection(item);
          };
          $scope.toggleChildren = function (scope) {
            if (!scope.$nodeScope.$modelValue.children) {
              $http.get("private/campaign/agac.do?node=" + scope.$nodeScope.$modelValue.id + "&campaignId=" + $scope.impId).then(function (response) {
                scope.$nodeScope.$modelValue.children = response.data.data;
                if (scope.$nodeScope.$modelValue.children.length > 0) {
                  for (var i = 0; i < scope.$nodeScope.$modelValue.children.length; i++) {
                    scope.$nodeScope.$modelValue.children[i].parentId = scope.$nodeScope.$modelValue.id;
                  }
        
                  if (scope.$nodeScope.$modelValue.checked) {
                    for (var i = 0; i < scope.$nodeScope.$modelValue.children.length; i++) {
                      scope.$nodeScope.$modelValue.children[i].checked = true;
                    }
        
                  }
                  scope.toggle();
                }
              });
            } else {
              if (scope.$nodeScope.$modelValue.children.length > 0) {
                scope.toggle();
              }
            }
          };
          var gruplist = [];
          $scope.SetChildrenToGroupList = function (children) {
            if (children) {
              for (var i = 0; i < children.length; i++) {
                var c = children[i];
                if (c.checked) {
                  gruplist.push({
                    checked: c.checked,
                    hasChild: c.hasChild,
                    id: c.id,
                    idTree: c.idTree,
                    leaf: c.leaf,
                    name: c.name
                  });
                } else if (children[i].children) {
                  $scope.SetChildrenToGroupList(children[i].children);
                }
              }
            }
          }
          $scope.sureModal = function (t) {
        
            gruplist = [];
            $scope.SetChildrenToGroupList($scope.children);
        
            if (gruplist.length > 0) 
            {
            
            
            $rootScope.modalInstance = $uibModal.open({
              animation: true,backdrop: 'static',
              controller: function ($scope, $uibModalInstance) {
           
                $scope.sureModalOk = function () {
                  t.sureMailModal(t)
                  $uibModalInstance.close();
                }
        
                $scope.sureModalClose = function () {
                  $uibModalInstance.close();
                };
              },
              ariaLabelledBy: "modal-title",
              ariaDescribedBy: "modal-body",
              templateUrl: "sureModal.html"
            });
          }
          else {
            t.saveImprovementUserOrganization(false)
          }
          };
          $scope.sureModalClose = function () {
            $rootScope.modalInstance.close();
          };
        
          $scope.saveImprovementUserOrganization = function (sendMail) {
            gruplist = [];
            $scope.SetChildrenToGroupList($scope.children);
          
            $http({
              method: "POST",
              url: "private/campaign/saveCampaignUserOrganization.do",
              data:
                "groupList=" + encodeURIComponent(JSON.stringify(gruplist)) + "&id=" + $scope.impId + "&mail=" + sendMail,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
              }
            }).then(function (res) {
              if (res.data.success == true) {
                //$scope.getSuggestion();
                if (gruplist.length > 0) Notification.success($translate.instant("label.save.return.success"));
                else Notification.warning($translate.instant("label.save.return.success"));
              } else {
                $translate("error.generic").then(function (msg) {
                  Notification.error(msg);
                });
              }
            });
          }
        
          $scope.sureMailModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
              animation: true,backdrop: 'static',
              controller: function ($scope, $uibModalInstance) {
           
                $scope.sureMailModalOk = function () {
                  t.saveImprovementUserOrganization(true)
                  $uibModalInstance.close();
                }
        
                $scope.sureMailModalClose = function () {
                  t.saveImprovementUserOrganization(false)
                  $uibModalInstance.close();
                };
              },
              ariaLabelledBy: "modal-title",
              ariaDescribedBy: "modal-body",
              templateUrl: "sureMailModal.html"
            });
          };
          $scope.sureMailModalClose = function () {
            $rootScope.modalInstance.close();
          };

        }
      };
    }
  ]);
  