app.controller("reportImprovementProcessCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate', 'Notification', 'dateFormat',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, Notification, dateFormat) { 

         /*Init */
         $scope.lang = $rootScope.lang;
         $scope.$watch('$rootScope', function (newValue, oldValue) {
             $scope.lang = $rootScope.lang;
         });
         $scope.init = function () {
             $scope.getImprovementProcessFieldStatusReport();
             $scope.getImprovementProcessReport();
         };
        
       /*Default Variables*/
        $scope.clearFilter = function () {
            var date = new Date();
            var year = date.getFullYear();
            year = year - 1;
            date.setFullYear(year);
            $scope.basTarih = date;
            $scope.bitTarih = new Date();
        };
        $scope.clearFilter();

         /*Functions*/
         $scope.getImprovementProcessReport = function () {
            var tarih = {};
            tarih.basTarih = dateFormat.formatter($scope.basTarih);
            tarih.bitTarih = dateFormat.formatter($scope.bitTarih);

            $http({
                method: 'POST',
                url: 'private/improvementProcessReport.do',
                data: 'market=' + encodeURIComponent(JSON.stringify(tarih)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.impData = res.data.data;
                      //  generateCharts();

                    }
                });
        };

       

        $scope.optionsValue = {responsive: true,colors : ['#4D5360']};
        $scope.colors = ['#7FDBFF', '#3498DB', '#717984', '#F1C40F','#72C02C', '#3498DB', '#FF4136', '#F1C40F','#3498DB', '#717984', '#F1C40F','#72C02C', '#3498DB', '#717984', '#F1C40F'];
        $scope.chartDataList = [];

        
        $scope.generateChartsData = function (statusFieldMap) {
            var dataFieldAndStatus = [];
            for (var i = 0; i < statusFieldMap.length; i++) {
                dataFieldAndStatus.push(String(statusFieldMap[i].count));
            }
            return dataFieldAndStatus;
         }

        $scope.getImprovementProcessFieldStatusReport = function () {
            var tarih = {};
            tarih.basTarih = dateFormat.formatter($scope.basTarih);
            tarih.bitTarih = dateFormat.formatter($scope.bitTarih);

            $http({
                method: 'POST',
                url: 'private/improvementProcessFieldStatusReport.do',
                data: 'market=' + encodeURIComponent(JSON.stringify(tarih)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.impDataFieldStatus = res.data.data;
                    $scope.chartDataList = [];
                    $scope.labels = [];
                    for(var i = 0 ; i<$scope.impDataFieldStatus.length ; i++){
                        $scope.chartDataList.push( $scope.generateChartsData($scope.impDataFieldStatus[i].statusCountMap ));
                    }

                    for(var i = 0 ; i<$scope.impDataFieldStatus[0].statusCountMap.length ; i++){
                        $scope.labels.push($translate.instant('improvement.status.'+  $scope.impDataFieldStatus[0].statusCountMap[i].state));

                    }
                    }
                });
        };

        $scope.applyFilter = function(){
            $scope.getImprovementProcessFieldStatusReport();
            $scope.getImprovementProcessReport();
        }
        $scope.excelExport = function () {
            var tarih = {};
            tarih.basTarih = dateFormat.formatter($scope.basTarih);
            tarih.bitTarih = dateFormat.formatter($scope.bitTarih);
            
            $scope.excelPromise = $http.get("private/improvement/exportExcelProcessReport.htm?market=" + encodeURIComponent(JSON.stringify(tarih)),{responseType: 'arraybuffer'})
                .then(function (response) {

                     var header = response.headers('Content-disposition')
                     var fileName = $translate.instant("label.improvement.report.excel.name");
                     

                    var blob = new Blob([response.data],
                        {type : 'application/vnd.ms-excel;charset=utf-8'});
                    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                    var link = angular.element('<a/>');
                    link.attr({
                        href : objectUrl,
                        download : fileName
                    })[0].click();

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        }
  
     

        $scope.getLetterAt = function (location) {
            var alphabet = "ABCDEFGHIJKLMNOPRSTUVYZ".split("");
            if (location < alphabet.length) {
                return alphabet[location];
            } else {
                return 'X';
            }
        };

         /* DatePicker Setup*/
         $scope.open1 = function () {
            $scope.popup1.opened = true;
        };
        $scope.popup1 = {
            opened: false
        };
        $scope.open2 = function () {
            $scope.popup2.opened = true;
        };
        $scope.popup2 = {
            opened: false
        };
        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[3];
        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2035, 5, 22),
             startingDay: 1
        };

    }]);