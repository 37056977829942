app.directive("fileUploader", [
    "$http",
    "$translate",
    "Notification",
    "Upload",
    function ($http, $translate, Notification, Upload) {
      return {
        restrict: "E",
        scope: {
          impId: "=",
          objectType: "=",
          objectId: "=",
          commandToView: "=",
          commandToEdit: "=",
          maxCount: "=",
          label: "=",
          supportedFormats: "=",
        },
        templateUrl: "partial/directive-imp/file-uploader.html",
        controller: function ($scope) {
          $scope.maxCount;
          $scope.txt = "";
          $scope.fileList = [];
          $scope.uploadedFiles = [];
          $scope.isLoading = false;
          $scope.isUploading = false;
          $scope.show = false;
          $scope.prevObjectId;
          $scope.$watch('objectId', function (newVal) {
            if (newVal && $scope.prevObjectId != newVal) {
              $scope.prevObjectId = newVal;
              $scope.show = true;
              $scope.getUploadedFiles();
            }
          });
          /****************** REMOVE FILE FROM UPLOAD QUEUE ******************/
          $scope.removeFile = function (i) {
            $scope.fileList.splice(i, 1);
          };
          /****************** UPDATE UPLOAD QUEUE ******************/
          $scope.updateFiles = function (newFiles) {
            if (newFiles.length > 0) {
              if ($scope.fileList.length + newFiles.length + $scope.uploadedFiles.length > $scope.filesMaxCount ) {
                Notification.error($translate.instant("label.file.count.max")+": "+$scope.filesMaxCount);
              } else {
                for(var i = 0; i<newFiles.length;i++){
                  $scope.fileList.push(newFiles[i]);
                }
                $scope.uploadEnabled = true;
                Notification.info($translate.instant("info.files.added"));
              }
            }
          };
          
          /****************** UPLOAD FILES ******************/
          $scope.uploadFiles = function () {
            if ($scope.fileList.length + $scope.uploadedFiles.length > $scope.maxCount) {
              Notification.error($translate.instant("error.files.max3"));
              return;
            }
            if ($scope.fileList && $scope.fileList.length <= $scope.maxCount && $scope.fileList.length > 0) {
              $scope.isUploading = true;
              var url = "private/improvement-file/upload.do?objectType="+$scope.objectType + "&objectId="+$scope.objectId + "&commandToView="+$scope.commandToView + "&commandToEdit="+$scope.commandToEdit + "&impId="+$scope.impId 
              + "&desc="+encodeURIComponent($scope.txt);
              Upload.upload({
                url: url,
                ignoreLoadingBar: true,
                data: {
                  files: $scope.fileList
                }
              }).then(
                function (response) {
                  $scope.isUploading = false;
                  if (response.data.success) {
                    $scope.txt = "";
                    $scope.fileList = [];
                    Notification.info($translate.instant("info.files.uploaded"));
                    $scope.fileList = [];
                    $scope.getUploadedFiles();
                  } else {
                    Notification.error($translate.instant(response.data.message));
                  }
                },
                function (response) {
                  $scope.isUploading = false;
                  if (response.status > 0) {
                    Notification.error(response.status + ": " + response.data);
                  }
                }
              );
            } else {
              Notification.error($translate.instant("error.files.max3"));
            }
          };        
          /****************** LIST PREVIOUSLY UPLOADED FILES ******************/ 
          $scope.getUploadedFiles = function () {
            $scope.isLoading = true;
            var path  = "private/improvement-file/list.do?objectType="+$scope.objectType + "&objectId="+$scope.objectId + "&impId="+$scope.impId;
            $http
              .get(path)
              .then(
                function (response) {
                  $scope.uploadedFiles = response.data.data ? response.data.data : [];
                  $scope.isLoading = false;
                },
                function (response) {
                  //Second function handles error
                  Notification.error("ERROR suggestion files");
                  $scope.isLoading = false;
                }
              );
          };
          /****************** DELETE UPLOADED FILE ******************/
          $scope.deleteFile = function (id) {
            $scope.isLoading = true;
            $scope.isUploading = true;
            var path = "private/improvement-file/delete.do?id=" + id +"&impId="+$scope.impId;
            
            $http({
              method: "POST",
              url: path,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
              }
            }).then(function (res) {
              $scope.isLoading = false;
              $scope.isUploading = false;
              $scope.getUploadedFiles();
              Notification.info($translate.instant("info.file.deleted"));
            });
          };
          /****************** END OF FUNCTIONS ******************/
        }
      };
    }
  ]);
  app.filter('formatFileTypes', function () {
    return function (input) {
      return input.replace(/\./g, " ").toUpperCase();
    };
  });