app.controller("ideaListCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, Notification) {
        $scope.competitionId = $routeParams.competitionId;
        $scope.loading = false;
        $scope.pageSize = 10;
        $scope.pagingMaxSize = 4;
        $scope.evalOrder = 'evaluation';
        $scope.query = $location.search().query || '';
        $scope.selectedStates = ($location.search().state && $location.search().state.split(',')) || [];
        $scope.selectedCategories = ($location.search().category && $location.search().category.split(',')) || [];
        for (var i = 0; i < $scope.selectedCategories.length; i++) {
            $scope.selectedCategories[i] = parseInt($scope.selectedCategories[i]);
        }
        $scope.pathL = $location.url();
        $scope.pathL = $scope.pathL.substring($location.path().length);
        $scope.sortStr = $location.search().sort || 'idea.enterDate desc';
        $scope.loadCompetition = function () {
            $http.get("private/market/retrieveProblemModelSpecific.do?problemId=" + $scope.competitionId + "&start=0&limit=1")
                .then(function (response) {
                    $scope.competition = response.data.data[0];
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.loadReport = function () {
            $http.get("private/eval/retrieveEvalForCompetition.do?competitionId=" + $scope.competitionId)
                .then(function (response) {
                    $scope.reporteval = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: report');
                });
        };
        $scope.loadReport();
        $scope.loadCategories = function () {
            $http.get("private/market/retrieveProblemCategory.do?problemId=" + $scope.competitionId + "&page=1&start=0&limit=25")
                .then(function (response) {
                    $scope.categories = response.data.data;

                    for (var i = 0; i < $scope.categories.length; i++) {
                        if ($scope.categories[i].parent != null && $scope.categories[i].parent.name != null) {
                            $scope.categories[i].selectionName = $scope.categories[i].parent.name + " > " + $scope.categories[i].name;
                        } else {
                            $scope.categories[i].selectionName = $scope.categories[i].name;
                        }
                    }
                    $scope.loadIdeas();
                    $scope.setSelectedCategoriesFromFilter();
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Categories ');
                });

        };
        $scope.loadIdeas = function () {
            $scope.loading = true;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            if (typeof $scope.query == 'undefined') $scope.query = '';
            var page = $location.search().page || 1;
            var sort = $location.search().sort || $scope.sortStr;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $http.get("private/idea/retrieve.do?problemId=" + $scope.competitionId + "&page=1&start=" +
                $scope.currentIndex + "&limit=" + $scope.pageSize + "&query="
                + encodeURIComponent($scope.query) + "&sort=" + sort + "&ideaState="
                + $scope.selectedStates.join() + "&ideaCategories=" + $scope.selectedCategories.join())
                //$http.get("private/idea/retrieveByUser.do?problemId=" + $scope.competitionId + "&page=1&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize + "&query=" + encodeURIComponent($scope.query) + "&sort=" + $scope.sortStr + "&ideaState=" + $scope.selectedStates.join())
                .then(function (response) {
                    $scope.ideas = response.data.data;
                    for (var i = 0; i < $scope.ideas.length; i++) {
                        for (var j = 0; j < $scope.categories.length; j++) {
                            if ($scope.ideas[i].problemCategoryId === $scope.categories[j].id) {
                                $scope.ideas[i].idea.category = $scope.categories[j];
                            }
                        }
                    }
                    $scope.ideaCount = response.data.total;
                    $scope.currentPage = page;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.displayRangeEnd = ($scope.currentIndex + $scope.pageSize) > $scope.ideaCount ? $scope.ideaCount : ($scope.currentIndex + $scope.pageSize);
                    $scope.loading = false;
                    $scope.isAdmin = $rootScope.currentUser.authorization.hasAdminAccess;
                    $scope.checkIsEvaluator();

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Ideas');
                });
        };
        $scope.checkIsEvaluator = function () {
            $http.get("private/problem/retrieveIsEvaluator.do?id=" + $scope.competitionId)
                .then(function (response) {
                    $scope.isEvaluator = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: checkIsEvaluator');
                });
        };
        function selectFilters(arr, selected) {
            if (arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                    arr[i].selected = false;
                    for (var j = 0; j < selected.length; j++) {
                        if (arr[i].id == selected[j]) {
                            arr[i].selected = true;
                            break;
                        }
                    }
                }
            }



        }

        $scope.isMine = function (id) {
            if (Number($rootScope.currentUser.id) == id) return true;
            else false;
        };
        $scope.isMineForlistParam = function (ideaOwnersModels) {
            for(var i = 0 ; i< ideaOwnersModels.length ; i++){
                if ($rootScope.currentUser && Number($rootScope.currentUser.id) == ideaOwnersModels[i].ownerId) return true;
            }
            return false;
        };
        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function () {
            $scope.currentIndex = ($scope.currentPage - 1) * $scope.pageSize;
            $location.path('/idea-list/' + $scope.competitionId).search('page', $scope.currentPage).search('sort', $scope.sortStr);
        };

        $scope.sort = function (key, type) {
            $scope.sortStr = key + ' ' + type;
            $scope.loadIdeas();
        };
        $scope.filterIdeas = function () {
            $scope.currentPage = 1;
            $scope.currentIndex = 0;
            $location.search().page = 1;
            $location.search().state = $scope.selectedStates.toString();
            $location.search().category = $scope.selectedCategories.toString();
            $location.path('/idea-list/' + $scope.competitionId).search('query', $scope.query).search('ideaState', $scope.selectedStates);
            $scope.pathL = $location.url();
            $scope.pathL = $scope.pathL.substring($location.path().length);
        };
        $scope.clearFilter = function () {
            $location.url($location.path());
            $scope.pathL = $location.url();
            $scope.pathL = $scope.pathL.substring($location.path().length);
            $scope.selectedCategories = [];
        };
        $scope.ideaStates = window.ideaStates;
        $scope.$watch('ideaStates|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedStates = nv.map(function (s) {
                    return s.id;
                });
            }
        }, true);

        $scope.selectCategroy = function (c) {
            var contains = 0;
            if (c.parentId == 0 && (c.hasChild == 1 || c.hasChild == 0)) {
                if (c.selected && c.hasChild == 0) $scope.selectedCategories.push(c.id);
                if (!c.selected && c.hasChild == 0) {
                    contains = $scope.selectedCategories.indexOf(c.id)
                    if (contains != -1) $scope.selectedCategories.splice(contains, 1);
                }
                for (var i = 0; i < $scope.categories.length; i++) {
                    if ($scope.categories[i].parentId == c.id) {

                        if (c.selected) {
                            $scope.categories[i].selected = c.selected;
                            $scope.selectedCategories.push($scope.categories[i].id);
                        } else {
                            $scope.categories[i].selected = c.selected;
                            contains = $scope.selectedCategories.indexOf($scope.categories[i].id)
                            if (contains != -1) $scope.selectedCategories.splice(contains, 1);
                        }

                    } else {
                        contains = $scope.selectedCategories.indexOf(c.parentId)
                        if (contains != -1) $scope.selectedCategories.splice(contains, 1);
                    }

                }
            } else {
                if (c.selected) {
                    $scope.selectedCategories.push(c.id);

                    checkSelectedAll(c);
                }
                else {
                    contains = $scope.selectedCategories.indexOf(c.id)
                    if (contains != -1) $scope.selectedCategories.splice(contains, 1);
                    checkSelectedAll(c);
                }
            }
        }
        var checkSelectedAll = function (c) {
            var returnValue = false;

            if (c.selected) {
                returnValue = true;
                for (var i = 0; i < $scope.categories.length; i++) {

                    if ($scope.categories[i].parentId === c.parentId && !$scope.categories[i].selected) {
                        returnValue = false; break;
                    }

                }
                for (var i = 0; i < $scope.categories.length; i++) {

                    if ($scope.categories[i].id === c.parentId) {
                        $scope.categories[i].selected = returnValue; break;
                    }
                }
            } else {

                for (var i = 0; i < $scope.categories.length; i++) {

                    if ($scope.categories[i].id === c.parentId) {
                        $scope.categories[i].selected = c.selected; break;
                    }
                }
            }

        }
        $scope.setSelectedCategoriesFromFilter = function () {
            var contains = 0;
            if ($scope.selectedCategories.length > 0) {

                for (var i = 0; i < $scope.categories.length; i++) {

                    contains = $scope.selectedCategories.indexOf($scope.categories[i].id);
                    if (contains != -1) {

                        $scope.categories[i].selected = true;
                        checkSelectedAll($scope.categories[i]);
                    }
                }

            }
            $scope.isShowFilterRow($scope.categories);
        }

        selectFilters(window.ideaStates, $scope.selectedStates);
        $scope.excelExport = function () {

            $http.get("private/idea/exportExcel.do?problemId=" + $scope.competitionId + "&page=1&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize + "&query=" + encodeURIComponent($scope.query) + "&sort=" + $scope.sortStr + "&ideaState=" + $scope.selectedStates.join(), { responseType: 'arraybuffer' })
                .then(function (response) {

                    var header = response.headers('Content-disposition')
                    var fileName = header.split("=")[1].replace(/\"/gi, '');
               

                    var blob = new Blob([response.data],
                        { type: 'application/vnd.ms-excel;charset=utf-8' });
                    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                    var link = angular.element('<a/>');
                    link.attr({
                        href: objectUrl,
                        download: fileName
                    })[0].click();

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        }
        $scope.excelExportNoPaging = function () {

            $http.get("private/idea/exportExcel.do?problemId=" + $scope.competitionId + "&page=1&start=0&limit=" + $scope.ideaCount + "&query=" + encodeURIComponent($scope.query) + "&sort=" + $scope.sortStr + "&ideaState=" + $scope.selectedStates.join() + "&ideaCategories=" + $scope.selectedCategories.join(), { responseType: 'arraybuffer' })
                .then(function (response) {

                    var header = response.headers('Content-disposition')
                    var fileName = header.split("=")[1].replace(/\"/gi, '');

                    var blob = new Blob([response.data],
                        { type: 'application/vnd.ms-excel;charset=utf-8' });
                    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                    var link = angular.element('<a/>');
                    link.attr({
                        href: objectUrl,
                        download: fileName
                    })[0].click();

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        }
        $scope.isShowFilterRow = function (filterList) {

            if (filterList == [] || filterList == null || filterList == undefined)
                return false;

            for (var i = 0; i < filterList.length; i++) {
                if (filterList[i].selected)
                    return true
            }
        }
        $scope.GetAvgEval = function (a) {

            if ($scope.reporteval && $scope.reporteval.length > 0) {
                for (var i = 0; i < $scope.reporteval.length; i++) {
                    if ($scope.reporteval[i].objectId == a.id) {
                        return $scope.reporteval[i].avgRating;
                    }
                }
            }


            return 0;
        }
        $scope.GetAvgEvalCount = function (a) {
            if ($scope.reporteval && $scope.reporteval.length > 0) {
                for (var i = 0; i < $scope.reporteval.length; i++) {
                    if ($scope.reporteval[i].objectId == a.id) {
                        return $scope.reporteval[i].evalCount;
                    }
                }
            }
            return 0;
        }
        $scope.generateStyle = function (c) {
            $scope.styleC = "";
            if (c.parentId == 0 && (c.hasChild == 1 || c.hasChild == 0)) {
                $scope.styleC = {
                    "white-space": "nowrap",
                    "overflow": "hidden",
                    "text-overflow": "ellipsis",
                    "text-align": "left",
                    "padding-left": "10px"
                };
            } else {
                $scope.styleC = {
                    "white-space": "nowrap",
                    "overflow": "hidden",
                    "text-overflow": "ellipsis",
                    "text-align": "left",
                    "padding-left": "20px"
                };
            }
            return $scope.styleC;
        }
        $scope.generateClass = function (c) {
            $scope.classC = "";
            if (c.parentId == 0 && (c.hasChild == 1 || c.hasChild == 0)) {
                $scope.classC = "btn btn-success";

            } else {
                $scope.classC = "btn btn-success padding-left";
            }
            if (c.selected) $scope.classC += " active";
            return $scope.classC;
        }
        $scope.loadCompetition();
        $scope.loadCategories();
    }
]);
window.ideaStates = [
    { id: 9, selected: false },
    { id: 0, selected: false },
    { id: 1, selected: false },
    { id: 2, selected: false }
];