app.controller("settingsCtrl", ['$scope', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate', '$uibModal',
    function ($scope, $http, $location, $rootScope, $routeParams, Notification, $translate, $uibModal) {

        $scope.innovationTypes = [];
        $scope.suggestionTypes = [];
        $scope.selectedBoard = [];
        $scope.pageSize = 50;
        $scope.pagingMaxSize = 4;
        $scope.innovationType = {};
        $scope.innovationTypeData = {};
        $scope.suggestionType = {};
        $scope.updatestate = false;
        $scope.model = {};
        $scope.model.allItemsSelected = false;
        $scope.data = { query: '' };
        $scope.data.query = $location.search().query || '';
        $scope.activeForm = $scope.data.query != '' ? 1 : 0;

        $scope.init = function (a) {
            $scope.innovationTypes = [];
            $scope.suggestionTypes = [];
            $scope.loading = true;
            if (a === 'i') $scope.getInnovationTypes();
            if (a === 's') $scope.getSuggestionTypes();
            if (a === 't') $scope.getTykUsers();
        };

        $scope.getInnovationTypes = function () {
            //query=&external=internal&page=1&start=0&limit=30
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $http.get("private/user/retrieveinnovationtypesAll.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                .then(function (response) {
                    $scope.innovationTypes = response.data.data;
                    $scope.typeTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.currentPage = page;
                    $scope.displayRangeEnd = ($scope.currentIndex + $scope.pageSize) > $scope.usersTotal ? $scope.usersTotal : ($scope.currentIndex + $scope.pageSize);
                    $scope.loading = false;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.getSuggestionTypes = function () {
            //query=&external=internal&page=1&start=0&limit=30
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $http.get("private/user/retrievesuggestiontypesAll.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                .then(function (response) {
                    $scope.suggestionTypes = response.data.data;
                    $scope.typeTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.currentPage = page;
                    $scope.displayRangeEnd = ($scope.currentIndex + $scope.pageSize) > $scope.usersTotal ? $scope.usersTotal : ($scope.currentIndex + $scope.pageSize);
                    $scope.loading = false;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.saveUpdateInnovation = function (i) {

            if(i) $scope.innovationTypeData = angular.copy(i);
            if($scope.innovationType.status) $scope.innovationTypeData = angular.copy($scope.innovationType);
            $http({
                method: 'POST',
                url: 'private/user/updateInnovationType.do',
                data: 'data=' + encodeURIComponent(JSON.stringify($scope.innovationTypeData)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.innovationType = {};
                    $scope.getInnovationTypes();
                    $scope.updatestate = false;
                    if (res.data.success == true) {
                        Notification.success($translate.instant("sugg.state.10"));
                    } else {
                        $scope.hasError = true;
                    }
                   
                });

        };
        $scope.saveUpdateSuggestion = function (i) {

            if(i) $scope.suggestionTypeData = angular.copy(i);
            if($scope.suggestionType.status) $scope.suggestionTypeData = angular.copy($scope.suggestionType);
            $http({
                method: 'POST',
                url: 'private/user/updateSuggestionType.do',
                data: 'data=' + encodeURIComponent(JSON.stringify($scope.suggestionTypeData)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.suggestionType = {};
                    $scope.getSuggestionTypes();
                    $scope.updatestate = false;
                    if (res.data.success == true) {
                        Notification.success($translate.instant("sugg.state.10"));
                    } else {
                        $scope.hasError = true;
                    }
                   
                });

        };
       
        $scope.openNewInnovationModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {

                        if ($scope.innovationType == "" || $scope.innovationType == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.innovationType == "" || $scope.innovationType == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.innovationType == "" || $scope.innovationType == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        }

                        //t.innovationType = angular.copy($scope.innovationType);
                        $scope.innovationType.status = 1;
                        t.saveUpdateInnovation($scope.innovationType);
                        $scope.Close();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                size: 'lg',
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "newInnovationType.html"
            });
        }
        $scope.sureModalClose = function () {
            $rootScope.modalInstance.close();
        };


        $scope.updateInnovationTpye = function (i) {

            if (i) {
                $scope.innovationType = angular.copy(i);
                $scope.innovationType.status = 1;
            }
        };
        $scope.deleteInnovationType = function (i) {

            if (i) {
                $scope.innovationTypeData = angular.copy(i);
                $scope.innovationTypeData.status = 0;
            }
            $scope.saveUpdateInnovation();
        };
        $scope.activateInnovationType = function (i) {

            if (i) {
                $scope.innovationTypeData = angular.copy(i);
                $scope.innovationTypeData.status = 1;
            }
            $scope.saveUpdateInnovation();
        };

        $scope.cancelUpdateInnovation = function (i) {
            $scope.innovationType = {};
            $scope.updatestate = false;

        };

        $scope.pageChanged = function () {

            $scope.currentIndex = ($scope.currentPage - 1) * $scope.pageSize;
            $location.path('/settings').search('page', $scope.currentPage);
            if ($scope.activeForm == 2) $scope.getTykUsers();

        };

        $scope.getTemplate = function (i) {
            if (i.id === $scope.innovationType.id) return 'edit';
            else return 'display';
        };

        $scope.getTemplateSugg = function (i) {
            if (i.id === $scope.suggestionType.id) return 'editSugg';
            else return 'displaySugg';
        };
        $scope.openNewSuggestionModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {

                        if ($scope.suggestionType == "" || $scope.suggestionType == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.suggestionType.name == "" || $scope.suggestionType.name == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.suggestionType.nameEn == "" || $scope.suggestionType.nameEn == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        }

                        //t.innovationType = angular.copy($scope.innovationType);
                        $scope.suggestionType.status = 1;
                        t.saveUpdateSuggestion($scope.suggestionType);
                        $scope.Close();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                size: 'lg',
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "newSuggestionType.html"
            });
        }

        $scope.updateSuggestionTpye = function (i) {

            if (i) {
                $scope.suggestionType = angular.copy(i);
                $scope.suggestionType.status = 1;
            }
        };
        $scope.deleteSuggestionTpye = function (i) {

            if (i) {
                $scope.suggestionTypeData = angular.copy(i);
                $scope.suggestionTypeData.status = 0;
            }
            $scope.saveUpdateSuggestion();
        };
        $scope.activateSuggestionTpye = function (i) {

            if (i) {
                $scope.suggestionTypeData = angular.copy(i);
                $scope.suggestionTypeData.status = 1;
            }
            $scope.saveUpdateSuggestion();
        };
        $scope.cancelSuggestionTpye = function (i) {
            $scope.suggestionType = {};
            $scope.updatestate = false;

        };

        $scope.openAssigneeTykModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {
                        t.boardAssignee();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "addTyk.html"
            });
        };
        $scope.openTykModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {
                        t.removeFromTyk();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "sureTyk.html"
            });
        };
        $scope.removeFromTyk = function () {
            $scope.dataTykUsers = [];

            for (var i = 0; i < $scope.model.userTyk.length; i++) {
                if ($scope.model.userTyk[i].selected) {
                    var obj = { id: $scope.model.userTyk[i].id }
                    $scope.dataTykUsers.push(obj);
                }
            }
            if ($scope.dataTykUsers.length < 1) {
                Notification.warning($translate.instant("label.header.user.select.user"));
                return;
            }
            $scope.myPromise = $http({
                method: 'POST',
                url: 'private/user/removeFromTyk.do',
                data: 'data=' + encodeURIComponent(JSON.stringify($scope.dataTykUsers)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.getTykUsers();
                    $scope.dataTykUsers = [];
                    $scope.sureModalClose();
                    if (res.data.success == true) {
                        Notification.success($translate.instant("sugg.status.completed"));
                    } else {
                        $scope.hasError = true;
                    }

                });

        };
        $scope.boardAssignee = function () {

            var arr = [];
            var user = {};

            for (var j = 0; j < $scope.selectedBoard.length; j++) {

                for (var i = 0; i < $scope.model.userTyk.length; i++) {

                    if ($scope.model.userTyk[i].selected && $scope.model.userTyk[i].exitDate == null) {

                        if ($scope.model.userTyk[i].userId == $scope.selectedBoard[j].id) {
                            Notification.warning($translate.instant("warning.field.same.value") + ":" + $scope.model.userTyk[i].firstName + " " + $scope.model.userTyk[i].lastName);
                            var user = {};
                            return;
                        }

                    }
                }
                user = { userId: $scope.selectedBoard[j].id }
                arr.push(user);
            }

            $scope.myPromise = $http({
                method: 'POST',
                url: 'private/user/saveTyk.do',
                data: 'data=' + encodeURIComponent(JSON.stringify(arr)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.selectedBoard = [];
                    $scope.dataTykUsers = [];
                    $scope.getTykUsers();
                    $scope.sureModalClose();
                    if (res.data.success == true) {
                        Notification.success($translate.instant("sugg.status.completed"));
                    } else {
                        $scope.hasError = true;
                    }

                });

        };
        $scope.selectAll = function () {
            // Loop through all the entities and set their isChecked property
            for (var i = 0; i < $scope.model.userTyk.length; i++) {
                if ($scope.model.userTyk[i].exitDate == null) {
                    $scope.model.userTyk[i].selected = $scope.model.allItemsSelected;
                }
            }
            $scope.showTerminateButton = $scope.model.allItemsSelected;
        };
        $scope.selectUserTyk = function (c) {
            $scope.showTerminateButton = false;
            if (c.exitDate == null) {
                c.selected = c.selected ? false : true;
            }
            for (var i = 0; i < $scope.model.userTyk.length; i++) {

                if ($scope.model.userTyk[i].selected && $scope.model.userTyk[i].exitDate == null) {
                    $scope.showTerminateButton = true;
                }
            }
            for (var i = 0; i < $scope.model.userTyk.length; i++) {

                if (!$scope.model.userTyk[i].selected && $scope.model.userTyk[i].exitDate == null) {
                    $scope.model.allItemsSelected = false;
                    return;
                }
            }

            $scope.model.allItemsSelected = true;
        };
        $scope.setTykClass = function (c) {

            if (c.exitDate) return 'danger';
            if (c.selected != undefined && c.selected) return 'active';
            if (c.selected != undefined && !c.selected) return 'default';

        };
        $scope.getTykUsers = function () {
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            $scope.myPromise = $http.get("private/user/retrieveTyk.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                .then(function (response) {
                    $scope.model.userTyk = response.data.data;
                    $scope.usersTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    var rangeEnd = $scope.currentIndex + $scope.pageSize;
                    $scope.displayRangeEnd = rangeEnd > $scope.usersTotal ? $scope.usersTotal : rangeEnd;
                    $scope.loading = false;
                    $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
    }]);