app.controller("rewardsManagementsCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', 'Notification', '$translate', '$uibModal', "Upload",
  function ($scope, $sce, $http, $location, $rootScope, Notification, $translate, $uibModal, Upload) {
    $scope.pageSize = 10;
    $scope.pagingMaxSize = 4;
    $scope.currentIndex = 0;
    $scope.calcindex = 0;
    $scope.currentPageCategory = 1;
    $scope.supportedFormats = ".xls,.xlsx"

    $scope.fileList = [];
    $scope.warningShow = true;
    $scope.x = 0;
    $scope.diff = 0;
    $scope.currentMax = 0;
    $scope.fileListError = [];
    //$scope.currentMax = $scope.selectedUser.remainingTotalPoints;
    // $scope.max= 0;

    $scope.updateFiles = function (newFiles) {
      if (newFiles.length > 0) {

        for (var i = 0; i < newFiles.length; i++) {
          $scope.fileList.push(newFiles[i]);
        }

        $scope.uploadEnabled = true;
        //  Notification.info($translate.instant("info.files.added"));
        $scope.uploadFiles();

      }
    };



    $scope.updateFilesPoint = function (newFiles) {
      if (newFiles.length > 0) {

        for (var i = 0; i < newFiles.length; i++) {
          $scope.fileList.push(newFiles[i]);
        }
        $scope.uploadEnabled = true;
        //  Notification.info($translate.instant("info.files.added"));
        $scope.uploadFilesPoint();

      }
    };



    $scope.uploadFilesPoint = function () {

      $scope.objectType = 1;
      $scope.objectId = 1
      $scope.commandToView = 1;
      $scope.commandToEdit = 1;
      $scope.impId = 1;
      $scope.txt = "bb";
      $scope.isUploading = true;

      var url = "private/point-file/upload.do?objectType=" + $scope.objectType + "&objectId=" + $scope.objectId +
        "&commandToView=" + $scope.commandToView + "&commandToEdit=" + $scope.commandToEdit + "&impId=" + $scope.impId
        + "&desc=" + encodeURIComponent($scope.txt);
      $scope.uploadWaitCGPoint = Upload.upload({
        url: url,
        ignoreLoadingBar: true,
        data: {
          files: $scope.fileList
        }
      }).then(
        function (response) {
          $scope.isUploading = false;
          if (response.data.success) {
            $scope.txt = "";
            $scope.fileList = [];

            //  Notification.info($translate.instant("info.files.uploaded"));

            $scope.pms = response.data.data;
            for (var i = 0; i < $scope.pms.length; i++) {

              if ($scope.pms[i].remainingTotalPoints < 0) {

                $scope.fileListError.push($scope.pms[i]);
              }//else{
              // $scope.fileListError = [];
              //}
            }
            if ($scope.fileListError.length > 0) {

              Notification.error($translate.instant("info.files.uploaded.error"));
              $scope.openReviewRemainder($scope.fileListError);
              $scope.fileListError = [];
            } else {
              Notification.info($translate.instant("info.files.uploaded"));
              $scope.openReview($scope.pms);
            }


            //$scope.openReview($scope.pms);
          } else {
            Notification.error($translate.instant(response.data.message));
          }
        },
        function (response) {
          $scope.isUploading = false;
          if (response.status > 0) {
            Notification.error(response.status + ": " + response.data);
          }
        }
      );
      $scope.fileList = [];
    };

    $scope.uploadFiles = function () {

      $scope.objectType = 1;
      $scope.objectId = 1
      $scope.commandToView = 1;
      $scope.commandToEdit = 1;
      $scope.impId = 1;
      $scope.txt = "aa";
      $scope.isUploading = true;

      var url = "private/kaizen-file/upload.do?objectType=" + $scope.objectType + "&objectId=" + $scope.objectId +
        "&commandToView=" + $scope.commandToView + "&commandToEdit=" + $scope.commandToEdit + "&impId=" + $scope.impId
        + "&desc=" + encodeURIComponent($scope.txt);
      $scope.uploadWaitCG = Upload.upload({
        url: url,
        ignoreLoadingBar: true,
        data: {
          files: $scope.fileList
        }
      }).then(
        function (response) {
          $scope.isUploading = false;
          if (response.data.success) {
            $scope.txt = "";
            $scope.fileList = [];
            Notification.info($translate.instant("info.files.uploaded"));

            $scope.kpms = response.data.data;

            $scope.openKaizenReview($scope.kpms);
          } else {
            Notification.error($translate.instant(response.data.message));
          }
        },
        function (response) {
          $scope.isUploading = false;
          if (response.status > 0) {
            Notification.error(response.status + ": " + response.data);
          }
        }
      );
      $scope.fileList = [];
    };

    $scope.getUsersPointsSpend = function () {
      if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
      $scope.myPromise = $http.get("private/user/pointsSpend.do?start=" + $scope.currentIndex + "&query=" + $scope.data.query + "&limit=" + $scope.pageSize)
        .then(function (response) {
          $scope.user = response.data.data;
          $scope.userTotal = response.data.total;
          $scope.displayRangeStart = $scope.currentIndex + 1;
          var rangeEnd = $scope.currentIndex + $scope.pageSize;
          $scope.displayRangeEnd = rangeEnd > $scope.usersTotal ? $scope.usersTotal : rangeEnd;
          $scope.loading = false;
          $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
        }, function (response) {
          //Second function handles error
          Notification.error('Error: getUsersSpecific');
        });
    };

    $scope.getPointsSpendDetailsByUser = function (t, i) {

      $scope.myPromise = $http.get("private/user/pointsSpendDetailsByUser.do?start=" + $scope.currentIndex + "&limit=10&userId=" + i.userId)
        .then(function (response) {
          $scope.userDetail = response.data.data;
          $scope.openDetail(t, i);
          $scope.totalSpend = response.data.total;
        }, function (response) {
          //Second function handles error
          Notification.error('Error: getPointsSpendDetailsByUser');
        });
    };

    $http.get("private/user/customTypeList.do")
      .then(function (response) {
        $scope.pointTypes = response.data.data;

      }, function (response) {
        Notification.error('Error: getPointsSpendDetailsByUser');
      });

    $scope.addPointsModal = function (t, i) {
      $scope.openAddPointsModel(t, i, $scope.pointTypes);
    };

    $scope.openAddPointsModel = function (t, i, pointTypes) {
      $rootScope.modalInstance = $uibModal.open({
        animation: true, backdrop: 'static',
        controller: function ($scope, $uibModalInstance) {
          $scope.selectedUser = i;
          $scope.total = t.totalSpend;
          $scope.spendData = {};

          $scope.userDetail = t.userDetail;
          $scope.openRewardDetail = function (a, b, c) {
            t.openRewardDetail(a, b, c);
          };

          console.log(JSON.stringify(pointTypes) + "----");
          $scope.pointTypes = pointTypes;
          $scope.addPointsData = {};
          $scope.user = i;
          $scope.addPointsData.userId = $scope.user.userId;


          $scope.convertPointTypeToText = function (typeId) {

            for (var index = 0; index < $scope.pointTypes.length; index++) {
              if ($scope.pointTypes[index].typeId == typeId) {
                /* res = $scope.pointTypes[index].title;
                 return res;*/

                 res = "label.user.point.type." + $scope.pointTypes[index].typeId;
                 return res;
              }
            }
            return res;
          }

          $scope.Close = function () {
            $uibModalInstance.close();
          };

          $scope.CloseDetail = function () {
            $uibModalInstance.close();
          };

          $scope.cancelSpend = function () {
            $scope.addPointsData.points = 0;
            $scope.addPointsData.description = "";
            $scope.addPointsData.id = 0;
            delete $scope.addPointsData.createdAt;


          };

          $scope.listBeforeAddedManumalPoints = function () {
            $http.get("private/user/getManualAddedPointByUser.do?start=0&limit=100&userId=" + $scope.user.userId)
              .then(function (response) {
                $scope.beforePoints = response.data.data;
                $scope.total = response.data.total;
              }, function (response) {
                //Second function handles error
                Notification.error('Error: getPointsSpendDetailsByUser');
              });
          }

          $scope.listBeforeAddedManumalPoints();


          $scope.saveOrUpdateAddPoints = function () {
            $scope.addPointsData.title = $scope.convertPointTypeToText($scope.addPointsData.type);
            $http({
              method: "POST",
              url: "private/user/saveOrUpdateManualPoint.do",
              data:
                "pointsData=" + encodeURIComponent(JSON.stringify(new Array($scope.addPointsData))),
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
              }
            }).then(function (res) {
              if (res.data.success == true) {
                $scope.addPointsData = res.data.data
                $scope.cancelSpend();
                $scope.listBeforeAddedManumalPoints();
                Notification.success($translate.instant("label.spend.alert.success"));
              }
            }, function (response) {
              Notification.error($translate.instant("label.spend.alert.error"));
            });

          };

          $scope.getPointsSpendDetailsByUser = function () {

            $scope.myPromise = $http.get("private/user/pointsSpendDetailsByUser.do?start=" + $scope.currentIndex + "&limit=10&userId=" + $scope.selectedUser.userId)
              .then(function (response) {
                $scope.userDetail = response.data.data;
                $scope.total = response.data.total;
              }, function (response) {
                //Second function handles error
                Notification.error('Error: getPointsSpendDetailsByUser');
              });
          };

        },
        size: 'lg',
        ariaLabelledBy: "modal-title",
        ariaDescribedBy: "modal-body",
        templateUrl: "addPoints.html"
      });
    };



    $scope.openDetail = function (t, i) {
      $rootScope.modalInstance = $uibModal.open({
        animation: true, backdrop: 'static',
        controller: function ($scope, $uibModalInstance) {
          $scope.edittingAllowSpend = false;
          $scope.updatingSpend = false;
          $scope.pagingMaxSize = 4;
          $scope.currentIndex = 0;
          $scope.calcindex = 0;
          $scope.pageSize = 10;
          $scope.currentPageCategory = 1;
          $scope.selectedUser = i;
          $scope.total = t.totalSpend;
          $scope.spendData = {};



          $scope.getTemplateSpend = function (i) {
            if (i.id === $scope.spendData.id && $scope.edittingAllowSpend) return 'editModeSpend';
            else return 'displayModeSpend';
          };
          $scope.userDetail = t.userDetail;
          $scope.openRewardDetail = function (a, b, c) {
            t.openRewardDetail(a, b, c);
          };
          $scope.Close = function () {
            $uibModalInstance.close();
          };

          $scope.CloseDetail = function () {
            $uibModalInstance.close();
          };

          $scope.cancelPointSpend = function () {
            $uibModalInstance.close();
          };

          $scope.cancelSpend = function (i) {
            $scope.updatingSpend = false;
            $scope.deleteSpend = false;
            $scope.spendData = {};
          };

          $scope.calculateRemainingPoints = function (value) {

            $scope.warningShow = true;
            /*if(value > $scope.selectedUser.remainingTotalPoints){
                Notification.error($translate.instant("label.spend.alert.error.point"));
                $scope.warningShow = true;

            }*/
          };

          /* $scope.changePoint=function(){
               $scope.warningShow = true;
              }*/

          $scope.updateSpend = function (i) {
            $scope.updatingSpend = true;
            $scope.spendData.status = true;
            $scope.spendData = angular.copy(i);

            $scope.x = i.spendPoints;


            $scope.currentMax = ($scope.x + $scope.selectedUser.remainingTotalPoints);

            if ($scope.currentMax > $scope.spendData.spendPoints) {
              $scope.isDisabled = true;

            }

            if ($scope.spendData.spendPoints > $scope.newmax) {

              Notification.error($translate.instant("label.spend.alert.successs"));
            }


          };
          $scope.saveOrUpdateSpend = function () {

            if (!$scope.deleteSpend) {

              $scope.spendData.status = true;
              $scope.spendData.userId = $scope.selectedUser.userId;
              Notification.success($translate.instant("label.spend.alert.success"));
              if ($scope.total > 0 && $scope.updatingSpend == false) {

                $scope.selectedUser.remainingTotalPoints = ($scope.selectedUser.remainingTotalPoints - $scope.spendData.spendPoints);
              }
              if ($scope.total == 0 && $scope.updatingSpend == false) {


                $scope.selectedUser.remainingTotalPoints = ($scope.selectedUser.totalPoints - $scope.spendData.spendPoints);
              }
              /*else{
              $scope.selectedUser.remainingTotalPoints = ($scope.selectedUser.totalPoints - $scope.spendData.spendPoints);
              }*/
              if ($scope.selectedUser.spendTotalPoints != 0 && $scope.updatingSpend == false) {

                $scope.selectedUser.spendTotalPoints = $scope.spendData.spendPoints + $scope.selectedUser.spendTotalPoints;
              }
              if ($scope.selectedUser.spendTotalPoints == 0 && $scope.updatingSpend == false) {

                $scope.selectedUser.spendTotalPoints = $scope.spendData.spendPoints;

              }
              /*else{
                  $scope.selectedUser.spendTotalPoints = $scope.spendData.spendPoints;
              }*/

              // $scope.getUsersPointsSpend();
              /* $location.url($location.path()); 
               location.reload($location.path()); */

            }


            if ($scope.updatingSpend) {

              $scope.diff = $scope.spendData.spendPoints - $scope.x;

              $scope.selectedUser.spendTotalPoints = ($scope.selectedUser.spendTotalPoints + ($scope.diff));


              //if($scope.diff < 0){
              $scope.selectedUser.remainingTotalPoints = ($scope.selectedUser.remainingTotalPoints - ($scope.diff));
              // }else{
              // $scope.selectedUser.remainingTotalPoints = ($scope.selectedUser.remainingTotalPoints + ($scope.diff));
              //}

            }

            if ($scope.deleteSpend == true) {

              $scope.selectedUser.remainingTotalPoints = $scope.selectedUser.remainingTotalPoints + $scope.spendData.spendPoints
              $scope.selectedUser.spendTotalPoints = $scope.selectedUser.spendTotalPoints - $scope.spendData.spendPoints;

            }

            $http({
              method: "POST",
              url: "private/user/saveOrUpdateSpend.do",
              data:
                "spendData=" + encodeURIComponent(JSON.stringify(new Array($scope.spendData))),
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
              }
            }).then(function (res) {
              if (res.data.success == true) {
                $scope.spendData = res.data.data
                $scope.cancelSpend();
                $scope.getPointsSpendDetailsByUser();
                // Notification.success($translate.instant("label.spend.alert.success"));
              }
            }, function (response) {
              //Second function handles error
              Notification.error($translate.instant("label.spend.alert.error"));
            });

          };
          $scope.deleteSpendFunc = function (i) {

            if (i) {
              $scope.spendData = angular.copy(i);
              $scope.spendData.status = 0;
              $scope.deleteSpend = true;
              $scope.saveOrUpdateSpend();
              $scope.deleteSpend = false;
            }


          };

          $scope.deleteSureModal = function (t, i) {

            $rootScope.modalInstance = $uibModal.open({
              animation: true, backdrop: 'static',
              controller: function ($scope, $uibModalInstance) {

                $scope.sureModalOk = function () {
                  t.deleteSpendFunc(i);
                  $uibModalInstance.close();
                }

                $scope.sureModalClose = function () {
                  $uibModalInstance.close();
                };
              },
              ariaLabelledBy: "modal-title",
              ariaDescribedBy: "modal-body",
              templateUrl: "deleteSureModal.html"
            });
          };

          $scope.getPointsSpendDetailsByUser = function () {

            $scope.myPromise = $http.get("private/user/pointsSpendDetailsByUser.do?start=" + $scope.currentIndex + "&limit=10&userId=" + $scope.selectedUser.userId)
              .then(function (response) {
                $scope.userDetail = response.data.data;
                $scope.total = response.data.total;
              }, function (response) {
                //Second function handles error
                Notification.error('Error: getPointsSpendDetailsByUser');
              });
          };


        },
        size: 'lg',
        ariaLabelledBy: "modal-title",
        ariaDescribedBy: "modal-body",
        templateUrl: "userSpendDetail.html"
      });
    };

    $scope.openKaizenReview = function (i) {
      $rootScope.modalInstance = $uibModal.open({
        animation: true, backdrop: 'static',
        controller: function ($scope, $uibModalInstance) {
          $scope.kpm = i;
          $scope.kpmsTotal = i.length;
          $scope.Close = function () {
            $uibModalInstance.close();
          };
          $scope.saveKaizenPoint = function () {

            $scope.approveWaitCG = $http({
              method: "POST",
              url: "private/kaizen-points/save.do",
              data:
                "kaizenPoints=" + encodeURIComponent(JSON.stringify($scope.kpm)),
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
              }
            }).then(function (res) {
              $scope.isLoading = false;
              if (res.data.success == true) {
                Notification.success($translate.instant("label.save.return.success"));
                $uibModalInstance.close();
              }
              else
                Notification.error("ERROR");
            },
              function (response) {
                //Second function handles error
                Notification.error("ERROR");
                $scope.isLoading = false;
              }
            );

          };

        },
        size: 'lg',
        ariaLabelledBy: "modal-title",
        ariaDescribedBy: "modal-body",
        templateUrl: "kaizenPointReview.html"
      });
    };


    $scope.openReview = function (i) {
      $rootScope.modalInstance = $uibModal.open({
        animation: true, backdrop: 'static',
        controller: function ($scope, $uibModalInstance) {
          $scope.pm = i;
          $scope.pmsTotal = i.length;
          $scope.Close = function () {
            $uibModalInstance.close();
          };
          $scope.savePoint = function () {

            $scope.approveWaitCGPoint = $http({
              method: "POST",
              url: "private/points/save.do",
              data:
                "points=" + encodeURIComponent(JSON.stringify($scope.pm)),
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
              }
            }).then(function (res) {
              $scope.isLoading = false;
              if (res.data.success == true) {
                Notification.success($translate.instant("label.save.return.success"));
                $uibModalInstance.close();
              }
              else
                Notification.error("ERROR");
            },
              function (response) {
                //Second function handles error
                Notification.error("ERROR");
                $scope.isLoading = false;
              }
            );

          };
        },
        size: 'lg',
        ariaLabelledBy: "modal-title",
        ariaDescribedBy: "modal-body",
        templateUrl: "pointReview.html"
      });
    };

    $scope.openReviewRemainder = function (i) {
      $rootScope.modalInstance = $uibModal.open({
        animation: true, backdrop: 'static',
        controller: function ($scope, $uibModalInstance) {
          $scope.pmre = i;
          $scope.pmsreTotal = i.length;
          $scope.Close = function () {
            $uibModalInstance.close();
          };


        },
        size: 'lg',
        ariaLabelledBy: "modal-title",
        ariaDescribedBy: "modal-body",
        templateUrl: "openReviewRemainder.html"
      });
    };



    //$scope.getUsersPointsSpend(); //ilk basta sayfada veri cikmasi icin

  }
]);


