app.controller("profileCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate','Notification',
    function($scope, $sce, $http, $location, $rootScope, $routeParams, $translate,Notification) {
        $scope.profile = {
            "isExternal" : 0
        };
        $scope.loadProfile = function() {
            $http.get("private/user/retrieveAuthorized.do?page=1&start=0&limit=25")
                .then(function(response) {
                    $scope.profile = response.data.data[0];

                }, function(response) {
                    //Second function handles error
                    Notification.error('Error: profile');
                });
        };



        //newpassword_confirmed:default1.

        $scope.passData = {
            oldpassword: '',
            newpassword: '',
            newpassword_confirmed: ''
        };
        $scope.dataLoading = false;
        $scope.changePassword = function(passData) {
            $scope.dataLoading = true;
            $http({
                    method: 'POST',
                    url: 'private/user/changePasswordProfilePage.do',
                    transformRequest: function(obj) {
                        var str = [];
                        for (var p in obj)
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        return str.join("&");
                    },
                    data: { oldpassword: passData.oldpassword, newpassword: passData.newpassword, newpassword_confirmed: passData.newpassword_confirmed },
                    headers: {
                        "content-type": "application/x-www-form-urlencoded"
                    }
                })
                .then(function(res) {
                    $scope.dataLoading = false;
                    if (res.data.success) {
                        $scope.passData = {
                            oldpassword: '',
                            newpassword: '',
                            newpassword_confirmed: ''
                        };
                        $translate('info.pass.changed').then(function(msg) {
                            Notification.info(msg);
                        });
                    } else {
                        Notification.error(res.data.message);
                    }
                });
        };
        $scope.loadProfile();
    }
]);