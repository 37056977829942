app.controller("contributionsCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate', 'Notification', '$uibModal', 'dateFormat',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, Notification, $uibModal, dateFormat) {

        $scope.userId = Number($routeParams.userId)  || -1;
        $scope.userName = $routeParams.userName
    
        /*Init */
        $scope.pagingMaxSize= 4;

        $scope.pageSizeCont = 10;
        $scope.limitCont = 10;
        //************Suggestion************
        $scope.startContSugg = 0;
        $scope.currentPageContSugg = 1;
        $scope.startContSuggComment = 0;
        $scope.currentPageContSuggComment = 1;
        $scope.startContSuggRate = 0;
        $scope.currentPageContSuggRate = 1;
         //************Idea************
        $scope.startContIdea = 0;
        $scope.currentPageContIdea = 1;
        $scope.startContIdeaComment = 0;
        $scope.currentPageContIdeaComment = 1;
        $scope.startContIdeaRate = 0;
        $scope.currentPageContIdeaRate = 1;
         //************Contribution************
        $scope.startContImp = 0;
        $scope.currentPageContImp = 1;
        $scope.startContImprovementComment = 0;
        $scope.currentPageContImprovementComment = 1;
        $scope.startContImprovementRate = 0;
        $scope.currentPageContImprovementRate = 1;

        

        $scope.isDetail = false;
        $scope.loading = false;


        $scope.init = function () {
         //   $scope.loading = true;
            $scope.contributionIdea();
            $scope.contributionSuggestion();
            $scope.frontendConfig && $scope.frontendConfig.improvementEnabled &&  $scope.contributionImprovement();
            $scope.frontendConfig && $scope.frontendConfig.improvementEnabled &&  $scope.contributionImprovementComment();
            $scope.frontendConfig && $scope.frontendConfig.improvementEnabled &&  $scope.contributionImprovementRate();
            $scope.contributionSuggestionComment();
            $scope.contributionSuggestionRate();
            $scope.contributionIdeaComment();
            $scope.contributionIdeaRate();
        };

    $scope.pageChangedContSugg = function () {
      //  $scope.currentPageCont =  $scope.currentPageCont + 1
        $scope.startContSugg = ($scope.currentPageContSugg - 1) * $scope.pageSizeCont;
        $scope.contributionSuggestion();
    };

    $scope.pageChangedContSuggComment = function () {
        //  $scope.currentPageCont =  $scope.currentPageCont + 1
          $scope.startContSuggComment = ($scope.currentPageContSuggComment - 1) * $scope.pageSizeCont;
          $scope.contributionSuggestionComment();
      };
      $scope.pageChangedContSuggRate = function () {
        //  $scope.currentPageCont =  $scope.currentPageCont + 1
          $scope.startContSuggRate = ($scope.currentPageContSuggRate - 1) * $scope.pageSizeCont;
          $scope.contributionSuggestionRate();
      };

    $scope.pageChangedContIdea = function () {
        //  $scope.currentPageCont =  $scope.currentPageCont + 1
          $scope.startContIdea = ($scope.currentPageContIdea - 1) * $scope.pageSizeCont;
          $scope.contributionIdea();
      };

     $scope.pageChangedContIdeaComment = function () {
        //  $scope.currentPageCont =  $scope.currentPageCont + 1
          $scope.startContIdeaComment = ($scope.currentPageContIdeaComment - 1) * $scope.pageSizeCont;
          $scope.contributionIdeaComment();
      };
      
      $scope.pageChangedContIdeaRate = function () {
        //  $scope.currentPageCont =  $scope.currentPageCont + 1
          $scope.startContIdeaRate = ($scope.currentPageContIdeaRate - 1) * $scope.pageSizeCont;
          $scope.contributionIdeaRate();
      };

      $scope.pageChangedContImp = function () {
        //  $scope.currentPageCont =  $scope.currentPageCont + 1
          $scope.startContImp = ($scope.currentPageContImp - 1) * $scope.pageSizeCont;
          $scope.contributionImprovement();
      };
    
      $scope.pageChangedContImprovementComment = function () {
        //  $scope.currentPageCont =  $scope.currentPageCont + 1
          $scope.startContImprovementComment = ($scope.currentPageContImprovementComment - 1) * $scope.pageSizeCont;
          $scope.contributionImprovementComment();
      };
         
      $scope.pageChangedContImprovementRate = function () {
        //  $scope.currentPageCont =  $scope.currentPageCont + 1
          $scope.startContImprovementRate = ($scope.currentPageContImprovementRate - 1) * $scope.pageSizeCont;
          $scope.contributionImprovementRate();
      };

      
    $scope.contributionSuggestion = function () {
            $http({
                method: 'POST',
                url: 'private/suggestion/contributionsRetrieve.do',
                data: 'start=' + $scope.startContSugg
                + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
                + '&byUserId=' + encodeURIComponent(JSON.stringify($scope.userId)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.suggestionForCont = res.data;
                        $scope.totalItemCountSugg = res.data.total;
                      //  getRewards(items);
                    }
                });
    };
  

    $scope.contributionSuggestionComment = function () {

        $http({
            method: 'POST',
            url: 'private/suggestion/contributionsCommentRetrieve.do',
            data: 'start=' + $scope.startContSuggComment
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&byUserId=' + encodeURIComponent(JSON.stringify($scope.userId)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.suggestionCommentForCont = res.data;
                    $scope.totalItemCountSuggComment = res.data.total;
                  //  getRewards(items);
                }
            });
    };
    
    $scope.contributionSuggestionRate = function () {

        $http({
            method: 'POST',
            url: 'private/suggestion/contributionsRateRetrieve.do',
            data: 'start=' + $scope.startContSuggRate
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&byUserId=' + encodeURIComponent(JSON.stringify($scope.userId)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.suggestionRateForCont = res.data;
                    $scope.totalItemCountSuggRate = res.data.total;
                //  getRewards(items);
                }
            });
    };

    $scope.contributionIdea = function () {      
            $http({
                method: 'POST',
                url: 'private/idea/contributionsRetrieve.do',
                data: 'start=' + $scope.startContIdea
                + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
                + '&byUserId=' + encodeURIComponent(JSON.stringify($scope.userId)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.IdeaForCont = res.data;
                        $scope.totalItemCountIdea = res.data.total;
                      //  getRewards(items);
                    }
                });
    };

    $scope.contributionIdeaComment = function () {      
        $http({
            method: 'POST',
            url: 'private/idea/contributionsCommentRetrieve.do',
            data: 'start=' + $scope.startContIdeaComment
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&byUserId=' + encodeURIComponent(JSON.stringify($scope.userId)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.IdeaCommentForCont = res.data;
                    $scope.totalItemCountIdeaComment = res.data.total;
                  //  getRewards(items);
                }
            });
    };
    $scope.contributionIdeaRate = function () {      
        $http({
            method: 'POST',
            url: 'private/idea/contributionsRateRetrieve.do',
            data: 'start=' + $scope.startContIdeaRate
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&byUserId=' + encodeURIComponent(JSON.stringify($scope.userId)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.IdeaRateForCont = res.data;
                    $scope.totalItemCountIdeaRate= res.data.total;
                  //  getRewards(items);
                }
            });
    };
    $scope.contributionImprovement = function () {
        $http({
            method: 'POST',
            url: 'private/improvement/contributionsRetrieve.do',
            data: 'start=' + $scope.startContImp
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&userId=' + encodeURIComponent(JSON.stringify($scope.userId)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.improvementForCont = res.data;
                    $scope.totalItemCountImp = res.data.total;
                  //  getRewards(items);
                }
            });
    };

    $scope.contributionImprovementComment = function () {      
        $http({
            method: 'POST',
            url: 'private/improvement/contributionsCommentRetrieve.do',
            data: 'start=' + $scope.startContImprovementComment
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&userId=' + encodeURIComponent(JSON.stringify($scope.userId)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.ImprovementCommentForCont = res.data;
                    $scope.totalItemCountImprovementComment = res.data.total;
                  //  getRewards(items);
                }
            });
    };
    $scope.contributionImprovementRate = function () {      
        $http({
            method: 'POST',
            url: 'private/improvement/contributionsRateRetrieve.do',
            data: 'start=' + $scope.startContImprovementRate
            + '&limit=' + encodeURIComponent(JSON.stringify( $scope.limitCont ))
            + '&userId=' + encodeURIComponent(JSON.stringify($scope.userId)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    $scope.ImprovementRateForCont = res.data;
                    $scope.totalItemCountImprovementRate= res.data.total;
                  //  getRewards(items);
                }
            });
    };
       
    app.filter('suggStatusLabel', function () {
        return function (input) {
            switch (input) {
                case 0:
                    return 'sugg.status.draft';
                case 2:
                    return 'sugg.status.submitted';
                case 4:
                    return 'sugg.status.prereject';
                case 5:
                    return 'sugg.status.preaccept';
                case 8:
                    return 'sugg.status.accept';
                case 9:
                    return 'sugg.status.lastreject';
                case 10:
                    return 'sugg.status.completed';
                case 11:
                    return 'sugg.status.firstreject';
                case 12:
                    return 'sugg.status.editor';
                case 20:
                    return 'label.total.pending';
                case 21:
                    return 'label.total.accepted';
                case 22:
                    return 'sugg.state.9';
                case 29:
                    return 'sugg.state.0';
                default:
                    return 'sugg.status.none';
            }
        };
    });

 
  


     

   
    }]);

