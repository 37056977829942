app.controller("projectEditCtrl", ["$scope",  "$http", "$rootScope", "$routeParams", "Notification", "dateFormat", "$translate", "$filter","$uibModal", function ($scope,
     $http,  $rootScope, $routeParams, Notification, dateFormat, $translate, $filter,$uibModal) {

    $scope.projectId = $routeParams.projectId ? $routeParams.projectId : 0;
    $scope.project = {};
    $scope.projectPriority = {};
    $scope.projectState = {};
    $scope.projectProgress = {};
    $scope.state = [{ stateId: 0 }];
    $scope.priority = [{ priorityId: 0 }];
    $scope.progress = [{ progressId: 0 }];
    $scope.percent = [{ percent: 0 }];
    $scope.projectOwners = [{ userId: 0, status: 1 }];
    $scope.projectUser = [];
    $scope.selectedResponsible = [];
    $scope.selectedprojectPriority = {};
    $scope.selecetedprojectState = {};
    $scope.selectedprojectProgress = {};
    $scope.startdatePlannedState = {
        opened: false
    };
    $scope.lang = $rootScope.lang;
    

    $scope.startdateActualState = {
        opened: false
    };
    $scope.enddatePlannedState = {
        opened: false
    };
    $scope.enddateActualState = {
        opened: false
    };

    /* Functions */

    $scope.init = function () {
        $scope.getProjectPriorities();
        $scope.getProjectStates();
        $scope.getProjectProgress();
        $scope.getProjectSuggestions();
        $scope.getProjectIdeas();
        $scope.getProjectImprovements();
    }
    $scope.getProjectPriorities = function () {

        $http.get("project/retrievePriorityNames.do").then(
            function (response) {
                var list = response.data.data;
                $scope.projectPriorities = list;

            });

    }
    $scope.getProjectStates = function () {

        $http.get("project/retrieveStateNames.do").then(
            function (response) {
                var list = response.data.data;
                $scope.projectStates = list;

            });

    }
    $scope.getProjectProgress = function () {

        $http.get("project/retrieveProgressNames.do").then(
            function (response) {
                var list = response.data.data;
                $scope.projectProgresses = list;

            });

    }
    $scope.getProjectSuggestions = function () {

        $http.get("project/retrieveProjectSuggestions.do?projectId=" + $scope.projectId).then(
            function (response) {
                var list = response.data.data;
                $scope.projectSuggestion = list;
               
            });

    }
    $scope.getProjectIdeas = function () {

        $http.get("project/retrieveProjectIdeas.do?projectId=" + $scope.projectId).then(
            function (response) {
                var list = response.data.data;
                $scope.projectIdea = list;
            });

    }
    $scope.getProjectImprovements= function () {

        $http.get("project/retrieveProjectImprovements.do?projectId=" + $scope.projectId).then(
            function (response) {
                var list = response.data.data;
                $scope.projectImprovement = list;
                $scope.getProjectOwners();
            });
    }
    
    $scope.deleteItemRelationModal = function (t,itemId,itemType) {
        $rootScope.modalInstance = $uibModal.open({
          animation: true,backdrop: 'static',
          controller: function ($scope, $uibModalInstance) {
       
            $scope.sureModalOk = function () {
               
                        $http
                          .get("project/deleteItemRelation.do?projectId=" + t.projectId + "&itemId=" + itemId + "&itemType="+itemType)
                          .then(
                            function (response) {
                                Notification.success($translate.instant('campaign.status.deleted'));
                                if(itemType == 1 )
                                    t.getProjectSuggestions();
                                if(itemType == 2 )
                                    t.getProjectIdeas();
                                if(itemType == 3 )
                                    t.getProjectImprovements();

                            },
                            function (response) {
                              //Second function handles error
                              Notification.error("ERROR delete item relation");
                            }
                          );
                          $scope.sureModalClose();
            }
    
            $scope.sureModalClose = function () {
              $uibModalInstance.close();
            };
          },
          ariaLabelledBy: "modal-title",
          ariaDescribedBy: "modal-body",
          templateUrl: "deleteItemRelation.html"
        });
      };

    $scope.saveProject = function () {

        validatesavedata();
        var url = "project/save.do";
        var projArr = [];
        $scope.project.id = $scope.project.id > 0 ? $scope.project.id : -1;
        $scope.project.status = 1;

 //       $scope.project.updateDate = new Date();
        projArr.push($scope.project);
        var data = 'project=' + encodeURIComponent(JSON.stringify(projArr)) +
            '&state=' + encodeURIComponent(JSON.stringify($scope.state)) +
            '&priority=' + encodeURIComponent(JSON.stringify($scope.priority)) +
            '&progress=' + encodeURIComponent(JSON.stringify($scope.progress)) +
            '&percent=' + encodeURIComponent(JSON.stringify($scope.percent)) +
            '&owners=' + encodeURIComponent(JSON.stringify($scope.projectOwners));

        $http({
            method: 'POST',
            url: url,
            data: data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                if (res.data.success == true) {
                    
                    $scope.projectId = res.data.data[0].id ? res.data.data[0].id  : 0
                    $scope.getProject();
                    if ($scope.projectId == 0)
                        Notification.success($translate.instant("success.project.defined"));
                    else
                        Notification.success($translate.instant("success.project.edited"));

                } else {
                    Notification.error($translate.instant("error.generic"));
                }
            });

    }
    var validatesavedata = function () {

        $scope.project.startdatePlannedStr = $scope.startdatePlanned != null ? $filter('date')(new Date($scope.startdatePlanned),"MM/dd/yyyy") : '';
        $scope.project.startdateActualStr = $scope.startdateActual != null ? $filter('date')(new Date($scope.startdateActual),"MM/dd/yyyy") : '';
        $scope.project.enddatePlannedStr = $scope.enddatePlanned != null ? $filter('date')(new Date($scope.enddatePlanned) ,"MM/dd/yyyy"): '';
        $scope.project.enddateActualStr = $scope.enddateActual != null ? $filter('date')(new Date($scope.enddateActual),"MM/dd/yyyy") : '';

        $scope.state[0].stateId = $scope.selecetedprojectState.id ? $scope.selecetedprojectState.id : 0;
        $scope.priority[0].priorityId = $scope.selectedprojectPriority.id ? $scope.selectedprojectPriority.id : 0;
        $scope.progress[0].progressId = $scope.selectedprojectProgress.id ? $scope.selectedprojectProgress.id : 0;

        $scope.state[0].id = $scope.project.stateId_db;
        $scope.priority[0].id =$scope.project.priorityId_db;
        $scope.progress[0].id = $scope.project.progressId_db;
        $scope.percent[0].id = $scope.project.percentId_db;
        

        if ($scope.projectUser.length > 0) $scope.projectOwners[0].userId = $scope.projectUser[0].userId;
        if ($scope.projectId > 0) {
            $scope.projectOwners[0].projectId = $scope.projectId;
            $scope.percent[0].projectId = $scope.projectId;
            $scope.state[0].projectId = $scope.projectId;
            $scope.priority[0].projectId = $scope.projectId;
            $scope.progress[0].projectId = $scope.projectId;
        }
        
        $scope.project.enddatePlannedStr ? '' : delete $scope.project.enddatePlannedStr;
        $scope.project.startdatePlannedStr ? '' : delete $scope.project.startdatePlannedStr;
        $scope.project.enddateActualStr ? '' : delete $scope.project.enddateActualStr;
        $scope.project.startdateActualStr ? '' : delete $scope.project.startdateActualStr;

        delete $scope.project.startdatePlanned;
        delete $scope.project.startdateActual;
        delete $scope.project.enddatePlanned;
        delete $scope.project.enddateActual;
    }
    $scope.addProjectResponsible = function () {
     
        
        var isExist = false;
        var puser_ = {
            fullname: $scope.selectedResponsible[0].name + " " + $scope.selectedResponsible[0].surname,
            userId: $scope.selectedResponsible[0].id,
        }
       
        for (var i = 0; i < $scope.projectUser.length; i++) {
            if ($scope.projectUser[i].userId == $scope.selectedResponsible[0].id) {
                Notification.warning($translate.instant("warning.field.same.value"));
                isExist = true;
            }
        }
    
        if (!isExist) {
            $scope.projectUser = []
            $scope.projectUser.push(puser_);
        }
    }
    $scope.removeSelected = function (i) {
        $scope.projectUser.splice(i, 1);
    }
    /* Functions */

    $scope.getProject = function () {

        $http.get("project/retrieveWithDependencies.do?projectId=" + $scope.projectId).then(
            function (response) {
                var list = response.data.data[0];
                $scope.project = list;
                $scope.percent[0].percent = angular.copy($scope.project.percent);
                $scope.startdatePlannedStr = $scope.project.startdatePlanned != null ? $filter('date')(new Date($scope.project.startdatePlanned),$scope.format) : '';
                $scope.startdateActualStr =$scope.project.startdateActual != null ? $filter('date')(new Date($scope.project.startdateActual),$scope.format) : '';
                $scope.enddatePlannedStr =$scope.project.enddatePlanned != null ? $filter('date')(new Date($scope.project.enddatePlanned),$scope.format)  : '';
                $scope.enddateActualStr =$scope.project.enddateActual != null ? $filter('date')(new Date($scope.project.enddateActual),$scope.format)  : '';
                $scope.startdatePlanned = $scope.project.startdatePlanned != null ? new Date($scope.project.startdatePlanned): null;
                $scope.startdateActual =$scope.project.startdateActual != null ? new Date($scope.project.startdateActual) : null;
                $scope.enddatePlanned =$scope.project.enddatePlanned != null ? new Date($scope.project.enddatePlanned)  : null;
                $scope.enddateActual =$scope.project.enddateActual != null ? new Date($scope.project.enddateActual)  : null;
                $scope.selecetedprojectState.id = $scope.project.stateId;
                $scope.selectedprojectPriority.id = $scope.project.priorityId;
                $scope.selectedprojectProgress.id = $scope.project.progressId;
               delete $scope.project.entrydate;
               delete $scope.project.entryDate;
               delete $scope.project.enterdate;
               delete $scope.project.enterDate;
                delete $scope.project.updateDate;
            });

    }
    $scope.getProjectOwners = function () {

        $http.get("project/retrieveProjectOwners.do?projectId=" + $scope.projectId).then(
            function (response) {
               var list = response.data.data;

                var _puser = {};
                if (list.length > 0) {
                    for (var i = 0; i < list.length; i++) {
                        _puser = {
                            fullname: list[0].fullName,
                            userId: list[0].id
                        };
                        _puser2 = {
                            name: list[0].fullName,
                            id: list[0].id
                        };
                        $scope.projectUser.push(_puser);
                        $scope.selectedResponsible.push(_puser2);
                    }
                }
                if ($routeParams.projectId) {
                    $scope.getProject();
                }
            });

    }

    /* DatePicker */


    $scope.openStartDatePlanned = function () {
        if ($scope.dateEvalStart != undefined) {
            $scope.dateOptions.minDate = NextDate(new Date());
            $scope.dateOptions.maxDate = BeforeDate($scope.dateEvalStart);
        }
        $scope.startdatePlannedState.opened = true;
    };
    $scope.openStartDateActual = function () {
        $scope.startdateActualState.opened = true;
    };
    $scope.openEndDatePlanned = function () {
        $scope.enddatePlannedState.opened = true;
    };
    $scope.openEndDateActual = function () {
        $scope.enddateActualState.opened = true;
    };
  
    $scope.dateOptions = {
        formatYear: "yy",
        maxDate: new Date(2023, 4, 22),
        minDate: new Date(1923, 1, 1),
        startingDay: 1
    };

    $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format =  $translate.instant('date.format.shortDate');//$scope.formats[3];
    $scope.altInputFormats = $scope.format;//['M!/d!/yyyy'];

    
}]);  