app.directive("benefitAnalysis", [
  "$http",
  "$translate",
  "Notification",
  "$uibModal",
  "$rootScope",
  "$filter",
  function ($http, $translate, Notification, $uibModal, $rootScope,$filter) {
    return {
      restrict: "E",
      scope: {
        impId: "=",
        commands: "=",
        commandTypeEdit: "=",
        commandTypeView: "=",
        authType: "=",
        label: "=",
        showToggle: "=",
        reloadImp:"&",
      },
      templateUrl: "partial/directive-imp/benefit-analysis.html",
      controller: function ($scope) {
        $scope.benefit = {
          id:0,
          description : '',
          status: true,
          improvementId: $scope.impId,
          editCommand: $scope.commandTypeEdit,
          viewCommand: $scope.commandTypeView
        }
        $scope.info = {
          firstRequest: true,
          warningShow:true
        }
        
        $scope.isLoading = false;
        $scope.$watch('commands', function (newVal) {
          if (newVal) {
            $scope.show=false;
            $scope.availableCommands = $scope.commands;
            for(var i =0; i < $scope.availableCommands.length ; i++){
              if( $scope.availableCommands[i] === $scope.commandTypeEdit ){
                $scope.show=true;
                $scope.getBenefitByCommandAndUser();
              }
            } 
          }
        });

        $scope.updateImp = function (){
          $scope.reloadImp();
        };

        $scope.getBenefitByCommandAndUser = function(){
   
          var path = "private/improvementBenefit/getByCommandAndUser.do";
          $http({
            method: 'GET',
            url: path + '?editCommand=' + encodeURIComponent($scope.commandTypeEdit) + '&impId=' + $scope.impId ,
            headers: {
                "content-type": "application/x-www-form-urlencoded"
            }
        }).then(
            function (response) {
              if(response.data.data.id){
                $scope.benefit = response.data.data;
                $scope.benefit.status = true;
                $scope.info.firstRequest = false;
              }else{
                $scope.benefit.status = false;
                $scope.saveBenefit();
              }      
            },
            function (response) {
             $scope.benefit.status = false;
              $scope.saveBenefit();
            
          //    Notification.error("ERROR improvementBenefit/getByCommandAndUser ");
            }
          );
        };

        $scope.saveBenefit = function () {
        
          $scope.info.warningShow=false;
          var path = "private/improvementBenefit/save.do";
          $http({
            method: 'POST',
            url: path,
            data: 'improvementBenefit=' + encodeURIComponent(JSON.stringify(new Array($scope.benefit))),
            headers: {
                "content-type": "application/x-www-form-urlencoded"
            }
        }).then(
            function (response) {
              $scope.isLoading = true;
              $scope.benefit = response.data.data;
              if ($scope.info.firstRequest) {
                $scope.getBenefitByCommandAndUser();
              }
              else {
                $scope.updateImp();
                Notification.success($translate.instant("info.benfit.submit"));
                }
            },
            function (response) {
              //Second function handles error
              Notification.error("ERROR improvement benefit");
            }
          );

          

        };



      }

    };
  }
]);


