app.directive("userEarnedPoints", [
    "$http",
    "$translate",
    "Notification",
    "$uibModal",
    "$rootScope",
    "$filter",
    function ($http, $translate, Notification, $uibModal, $rootScope,$filter) {
      return {
        restrict: "E",
        scope: {
          impId: "=",
          commands: "=",
          commandType: "=",
          label: "=",
          show: "=",
          showToggle: "="
        },
        templateUrl: "partial/directive-imp/user-earned-points.html",
        controller: function ($scope) {
       
          $scope.isLoading = false;
          $scope.$watch('commands', function (newVal) {
            if (newVal) {
              $scope.show=false;
              $scope.availableCommands = $scope.commands;
              for(var i =0; i < $scope.availableCommands.length ; i++){
                if( $scope.availableCommands[i] === $scope.commandType ){
                  $scope.show=true;
                  $scope.selectedFieldId = $scope.fieldId;
                  $scope.getUserEarnedPoints();
                }
              }
               
            }
          });
          $scope.getUserEarnedPoints = function () {
            $scope.show = false;
            var path = "private/improvement-reward/listUserEarnedBeforePoints.do?impId=" + $scope.impId;
            $http.get(path).then(
              function (response) {
                $scope.pointList = response.data.data;
              
                $scope.userPoints = {};
            
                for(var i = 0 ; i <  $scope.pointList.length ; i++){
                  if( $scope.userPoints[$scope.pointList[i].userId] ){
                    if($scope.pointList[i].pointType != 220 && $scope.pointList[i].pointType != 440 && $scope.pointList[i].pointType != 420 ){
                      $scope.userPoints[$scope.pointList[i].userId] += $scope.pointList[i].points;
                      $scope.show = true;
                    }
                  }
                  else{
                    if($scope.pointList[i].pointType != 220 && $scope.pointList[i].pointType != 440 && $scope.pointList[i].pointType != 420 ){
                      $scope.userPoints[$scope.pointList[i].userId] = $scope.pointList[i].points;
                      $scope.show = true;
                    }
                  }
                }
              },
              function (response) {
                $scope.show=false;
             //   Notification.error("ERROR improvement recommended");
              }
            );
          };

        $scope.findUserNameByUserId = function(id){
          for (var i = 0 ;  $scope.pointList.length ; i++){
              if($scope.pointList[i].userId == id)
                return $scope.pointList[i].userfullName
          }
        }

  

        }

      };
    }
  ]);
  
 
