app.controller("ideaCtrl", ['$scope', 'Upload', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate', '$uibModal',
    function ($scope, Upload, $sce, $http, $location, $rootScope, $routeParams, Notification, $translate, $uibModal) {
        $scope.isLoading = false;
        $scope.showError = false;
        $scope.showMessage = false;
        $scope.isMine = false;
        $scope.ratingAllowed = false;
        $scope.termsChecked = true;
        $scope.ref = 0;
        $scope.fileList = [];
        $scope.uploadedFiles = [];
        $scope.fileProgress = 0;
        $scope.ideaId = $routeParams.ideaId;
        $scope.competitionId = $routeParams.competitionId;
        $scope.ideaRating = { rate: 0, ideaId: $scope.ideaId };
        $scope.idea = {};
        $scope.ideaComment = {};
        $scope.isCommentShow = false;
        $scope.isCommentEnabled = false;
        $scope.ideaCriterias = [];
        $scope.extData = {};
        $scope.loadIdeaFlag = false;
        $scope.showUserForm = true;
        $scope.showAdminCommentSection = false;
        $scope.kaptchaUrl = 'notsecure/captcha-image.do';
        $scope.ideaFields = [];
        $scope.categories = [];
        $scope.category = {};
        $scope.query = $location.search().query || '';
        $scope.selectedStates = ($location.search().state && $location.search().state.split(',')) || [];
        $scope.selectedCategories = ($location.search().category && $location.search().category.split(',')) || [];
        $scope.pathL =$location.url();
        $scope.pathL = $scope.pathL.substring($location.path().length);
        var Modes = { VIEW: 0, NEW: 1, EDIT: 2 };
        $scope.mode = 0;
        $scope.eduOptions = [
            {value:0, label:"label.edu.primary"},
            {value:1, label:"label.edu.secondary"},
            {value:2, label:"label.edu.high"},
            {value:3, label:"label.edu.college"},
            {value:4, label:"label.edu.undergraduate"},
            {value:5, label:"label.edu.graduate"},
            {value:6, label:"label.edu.phd"},
        ];
        
        $scope.selectedEdu = $scope.eduOptions[4];

        if ($rootScope.authenticated) {
            $scope.showUserForm = false;

        }
        $scope.myInfo = {
            ownerId: $rootScope.currentUser == undefined ? 0 : $rootScope.currentUser.id,
            fullName: $rootScope.currentUser == undefined ? 0 : $rootScope.currentUser.fullName,
            share: 100,
            isCorresponder: true,
            mainOwner:true,
        }
   
        //----------------------------------multi owners add event----------------------------
        $scope.selectedSuggestionOwners = [];
        $scope.suggestionOwners = [];
            $scope.deleteSuggestionOwner= function (ind) {
                if( $scope.suggestionOwners[ind].mainOwner){
                $scope.myInfo.mainOwner = true;
                }
                $scope.suggestionOwners.splice(ind, 1);
                $scope.selectedSuggestionOwners.splice(ind, 1);
                $scope.validateOwnerShares();
            }
        
            $scope.validateOwnerShares = function () {
                var total = 0;
                for (var i = 0; i < $scope.suggestionOwners.length; i++) {
                var share = $scope.suggestionOwners[i].share;
                if (share == undefined || share == null) {
                    $scope.isOwnerSharesValid = false;
                    return;
                }
                total += share;
                }
                if (total > 100) {
                $scope.isOwnerSharesValid = false;
                }
                else {
                $scope.isOwnerSharesValid = true;
                $scope.myInfo.share = 100 - total;
                }
            
            }
            $scope.updateSuggestionOwners = function (t) {
                $scope.myInfo.mainOwner = true;
       
               
               var newList = []
               for (var i = 0; i < $scope.selectedSuggestionOwners.length; i++) {
                 if ($scope.myInfo.ownerId != $scope.selectedSuggestionOwners[i].id) {
                   var so = $scope.selectedSuggestionOwners[i];
                   var newSo = {};
                   newSo.ownerId = so.id;
                   newSo.fullName = so.name + " " + so.surname;
                   newSo.share = 1;
                   newSo.mainOwner = so.mainOwner;
                   for (var j = 0; j < $scope.suggestionOwners.length; j++) {
                     if ($scope.suggestionOwners[j].id == so.id) newSo.share = $scope.suggestionOwners[j].share;
                   }
                   newList.push(newSo);
                 }
               }
               $scope.suggestionOwners = newList;
               $scope.validateOwnerShares();
              
             }
                    
            $scope.addOwnerConfirmModal = function (t) {

                $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.Ok = function () {
                    t.updateSuggestionOwners(t)
            
                    $uibModalInstance.close();
                    }
            
                    $scope.Close = function () {
                    $uibModalInstance.close();
                    }
                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "addOwnerConfirmModal.html"
                });
            };
        //----------------------------------multi owners add event----------------------------
        $scope.getRef = function () {
            var path = "notsecure/idea/getReference.do";
            if ($rootScope.authenticated)
                path = "private/idea/getReference.do";
            $http({
                method: 'POST',
                url: path,

                data: 'object=idea',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    $scope.idea.referenceNumber = $scope.ref = res.data.data;
                    $scope.loadCompetition();
                });
        };
        $scope.loadIdeaForSubmit = function () {
            $http.get("private/idea/retrieveSpecific.do?ideaId=" + $scope.ideaId + "&start=0&limit=25")
                .then(function (response) {
                    var i = response.data.data[0];
                    if (i.ownerId == $rootScope.currentUser.id) $scope.isMine = true;
                    var allowEdit = $scope.isMine && (i.status == 9 || i.status < 2)
                    if (!allowEdit) {
                        $location.path('idea/' + i.id);
                    }
                    $scope.idea.id = i.id;
                    $scope.idea.cons = i.cons;
                    $scope.idea.description = i.description;
                    $scope.idea.name = i.name;
                    $scope.idea.ownerId = i.ownerId;
                    $scope.idea.showCase = i.showCase;
                    $scope.idea.pros = i.pros;
                    $scope.idea.referenceNumber = $scope.ref = i.referenceNumber;
                    $scope.idea.status = i.status;
                    $scope.idea.problemCategoryId = i.problemCategoryId;
                    $scope.getUploadedFiles();
                    //$scope.loadProblemCriteria();
                    $scope.ideaFields = i.ideaFieldViewModels;
                    $scope.problemCategoryId = i.problemCategoryId;
                    $scope.loadCategories();
                    //----------------------oneri sahibi ekleme ile ilgili------------------------
                    $scope.ideaOwnersModels = i.ideaOwnersModels;
                    for(var j = 0; j < i.ideaOwnersModels.length; j++){
                        if(i.ideaOwnersModels[j].ownerId != $scope.myInfo.ownerId){
                          $scope.suggestionOwners.push(i.ideaOwnersModels[j]);
                        }
                        $scope.selectedSuggestionOwners.push({
                          id: i.ideaOwnersModels[j].ownerId,
                          name: i.ideaOwnersModels[j].fullName,
                          mainOwner: i.ideaOwnersModels[j].mainOwner,
                          surname: ""
                        });
                      }
                      $scope.validateOwnerShares();
                    //----------------------oneri sahibi ekleme ile ilgili------------------------

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.loadIdea = function () {
            $http.get("private/idea/retrieveSpecific.do?ideaId=" + $scope.ideaId + "&start=0&limit=25")
                .then(function (response) {
                    $scope.idea = response.data.data[0];

                    $scope.problemId = response.data.data[0].problemId;
                    if ($rootScope.currentUser.authorization != undefined) {
                        $scope.isAdmin = $rootScope.currentUser.authorization.hasAdminAccess;
                    }
                    if ($scope.idea.ownerId == $rootScope.currentUser.id) $scope.isMine = true;
                    $scope.loadIdeaComment();
                    //$scope.loadProblemCriteria();
                    $scope.loadRating();
                    //$scope.loadIdeaCriteria();
                    $scope.loadCompetition();
                    $scope.loadIdeas();
                    $scope.isAdmin && $scope.loadUserInfo();
                    $scope.showAdminCommentSection = $scope.idea.status != 9 && ($scope.isAdmin || $scope.isMine);
                    $scope.showAdminCommentSection && $scope.getAdminComments();
                    $scope.problemCategoryId = response.data.data[0].problemCategoryId;
                    $scope.loadCategories();
                   
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.loadIdeas = function () {
            if (typeof $scope.query == 'undefined') $scope.query = '';
            var page = $location.search().page || 1;
            var sort = $location.search().sort || $scope.sortStr;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $http
            .get("private/idea/retrieve.do?problemId=" + $scope.problemId + "&page=1&start=0&limit=500&query="
             + encodeURIComponent($scope.query) 
             + "&sort=idea.enterDate desc" 
             + "&ideaState=" 
             + $scope.selectedStates.join() 
             + "&ideaCategories=" + $scope.selectedCategories.join())
                .then(
                    function (response) {
                        var list = response.data.data;
                        $scope.ideaCount = response.data.total;
                        if (list) {
                            for (var i = 0; i < list.length; i++) {

                                if (list[i].id == $scope.idea.id) {
                                    if (i > 0) {
                                        $scope.showPrev = true;
                                        $scope.prevId = list[i - 1].id;
                                    }
                                    if (i < list.length - 1) {
                                        $scope.showNext = true;
                                        $scope.nextId = list[i + 1].id;
                                    }
                                }
                      
                            }
                        }
                    },
                    function (response) {
                        //Second function handles error
                        Notification.error("Error: Ideas");
                    }
                );
        };
        $scope.loadIdeaComment = function () {
            $http.get("private/ideaComment/retrieve.do?ideaId=" + $scope.ideaId + "&page=1&start=0&limit=25")
                .then(function (response) {
                    $scope.ideaComments = response.data.data;
                    $scope.commentCount = response.data.total;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.loadProblemCriteria = function () {
            var path = "notsecure/getOpenCompetitionAcceptanceCriteria.do?problemId=" + $scope.problemId;
            if ($rootScope.authenticated)
                path = "private/market/retrieveProblemAcceptanceCriteria.do?problemId=" + $scope.problemId + "&page=1&start=0&limit=100";
            $http.get(path)
                .then(function (response) {
                    $scope.problemCriterias = response.data.data;
                    for (var i = 0; i < $scope.problemCriterias.length; i++) {
                        $scope.ideaCriterias.push({ accCritId: $scope.problemCriterias[i].id, description: '' });
                    }
                    if ($scope.mode == Modes.EDIT) {
                        $scope.loadIdeaCriteria();
                    }

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.loadRating = function () {
            $http.get("private/idea/retrieveIdeaRating.do?ideaId=" + $scope.ideaId + "")
                .then(function (response) {
                    $scope.ideaRating.rate = response.data.data;
                    $scope.getAvgRating();
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.getAvgRating = function () {
            $http.get("private/idea/retrieveIdeaRating.do?ideaId=" + $scope.ideaId + "")
                .then(function (response) {
                    $scope.avgRating = response.data.data;

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.saveRating = function () {
            $scope.ratingSubmitted = true;
            $http({
                method: "POST",
                url: "private/idea/saveRating.do",
                data:
                    "data=" + encodeURIComponent(JSON.stringify($scope.ideaRating)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            }).then(function (res) {
                if (res.data.success == true) {
                    $scope.loadIdea();
                    $translate("info.rating.submit").then(function (msg) {
                        Notification.success(msg);
                    });
                } else {
                    $scope.ratingSubmitted = false;
                    $translate("error.rating.submit").then(function (msg) {
                        Notification.error(msg);
                    });
                }
            });
        };
        $scope.loadCategories = function () {
            $http.get("private/market/retrieveProblemCategory.do?problemId=" + $scope.problemId + "&page=1&start=0&limit=25")
                .then(function (response) {
                    $scope.categories = response.data.data;

                    for (var i = 0; i < $scope.categories.length; i++) {
                        $scope.categories[i].disabled = false;
                        if ($scope.categories[i].hasChild != 0) {
                            $scope.categories[i].disabled = true;
                        }
                        if ($scope.categories[i].id === $scope.problemCategoryId) {
                            $scope.category.id = $scope.categories[i].id;
                            $scope.idea.category = {};
                            $scope.idea.category.id = $scope.category.id;
                            $scope.idea.category.name = $scope.categories[i].name;
                            if ($scope.categories[i].parent != null && $scope.categories[i].parent.name != null) {
                                $scope.idea.category.selectionName = $scope.categories[i].parent.name + " > " + $scope.categories[i].name;
                            } else {
                                $scope.idea.category.selectionName = $scope.categories[i].name;
                            }
                            $scope.idea.category.hasChild = $scope.categories[i].hasChild;
                            $scope.idea.category.parent = $scope.categories[i].parent;
                        }
                        if ($scope.categories[i].parent != null && $scope.categories[i].parent.name != null) {
                            $scope.categories[i].selectionName = " > " + $scope.categories[i].name;
                        } else {
                            $scope.categories[i].selectionName = $scope.categories[i].name;
                        }

                    }
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Categories');
                });

        };
        $scope.loadIdeaCriteria = function () {
            $http.get("private/idea/retrieveIdeaAcceptanceCriteria.do?start=0&limit=100&ideaId=" + $scope.idea.id + "&page=1")
                .then(function (response) {
                    var resData = response.data.data;
                    if (!(resData && resData.length > 0)) return;

                    if ($scope.ideaCriterias && $scope.ideaCriterias.length > 0) {
                        for (var i = 0; i < $scope.ideaCriterias.length; i++) {
                            for (var j = 0; j < resData.length; j++) {
                                if ($scope.ideaCriterias[i].accCritId == resData[j].accCritId) {
                                    $scope.ideaCriterias[i].description = resData[j].description;
                                    $scope.ideaCriterias[i].id = resData[j].id;
                                    $scope.ideaCriterias[i].ideaId = resData[j].ideaId;
                                }
                            }
                        }
                    } else {
                        $scope.ideaCriterias = response.data.data;
                    }


                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.loadCompetition = function () {
            var path = "notsecure/getOpenCompetition.do?id=" + $scope.problemId;
            if ($rootScope.authenticated)
                path = "private/market/retrieveProblemModelSpecific.do?problemId=" + $scope.problemId + "&start=0&limit=25";
            $http.get(path)
                .then(function (response) {

                    $scope.competition = response.data.data[0];
                    $scope.getUploadedFiles();
                    checkIdeaComment();
                    if ($scope.competition === undefined) {
                        $location.path("/login");
                    }
                    if (($scope.mode == Modes.NEW || $scope.mode == Modes.EDIT) && $scope.competition.problemState != 'A') {
                        $location.path("/");
                    }
                    if ($scope.mode == Modes.NEW) {
                        switch ($scope.competition.publicMode) {
                            case 0:
                            case 1:
                                if ($rootScope.authenticated == false) {
                                    $location.path("/login");
                                }
                                break;
                        }
                    }

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };

        $scope.getUploadedFiles = function () {

            var path = "notsecure/idea/retrieveIdeaFiles.do?ideaReferenceNumber=";
            if ($scope.idea.referenceNumber) $scope.ref = $scope.idea.referenceNumber;
            $http.get(path + $scope.ref + "&problemId=" + $scope.competition.id + "&start=0&limit=25")
                .then(function (response) {
                    $scope.uploadedFiles = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('ERROR suggestion files');
                });
        };
        $scope.loadUserInfo = function () {
            path = "private/user/retrieveUserDetails.do?userId=" + $scope.idea.ownerId;
            $http.get(path)
                .then(function (response) {
                    var data = response.data;
                    if (Array.isArray(data)) {
                        $scope.userDetail = response.data[0];
                        $scope.userExternal = response.data[1];
                        $scope.userStatus = $scope.userDetail.isExternal == 0 ? $translate.instant('label.personel') : $translate.instant('label.external.user');
                    }

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        }
        $scope.removeFile = function (i) {
            $scope.fileList.splice(i, 1);
        };
        $scope.updateFiles = function (newFiles) {

            if (newFiles.length > 0) {
                if (($scope.fileList.length + newFiles.length + $scope.uploadedFiles.length) > 3) {
                    Notification.error($translate.instant('error.files.max3'));
                } else {
                    for (var i = 0; i < newFiles.length; i++) {
                        $scope.fileList.push(newFiles[i]);
                    }
                    // newFiles.forEach(function (f) {
                    //     $scope.fileList.push(f);
                    // });

                    $scope.uploadEnabled = true;
                    Notification.info($translate.instant('info.files.added'));
                }
            }
        };
        $scope.deleteFile = function (id) {
            var url = "notsecure/idea/deleteFileById.do";
            if ($rootScope.authenticated) {
                url = "private/idea/deleteFileById.do";
            }
            $scope.loadDeleteFiles = $http({
                method: 'POST',
                url: url,

                data: 'fileId=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    $scope.getUploadedFiles();
                    Notification.info($translate.instant('info.file.deleted'));
                });
        };
        $scope.uploadFiles = function () {
            if (($scope.fileList.length + $scope.uploadedFiles.length) > 3) {
                Notification.error($translate.instant('error.files.max3'));
                return;
            }
            if ($scope.fileList && $scope.fileList.length < 4 && $scope.fileList.length > 0) {
                handleMsg(MessageTypes.LOADING, 'info.files.uploading');
                for (var i = 0; i < $scope.fileList.length; i++) {
                    var payload = new FormData();

                    payload.append("ideaReferenceNumber", $scope.ref);
                    payload.append('problemId', $scope.problemId);
                    payload.append('file', $scope.fileList[i]);
                    payload.append('authenticated', false);
                    var url = "notsecure/idea/uploadFile.htm";
                    if ($rootScope.authenticated) {
                        url = 'private/idea/uploadFile.htm';
                        payload.append('authenticated', true);
                    }
                    $scope.loadUpdateFiles = $http({
                        url: url,
                        method: 'POST',
                        ignoreLoadingBar: true,
                        data: payload,
                        //assign content-type as undefined, the browser
                        //will assign the correct boundary for us
                        headers: { 'Content-Type': undefined },
                        //prevents serializing payload.  don't do it.
                        transformRequest: angular.identity
                    }).then(function (response) {
                        if (response.data.success) {
                            $scope.fileList = [];
                            Notification.info($translate.instant("info.files.uploaded"));
                            $scope.fileList = [];
                            $scope.getUploadedFiles();
                        } else {
                            Notification.error($translate.instant(response.data.message));
                        }


                    }, function (response) {
                        if (response.status > 0) {
                            Notification.error(response.status + ': ' + response.data);
                        }
                    }, function (evt) {
                        $scope.fileProgress =
                            Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                        if (evt.loaded == evt.total) { }
                    });
                }
            } else {
                Notification.error($translate.instant('error.files.max3'));
            }
        };
       
        $scope.save = function () {
            if ($scope.fileList.length > 0) {
                Notification.error(
                    $translate.instant("info.not.uploaded.files.exists")
                );
                return;
            }
            if (!$scope.termsChecked) {
                Notification.error($translate.instant('label.terms'));
                return;
            }
            //------------------------------öneri sahibi ekleme alani--------------------
            var suggOwners = []
            suggOwners.push(
              {
                ownerId: $scope.myInfo.ownerId,
                share: $scope.myInfo.share,
                corresponder: $scope.myInfo.isCorresponder,
                mainOwner: $scope.myInfo.mainOwner
              }
            );
            for (var i = 0; i < $scope.suggestionOwners.length; i++) {
           
              var so = $scope.suggestionOwners[i];
              suggOwners.push({
                ownerId: so.ownerId,
                share: so.share,
                corresponder: false,
                mainOwner: so.mainOwner
              });
            }
            
            //------------------------------öneri sahibi ekleme alani--------------------
            $scope.isLoading = true;
            $scope.idea.status = 0;
            $scope.idea.problemId = $scope.competitionId;
            var url = 'notsecure/idea/saveExternal.do';
            $scope.extData.education = $scope.selectedEdu.value;
            var arr = [];
            arr.push($scope.idea);
            var category = {};
            if ($scope.category != undefined) {
                category.id = $scope.category.id;
            }
            var data = 'ideaData=' + encodeURIComponent(JSON.stringify(arr)) + '&ideaFields=' + encodeURIComponent(JSON.stringify($scope.normalizeIdeaFields($scope.ideaFields))) + '&userData=' + encodeURIComponent(JSON.stringify($scope.userData)) + '&extData=' + encodeURIComponent(JSON.stringify($scope.extData)) + '&kaptcha=' + $scope.kaptcha + '&category=' + encodeURIComponent(JSON.stringify(category));
            if ($rootScope.authenticated) {
                url = 'private/idea/save.do';
                data = 'data=' + encodeURIComponent(JSON.stringify(new Array($scope.idea))) 
                + '&ideaFields=' + encodeURIComponent(JSON.stringify($scope.normalizeIdeaFields($scope.ideaFields)))
                 + '&category=' + encodeURIComponent(JSON.stringify(category))
                 + "&owners=" + encodeURIComponent(JSON.stringify(suggOwners));
            }

            $http({
                method: 'POST',
                url: url,
                data: data,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
            .then(function (res) {
                $scope.isLoading = false;
                if (res.data.success == true) {
                    if(($scope.ideaId != null)){
                   
                    Notification.info($translate.instant('info.idea.submitted.update'));
                    $location.path('competition/' + $scope.problemId);                        
                    }else{
                       
                        Notification.info($translate.instant('info.idea.submitted'));                            
                    }
                    $scope.kaptchaUrl = 'notsecure/captcha-image.do?_ts=' + new Date().getTime();
                    $location.path('competition/' + $scope.problemId);
                } else {
                    Notification.error(res.data.message);
                    $scope.kaptchaUrl = 'notsecure/captcha-image.do?_ts=' + new Date().getTime();
                }
            });
        };
        $scope.normalizeIdeaFields = function (list) {

            for (var i = 0; i < list.length; i++) {
                if (list[i] == undefined) {
                    list[i] = { "txt": null }
                }
                list[i].problemFieldId = $scope.competition.problemFields[i].id;
            }
            return list;
        }
        $scope.saveDraft = function () {
            if ($scope.fileList.length > 0) {
                Notification.error(
                    $translate.instant("info.not.uploaded.files.exists")
                );
                return;
            }
            if (!$scope.termsChecked) {
                Notification.error($translate.instant('label.terms'));
                return;
            }
               //------------------------------öneri sahibi ekleme alani--------------------
               var suggOwners = []
               suggOwners.push(
                 {
                   ownerId: $scope.myInfo.ownerId,
                   share: $scope.myInfo.share,
                   corresponder: $scope.myInfo.isCorresponder,
                   mainOwner: $scope.myInfo.mainOwner
                 }
               );
               for (var i = 0; i < $scope.suggestionOwners.length; i++) {
              
                 var so = $scope.suggestionOwners[i];
                 suggOwners.push({
                   ownerId: so.ownerId,
                   share: so.share,
                   corresponder: false,
                   mainOwner: so.mainOwner
                 });
               }
              
               //------------------------------öneri sahibi ekleme alani--------------------
            $scope.isLoading = true;
            $scope.idea.problemId = $scope.competitionId;
            //delete $scope.suggestion.entrydate;
            $scope.idea.status = 9;
            var arr = [];
            arr.push($scope.idea);

            var category = {};
            if ($scope.category != undefined) {
                category.id = $scope.category.id;
            }

            $http({
                method: 'POST',
                url: 'private/idea/save.do',
                data: 'data=' + encodeURIComponent(JSON.stringify(arr)) + '&ideaFields=' + encodeURIComponent(JSON.stringify($scope.normalizeIdeaFields($scope.ideaFields))) + '&category=' + encodeURIComponent(JSON.stringify(category)) + "&owners=" + encodeURIComponent(JSON.stringify(suggOwners)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    $scope.isLoading = false;
                    if (res.data.success == true) {
                        $scope.ideaId = res.data.data[0].id;
                        Notification.info($translate.instant('info.draft.saved'));
                        $location.path('submit-idea/' + $scope.problemId + '/' + res.data.data[0].id);
                    }

                });
        };
        $scope.ideaProject = function () {
            $http.get("project/retrieveUsedIdeaByID.do?id=" + $scope.ideaId).then(
                function (response) {
                    var datas = response.data;
                    $scope.projectsugg = datas.data;
                    $scope.isprojectAssinged = datas.success;

                });
        }
        function handleMsg(type, msg) {
            if (type == MessageTypes.ERROR) {
                $scope.showError = true;
                $scope.showMessage = false;
                $scope.showMessageSpinner = false;
                $scope.errorMsg = msg;
            } else if (type == MessageTypes.INFO) {
                $scope.showError = false;
                $scope.showMessage = true;
                $scope.showMessageSpinner = false;
                $scope.result = msg;
            } else if (type == MessageTypes.LOADING) {
                $scope.showError = false;
                $scope.showMessage = true;
                $scope.showMessageSpinner = true;
                $scope.result = msg;
            }
        }
        var MessageTypes = {
            ERROR: 1,
            INFO: 2

        };
        var regexIdeaView = /\/idea\/\d[0-9]*/g;
        var regexSubmitIdea = /\/submit-idea\/\d[0-9]*/;
        var regexEditIdea = /\/submit-idea\/\d[0-9]*\/\d[0-9]*/;
        if (regexIdeaView.test($location.path())) {
            $scope.ideaId = $routeParams.ideaId;
            $scope.loadIdea();
            $scope.mode = Modes.VIEW;
            $scope.ideaProject();
        } else if (regexEditIdea.test($location.path())) {
            $scope.mode = Modes.EDIT;
            $scope.problemId = $routeParams.competitionId;
            $scope.ideaId = $routeParams.ideaId;
            $scope.loadCompetition();
            $scope.loadIdeaForSubmit();
            $scope.loadIdeaFlag = true;
            $scope.loadCategories();
        } else if (regexSubmitIdea.test($location.path())) {
            $scope.problemId = $routeParams.competitionId;
            $scope.getRef();
            //$scope.loadProblemCriteria();
            $scope.mode = Modes.NEW;
            $scope.loadCategories();
        }

        var checkIdeaComment = function () {
            if ($scope.idea.ideaUserComment == 1 && $scope.idea.idea.status == 1) {
                if ($scope.competition.problemState == 'A') {
                    $scope.isCommentEnabled = true;

                }
                $scope.isCommentShow = true;

            }
            if ($scope.idea.evalComment == 1 && $scope.idea.idea.status == 1) {
                if ($scope.competition.problemState == 'E') {
                    $scope.isCommentEnabled = true;
                    $scope.ratingAllowed = true;
                }
                $scope.isCommentShow = true;
            }
        }

        $scope.isMineForList = function (params) {
     
            if (typeof params === "object") {
                for (var i = 0; i < params.length; i++) {
                    if (Number($rootScope.currentUser.id) == params[i].ownerId) return true;
                }
            }
            else {
                if (Number($rootScope.currentUser.id) == params) return true;
                else return false;
            }
            return false;

        };

        $scope.checkExternal = function () {
            $http({
                method: 'POST', 
                url: 'notsecure/user/checkExternalUser.do',

                data: 'email=' + $scope.userData.email + '&firstName=' + $scope.userData.firstName + '&surname=' + $scope.userData.surname,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.checkWarning) {
                        $scope.isExternal = true;
                        $translate('error.idea.isExternal').then(function (msg) {
                            Notification.error(msg);
                        });
                    }
                });
        }
        $scope.status = {
            isFirstOpen: true,
            isFirstDisabled: false
        };
        $scope.oneAtATime = true;

        $scope.deleteModal = function () {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: 'ideaCtrl',
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'sureModal.html'

            });


        };
        $scope.deleteModalClose = function () {
            $rootScope.modalInstance.close();
        };
        $scope.deleteIdea = function () {

            $http(
                {
                    method: 'POST',
                    url: 'private/Idea/deleteDraft.do',
                    data: 'ideaId=' + encodeURIComponent(JSON.stringify($scope.idea.id)),
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                    }
                }
            ).then(
                function (res) {
                    if (res.data.success == true) {
                        Notification.success($translate.instant("info.draft.deleted"));
                        $scope.deleteModalClose();
                        $location.path('/competition/' + $scope.problemId);
                    }
                }, function (res) {
                    Notification.error('Error check sugg deleted: ' + res.message);
                    $scope.deleteModalClose();
                }
            )
        }
        $scope.approveOrdenyIdea = function (i, a) {
            var state = 0;
            if (a === 1) {
                state = 1;
            }
            if (a === 2) {
                if (i.explanation == null || i.explanation == "") {
                    Notification.warning($translate.instant('info.idea.deny.message')); return;
                }
                state = 2;
            }
            $http({
                method: 'POST',
                url: 'private/idea/approvalIdea.do',
                data: 'id=' + encodeURIComponent(JSON.stringify(i.id)) + '&state=' + encodeURIComponent(JSON.stringify(state)) + '&explanation=' + encodeURIComponent(JSON.stringify(i.explanation)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data) {
                        $scope.loadIdea();
                        Notification.success($translate.instant('idea.status.completed'));

                    }
                });
        }
        $scope.submitIdeaComment = function () {
            $scope.ideaComment.ideaId = $scope.idea.id;
            $http({
                method: "POST",
                url: "private/ideaComment/save.do",
                data:
                    "data=" +
                    encodeURIComponent(JSON.stringify($scope.ideaComment)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            }).then(function (res) {
                $scope.commentSubmitPhase = false;
                if (res.data.success == true) {
                    $scope.ideaComment.comment = "";
                    $scope.loadIdeaComment();
                    $translate("info.comment.submit").then(function (msg) {
                        Notification.success(msg);
                    });
                } else {
                    $translate("error.comment.submit").then(function (msg) {
                        Notification.error(msg);
                    });
                }
            });
        };
        /* Project Portfolio */
        $scope.projectPortfolioModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                size: 'lg',
                controller: function ($scope, $uibModalInstance) {
                    $scope.project = {};
                    $scope.selectedproject = {};
                    $scope.openNeworAddContent = function () {
                        if ($scope.radioModel == 'Add') {
                            $http.get("project/retrieveProjects.do?&start=0&limit=25&states=1&priorities=").then(
                                function (response) {
                                    var list = response.data.data;
                                    $scope.projects = list;

                                });
                        }
                        else {
                            $scope.project.title = t.idea.showCase;
                            $scope.project.purpose = t.competition.description;
                            $scope.project.scope = t.idea.description;
                            $scope.project.status = 1;
                        }
                    }
                    $scope.projectModalSave = function () {

                        $http({
                            method: 'POST',
                            url: 'project/createFromIdeaOrSuggestionOrImprovements.do',

                            data: 'project=' + encodeURIComponent(JSON.stringify($scope.project)) + '&suggestionId=' + 0 + '&ideaId=' + t.idea.id,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                            }
                        })
                            .then(function (res) {
                                if (res.data.success) {
                                    Notification.success($translate.instant('competition.state.closed'));
                                    t.ideaProject();
                                    $uibModalInstance.close();
                                }
                            });

                    }
                    $scope.assignToProject = function () {
                        $http({
                            method: 'POST',
                            url: 'project/addToCurrentProject.do',

                            data: 'projectId=' + $scope.selectedproject.id + '&suggestionId=' + 0 + '&ideaId=' + t.idea.id,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                            }
                        })
                            .then(function (res) {
                                if (res.data.success) {
                                    Notification.success($translate.instant('competition.state.closed'));
                                    t.ideaProject();
                                    $uibModalInstance.close();
                                }
                            });
                    }
                    $scope.projectModalClose = function () {
                        $uibModalInstance.close();
                    }
                    $scope.selectProject = function (p) {
                        $scope.selectedproject = p;
                    }
                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "projectPortfolioModal.html"
            });
        };
        $scope.ideaProject();

        $scope.ideaSaveEditModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.tempIdea = t.idea;
                    $scope.Ok = function () {
                        t.save();
                        $uibModalInstance.close();
                    }

                    $scope.Close = function () {
                        $uibModalInstance.close();
                    }
                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "ideaSaveEditModal.html"
            });
        };

        $scope.getAdminComments = function () {
            $http
                .get(
                    "private/ideaComment/retrieveAdminComment.do?ideaId=" +
                    $scope.idea.id +
                    "&page=1&start=0&limit=25"
                )
                .then(
                    function (response) {
                        $scope.adminComments = response.data.data;
                    },
                    function (response) {
                        //Second function handles error
                        Notification.error("ERROR admin comments");
                    }
                );
        };
        $scope.adminCommentSubmitting = false;
        $scope.submitAdminComment = function () {
            $scope.adminCommentSubmitting = true;
            $http({
                method: "POST",
                url: "private/ideaComment/saveAdminComment.do",

                data:
                    "data=" +
                    encodeURIComponent(JSON.stringify(new Array($scope.adminComment))) +
                    "&id=" +
                    $scope.idea.id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            }).then(function (res) {
                $scope.adminCommentSubmitting = false;
                if (res.data.success == true) {
                    $scope.adminComment.comment = "";
                    $scope.getAdminComments();
                    $translate("info.comment.submit").then(function (msg) {
                        Notification.success(msg);
                    });
                } else {
                    $translate("error.comment.submit").then(function (msg) {
                        Notification.error(msg);
                    });
                }
            });
        };
    }]);