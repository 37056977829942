app.controller("rewardsManagementCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', 'Notification', '$translate',
    function ($scope, $sce, $http, $location, $rootScope, Notification, $translate) {
   
 /*
        $scope.getImprovementList = function () {
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $scope.myPromise = $http.get("private/improvement/list.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize + "&roleFilters=" + $scope.selectedFilterRole.join()
                + "&stateFilters=" + $scope.selectedFilterStatus.join() + "&categoryFilters=" + $scope.selectedFilterCategory + "&fieldFilters=" + $scope.selectedFilterField
                + "&query=" + $scope.query + "&sort=" + $scope.sortStr)
                .then(function (response) {
                    $scope.improvements = response.data.data;
                    $scope.improvementsTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.currentPage = page;
                    var rangeEnd = $scope.currentIndex + $scope.pageSize;
                    $scope.displayRangeEnd = rangeEnd > $scope.impFieldsTotal ? $scope.impFieldsTotal : rangeEnd;
                    $scope.loading = false;
                    $scope.addShowOwnerParameters();

                    //     $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
                }, function (response) {
                    //Second function handles error
                    $scope.improvements = []
                    //  Notification.error('Error: getImprovementList');
                });
        };
*/
       


       
    }
]);
