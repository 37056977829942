app.controller("newsListCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, Notification, $translate) {
        $scope.loading = true;
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        var path = "notsecure/news/getList.do?start=0&limit=10";

        if($rootScope.authenticated)
            path = "private/news/getList.do?start=0&limit=10";

         $scope.getNewsList = function(){
      
            $http.get(path)
                .then(function (response) {
                    $scope.news = response.data.data;
                }, function (response) {
                    //Second function handles error
                $scope.isShowNotification = true;
                });
        };

    
     $scope.getNewsList();
            

    }
]);