app.controller("competitionSettingsCtrl", ['$scope', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate', '$uibModal',
    function ($scope, $http, $location, $rootScope, $routeParams, Notification, $translate, $uibModal) {

        $scope.innovationTypes = [];
        $scope.suggestionTypes = [];
        $scope.selectedBoard = [];
        $scope.pageSize = 50;
        $scope.pagingMaxSize = 4;
        $scope.innovationType = {};
        $scope.innovationTypeData = {};
        $scope.suggestionType = {};
        $scope.updatestate = false;
        $scope.model = {};
        $scope.model.allItemsSelected = false;
        $scope.data = { query: '' };
        $scope.data.query = $location.search().query || '';
        $scope.activeForm = $scope.data.query != '' ? 1 : 0;

        $scope.init = function (a) {
            $scope.innovationTypes = [];
            if (a === 'i') $scope.getInnovationTypes();
           
        };

        $scope.getInnovationTypes = function () {
            //query=&external=internal&page=1&start=0&limit=30
            if (typeof $scope.data == 'undefined') $scope.data = { query: '' };
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $http.get("private/user/retrieveinnovationtypesAll.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize)
                .then(function (response) {
                    $scope.innovationTypes = response.data.data;
                    $scope.typeTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.currentPage = page;
                    $scope.displayRangeEnd = ($scope.currentIndex + $scope.pageSize) > $scope.usersTotal ? $scope.usersTotal : ($scope.currentIndex + $scope.pageSize);
                    $scope.loading = false;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
   
      
      
       
        $scope.openNewInnovationModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {

                    $scope.Ok = function () {

                        if ($scope.innovationType == "" || $scope.innovationType == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.innovationType == "" || $scope.innovationType == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        } if ($scope.innovationType == "" || $scope.innovationType == undefined) {
                            Notification.warning($translate.instant("warning.invalid.form"));
                            return;
                        }

                        //t.innovationType = angular.copy($scope.innovationType);
                        $scope.innovationType.status = 1;
                        t.saveUpdateInnovation($scope.innovationType);
                        $scope.Close();
                    };
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    };

                },
                size: 'lg',
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "newInnovationType.html"
            });
        }
        $scope.sureModalClose = function () {
            $rootScope.modalInstance.close();
        };


        $scope.updateInnovationTpye = function (i) {

            if (i) {
                $scope.innovationType = angular.copy(i);
                $scope.innovationType.status = 1;
            }
        };
        $scope.deleteInnovationType = function (i) {

            if (i) {
                $scope.innovationTypeData = angular.copy(i);
                $scope.innovationTypeData.status = 0;
            }
            $scope.saveUpdateInnovation();
        };
        $scope.activateInnovationType = function (i) {

            if (i) {
                $scope.innovationTypeData = angular.copy(i);
                $scope.innovationTypeData.status = 1;
            }
            $scope.saveUpdateInnovation();
        };

        $scope.cancelUpdateInnovation = function (i) {
            $scope.innovationType = {};
            $scope.updatestate = false;

        };

        $scope.pageChanged = function () {

            $scope.currentIndex = ($scope.currentPage - 1) * $scope.pageSize;
            $location.path('/settings').search('page', $scope.currentPage);
            if ($scope.activeForm == 2) $scope.getTykUsers();

        };

        $scope.getTemplate = function (i) {
            if (i.id === $scope.innovationType.id) return 'edit';
            else return 'display';
        };

        $scope.getTemplateSugg = function (i) {
            if (i.id === $scope.suggestionType.id) return 'editSugg';
            else return 'displaySugg';
        };
    
        $scope.saveUpdateInnovation = function (i) {

            if(i) $scope.innovationTypeData = angular.copy(i);
            if($scope.innovationType.status) $scope.innovationTypeData = angular.copy($scope.innovationType);
            $http({
                method: 'POST',
                url: 'private/user/updateInnovationType.do',
                data: 'data=' + encodeURIComponent(JSON.stringify($scope.innovationTypeData)),
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.innovationType = {};
                    $scope.getInnovationTypes();
                    $scope.updatestate = false;
                    if (res.data.success == true) {
                        Notification.success($translate.instant("sugg.state.10"));
                    } else {
                        $scope.hasError = true;
                    }
                   
                });

        };
      

    }]);