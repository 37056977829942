app.directive("fieldUserSelector", [
    "$http",
    "$translate","$uibModal","$log",
    function($http, $translate,$uibModal,$log) {
      return {
        restrict: "E",
        scope: {
          label: "=",
          modalTitle: "=",
          selected: "=",
          selectionLimit: "=",
          fieldId:"=",
          authType:"=",
          update:"&"
        },
        templateUrl: "partial/directive-imp/field-user-selector.html",
        controller: function($scope) {
            $scope.openUserSelector = function(size, template) {
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: template || 'FieldUserSelectorModal.html',
                    controller: 'fieldUserSelectorCtrl',
                    size: size,
                    resolve: {
                        items: function () {
                          return $scope.selected;
                        },
                        limit: function () {
                            return $scope.selectionLimit;
                        },
                        update: function () {
                            return $scope.update;
                        },
                        typeofuser: function () {
                            return $scope.typeOfUser;
                        },
                        fieldId: function () {
                            return $scope.fieldId;
                        },
                        authType: function () {
                            return $scope.authType;
                        }
                      }
                  });
          
                modalInstance.result.then(function(users) {
                    $scope.selected = users;
                  //$scope.update();
                }, function() {
                  $log.info('Modal dismissed at: ' + new Date());
                });
              };
              $scope.toggleAnimation = function() {
                $scope.animationsEnabled = !$scope.animationsEnabled;
              };
        }
      };
    }
  ]);
  app.controller('fieldUserSelectorCtrl', function ($uibModalInstance, items, limit,update,typeofuser,fieldId,authType, $http,$scope,Notification) {
    var $ctrl = this;
    var selectionLimit = limit;
    $scope.items = items;
    $scope.selectedUsers = items;
    $scope.userType = typeofuser;
    $scope.fieldId = fieldId;
    $scope.authType = authType;
    $scope.query = "";
    $scope.loading = false;
    $scope.pageSize = 10;
    $scope.currentIndex = 0;
    $scope.currentPage = 1;
    $scope.userCount = 0;
    $scope.oldSelection = [];
    for(var i=0; i < $scope.items.length; i++){
        $scope.oldSelection.push({
            id: $scope.items[i].id,
            name: $scope.items[i].name
        });
    }
    $scope.loadUsers = function()
    {
        $scope.loading = true;
        $scope.users = [];
    
        var _url = "private/improvement-field-user/list-by-type.do";
        
        var data = 'fieldId=' + $scope.fieldId+"&authType="+$scope.authType+"&start="+$scope.currentIndex+"&limit="+$scope.pageSize+"&query="+$scope.query;
        $http({
            method: 'GET',
            url:_url+"?"+data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
        .then(function (response) {
            $scope.users = [];
            var responseData = response.data.data;
            for(var i=0; i < responseData.length; i++){
                var fullName = responseData[i].userFullName;
                $scope.users.push({
                    id: responseData[i].userId,
                    name: responseData[i].name,
                    surname: responseData[i].surname,
                    title: responseData[i].userTitle,
                    authType : responseData[i].authType,
                    fieldId : responseData[i].fieldId
                });
            }
            $scope.loading = false;
            $scope.showMaxUserInfo = false;
            $scope.userCount = response.data.total;
            $scope.displayRangeStart = $scope.currentIndex +1;
            var rangeEnd = $scope.currentIndex + $scope.pageSize;
            $scope.displayRangeEnd = rangeEnd > $scope.userCount ? $scope.userCount : rangeEnd;
            
        }, function (response) {
            //Second function handles error
            Notification.error('ERROR editor');
            $scope.loading = false;
        });

    };

    $scope.getUserList = function () {
        var _url = "private/user/retrieveAllUsers.do";
        var data = 'query=' + $scope.query+"&page="+$scope.currentPage+"&start="+$scope.currentIndex+"&limit="+$scope.pageSize;
        $scope.myPromise = $http({
            method: 'POST',
            url:_url,
            data: data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
        .then(function (response) {
            $scope.users = response.data.data;
            $scope.loading = false;
            $scope.showMaxUserInfo = false;
            $scope.usersTotal = response.data.total;
            $scope.displayRangeStart = $scope.currentIndex +1;
            var rangeEnd = $scope.currentIndex + $scope.pageSize;
            $scope.displayRangeEnd = rangeEnd > $scope.usersTotal ? $scope.usersTotal : rangeEnd;
            
        }, function (response) {
            //Second function handles error
            Notification.error('ERROR editor');
            $scope.loading = false;
        });
    };


    $scope.clearSearch = function(){
        $scope.query = '';
        //$scope.loadUsers();
        $scope.getUserList();
    };
    $scope.remeveSelected = function(ind)
    {
        $scope.selectedUsers.splice(ind, 1);
        $scope.showMaxUserInfo = false;
        $scope.dublicateUser = null;
    };
    $scope.selectUser = function(user)
    {
        /* if (~$scope.selectedUsers.indexOf(user)) 
        {
            $scope.dublicateUser = user;
            return;
        } */
        if(selectionLimit == 1)
        {
            $scope.selectedUsers[0] = user;
            return;
        }
        for(var i = 0; i < $scope.selectedUsers.length; i++)
        {
            if($scope.selectedUsers[i].id === user.id)
            {
                $scope.dublicateUser = user;
                return;
            }
        }
        if($scope.selectedUsers.length >= selectionLimit)
        {
            $scope.showMaxUserInfo = true;
            return;
        }
        $scope.selectedUsers.push(user);
        
    };
    $scope.setPage = function(pageNo) {
        $scope.currentPage = pageNo;
    };

    $scope.pageChanged = function() {
        $scope.currentIndex = ($scope.currentPage - 1) * $scope.pageSize;
        $scope.getUserList();
      //  $scope.loadUsers();
    };
    $scope.ok = function () {
        update();
      $uibModalInstance.close($scope.selectedUsers);
    };
    $scope.cancel = function () {
      $uibModalInstance.close($scope.oldSelection);
    };
    $scope.loadUsers();
  });