app.controller("campaignViewCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification', "$uibModal",'$translate',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, Notification,$uibModal, $translate) {
        $scope.loading = true;
        $scope.isAdmin = $rootScope.currentUser.authorization && $rootScope.currentUser.authorization.hasAdminAccess;
        $scope.id = $routeParams.campaignId;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        var path = "private/campaign/retrieveCampaign.do?id="+$scope.id;
        $http.get(path)
            .then(function (response) {
                $scope.authenticated = $rootScope.authenticated;
                if(!($scope.authenticated && $rootScope)) $location.path("/login");
        
                $scope.campaign = response.data.data;
                $scope.loading = false;
            }, function (response) {
                //Second function handles error
                Notification.error('hata!');
            });
            $scope.completeCampaign = function(){
                $http.get("private/campaign/completeCampaign.do?id="+$scope.id)
                    .then(function (response) {
                        $scope.campaign = response.data.data;
                        $scope.loading = false;
                        Notification.success($translate.instant("info.campaign.completed"));
                        window.location.reload();
                    }, function (response) {
                        //Second function handles error
                        Notification.error($translate.instant("error.generic"));
                    });
            }


        $scope.closeCampaignModal = function (t) {
                $rootScope.modalInstance = $uibModal.open({
                    animation: true,backdrop: 'static',
                    controller: function ($scope, $uibModalInstance) {
        
                        $scope.closeCampaignModalOk = function () {
                          t.completeCampaign();
                          $uibModalInstance.close();
                        }
                        $scope.closeCampaignModalClose = function () {
                          $uibModalInstance.close();
                        }
                    },
                    ariaLabelledBy: "modal-title",
                    ariaDescribedBy: "modal-body",
                    templateUrl: "closeCampaignModal.html"
                });
            };
    }
]);