app.directive("contentEditor", [
    "$http",
    "$translate",
    "Notification",
    "textAngularManager",
    function ($http, $translate, Notification, textAngularManager) {
      return {
        restrict: "E",
        scope: {
          key: "=",
          desc:"=",
        },
        templateUrl: "partial/directive/content-editor.html",
        controller: function ($scope) {
          $scope.isLoading = true;
          var path = "private/content-object-by-key.do?key=" + $scope.key;
          $scope.loadContent = function () {
            $http
              .get(path)
              .then(
              function (response) {
                if(response.data.data){
                  $scope.content = response.data.data;
                }else{
                  $scope.content = {
                    contentKey:$scope.key
                  }
                }
                $scope.isLoading = false;
              },
              function (response) {
                $translate("error.generic").then(function (msg) {
                  Notification.error(msg);
                });
                $scope.isLoading = false;
              }
              );
          };
         $scope.save = function(){
            $http({
                method: "POST",
                url: "private/content/save.do",
                data: "content=" + encodeURIComponent(JSON.stringify($scope.content)),
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
              }).then(function(res) {
                if (res.data.success == true) {
                  $translate("info.content.submit").then(function(msg) {
                    Notification.success(msg);
                  });
                  $scope.isLoading = false;
                } else {
                  $translate("error.content.submit").then(function(msg) {
                    Notification.error(msg);
                  });
                  $scope.isLoading = false;
                }
              });
         }
          $scope.loadContent();
        }
      };
    }
  ]);
  