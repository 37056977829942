
app.directive('starRating', function () {
    return {
        restrict: 'EA',
        template: '<ul class="star-rating" ng-class="{readonly: readonly}">' +
        ' <span  ng-hide= "readonly" >  '+
        ' <span class="star-remove-rating"  ng-show= "sendRate" > <i class="fa fa-times-circle"  aria-hidden="true" ng-click="toggle(-1)"></i></span>' +
        ' <span class="star-remove-rating-disabled"  ng-show= "!sendRate" > <i class="fa fa-times-circle"  aria-hidden="true" ></i></span>' +
        ' </span>  '+
        '  <li ng-repeat="star in stars" class="star" ng-class="{filled: star.filled}" ng-click="toggle($index)">' +
        '    <i class="fa fa-star"></i>' +
        '  </li>' +
        '</ul>',
        scope: {
            ratingValue: '=ngModel',
            max: '=?',
            onRatingSelect: '&?',
            readonly: '=?'
        },
        link: function (scope, element, attributes) {
            if (scope.max == undefined) {
                scope.max = 5;
            }
            var sendFlag = false;

            function updateStars() {
        
                scope.stars = [];
                for (var i = 0; i < scope.max; i++) {
                    scope.stars.push({
                        filled: i < scope.ratingValue
                    });
                }

                scope.sendRate = scope.ratingValue > 0 ? true : false;
            }
            scope.toggle = function (index) {
                if (scope.readonly == undefined || scope.readonly === false) {
                    if( index == -1 ){
                        scope.ratingValue = 0;
                        sendFlag = true; 
                        scope.sendRate = false;
                    }
                    else{
                        scope.ratingValue = index + 1;
                        sendFlag = true;
                    }
                }
            };
            scope.$watch('ratingValue', function (newValue, oldValue) {
                if (newValue || newValue === 0 ) {
                    updateStars();
                    if (sendFlag) {
                        scope.onRatingSelect();
                        sendFlag = false;
                    }

                }
            });
        }
    };
});