app.controller("adminCampaignEditCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate', 'dateFormat', "$uibModal",
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, Notification, $translate, dateFormat , $uibModal) {
        $scope.loading = true;
        $scope.id = $routeParams.campaignId;
        if($rootScope.frontendConfig.suggestionEnabled){  
          $scope.campaign = {objectType: '1'};
        }else if(!$rootScope.frontendConfig.suggestionEnabled){ 
          $scope.campaign = {objectType: '2'};
        }
        $scope.showSaveDraft = false;
        $scope.showSubmit = true;
        $scope.gruplist = [];

        document.body.scrollTop = document.documentElement.scrollTop = 0;
        $scope.getCampaign = function(){ 
            var path = "private/campaign/retrieveCampaign.do?id="+$scope.id;
            $http.get(path)
                .then(function (response) {
                    $scope.campaign = response.data.data;
                    $scope.oldState = $scope.campaign.status;
                    $scope.showSaveDraft =  $scope.oldState ==  2 ? false : true;
                    $scope.loading = false;
                    $scope.dateEnd = new Date($scope.campaign.endDate);
                    $scope.objectType = $scope.campaign.objectType;
                    $scope.editMode = 1;
                }, function (response) {
                    //Second function handles error
                    Notification.error('hata!');
                });
        }
        
     
        $scope.saveCampaignModal = function (t , i) {
          $rootScope.modalInstance = $uibModal.open({
              animation: true,backdrop: 'static',
              controller: function ($scope, $uibModalInstance) {
  
                $scope.sendMail = false;
                  $scope.submitSaveCampaignModalOk = function () {
                    t.save( i , $scope.sendMail )
                      $uibModalInstance.close();
                  }
                  $scope.submitSaveCampaignModalClose = function () {
                    $uibModalInstance.close();
                  }
                  $scope.decide = function (param){
                    return  param == true ? false : true
                  }
  
              },
              ariaLabelledBy: "modal-title",
              ariaDescribedBy: "modal-body",
              templateUrl: "saveCampaignModal.html"
          });
      };
  
      $scope.submitCampaignModal = function (t , i) {
        $rootScope.modalInstance = $uibModal.open({
            animation: true,backdrop: 'static',
            controller: function ($scope, $uibModalInstance) {

              $scope.sendMail = false;
                $scope.submitCampaignModalOk = function () {
                  t.save( i , $scope.sendMail )
                    $uibModalInstance.close();
                }
                $scope.submitCampaignModalClose = function () {
                  $uibModalInstance.close();
                }
                $scope.decide = function (param){
                  return  param == true ? false : true
                }

            },
            ariaLabelledBy: "modal-title",
            ariaDescribedBy: "modal-body",
            templateUrl: "submitCampaignModal.html"
        });
    };

        $scope.delete = function(){
          if (!confirm($translate.instant("label.sure.delete"))) return;
          
      $http({
        method: 'POST',
        url: 'private/campaign/deleteCampaign.do',
        data: 'campId=' + $scope.campaign.id,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
      })
        .then(function (res) {
          if (res.data.success == true) {
            if( $scope.campaign.status == 2 ){
              Notification.success($translate.instant("info.campaign.deleted"));
            }else
                Notification.success($translate.instant("info.campaign.deleted"));

            $location.path('/admin-campaigns');
          } else {
            Notification.error($translate.instant("error.generic"));
          }
        });

      }
  /* date options */
  $scope.dateOptions = {
    formatYear: "yy",
    maxDate: new Date(2023, 4, 22),
    minDate: new Date(),
    startingDay: 1
  };
  $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
  $scope.format = $scope.formats[3];
  $scope.altInputFormats = ['M!/d!/yyyy'];

  // Disable weekend selection
  function disabled(data) {
    var date = data.date,
      mode = data.mode;
    return mode === "day" && (date.getDay() === 0 || date.getDay() === 6);
  }
  $scope.dpEnd = {
    opened: false
  };
  $scope.openEnd = function () {
    $scope.dpEnd.opened = true;
  };
  var formatStringToDate = function (a) {
    var date = new Date(a);
    return date.toString($scope.formats[3]);
  }





  //----------------------------------OPEN DISCUSSION----------------------------------------------

  $scope.getSelectedDepartment = function () {
    $http.get("private/campaign/listCampaignSelectedDepartment.do?id=" + $scope.id).then(
      function (response) {
        $scope.selectedDepartment = response.data.data;
      },
      function (response) {
        //Second function handles error
        Notification.error("hata!");
      }
    );
  };

  $scope.treeOptions = {};
  $scope.getAgac = function (node) {

    $http.get("private/campaign/agac.do?node=" + node + "&campaignId=" + $scope.id).then(
      function (response) {
        $scope.children = response.data.data;
      },
      function (response) {
        //Second function handles error
        Notification.error("hata!");
      }
    );
  };


 
  $scope.findSelectionById = function (id, children) {
    for (var i = 0; i < children.length; i++) {
      if (children[i].id == id) return children[i];
      else if (children[i].children) return $scope.findSelectionById(id, children[i].children);
    }
  }


  $scope.toggleParentSelection = function (item) {
    if (item.parentId) {
      var parent = $scope.findSelectionById(item.parentId, $scope.children);
      var checkedCount = 0;
      var childCount = 0;
      for (var i = 0; i < parent.children.length; i++) {
        childCount++;
        if (parent.children[i].checked) checkedCount++;
      }
      parent.checked = (childCount == checkedCount);
      $scope.toggleParentSelection(parent);
    }
  }

  $scope.toggleChildSelection = function (item, checked) {
    item.checked = checked;
    if (item.children != null) {
      for (var i = 0; i < item.children.length; i++) {
        $scope.toggleChildSelection(item.children[i], checked);
      }
    }
  }
  
  $scope.toggleGroupSelection = function (item) {
    item.checked = !item.checked;
    $scope.toggleChildSelection(item, item.checked);
    $scope.toggleParentSelection(item);
  };

  $scope.toggleChildren = function (scope) {
    if (!scope.$nodeScope.$modelValue.children) {
      $http.get("private/campaign/agac.do?node=" + scope.$nodeScope.$modelValue.id + "&campaignId=" + $scope.campaign.id).then(function (response) {
        scope.$nodeScope.$modelValue.children = response.data.data;
        if (scope.$nodeScope.$modelValue.children.length > 0) {
          for (var i = 0; i < scope.$nodeScope.$modelValue.children.length; i++) {
            scope.$nodeScope.$modelValue.children[i].parentId = scope.$nodeScope.$modelValue.id;
          }

          if (scope.$nodeScope.$modelValue.checked) {
            for (var i = 0; i < scope.$nodeScope.$modelValue.children.length; i++) {
              scope.$nodeScope.$modelValue.children[i].checked = true;
            }

          }
          scope.toggle();
        }
      });
    } else {
      if (scope.$nodeScope.$modelValue.children.length > 0) {
        scope.toggle();
      }
    }
  };


  $scope.SetChildrenToGroupList = function (children) {
    if (children) {
      for (var i = 0; i < children.length; i++) {
        var c = children[i];
        if (c.checked) {
          $scope.gruplist.push({
            checked: c.checked,
            hasChild: c.hasChild,
            id: c.id,
            idTree: c.idTree,
            leaf: c.leaf,
            name: c.name
          });
        } else if (children[i].children) {
          $scope.SetChildrenToGroupList(children[i].children);
        }
      }
    }
  }

 

  $scope.sureModalClose = function () {
    $rootScope.modalInstance.close();
  };

 
  $scope.sureMailModal = function (t) {
    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
   
        $scope.sureMailModalOk = function () {
          t.saveImprovementUserOrganization(true)
          $uibModalInstance.close();
        }

        $scope.sureMailModalClose = function () {
          t.saveImprovementUserOrganization(false)
          $uibModalInstance.close();
        };
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "sureMailModal.html"
    });
  };
  $scope.sureMailModalClose = function () {
    $rootScope.modalInstance.close();
  };

  //----------------------------------OPEN DISCUSSION----------------------------------------------

  
  if($scope.id)
  {
    $scope.getCampaign();
    $scope.getAgac('root');
    $scope.getSelectedDepartment();  }
  else{
 
      var path = "private/campaign/saveNewTemp.do";
      $http.get(path)
          .then(function (response) {
              $scope.id = response.data.data;
              $scope.campaign.id =   $scope.id ;
              $scope.oldState = -1;
              $scope.showSaveDraft = true;
              $scope.editMode = 0;  
              $scope.getAgac('root');
              if($rootScope.frontendConfig.suggestionEnabled && $rootScope.frontendConfig.improvementEnabled){
                $scope.objectType = 1;
                console.log($rootScope.frontendConfig.suggestionEnabled + " - " + $rootScope.frontendConfig.improvementEnabled + " - " + $scope.objectType);
              }
              if($rootScope.frontendConfig.suggestionEnabled && !$rootScope.frontendConfig.improvementEnabled){
                $scope.objectType = 1;
                console.log($rootScope.frontendConfig.suggestionEnabled + " - " + $rootScope.frontendConfig.improvementEnabled + " - " + $scope.objectType);
              }
              if(!$rootScope.frontendConfig.suggestionEnabled && $rootScope.frontendConfig.improvementEnabled){
                $scope.objectType = 2;
                console.log($rootScope.frontendConfig.suggestionEnabled + " - " + $rootScope.frontendConfig.improvementEnabled + " - " + $scope.objectType);
              }
              console.log("scope: " + $scope.objectType);
          }, function (response) {
              //Second function handles error
              Notification.error('hata!');
          });
  
  }
  $scope.save = function(status , isSendMail){
    if(isSendMail == undefined || isSendMail == null)
        isSendMail = false;
    $scope.campaign.status = status;
    $scope.dateEnd ? $scope.campaign.endDate = $scope.dateEnd : '';

$scope.SetChildrenToGroupList($scope.children);

if($scope.gruplist.length == 0 && status == 2){
  Notification.warning($translate.instant("info.select.user"));
  $scope.campaign.status = $scope.oldState;
  return;
}

$http({
  method: 'POST',
  url: 'private/campaign/save.do',
  data: 'data=' + encodeURIComponent(JSON.stringify($scope.campaign))  +
  "&groupList=" + encodeURIComponent(JSON.stringify($scope.gruplist)) +
  "&oldState=" +    $scope.oldState +
  "&isSendMail=" + encodeURIComponent(JSON.stringify(isSendMail)),
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  }
})
  .then(function (res) {
    $scope.gruplist = [];
    if (res.data.success == true) {
      $scope.campaign = res.data.data;
   
      if ($scope.campaign.status == 1){
        Notification.success($translate.instant("info.campaign.draft.saved"));
      }
      else if ( $scope.oldState == 2){
        Notification.success($translate.instant("label.save.return.success"));
        $location.path('/admin-campaign-view/'+$scope.campaign.id);
      }
      else{
        Notification.success($translate.instant("info.campaign.submitted"));
        $location.path('/admin-campaign-view/'+$scope.campaign.id);
      }

    $scope.oldState = $scope.campaign.status;

    } else {
      $scope.campaign.status = $scope.oldState;
      Notification.error($translate.instant("error.generic"));
    }
  });

}



}]);