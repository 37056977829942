app.controller("projectListCtrl", ["$scope", "$sce", "$http", "$location", "$rootScope", "$routeParams", "Notification", "dateFormat", "$translate", "$uibModal", function ($scope,
    $sce, $http, $location, $rootScope, $routeParams, Notification, dateFormat, $translate, $uibModal) {


    $scope.project = {};
    $scope.projects = [];
    $scope.loading = true;
    $scope.init = function () {
        $scope.getProjectList();
        $scope.getProjectStateNames();
    }

    /* Functions */

    $scope.getProjectList = function () {
        $http.get("project/retrieveProjects.do?&start=0&limit=25&states=&priorities=").then(
            function (response) {
                var list = response.data.data;
                $scope.projects = list;
                $scope.loading = false;
            });
    }
    $scope.getProjectStateNames = function () {
        $http.get("project/retrieveStateNames.do").then(
            function (response) {
                var list = response.data.data;
                $scope.projectStates = list;

            });
    }

            /* Excel */
            $scope.excelExport = function () {
              //  $scope.loading = true;
              //  document.body.scrollTop = document.documentElement.scrollTop = 0;
                $http.get("private/project/exportExcel.htm?page=1&start=0&limit=25", { responseType: 'arraybuffer' })
                    .then(function (response) {
    
                        var header = response.headers('Content-disposition')
                        var fileName = header.split("=")[1].replace(/\"/gi, '');
    
                        var blob = new Blob([response.data],
                            { type: 'application/vnd.ms-excel;charset=utf-8' });
                        var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                        var link = angular.element('<a/>');
                        link.attr({
                            href: objectUrl,
                            download: fileName
                        })[0].click();
    
                        $scope.currentPage = page;
                        $scope.loading = false;
    
                    }, function (response) {
                        //Second function handles error
                        Notification.error('Error: competition');
                    });
    
            };


}]);
app.filter('projectState', function () {
    return function (input) {
        switch (input) {
            case "Paused":
                return 'project.state.paused';
            case "In Progress":
                return 'project.state.inprogress';
            case "Completed":
                return 'project.state.completed';
            case "Canceled":
                return 'project.state.canceled';
            case "Not Started":
                return 'project.state.not.started'; 
        }
    };
});
app.filter('projectPriority', function () {
    return function (input) {
        switch (input) {
            case "Low":
                return 'project.priority.low';
            case "Medium":
                return 'project.priority.medium';
            case "High":
                return 'project.priority.high';
        }
    };
});
app.filter('projectProgress', function () {
    return function (input) {
        switch (input) {
            case "Good":
                return 'project.progress.good';
            case "Fair":
                return 'project.progress.fair';
            case "Poor":
                return 'project.progress.poor';
        }
    };
});
