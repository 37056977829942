app.controller("improvementsCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', 'Notification', '$translate',
    function ($scope, $sce, $http, $location, $rootScope, Notification, $translate) {
        $scope.query = $location.search().query || '';
        $scope.selectedFilterRole = ($location.search().roleFilters && $location.search().roleFilters.split(',')) || [];
        $scope.selectedFilterStatus = ($location.search().stateFilters && $location.search().stateFilters.split(',')) || [];
        $scope.selectedFilterCategory = ($location.search().categoryFilters && $location.search().categoryFilters.split(',')) || [];
        $scope.selectedFilterField = ($location.search().fieldFilters && $location.search().fieldFilters.split(',')) || [];
        $scope.selectedFilterCampaign = ($location.search().campaignFilters && $location.search().campaignFilters.split(',')) || [];

        $scope.sortStr = $location.search().sort || "id desc";

        $scope.isAdmin = ($rootScope.currentUser && $rootScope.currentUser.authorization) ? $rootScope.currentUser.authorization.hasAdminAccess : false;
        $scope.pageSize = 10;
        $scope.currentPage = 1;
        $scope.currentIndex = 0;
        $scope.loading = false;
        $scope.stateList = [];
        $scope.campaignList = [];
        $scope.roleList = [];
        $scope.improvementFilterCategoryList = [];

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function () {

            $scope.currentIndex = ($scope.currentPage - 1) * $scope.pageSize;
            $location.search().stateFilters = $scope.selectedFilterStatus.toString();
            $location.search().categoryFilters = $scope.selectedFilterCategory.toString();
            $location.search().campaignFilters = $scope.selectedFilterCampaign.toString();
            $location.search().roleFilters = $scope.selectedFilterRole.toString();
            $location.search().fieldFilters = $scope.selectedFilterField.toString();
            $location.search().sort = $scope.sortStr;
            $location.path('/improvements').search('page', $scope.currentPage);

        };

        $scope.go = function (path) {
            $location.path(path);
        };

        $scope.getImprovementList = function () {
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $scope.myPromise = $http.get("private/improvement/list.do?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize + "&roleFilters=" + $scope.selectedFilterRole.join()
                + "&stateFilters=" + $scope.selectedFilterStatus.join() + "&categoryFilters=" + $scope.selectedFilterCategory + "&fieldFilters=" + $scope.selectedFilterField + "&campaignFilters=" + $scope.selectedFilterCampaign
                + "&query=" + $scope.query + "&sort=" + $scope.sortStr)
                .then(function (response) {
                    $scope.improvements = response.data.data;
                    $scope.improvementsTotal = response.data.total;
                    $scope.displayRangeStart = $scope.currentIndex + 1;
                    $scope.currentPage = page;
                    var rangeEnd = $scope.currentIndex + $scope.pageSize;
                    $scope.displayRangeEnd = rangeEnd > $scope.impFieldsTotal ? $scope.impFieldsTotal : rangeEnd;
                    $scope.loading = false;
                    $scope.addShowOwnerParameters();

                    //     $scope.calcindex = $scope.pageSize * ($scope.pagination.currentPage - 1);
                }, function (response) {
                    //Second function handles error
                    $scope.improvements = []
                    //  Notification.error('Error: getImprovementList');
                });
        };

        $scope.addShowOwnerParameters = function () {
            for (var k = 0; k < $scope.improvements.length; k++) {
                $scope.improvements[k].userAuthList = [];
                for (var i = 0; i < $scope.improvements[k].authorizationList.length; i++) {
                    if ($scope.improvements[k].authorizationList[i].authType === 4)
                        $scope.improvements[k].userMentor = $scope.improvements[k].authorizationList[i];
                    if ($scope.improvements[k].authorizationList[i].authType === 6)
                        $scope.improvements[k].userImplementer = $scope.improvements[k].authorizationList[i];

                    if ($scope.improvements[k].authorizationList[i].userId == $rootScope.currentUser.id)
                        $scope.improvements[k].userAuthList.push($scope.improvements[k].authorizationList[i].authType)
                }

                $scope.improvements[k].isShowOwner = $scope.isImprovementOwnersShow([0, 1], $scope.improvements[k].userAuthList)
            }
        }

        $scope.isImprovementOwnersShow = function (showList, userAuthList) {
            if (showList == undefined || showList == null || showList.length == 0)
                return false;
            for (var i = 0; i < showList.length; i++) {
                for (var j = 0; j < userAuthList.length; j++) {
                    if (showList[i] == userAuthList[j])
                        return true;
                }
            }
            return false;
        }

        $scope.getImprovementFilterList = function () {
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
            $scope.cgImpFilterListResult = $http.get("private/improvement/getFilterList.do")
                .then(function (response) {
                    var stateIdList = response.data.data.stateIdList;
                    var roleIdList = response.data.data.roleIdList;
                    $scope.campaignList1 = response.data.data.campaignList;
                    var campaignList = response.data.data.campaignList;
                    for (var i = 0; i < stateIdList.length; i++) { $scope.stateList.push({ id: stateIdList[i], selected: false }); }
                    for (var i = 0; i < roleIdList.length; i++) { $scope.roleList.push({ id: roleIdList[i], selected: false }); }
                    for (var i = 0; i < campaignList.length; i++) { $scope.campaignList.push({ id: campaignList[i].id, selected: false }); }
                    
                    $scope.campaignList.unshift({ id: 0, name: $translate.instant("label.campaign.free"), selected: false });
                    $scope.campaignList1.unshift({ id: 0,code:"Ser", name: $translate.instant("label.campaign.free"), selected: false });
                 
                    $scope.improvementFilterCategoryList = response.data.data.improvementCategoryList
                    $scope.improvementFilterFieldList = response.data.data.improvementFieldList


                    $scope.query = $location.search().query || '';
                    $scope.sortStr = $location.search().sort || 'id desc';
                    $scope.selectedFilterRole = ($location.search().roleFilters && $location.search().roleFilters.split(',')) || [];
                    $scope.selectedFilterStatus = ($location.search().stateFilters && $location.search().stateFilters.split(',')) || [];
                    $scope.selectedFilterCategory = ($location.search().categoryFilters && $location.search().categoryFilters.split(',')) || [];
                    $scope.selectedFilterField = ($location.search().fieldFilters && $location.search().fieldFilters.split(',')) || [];
                    $scope.selectedFilterCampaign= ($location.search().campaignFilters && $location.search().campaignFilters.split(',')) || [];


                    selectFilters($scope.campaignList, $scope.selectedFilterCampaign);
                    selectFilters($scope.stateList, $scope.selectedFilterStatus);
                    selectFilters($scope.roleList, $scope.selectedFilterRole);
                    selectFilters($scope.improvementFilterCategoryList, $scope.selectedFilterCategory);
                    selectFilters($scope.improvementFilterFieldList, $scope.selectedFilterField);
                }, function (response) {

                    Notification.error('Error: stateList');
                });
        };
        $scope.getCampaignName = function(id){
            for(var i = 0; i< $scope.campaignList1.length; i++)
                if($scope.campaignList1[i].id == id)
                    return $scope.campaignList1[i].code + '-' + $scope.campaignList1[i].name;
        }; 
        

        $scope.getImprovementFilterList();
        $scope.getImprovementList();


        $scope.sort = function (key, type) {
            $scope.sortStr = key + ' ' + type;
            $location.search().sort = $scope.sortStr;
            $scope.getImprovementList();
        };
        $scope.filterSuggestions = function () {

            $scope.currentPage = 1;
            $scope.currentIndex = 0;
            $location.search().page = 1;
            $location.search().stateFilters = $scope.selectedFilterStatus.toString();
            $location.search().categoryFilters = $scope.selectedFilterCategory.toString();
            $location.search().campaignFilters = $scope.selectedFilterCampaign.toString();
            $location.search().roleFilters = $scope.selectedFilterRole.toString();
            $location.search().fieldFilters = $scope.selectedFilterField.toString();
            $location.search().sort = $scope.sortStr;
            //   $location.search().query = $scope.query;
            //    $scope.getImprovementList();

            $location.path('/improvements').search('query', $scope.query);
        };
        $scope.clearFilter = function () {


            $location.search().categoryFilters = "";
            $location.search().stateFilters = "";
            $location.search().roleFilters = "";
            $location.search().fieldFilters = "";
            $location.search().sort = "";
            $location.search().query = "";
            $location.url($location.path());
            window.location.reload(true);
            //   
        };

        $scope.isOwnerImprovement = function (list, ownerId) {
            for(var i = 0 ; i<list.length ; i++)
                if(list[i].ownerId == ownerId)
                    return true;
        };


        function selectFilters(arr, selected) {
            if (arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                    arr[i].selected = false;
                    for (var j = 0; j < selected.length; j++) {
                        if (arr[i].id == selected[j]) {
                            arr[i].selected = true;
                            break;
                        }
                    }
                }
            }
        }

        $scope.$watch('improvementFilterCategoryList|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedFilterCategory = nv.map(function (t) {
                    return t.id;
                });
            }
        }, true);
        $scope.$watch('stateList|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedFilterStatus = nv.map(function (t) {
                    return t.id;
                });
            }
        }, true);
        $scope.suggestionStates = window.suggestionStates;

        $scope.$watch('roleList|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedFilterRole = nv.map(function (s) {
                    return s.id;
                });
            }
        }, true);

        $scope.$watch('campaignList|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedFilterCampaign = nv.map(function (s) {
                    return s.id;
                });
            }
        }, true);

        $scope.$watch('improvementFilterFieldList|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedFilterField = nv.map(function (s) {
                    return s.id;
                });
            }
        }, true);

        $scope.roleFilters = window.roleFilters;

        $scope.$watch('roleFilters|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedRoles = nv.map(function (r) {
                    return r.id;
                });
            }
        }, true);

        $scope.isShowFilterRow = function (filterList) {

            if (filterList == [] || filterList == null || filterList == undefined)
                return false;

            for (var i = 0; i < filterList.length; i++) {
                if (filterList[i].selected)
                    return true
            }
        }

        $scope.excelExport = function () {
            $scope.loading = true;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            if (typeof $scope.query == 'undefined') $scope.query = '';
            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;
           $scope.myPromise = $http.get("private/improvement/exportExcel.htm?start=" + $scope.currentIndex + "&limit=" + $scope.pageSize + "&roleFilters=" + $scope.selectedFilterRole.join()
                + "&stateFilters=" + $scope.selectedFilterStatus.join() + "&categoryFilters=" + $scope.selectedFilterCategory + "&campaignFilters=" + $scope.selectedFilterCampaign + "&fieldFilters=" + $scope.selectedFilterField
                + "&query=" + $scope.query + "&sort=" + $scope.sortStr, { responseType: 'arraybuffer' })
                .then(function (response) {

                    var header = response.headers('Content-disposition')
                    var fileName = header.split("=")[1].replace(/\"/gi, '');

                    var blob = new Blob([response.data],
                        { type: 'application/vnd.ms-excel;charset=utf-8' });
                    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                    var link = angular.element('<a/>');
                    link.attr({
                        href: objectUrl,
                        download: fileName
                    })[0].click();

                    $scope.currentPage = page;
                    $scope.loading = false;

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });

        }

        $scope.runAlarm = function () {
         
            var path = "private/improvement/runAlarm.do";
            $http
              .get(path).then(
                function (response) {})}
      
    }
]);
app.filter('improvementStatusStyle', function () {
    return function (input) {
        return 'improvement.status.style.' + input;
    };
});

app.filter('improvementStatusLabel', function () {
    return function (input) {
        return 'improvement.status.' + input;
    };
});

app.filter('auditStatusLabel', function () {
    return function (input) {
        return "label.improvement." + input;
    };
});

app.filter('roleFilterLabel', function () {
    return function (input) {
        return "improvement.role.label." + input;
    };
});

app.filter('htmlToPlaintext', function () {
    return function(text) {
        return  text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
});

//müşteriye özel badge görsellerinin text e çevrilmesi
app.filter('impRoleLabel', function () {
    return function (input) {
        return "imp.badge." + input;
    };
});
app.filter('impRoleCssClass', function () {
    return function (input) {
        return "imp-" + input;
    };
});