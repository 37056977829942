app.controller("reportProblemCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate', 'Notification', 'dateFormat',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, Notification, dateFormat) { 
        
         $scope.pageSize = 50;
         $scope.pagingMaxSize= 4;
         $scope.probId=0;
         $scope.competitionId = $routeParams.competitionId;
         $scope.query = $location.search().query || '';
         $scope.selectedStates = ($location.search().state && $location.search().state.split(',')) || [];
         $scope.selectedCategories = ($location.search().category && $location.search().category.split(',')) || [];
         for (var i = 0; i < $scope.selectedCategories.length; i++) {
             $scope.selectedCategories[i] = parseInt($scope.selectedCategories[i]);
         }  

         $scope.lang = $rootScope.lang;
         $scope.$watch('$rootScope', function (newValue, oldValue) {
             $scope.lang = $rootScope.lang;
         });
         $scope.init = function () {
             $scope.getProblemReport();
         };
        
         $scope.sortStr = $location.search().sort || 'idea.enterDate desc';
         var sort = $location.search().sort || $scope.sortStr;

         $scope.pageChanged = function () {
            $scope.currentIndex = ($scope.currentPage - 1) * $scope.pageSize;
            $location.path('/idea-list/' + $scope.competitionId).search('page', $scope.currentPage).search('sort', $scope.sortStr);
        };

        $scope.sort = function (key, type) {
            $scope.sortStr = key + ' ' + type;
            $scope.loadIdeas();
        };


        $scope.clearFilter = function () {
            var date = new Date();
            var year = date.getFullYear();
            year = year - 1;
            date.setFullYear(year);
            $scope.basTarih = date;
            $scope.bitTarih = new Date();
        };
        $scope.clearFilter();

        $scope.filterIdeas = function () {
            $scope.currentPage = 1;
            $scope.currentIndex = 0;
            $location.search().page = 1;
            $location.search().state = $scope.selectedStates.toString();
            $location.search().category = $scope.selectedCategories.toString();
            $location.path('/idea-list/' + $scope.competitionId).search('query', $scope.query).search('ideaState', $scope.selectedStates);
            $scope.pathL = $location.url();
            $scope.pathL = $scope.pathL.substring($location.path().length);
        };

         $scope.getProblemReport = function () {
            var tarih = {};
            tarih.basTarih = dateFormat.formatter($scope.basTarih);
            tarih.bitTarih = dateFormat.formatter($scope.bitTarih);

            var page = $location.search().page || 1;
            $scope.currentIndex = (page - 1) * $scope.pageSize;

            $http.get("private/idea/retrieve.do?problemId=" + $scope.competitionId + "&page=1&start=" +
            $scope.currentIndex + "&limit=" + $scope.pageSize + "&query="
            + encodeURIComponent($scope.query) + "&sort=" + sort + "&ideaState="
            + $scope.selectedStates.join() + "&ideaCategories=" + $scope.selectedCategories.join())

            $http({
                method: 'POST',
                url: 'private/problemReport.do',
                data: 'market=' + encodeURIComponent(JSON.stringify(tarih)),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        $scope.probData = res.data.data
                        generateCharts();
                        $scope.probTotal = res.data.total;
                        $scope.currentPage = page;
                        $scope.displayRangeStart = $scope.currentIndex + 1;
                        $scope.displayRangeEnd = ($scope.currentIndex + $scope.pageSize) > $scope.probTotal ? $scope.probTotal : ($scope.currentIndex + $scope.pageSize);

                    }
                });
        };


        $scope.pageChanged = function () {

            $scope.currentIndex = ($scope.currentPage - 1) * $scope.pageSize;
            $location.path('/report-user').search('page', $scope.currentPage);

        };


        var generateCharts = function () {
            $scope.labelAlan = [];
            $scope.dataAlan = [];
            $scope.optionsAlan = {};
            $scope.labelDurum = [];
            $scope.dataDurum = [];
            $scope.optionsDurum = {};

            if ($scope.probData) {
                for (var index = 0; index < $scope.probData.innovationTypeList.length; index++) {
                    $scope.labelAlan.push($scope.getLetterAt(index) + '-' + ($scope.lang == "tr" ? $scope.probData.innovationTypeList[index].name_tr : $scope.probData.innovationTypeList[index].name));
                    $scope.dataAlan.push($scope.probData.innovationTypeList[index].total);
                }
                 
                var chartOptions = {responsive: true,scales: {yAxes: [{display: true,ticks: {beginAtZero: true}}]}};
                $scope.optionsAlan = chartOptions;
                $scope.labelDurum = [$translate.instant('problem.status.A') , $translate.instant('problem.status.B'),$translate.instant('problem.status.C'),$translate.instant('problem.status.D')];
                //$scope.labelDurum = ["A-Gonderildi","A-Gonderildi","A-Gonderildi","A-Gonderildi","E-Kabul Edildi","A-Gonderildi","A-Gonderildi","A-Gonderildi"];
                $scope.dataDurum = [$scope.probData.ann, $scope.probData.eva, $scope.probData.pen, $scope.probData.com];
                $scope.optionsDurum = chartOptions;     

            } else {
                $scope.labelAlan.push("Veri yok");
                $scope.dataAlan.push(0);
                $scope.labelDurum.push("Veri yok");
                $scope.dataDurum.push(0);
            }

        };

        var formatDate = function (a) {

            if (typeof (a) != 'string') {
                var dd = a.getDate();
                var mm = a.getMonth() + 1; //January is 0!

                var yyyy = a.getFullYear();
                if (dd < 10) {
                    dd = '0' + dd;
                }
                if (mm < 10) {
                    mm = '0' + mm;
                }
                var date = mm + '/' + dd + '/' + yyyy;
                return date;
            }
            return a;
        };

        $scope.getLetterAt = function (location) {
            var alphabet = "ABCDEFGHIJKLMNOPRSTUVYZ".split("");
            if (location < alphabet.length) {
                return alphabet[location];
            } else {
                return 'X';
            }
        };

         /* DatePicker Setup*/
         $scope.open1 = function () {
            $scope.popup1.opened = true;
        };
        $scope.popup1 = {
            opened: false
        };
        $scope.open2 = function () {
            $scope.popup2.opened = true;
        };
        $scope.popup2 = {
            opened: false
        };
        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[3];
        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2035, 5, 22),
             startingDay: 1
        };



        $scope.ideaStates = window.ideaStates;
        $scope.$watch('ideaStates|filter:{selected:true}', function (nv) {
            if (nv) {
                $scope.selectedStates = nv.map(function (s) {
                    return s.id;
                });
            }
        }, true);


        $scope.problemIds = [];
        selectFilters(window.ideaStates, $scope.selectedStates);

        $scope.checkAll = function(id) {
        
            /*$scope.problemIds.push(id);

                var result = $scope.ContainsFunc($scope.problemIds, id);
                    if(result == false){
                 
                        $scope.problemIds.splice(id,1);
                    }*/



           /*  arr = angular.isArray(arr) ? arr : [];
            for (var i = 0; i < arr.length; i++) {
                if (angular.equals(arr[i].problemId, id)) {
          
                    $scope.problemIds.splice(i, 1);

                }
              }    
              $scope.problemIds.push(id);
        */


              
              if( $scope.problemIds.length == 0){
                    $scope.problemIds.push(id);
                }else{
                    var result = $scope.ContainsFunc($scope.problemIds, id);
                    if(result == true){
                        $scope.problemIds.splice($scope.problemIds.indexOf(id),1);
                    }else{
                        $scope.problemIds.push(id);
                    }
                }
        };
        $scope.ContainsFunc = function(arr, item){
            if (angular.isArray(arr)) {
                for (var i = 0; i < arr.length; i++) {
                  if (angular.equals(arr[i], item)) {
                    return true;
                  }
                }
              }
              return false;

        };

        $scope.excelExport = function(){

           $http.get("private/idea/exportProbExcel.do?problemId=" + $scope.problemIds + "&page=1&start=" + $scope.currentIndex + "&limit=" + $scope.pageSize + "&query=" + encodeURIComponent($scope.query) + "&sort=" + $scope.sortStr + "&ideaState=" + $scope.selectedStates.join(), { responseType: 'arraybuffer' })
           .then(function (response) {

               var header = response.headers('Content-disposition')
               var fileName = header.split("=")[1].replace(/\"/gi, '');

               var blob = new Blob([response.data],
                   { type: 'application/vnd.ms-excel;charset=utf-8' });
               var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
               var link = angular.element('<a/>');
               link.attr({
                   href: objectUrl,
                   download: fileName
               })[0].click();

           }, function (response) {
               //Second function handles error
               Notification.error('Error: competition');
           });
          };


        function selectFilters(arr, selected) {
            if (arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                    arr[i].selected = false;
                    for (var j = 0; j < selected.length; j++) {
                        if (arr[i].id == selected[j]) {
                            arr[i].selected = true;
                            break;
                        }
                    }
                }
            }



        }



        window.ideaStates = [
            { id: 9, selected: false },
            { id: 0, selected: false },
            { id: 1, selected: false },
            { id: 2, selected: false }
        ];


    }]);