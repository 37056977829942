app.directive("evalImp", [
  "$http",
  "$translate",
  "Notification",
  function($http, $translate, Notification) {
    return {
      
      restrict: "E",
      scope: {
        objectId: "=",
        evalType: "=",
        evalDesc: "=",
        evalTitle: "=",
        objectType: "=",
        evalScore: "=",
        commands: "=",
        commandType:"=",
        showToggle: "="
      },
      templateUrl: "partial/directive-imp/eval-dir.html",
      controller: function($scope) {
        $scope.isLoading = true;
        $scope.isVisible = false;
        $scope.$watch('commands', function (newVal) {
            if (newVal) {
              $scope.isVisible = false;
              $scope.availableCommands = $scope.commands;
         
              if($scope.availableCommands != null && $scope.availableCommands.length > 0){
                for(var i =0; i < $scope.availableCommands.length ; i++){
                  if( $scope.availableCommands[i] === $scope.commandType  ){
                   $scope.isVisible=true;
                    $scope.loadEval();
                  }
                }
              }
            }
          });
        $scope.userScore = 0;
        $scope.calculateUserScore = function() {
      
          var scoreOver100 = 0;
          var len = $scope.evalData.evalItems.length;
          for (var i = 0; i < len; i++) {
            var p = $scope.evalData.evalItems[i];
            scoreOver100 += Math.floor(p.score * p.weight / p.qtype);
          }
          $scope.userScore = Math.round(
            scoreOver100 / 100 * $scope.evalData.evalOver
          );
        };
        
        $scope.loadEval = function() {
      
          $http
            .get(
              "private/eval/retrieveEval.do?objectId=" +
                $scope.objectId +
                "&evalType=" +
                $scope.evalType +
                "&objectType=" +
                $scope.objectType
            )
            .then(
              function(response) {
                if (response.data.success == true) {
                  if (response.data.data) {
                    $scope.evalData = response.data.data;
                    $scope.evalScore =   $scope.evalData.score;
                    $scope.participantCount =   $scope.evalData.participantCount;
                    if($scope.evalData.evalComment)
                      $scope.evalComment =  $scope.evalData.evalComment.length > 0 ? $scope.evalData.evalComment[0].comment : "-";
                    else
                      $scope.evalData.evalComment = [ {"comment": "-"} ];

                    $scope.isLoading = false;
                
                      $scope.isVisible = true;
                      $scope.calculateUserScore();
                               
                  }
                }
              },
              function(response) {
                $translate("error.eval.load").then(function(msg) {
                  Notification.error(msg);
                });
                $scope.isLoading = false;
              }
            );
        };
        $scope.loadEvalForLearnEvalScore = function() {
      
          $http
            .get(
              "private/eval/retrieveEval.do?objectId=" +  $scope.objectId + "&evalType=" + $scope.evalType + "&objectType=" + $scope.objectType
            )
            .then(
              function(response) {
                if (response.data.success == true) {
                  if (response.data.data) {
                    $scope.evalData2 = response.data.data;
                    $scope.evalScore =   $scope.evalData2.score;
                  }
                }
              },
              function(response) {
                $translate("error.eval.load").then(function(msg) {
                  Notification.error(msg);
                });
                $scope.isLoading = false;
              }
            );
  
        };
        $scope.submitEval = function() {
          
          $scope.isLoading = true;
          if($scope.evalData.evalComment){
            $scope.evalData.evalComment.length > 0 ?   $scope.evalData.evalComment[0].comment = $scope.evalComment : $scope.evalData.evalComment.push( {"comment":$scope.evalComment} );
          }
          else{
            $scope.evalData.push( {"evalComment": {"comment":$scope.evalComment}} );
          }

          $http({
            method: "POST",
            url: "private/eval-imp/save.do",
            data: "data=" + encodeURIComponent(angular.toJson($scope.evalData)) + "&impId="+$scope.objectId + "&commandType="+$scope.commandType ,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
          }).then(function(res) {
            if (res.data.success == true) {
              $translate("info.eval.submit").then(function(msg) {
                Notification.success(msg);

                $scope.evalData = res.data.data;
                $scope.evalScore =   $scope.evalData.score;
                if($scope.evalData.evalComment)
                  $scope.evalComment =  $scope.evalData.evalComment.length > 0 ? $scope.evalData.evalComment[0].comment : "-";
                else
                  $scope.evalData.evalComment = [ {"comment": "-"} ];

                $scope.isLoading = false;
            
                  $scope.isVisible = true;
                //  $scope.calculateUserScore();

              });
              $scope.isLoading = false;
            } else {
              $translate("error.eval.submit").then(function(msg) {
                Notification.error(msg);
              });
              $scope.isLoading = false;
            }
          });
         // setTimeout(function(){    $scope.loadEvalForLearnEvalScore() }, 2000);
        };
        
      }
    };
  }
]);
