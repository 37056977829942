app.controller("suggestionDetailCtrl", ["$scope", "$sce", "$http", "$location", "$rootScope", "$routeParams", "$translate", "$uibModal", "Notification", function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, $uibModal, Notification) {

  var _this = this;
  var id = $routeParams.suggestionId;
  $scope.id = id;
  $scope.commentAllowed = false;
  $scope.ratingAllowed = false;
  $scope.avgRating = 0;
  $scope.suggestionRating = { rate: 0, suggestionId: id };
  $scope.suggestionComment = { suggestionId: id, comment: "" };
  $scope.editorComment = { comment: "" };
  $scope.expertComment = { suggestionId: id, comment: "" };
  $scope.showTykForm = false;
  $scope.showEditorComment = false;
  $scope.bnfSelectedFinancialType;
  $scope.abnfSelectedFinancialType;
  $scope.owners = [];
  $scope.suggestionOwners = [];
  $scope.isEditor = false;
  $scope.rewardList = [];
  $scope.selectedEditor = [];
  $scope.selectedExperts = [];
  $scope.experts = [];
  $scope.selectedSuggType = 0;
  $scope.evalTotalScore = 0;
  $scope.bnfFinancialTypes = [
    { value: 0, label: "label.bnfFinancialType0" },
    { value: 1, label: "label.bnfFinancialType1" },
    { value: 2, label: "label.bnfFinancialType2" },
    { value: 3, label: "label.bnfFinancialType3" },
    { value: 4, label: "label.bnfFinancialType4" }
  ];
  $scope.getSuggestion = function () {
    $scope.showPreRejectForm = false;
    $http
      .get(
        "private/suggestion/retrieveModelByUser.do?query=&suggType=&suggClass=&suggState=&page=1&start=0&limit=5&sortState=&suggClass=&roleFilters=&ids=" +
        id
      )
      .then(
        function (response) {

          $scope.suggestion = response.data.data[0];
          var sugg = $scope.suggestion.state,
            isEditor = $scope.suggestion.editorId === Number($rootScope.currentUser.id),
            isExpert = $scope.suggestion.amIActiveExpert == 1
            userId = +$rootScope.currentUser.id,
            ownerId = $scope.suggestion.user;
            
            $scope.evalTotalScore = $scope.suggestion.preevalPoint;
     

          var isAdmin = $rootScope.currentUser.authorization && $rootScope.currentUser.authorization.hasAdminAccess

          $scope.suggestionOwners = $scope.suggestion.suggestionOwners;
          $scope.isAdmin = isAdmin;
          $scope.isExpert = isExpert;
          $scope.isEditor = isEditor;
          $scope.ref = $scope.suggestion.referenceNumber;
          $scope.avgRating = $scope.suggestion.avgRate;
          var conf = ($scope.conf = new SuggConf(sugg, isEditor, isExpert, isAdmin, userId, ownerId));
          if (conf.showApproveRejectForm) {
            if (sugg == 2) {
              $scope.rejectTarget = 11;
              $scope.nextTarget = 0;
              $scope.approveTarget = 0;
              $scope.expLabel = "label.firstReject.explanation";
              $scope.rejectLabel = "label.firstReject";
            } else if (sugg == 12) {
              $scope.rejectTarget = 4;
              $scope.nextTarget = 5;
              $scope.approveTarget = 8;
              $scope.expLabel = "label.firstReject.explanation";
              $scope.rejectLabel = "label.preReject";
              $scope.nextLabel = "label.preAccept";
            } else if (sugg == 5) {
              $scope.rejectTarget = 9;
              $scope.nextTarget = 0;
              $scope.approveTarget = 8;
              $scope.expLabel = "label.firstReject.explanation";
              $scope.rejectLabel = "label.reject";
            }
          }
          $scope.conf.ratingAllowed =false;
          
         
          if($scope.isMine($scope.suggestion.suggestionOwners)){
            $scope.conf.ratingAllowed =false;
          }else{
            $scope.getMyRating();
          }
         
          conf.showEditorComment && $scope.getEditorComments();
          conf.showExpertComment && $scope.getExpertComments();
          conf.checkTykUser && $scope.checkTykUser();
          conf.showComments && $scope.getComments();
          conf.showAssignUser && $scope.getSelectedDepartment();
          conf.showAssignUser && $scope.getAgac('root') ;
          (isAdmin || isEditor) && $scope.loadOwners();
          (isAdmin || isEditor) && $scope.loadEditor();
          (isAdmin || isEditor) && $scope.loadExperts();
          (conf.showPreevalForm || conf.showPreevalResult) &&
            $scope.getBenefit(0);
          conf.showActualBenefitForm && $scope.getBenefit(1);
          conf.suggTypeEditable && $scope.getSuggTypes();
          $scope.getFiles();
          $scope.getSuggestionModel();
          $scope.getOwnerRewards(userId,  $scope.id);
          $scope.getOwnerPoints(userId,  $scope.id);
        },
        function (response) {
          Notification.error("ERROR suggestion detail: " + id);
        }
      );
  };
  $scope.getOwnerRewards = function (userId, suggId) {
    
    $http
    .get(
      "private/getOwnerRewards.do?userId=" +
      userId+
      "&suggId=" +
      suggId+
      "&r=" +
      Math.random()
    )
    .then(
      function (response) {
      $scope.ownerRewards = response.data.data;
      var i = 5;
      },
      function (response) {
        //Second function handles error
        Notification.error("ERROR suggestion detail: " + id);
      }
    );
  }
  $scope.getOwnerPoints = function (userId, suggId) {
    $http
    .get(
      "private/getOwnerPoints.do?userId=" +
      userId+
      "&suggId=" +
      suggId+
      "&r=" +
      Math.random()
    )
    .then(
      function (response) {
      $scope.userEarnedPoints = response.data.data;
        for(var i = 0 ; i<$scope.userEarnedPoints.length ; i++){
          var points = {userFullName: $scope.userEarnedPoints[i].userFullName, 
            rewards: $scope.userEarnedPoints[i].point+" label.point"
           };
           points.tip = $scope.userEarnedPoints[i].pointType == 65 ? 2 : $scope.userEarnedPoints[i].pointType == 70 ? 3 : 0;
  
          $scope.ownerRewards.push(points);
        }
       
      },
      function (response) {
        //Second function handles error
        Notification.error("ERROR suggestion detail: " + id);
      }
    );
  }

  $scope.treeOptions = {};

  $scope.getSelectedDepartment = function () {
  
    $http.get("private/suggestion/listSuggestionSelectedDepartment.do?id=" + $scope.id).then(
      function (response) {
        $scope.selectedDepartment = response.data.data;
      },
      function (response) {
        //Second function handles error
        Notification.error("hata!");
      }
    );
  };

  $scope.getAgac = function (node) {
    $http.get("private/suggestion/agac.do?node=" + node + "&suggestionId=" + $scope.id).then(
      function (response) {
        $scope.children = response.data.data;
      },
      function (response) {
        //Second function handles error
        Notification.error("hata!");
      }
    );
    
  };
  $scope.findSelectionById = function (id, children) {
    for (var i = 0; i < children.length; i++) {
      if (children[i].id == id) return children[i];
      else if (children[i].children) return $scope.findSelectionById(id, children[i].children);
    }
  }
  $scope.toggleParentSelection = function (item) {
    if (item.parentId) {
      var parent = $scope.findSelectionById(item.parentId, $scope.children);
      var checkedCount = 0;
      var childCount = 0;
      for (var i = 0; i < parent.children.length; i++) {
        childCount++;
        if (parent.children[i].checked) checkedCount++;
      }
      parent.checked = (childCount == checkedCount);
      $scope.toggleParentSelection(parent);
    }
  }
  $scope.toggleChildSelection = function (item, checked) {
    item.checked = checked;
    if (item.children != null) {
      for (var i = 0; i < item.children.length; i++) {
        $scope.toggleChildSelection(item.children[i], checked);
      }
    }
  }
  $scope.toggleGroupSelection = function (item) {
    item.checked = !item.checked;
    $scope.toggleChildSelection(item, item.checked);
    $scope.toggleParentSelection(item);
  };
  $scope.toggleChildren = function (scope) {
    if (!scope.$nodeScope.$modelValue.children) {
      $http.get("private/suggestion/agac.do?node=" + scope.$nodeScope.$modelValue.id + "&suggestionId=" + $scope.id).then(function (response) {
        scope.$nodeScope.$modelValue.children = response.data.data;
        if (scope.$nodeScope.$modelValue.children.length > 0) {
          for (var i = 0; i < scope.$nodeScope.$modelValue.children.length; i++) {
            scope.$nodeScope.$modelValue.children[i].parentId = scope.$nodeScope.$modelValue.id;
          }

          if (scope.$nodeScope.$modelValue.checked) {
            for (var i = 0; i < scope.$nodeScope.$modelValue.children.length; i++) {
              scope.$nodeScope.$modelValue.children[i].checked = true;
            }

          }
          scope.toggle();
        }
      });
    } else {
      if (scope.$nodeScope.$modelValue.children.length > 0) {
        scope.toggle();
      }
    }
  };
  var gruplist = [];
  $scope.SetChildrenToGroupList = function (children) {
    if (children) {
      for (var i = 0; i < children.length; i++) {
        var c = children[i];
        if (c.checked) {
          gruplist.push({
            checked: c.checked,
            hasChild: c.hasChild,
            id: c.id,
            idTree: c.idTree,
            leaf: c.leaf,
            name: c.name
          });
        } else if (children[i].children) {
          $scope.SetChildrenToGroupList(children[i].children);
        }
      }
    }
  }
  $scope.sureModal = function (t) {

    gruplist = [];
    $scope.SetChildrenToGroupList($scope.children);

    if (gruplist.length > 0) 
    {
    
    
    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
   
        $scope.sureModalOk = function () {
          t.sureMailModal(t)
          $uibModalInstance.close();
        }

        $scope.sureModalClose = function () {
          $uibModalInstance.close();
        };
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "sureModal.html"
    });
  }
  else {
    t.saveSuggestionUsers(false)
  }
  };
  $scope.sureModalClose = function () {
    $rootScope.modalInstance.close();
  };

  $scope.saveSuggestionUsers = function (sendMail) {
    
    gruplist = [];
    $scope.SetChildrenToGroupList($scope.children);
  
    $http({
      method: "POST",
      url: "private/suggestion/saveSuggestionUserSecim.do",
      data:
        "groupList=" + encodeURIComponent(JSON.stringify(gruplist)) + "&id=" + $scope.id + "&mail=" + sendMail,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      if (res.data.success == true) {
        $scope.getSelectedDepartment();
        //$scope.getSuggestion();
        if (gruplist.length > 0) Notification.success($translate.instant("info.suggetion.open.comment"));
        else Notification.warning($translate.instant("info.suggetion.closed.comment"));
      } else {
        $translate("error.generic").then(function (msg) {
          Notification.error(msg);
        });
      }
    });
   
  }

  $scope.sureMailModal = function (t) {
    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
   
        $scope.sureMailModalOk = function () {
          t.saveSuggestionUsers(true)
          $uibModalInstance.close();
        }

        $scope.sureMailModalClose = function () {
          t.saveSuggestionUsers(false)
          $uibModalInstance.close();
        };
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "sureMailModal.html"
    });
  };
  $scope.sureMailModalClose = function () {
    $rootScope.modalInstance.close();
  };


  $scope.getSuggTypes = function () {
    $http
      .get(
        "private/suggestion/retrieveSuggestionTypesAllByLang.do?query=&page=1&start=0&limit=200"
      )
      .then(
        function (response) {
          $scope.suggestionTypes = response.data.data;
          $scope.selectedSuggType = Number($scope.suggestion.type);
        },
        function (response) {
          Notification.error("ERROR suggestion types");
        }
      );
  };
  $scope.generateSuggTypes = function (t) {
    // {{t.status === 0 ? t.name + " (" + "label.header.deactive" | translate + ")" : t.name }}
    var str = "";
    if (t.status === 0) {
        str += t.name;
        str += " (" + $translate.instant('label.header.deactive') + ")";
    }
    else {
        str += t.name;
    }
    return str;
};
  $scope.updateSuggestionType = function () {
    if (!confirm($translate.instant("info.confirm.sugg.type"))) {
      //alert($scope.selectedSuggType + " "+ $scope.suggestion.type);
      $scope.selectedSuggType = Number($scope.suggestion.type);
    } else {
      //private/suggestion/setSuggestionType.do
      $scope.suggestion.type = Number($scope.selectedSuggType);
      $http({
        method: "POST",
        url: "private/suggestion/setSuggestionType.do",
        data:
          "type=" + $scope.suggestion.type + "&suggId=" + $scope.id,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
      }).then(function (res) {
        if (res.data.success == true) {
          //$scope.getSuggestion();
          Notification.success($translate.instant("info.sugg.type.changed"));
        } else {
          $translate("error.generic").then(function (msg) {
            Notification.error(msg);
          });
        }
      });
    }
  };
  $scope.loadExperts = function () {
    $http.get("private/suggestion/retrieveExperts.do?id=" + id).then(
      function (response) {
        var exp = response.data.data;
        $scope.experts=[];
        $scope.selectedExperts = [];
        for (var i = 0; i < exp.length; i++) {
          if (exp[i].status == 1) {
            $scope.selectedExperts.push({
              id: exp[i].expertId,
              name: exp[i].fullname
            });
            $scope.experts.push({
              expertId: exp[i].expertId,
              fullname: exp[i].fullname
            });
          }
        }
      },
      function (response) {
        Notification.error("ERROR experts");
      }
    );
  };
  $scope.saveExpert = function (users) {
    var list = [];
    $scope.experts=[];
    for (var i = 0; i < $scope.selectedExperts.length; i++) {
      list[i] = {
        suggestionId: $scope.id,
        expertId: $scope.selectedExperts[i].id,
        status: 1
      };
      $scope.experts.push({
        expertId: $scope.selectedExperts[i].id,
        fullname: $scope.selectedExperts[i].name
      });
    }
    if (list.length == 0) return;
    $http({
      method: "POST",
      url: "private/suggestion/saveExperts.do",
      data:
        "data=" +
        encodeURIComponent(JSON.stringify(list)) +
        "&id=" +
        $scope.id,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      if (res.data.success == true) {
        $scope.getSuggestion();
      } else {
        $translate("error.generic").then(function (msg) {
          Notification.error(msg);
        });
      }
    });
  };
  $scope.loadEditor = function () {
    $scope.selectedEditor = [];
    $http.get("private/suggestion/retrieveEditor.do?id=" + id).then(
      function (response) {
        $scope.editor = response.data.data;
        if ($scope.editor){
        $scope.selectedEditor.push({
            id: $scope.editor.id,
            name: $scope.editor.fullName
          });
        } 
        if ($scope.reload) window.location.reload();
      },
      function (response) {
        Notification.error("ERROR editor comments");
      }
    );
  };
  $scope.loadOwners = function () {
    $http.get("private/suggestion/retrieveOwners.do?id=" + id).then(
      function (response) {
        $scope.owners = response.data.data;
        for (var i = 0; i < $scope.owners.length; i++) {
          var u = $scope.owners[i];
          $scope.rewardList.push({
            ownerId: u.ownerId,
            fullName: u.fullName,
            share: u.share,
            isCorresponder: u.isCorresponder,
            reward: '',
            rewardPoint: 0
          });
        }

      },
      function (response) {
        Notification.error("ERROR while loading owners");
      }
    );
  };
  $scope.refreshPage = function () {
    window.location.reload();
  };
  $scope.saveEditor = function (users) {
    $scope.reload = false;
    var uid = $rootScope.currentUser.id;
    if (
      ($scope.editor && $scope.editor.id == uid) ||
      ($scope.selectedEditor && $scope.selectedEditor[0].id == uid)
    )
      $scope.reload = true;
    $http({
      method: "POST",
      url: "private/suggestion/saveEditor.do",
      data:
        "data=" +
        encodeURIComponent(JSON.stringify($scope.selectedEditor)) +
        "&id=" +
        $scope.id,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      if (res.data.success == true) {
        $scope.getSuggestion();
      } else {
        $translate("error.generic").then(function (msg) {
          Notification.error(msg);
        });
      }
    });
  };
  $scope.getEditorComments = function () {
    $http
      .get(
        "private/suggestionComment/retrieveAdminComment.do?suggestionId=" +
        id +
        "&page=1&start=0&limit=100"
      )
      .then(
        function (response) {
          $scope.editorComments = response.data.data;
        },
        function (response) {
          //Second function handles error
          Notification.error("ERROR editor comments");
        }
      );
  };
  $scope.editorCommentSubmitPhase = false;
  $scope.submitEditorComment = function () {
    $scope.editorCommentSubmitPhase = true;
    $http({
      method: "POST",
      url: "private/suggestionComment/saveAdminComment.do",

      data:
        "data=" +
        encodeURIComponent(JSON.stringify(new Array($scope.editorComment))) +
        "&id=" +
        $scope.id,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      $scope.editorCommentSubmitPhase = false;
      if (res.data.success == true) {
        $scope.editorComment.comment = "";
        $scope.getEditorComments();
        $translate("info.comment.submit").then(function (msg) {
          Notification.success(msg);
        });
      } else {
        $translate("error.comment.submit").then(function (msg) {
          Notification.error(msg);
        });
      }
    });
  };
  $scope.getExpertComments = function () {
    var path = "private/suggestionComment/retrieveExpertComment.do";
    if ($scope.isAdmin || $scope.showTykForm || $scope.isEditor) {
      path = "private/suggestionComment/retrieveExpertCommentAdmin.do";
    }
    $http
      .get(
        path + "?suggestionId=" +
        id +
        "&page=1&start=0&limit=100"
      )
      .then(
        function (response) {
          $scope.expertComments = response.data.data;
        },
        function (response) {
          //Second function handles error
          Notification.error("ERROR editor comments");
        }
      );
  };
  $scope.expertCommentSubmitPhase = false;
  $scope.submitExpertComment = function () {
    $scope.expertCommentSubmitPhase = true;
    $http({
      method: "POST",
      url: "private/suggestionComment/saveExpertComment.do",

      data:
        "data=" + encodeURIComponent(JSON.stringify(new Array($scope.expertComment))),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      $scope.expertCommentSubmitPhase = false;
      if (res.data.success == true) {
        $scope.expertComment.comment = "";
        $scope.getExpertComments();
        $translate("info.comment.submit").then(function (msg) {
          Notification.success(msg);
        });
      } else {
        $translate("error.comment.submit").then(function (msg) {
          Notification.error(msg);
        });
      }
    });
  };
  $scope.getBenefit = function (bnfType) {
    $http
      .get(
        "private/suggestion/retrieveBenefitBySuggestionIdAndType.do?suggestionId=" +
        id +
        "&type=" +
        bnfType
      )
      .then(
        function (response) {
          if (bnfType) {
            $scope.actualBenefit = response.data.data
              ? response.data.data[0]
              : {};
            $scope.abnfSelectedFinancialType =
              $scope.bnfFinancialTypes[$scope.actualBenefit.bnfFinancialType];
          } else {
            $scope.benefit = response.data.data ? response.data.data[0] : {};
            $scope.bnfSelectedFinancialType =
              $scope.bnfFinancialTypes[$scope.benefit.bnfFinancialType];
          }
        },
        function (response) {
          //Second function handles error
          Notification.error("ERROR suggestion benefit");
        }
      );
  };

  $scope.getComments = function () {
    // private/suggestionComment/retrieve.do
    $http
      .get(
        "private/suggestionComment/retrieve.do?suggestionId=" +
        id +
        "&start=0&limit=25"
      )
      .then(
        function (response) {
          $scope.comments = response.data.data;
          $scope.commentCount = response.data.total;
        },
        function (response) {
          //Second function handles error
          Notification.error("ERROR suggestion comments: " + id);
        }
      );
  };
  $scope.getFiles = function () {
    var authenticated = false;
    var path =
      "notsecure/suggestion/retrieveSuggFiles.do?suggReferenceNumber=";
    if ($rootScope.authenticated) {
      path = "private/suggestion/retrieveSuggFiles.do?suggReferenceNumber=";
      authenticated = true;
    }
    $http.get(path + $scope.ref + "&start=0&limit=25").then(
      function (response) {
        $scope.files = response.data.data;
      },
      function (response) {
        //Second function handles error
        Notification.error("ERROR suggestion files: " + id);
      }
    );
  };

  $scope.commentSubmitPhase = false;
  $scope.submitComment = function () {
    $scope.commentSubmitPhase = true;
    $http({
      method: "POST",
      url: "private/suggestionComment/save.do",
      data:
        "data=" +
        encodeURIComponent(JSON.stringify(new Array($scope.suggestionComment))),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      $scope.commentSubmitPhase = false;
      if (res.data.success == true) {
        $scope.suggestionComment.comment = "";
        $scope.getComments();
        $translate("info.comment.submit").then(function (msg) {
          Notification.success(msg);
        });
      } else {
        $translate("error.comment.submit").then(function (msg) {
          Notification.error(msg);
        });
      }
    });
  };
  $scope.isMine = function (params) {
  
    if (typeof params === "object")
     { 
        for(var i = 0 ; i<params.length ; i++){
            if (Number($rootScope.currentUser.id) == params[i].ownerId) return true;
        }
      }
     else{
        if (Number($rootScope.currentUser.id) == params) return true;
        else return false;
      }
  };
  $scope.saveRating = function () {
    $scope.ratingSubmitted = true;
    $http({
      method: "POST",
      url: "private/suggestion/saveRating.do",
      data:
        "data=" + encodeURIComponent(JSON.stringify($scope.suggestionRating)),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      $scope.getAvgRating();
      if (res.data.success == true) {
    //    $scope.getSuggestion();
       $scope.getAvgRating();
        $translate("info.rating.submit").then(function (msg) {
          Notification.success(msg);
        });
      } else {
        $scope.ratingSubmitted = false;
        $translate("error.rating.submit").then(function (msg) {
          Notification.error(msg);
        });
      }
    });
  };
  $scope.getMyRating = function () {
    $scope.ratingSubmitted = true;
    $scope.suggestionRating.rate = 0;
    $http({
      method: "POST",
      url: "private/suggestion/retrieveRating.do",
      data: "suggId=" + id,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      if (res.data.success == true) {
        $scope.suggestionRating.rate = res.data.data[0];
        if(  $scope.suggestionRating.rate == 0 ||  $scope.suggestionRating.rate == undefined ){
            if($scope.suggestion.state != 12)
                  $scope.conf.ratingAllowed = false;
          if($scope.suggestion.state == 12 && $scope.suggestion.userCommentEnabled )
                  $scope.conf.ratingAllowed = true;
        }
        else{
          $scope.conf.ratingAllowed = true;
        }
      }
    });
  };
  $scope.getAvgRating = function () {
    // private/suggestion/retrieveAvgRating.do
    $http.get("private/suggestion/retrieveAvgRating.do?suggId=" + id).then(
      function (response) {
        $scope.avgRating = response.data.data;
      },
      function (response) {
        //Second function handles error
        Notification.error("ERROR suggestion rating: " + id);
      }
    );
  };
  $scope.checkTykUser = function () {
    $http({
      method: "POST",
      url: "private/suggestion/isTykMember.do",
      data: "suggestionId=" + id,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(
      function (res) {
        if (res.data.success == true) {
          $scope.showTykForm = true;
          $scope.getExpertComments();
          $scope.getEditorComments();
          $scope.conf.showAdministration = true;
          $scope.conf.showPreevalResult = true;
          $scope.conf.showAssignmentInfo = true;
          $scope.loadExperts();
          $scope.loadEditor();
          $scope.conf.showPreevalResultToTyk && $scope.getBenefit(0);

        }else{
          $scope.showTykForm = false;
        }
      },
      function (res) {
        Notification.error("Error check TYK user: " + res.message);
      }
    );
  };
  $scope.deleteModal = function (t) {
    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
        $scope.deleteModalOk = function () {
         t.deleteSuggestion();
         $scope.deleteModalClose();
        };
        
        $scope.deleteModalClose = function () {
          $rootScope.modalInstance.close();
        };

      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "deleteModal.html"
    });
  };
  $scope.improvementTransferModel = function (t) {
    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
        $scope.modalOk = function () {
         t.transferToImprovement();
         $scope.modalClose();
        };
        
        $scope.modalClose = function () {
          $rootScope.modalInstance.close();
        };

      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "improvementTransferModel.html"
    });
  };
  $scope.transferToImprovement = function(){
    $location.path("/improvement-transfer/"+$scope.suggestion.id);
  }
  $scope.deleteSuggestion = function () {
    var arr = [];
    delete $scope.suggestionModel.entrydate;
    delete $scope.suggestionModel.updateDate;
    delete $scope.suggestionModel.completedDate;
    arr.push($scope.suggestionModel);
    $http({
      method: "POST",
      url: "private/suggestion/delete.do",
      data: "data=" + encodeURIComponent(JSON.stringify(arr)),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(
      function (res) {
        if (res.data.success == true) {
        Notification.success($translate.instant("info.draft.deleted"));
         $location.path("/suggestions");
        }
      },
      function (res) {
        Notification.error("Error check sugg deleted: " + res.message);
      }
    );
  };
  $scope.completeSuggestion = function (isMailSendOwner) {

    $http({
      method: "POST",
      url: "private/suggestion/completeSuggestion.do",
      data: "id=" + id + "&rewards=" + encodeURIComponent(JSON.stringify($scope.rewardList)) + "&isMailSendOwnerX=" + encodeURIComponent(JSON.stringify(isMailSendOwner)),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(
      function (res) {
        if (res.data.success == true) {
          window.location.reload(true);
        }
      },
      function (res) {
        Notification.error("Error on suggestion completion : " + res.message);
      }
    );
  }

  $scope.completeSuggestionModal = function (t) {
    $rootScope.modalInstance = $uibModal.open({
        animation: true,backdrop: 'static',
        controller: function ($scope, $uibModalInstance) {

            $scope.ownerSendMail = true;
            $scope.completeSuggestionOk = function () {
               t.completeSuggestion(  $scope.ownerSendMail )
                $uibModalInstance.close();
            }
            $scope.completeSuggestionClose = function () {
              $uibModalInstance.close();
            }
            $scope.decide = function (param){
              return  param == true ? false : true
            }

        },
        ariaLabelledBy: "modal-title",
        ariaDescribedBy: "modal-body",
        templateUrl: "completeSuggestionModal.html"
    });
};

  $scope.getSuggestionModel = function () {
    ///private/suggestion.do
    $http
      .get(
        "private/suggestion.do?id=" +
        $scope.suggestion.id +
        "&r=" +
        Math.random()
      )
      .then(
        function (response) {
          $scope.suggestionModel = response.data.data;
        },
        function (response) {
          //Second function handles error
          Notification.error("ERROR suggestion detail: " + id);
        }
      );
  };
  $scope.getSuggestion();
  $scope.getComments();


  /* Project Portfolio */
  $scope.projectPortfolioModal = function (t) {
    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      size: 'lg',
      controller: function ($scope, $uibModalInstance) {
        $scope.project = {};
        $scope.selectedproject = {};
        $scope.openNeworAddContent = function () {
          if ($scope.radioModel == 'Add') {
            $http.get("project/retrieveProjects.do?&start=0&limit=25&states=1&priorities=").then(
              function (response) {
                var list = response.data.data;
                $scope.projects = list;

              });
          }
          else {
            $scope.project.title = t.suggestion.title;
            $scope.project.purpose = t.suggestion.problemDesc;
            $scope.project.scope = t.suggestion.description;
            $scope.project.status = 1;
          }
        }
        $scope.projectModalSave = function () {

          $http({
            method: 'POST',
            url: 'project/createFromIdeaOrSuggestionOrImprovements.do',

            data: 'project=' + encodeURIComponent(JSON.stringify($scope.project)) + '&suggestionId=' + t.id + '&ideaId=' + 0,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
          })
            .then(function (res) {
              if (res.data.success) {
                Notification.success($translate.instant('competition.state.closed'));
                t.ideaProject();
                $uibModalInstance.close();
              }
            });

        }
        $scope.assignToProject = function () {
          $http({
            method: 'POST',
            url: 'project/addToCurrentProject.do',

            data: 'projectId=' + $scope.selectedproject.id + '&suggestionId=' + t.id + '&ideaId=' + 0,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
          })
            .then(function (res) {
              if (res.data.success) {
                Notification.success($translate.instant('competition.state.closed'));
                t.ideaProject();
                $uibModalInstance.close();
              }
            });
        }
        $scope.projectModalClose = function () {
          $uibModalInstance.close();
        }
        $scope.selectProject = function (p) {
          $scope.selectedproject = p;
        }
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "projectPortfolioModal.html"
    });
  };
  $scope.ideaProject = function () {
    $http.get("project/retrieveUsedSuggestionByID.do?id=" + $scope.id).then(
      function (response) {
        var datas = response.data;
        $scope.projectsidea = datas.data;
        $scope.isprojectAssinged = datas.success;

      });
  }
  $scope.ideaProject();



  $scope.addAssignConfirmModal = function (t) {

    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
        $scope.Ok = function () {
          t.saveEditor()

          $uibModalInstance.close();
        }

        $scope.Close = function () {
          t.selectedEditor = [];
          if(t.editor){
          t.selectedEditor.push({
            id: t.editor.id,
            name: t.editor.fullName
          });
        }
          $uibModalInstance.close();
        }
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "addAssignConfirmModal.html"
    });
  };

  $scope.addExpertConfirmModal = function (t) {

    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
        $scope.Ok = function () {
          t.saveExpert()

          $uibModalInstance.close();
        }

        $scope.Close = function () {
          t.selectedExperts = [];
          for(var i=0; i < t.experts.length; i++){
            t.selectedExperts.push({
              id: t.experts[i].expertId,
              name: t.experts[i].fullname
            });
          }
          
          
          $uibModalInstance.close();
        }
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "addExpertConfirmModal.html"
    });
  };

}
]);

app.controller("submitSuggestionCtrl", ["$scope", "Upload", "$timeout", "$sce", "$http", "$location", "$routeParams", "$rootScope", "$translate", "Notification", "textAngularManager", "$uibModal", function ($scope, Upload, $timeout, $sce, $http, $location, $routeParams, $rootScope, $translate, Notification, textAngularManager, $uibModal) {
  $scope.isLoading = false;
  $scope.isNew = true;
  $scope.showSaveDraft = $rootScope.authenticated;
  $scope.showSubmit = true;
  $scope.termsChecked = true;
  $scope.termsChecked1 = true;
  $scope.termsChecked2 = true;
  $scope.showUserForm = false;
  $scope.isExternal = $rootScope.authenticated && ($rootScope.currentUser.externalState == 2);
  $scope.kaptchaUrl = "notsecure/captcha-image.do";
  $scope.extData = { idno: 0, education: 3 };
  $scope.eduOptions = [
            {value:0, label:"label.edu.primary"},
            {value:1, label:"label.edu.secondary"},
            {value:2, label:"label.edu.high"},
            {value:3, label:"label.edu.college"},
            {value:4, label:"label.edu.undergraduate"},
            {value:5, label:"label.edu.graduate"},
            {value:6, label:"label.edu.phd"},
  ];
  
  $scope.selectedEdu = $scope.eduOptions[4];

  $scope.getRef = function () {
    var path = "notsecure/idea/getReference.do";
    if ($rootScope.authenticated) path = "private/idea/getReference.do";
    $http({
      method: "POST",
      url: path,

      data: "object=sugg",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      $scope.ref = res.data.data;
    });
  };
  $scope.getSuggestion = function () {
    ///private/suggestion.do
    $http
      .get("private/suggestion.do?id=" + $scope.id + "&r=" + Math.random())
      .then(
        function (response) {
          $scope.suggestion = response.data.data;
          $scope.ref = $scope.suggestion.referenceNumber;
          if ($scope.suggestion.campaignId == null)
            $scope.suggestion.campaignId = 0;
          if (
            ($scope.suggestion.state == 0 || $scope.suggestion.state == 12) &&
            $scope.suggestion.user == $rootScope.currentUser.id
          ) {
            $scope.loadOwners();
            $scope.getUploadedFiles();
          } else {
            Notification.error("Yetkiniz yok!");
            window.history.back();
          }
          if ($scope.suggestion.state != 0 || !$rootScope.authenticated) {
            $scope.showSaveDraft = false;
          }
          if ($scope.suggestion.state == 12) {
            $scope.showSubmit = false;
          }
        },
        function (response) {
          //Second function handles error
          Notification.error("ERROR suggestion detail: " + id);
        }
      );
  };
  if ($routeParams.suggestionId) {
    $scope.id = $routeParams.suggestionId;
    $scope.getSuggestion();
    $scope.isNew = false;
  } else {
    // if suggestionId is undefined, new suggestion is coming! get a ref number
    $scope.id = 0;
    $scope.getRef();
  }

  $scope.fileList = [];
  $scope.uploadedFiles = [];
  $scope.fileProgress = 0;
  $scope.showError = false;
  $scope.showMessage = false;
  $scope.suggestion = { rating: 0 };
  $scope.suggestionTypes = {};
  $scope.campaigns = {};
  $scope.ref = 0;

  $scope.removeFile = function (i) {
    $scope.fileList.splice(i, 1);
  };
  $scope.updateFiles = function (newFiles) {
    if (newFiles.length > 0) {
      if ($scope.fileList.length + newFiles.length + $scope.uploadedFiles.length > 3) {
        handleMsg(MessageTypes.ERROR, "error.files.max3");
      } else {

        for (var i = 0; i < newFiles.length; i++) {
          $scope.fileList.push(newFiles[i]);
        }

        // newFiles.forEach(function (f) {
        //   $scope.fileList.push(f);
        // });

        $scope.uploadEnabled = true;
        Notification.info($translate.instant("info.files.added"));
      }
    }
  };
  // private/suggestion/retrieveSuggestionTypesByLang.do?query=&page=1&start=0&limit=200
  // private/campaign/retrieveCampaigns.do?_dc=1489664121974&query=&page=1&start=0&limit=200
  $scope.uploadFiles = function () {
    if ($scope.fileList.length + $scope.uploadedFiles.length > 3) {
      handleMsg(MessageTypes.ERROR, "error.files.max3");
      return;
    }
    if (
      $scope.fileList &&
      $scope.fileList.length < 4 &&
      $scope.fileList.length > 0
    ) {
      var url = "notsecure/suggestion/uploadFile.htm?suggReferenceNumber=";
      if ($rootScope.authenticated) {
        url = "private/suggestion/uploadFile.htm?suggReferenceNumber=";
      }
      $scope.loadUpdateFiles =  Upload.upload({
        url: url + $scope.ref,
        ignoreLoadingBar: true,
        data: {
          files: $scope.fileList
        }
      }).then(
        function (response) {
          if (response.data.success) {
            $scope.fileList = [];
            Notification.info($translate.instant("info.files.uploaded"));
            $scope.fileList = [];
            $scope.getUploadedFiles();
          } else {
            Notification.error($translate.instant(response.data.message));
          }
        },
        function (response) {
          if (response.status > 0) {
            Notification.error(response.status + ": " + response.data);
          }
        },
        function (evt) {
          $scope.fileProgress = Math.min(
            100,
            parseInt(100.0 * evt.loaded / evt.total)
          );
          if (evt.loaded == evt.total) {
          }
        }
      );
    } else {
      Notification.error($translate.instant("error.files.max3"));
    }
  };
  $scope.getSuggTypes = function () {
    var path = "notsecure/suggestion/retrieveSuggestionTypesByLang.do";
    if ($rootScope.authenticated) {
      path =
        "private/suggestion/retrieveSuggestionTypesByLang.do?query=&page=1&start=0&limit=200";
    }
    $http.get(path).then(
      function (response) {
        $scope.suggestionTypes = response.data.data;
        if ($scope.isNew) {
          $scope.suggestion.type = $scope.suggestionTypes[0].id;
        }
      },
      function (response) {
        //Second function handles error
        Notification.error("ERROR suggestion types");
      }
    );
  };
  $scope.getCampaigns = function () {
    // private/suggestionComment/retrieve.do
    $http
      .get(
        "private/campaign/retrieveCampaignsUser.do?_dc=1489664121974&query=&page=1&start=0&limit=200&objectType=1"
      )
      .then(
        function (response) {
          $scope.campaigns = response.data.data ? response.data.data : [];
          $scope.campaigns.unshift({ id: 0, name: $translate.instant("label.campaign.free") });
          if ($scope.isNew) {
            $scope.suggestion.campaignId = 0;
          }
        },
        function (response) {
          //Second function handles error
          Notification.error("ERROR campaigns");
        }
      );
  };
  $scope.getUploadedFiles = function () {
    var authenticated = false;
    var path =
      "notsecure/suggestion/retrieveSuggFiles.do?suggReferenceNumber=";
    if ($rootScope.authenticated) {
      path = "private/suggestion/retrieveSuggFiles.do?suggReferenceNumber=";
      authenticated = true;
    }
    $http
      .get(
        path + $scope.ref + "&start=0&limit=25&authenticated=" + authenticated
      )
      .then(
        function (response) {
          $scope.uploadedFiles = response.data.data;
        },
        function (response) {
          //Second function handles error
          Notification.error("ERROR suggestion files");
        }
      );
  };
  $scope.deleteFile = function (id) {
    var path = "notsecure/suggestion/deleteFileById.do";
    if ($rootScope.authenticated) {
      path = "private/suggestion/deleteFileById.do";
    }
    $http({
      method: "POST",
      url: path,

      data: "fileId=" + id,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      $scope.getUploadedFiles();
      Notification.info($translate.instant("info.file.deleted"));
    });
  };

  $scope.save = function (toSubmit) {
    if (!$scope.termsChecked) {
      Notification.error($translate.instant('label.terms'));
      return;
  }
    delete $scope.suggestion.entrydate;
    delete $scope.suggestion.updateDate;
    delete $scope.suggestion.completedDate;
    $scope.suggestion.referenceNumber = $scope.ref;

    if ($scope.fileList.length > 0) {
      Notification.error(
        $translate.instant("info.not.uploaded.files.exists")
      );
      return;
    }
    $scope.isLoading = true;
    if ($rootScope.authenticated) {
      var submit = toSubmit ? 2 : 1;
      if ($scope.suggestion.state == 12) {
        delete $scope.suggestion.editorDate;
      }
      var suggOwners = []
      suggOwners.push(
        {
          ownerId: $scope.suggestionCoresponder.id,
          share: $scope.suggestionCoresponder.share
        }
      );
      for (var i = 0; i < $scope.suggestionOwners.length; i++) {
        var so = $scope.suggestionOwners[i];
        suggOwners.push({
          ownerId: so.id,
          share: so.share
        });
      }
      if ($scope.suggestion.campaignId == 0)
        $scope.suggestion.campaignId = null;
      var arr_ = [];
      arr_.push($scope.suggestion);
      $http({
        method: "POST",
        url: "private/suggestion/save.do",

        data:
          "data=" +
          encodeURIComponent(JSON.stringify(arr_)) +
          "&submit=" +
          submit +
          "&suggOwners=" + encodeURIComponent(JSON.stringify(suggOwners)),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
      }).then(function (res) {
        $scope.isLoading = false;
        if (res.data.success == true) {
          if (submit == 1) {
            if ($scope.suggestion.state == 12) {
              handleMsg(MessageTypes.INFO, "info.suggestion.saved");
              Notification.success($translate.instant("info.suggestion.saved"));
            }
            else {
              handleMsg(MessageTypes.INFO, "info.draft.saved");
              Notification.success($translate.instant("info.draft.saved"));
            }
            $location.path("/submit-suggestion/" + res.data.data[0].id);

          }
          if (submit == 2) {
            handleMsg(MessageTypes.INFO, "info.sugg.submitted");
            $translate("info.sugg.submitted").then(function (msg) {
              Notification.success(msg);
            });
            $location.path("/suggestions");
          }
        } else {
          $scope.kaptchaUrl =
            "notsecure/captcha-image.do?_ts=" + new Date().getTime();
          Notification.error("Error");
        }
      });
    } else {
      $scope.extData.education = $scope.selectedEdu.value;
      $http({
        method: "POST",
        url: "notsecure/suggestion/saveExternal.do",

        data:
          "suggData=" +
          encodeURIComponent(JSON.stringify(new Array($scope.suggestion))) +
          "&userData=" +
          encodeURIComponent(JSON.stringify($scope.userData)) +
          "&extData=" +
          encodeURIComponent(JSON.stringify($scope.extData)) +
          "&kaptcha=" +
          $scope.kaptcha,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
      }).then(function (res) {
        $scope.isLoading = false;
        if (res.data.success == true) {
          handleMsg(MessageTypes.INFO, "info.sugg.submitted");
          $translate("info.sugg.submitted").then(function (msg) {
            Notification.success(msg);
          });
          $scope.kaptchaUrl =
            "notsecure/captcha-image.do?_ts=" + new Date().getTime();
          $location.path("/");
        } else {
          Notification.error(res.data.message);
          $scope.kaptchaUrl =
            "notsecure/captcha-image.do?_ts=" + new Date().getTime();
        }
      });
    }
  };


  $scope.update = function (toSubmit) {

    delete $scope.suggestion.entrydate;
    delete $scope.suggestion.updateDate;
    delete $scope.suggestion.completedDate;
    $scope.suggestion.referenceNumber = $scope.ref;

    if ($scope.fileList.length > 0) {
      Notification.error(
        $translate.instant("info.not.uploaded.files.exists")
      );
      return;
    }
    $scope.isLoading = true;
    if ($rootScope.authenticated) {
      var submit = toSubmit ? 2 : 1;
      if ($scope.suggestion.state == 12) {
        delete $scope.suggestion.editorDate;
      }
      var suggOwners = []
      suggOwners.push(
        {
          ownerId: $scope.suggestionCoresponder.id,
          share: $scope.suggestionCoresponder.share
        }
      );
      for (var i = 0; i < $scope.suggestionOwners.length; i++) {
        var so = $scope.suggestionOwners[i];
        suggOwners.push({
          ownerId: so.id,
          share: so.share
        });
      }
      if ($scope.suggestion.campaignId == 0)
        $scope.suggestion.campaignId = null;
      var arr_ = [];
      arr_.push($scope.suggestion);
      $http({
        method: "POST",
        url: "private/suggestion/update.do",

        data:
          "data=" +
          encodeURIComponent(JSON.stringify(arr_)) +
          "&submit=" +
          submit +
          "&suggOwners=" + encodeURIComponent(JSON.stringify(suggOwners)),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
      }).then(function (res) {
        $scope.isLoading = false;
        if (res.data.success == true) {
          if (submit == 1) {
            if ($scope.suggestion.state == 12) {
              handleMsg(MessageTypes.INFO, "info.suggestion.saved");
              Notification.success($translate.instant("info.suggestion.saved"));
             // $location.path("/suggestions/" + res.data.data);
            }
            else {
              handleMsg(MessageTypes.INFO, "info.draft.saved");
              Notification.success($translate.instant("info.draft.saved"));
            }
            $location.path("/submit-suggestion/" + res.data.data[0].id);

          }
          if (submit == 2) {
            handleMsg(MessageTypes.INFO, "info.sugg.submitted");
            $translate("info.sugg.submitted.update").then(function (msg) {
              Notification.success(msg);
            });
            $location.path("/suggestions");
          }
        } else {
          $scope.kaptchaUrl =
            "notsecure/captcha-image.do?_ts=" + new Date().getTime();
          Notification.error("Error");
        }
      });
    } else {
      $scope.extData.education = $scope.selectedEdu.value;
      $http({
        method: "POST",
        url: "notsecure/suggestion/saveExternal.do",

        data:
          "suggData=" +
          encodeURIComponent(JSON.stringify(new Array($scope.suggestion))) +
          "&userData=" +
          encodeURIComponent(JSON.stringify($scope.userData)) +
          "&extData=" +
          encodeURIComponent(JSON.stringify($scope.extData)) +
          "&kaptcha=" +
          $scope.kaptcha,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
      }).then(function (res) {
        $scope.isLoading = false;
        if (res.data.success == true) {
          handleMsg(MessageTypes.INFO, "info.sugg.submitted");
          $translate("info.sugg.submitted").then(function (msg) {
            Notification.success(msg);
          });
          $scope.kaptchaUrl =
            "notsecure/captcha-image.do?_ts=" + new Date().getTime();
          $location.path("/");
        } else {
          Notification.error(res.data.message);
          $scope.kaptchaUrl =
            "notsecure/captcha-image.do?_ts=" + new Date().getTime();
        }
      });
    }
  };


  $scope.suggestionCoresponder = {
    id: $rootScope.currentUser == undefined ? 0 : $rootScope.currentUser.id,
    fullName: $rootScope.currentUser == undefined ? 0 : $rootScope.currentUser.fullName,
    share: 100
  }
  $scope.selectedSuggestionOwners = [];
  $scope.suggestionOwners = [];
  $scope.updateSuggestionOwners = function (t) {
    var newList = []
    for (var i = 0; i < $scope.selectedSuggestionOwners.length; i++) {
      if ($scope.suggestionCoresponder.id != $scope.selectedSuggestionOwners[i].id) {
        var so = $scope.selectedSuggestionOwners[i];
        var newSo = {};
        newSo.id = so.id;
        newSo.fullName = so.name + " " + so.surname;
        newSo.share = 1;
        for (var j = 0; j < $scope.suggestionOwners.length; j++) {
          if ($scope.suggestionOwners[j].id == so.id) newSo.share = $scope.suggestionOwners[j].share;
        }
        newList.push(newSo);
      }
    }
    $scope.suggestionOwners = newList;
    $scope.validateOwnerShares();
  }
  $scope.deleteSuggestionOwner = function (ind) {
    $scope.suggestionOwners.splice(ind, 1);
    $scope.selectedSuggestionOwners.splice(ind, 1);
    $scope.validateOwnerShares();
  }
  $scope.isOwnerSharesValid = true;
  $scope.validateOwnerShares = function () {
    var total = 0;
    for (var i = 0; i < $scope.suggestionOwners.length; i++) {
      var share = $scope.suggestionOwners[i].share;
      if (!share) {
        $scope.isOwnerSharesValid = false;
        return;
      }
      total += share;
    }
    if (total > 99) {
      $scope.isOwnerSharesValid = false;
    }
    else {
      $scope.isOwnerSharesValid = true;
      $scope.suggestionCoresponder.share = 100 - total;
    }
  }
  $scope.loadOwners = function () {
    $http.get("private/suggestion/retrieveOwners.do?id=" + $scope.id).then(
      function (response) {
        var owners = response.data.data;
        $scope.selectedSuggestionOwners = [];
        $scope.suggestionOwners = [];
        for (var i = 0; i < owners.length; i++) {
          if (owners[i].corresponder) {
            $scope.suggestionCoresponder = {
              id: owners[i].ownerId,
              fullName: owners[i].fullName,
              share: owners[i].share
            }
          } else {
            $scope.suggestionOwners.push({
              id: owners[i].ownerId,
              fullName: owners[i].fullName,
              share: owners[i].share
            });
            $scope.selectedSuggestionOwners.push({
              id: owners[i].ownerId,
              name: owners[i].name,
              surname: owners[i].surname
            });
          }
        }
      },
      function (response) {
        Notification.error("ERROR experts");
      }
    );
  };
  $scope.getSuggTypes();
  if ($rootScope.authenticated) {
    $scope.getCampaigns();
  }

  function handleMsg(type, msg) {
    if (type == MessageTypes.ERROR) {
      $scope.showError = true;
      $scope.showMessage = false;
      $scope.errorMsg = msg;
    } else if (type == MessageTypes.INFO) {
      $scope.showError = false;
      $scope.showMessage = true;
      $scope.result = msg;
    }
  }
  var MessageTypes = {
    ERROR: 1,
    INFO: 2
  };
  $scope.suggestionSaveEditModal = function (t) {
    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
        $scope.Ok = function () {
          t.save(true);
          $uibModalInstance.close();
        }

        $scope.Close = function () {
          $uibModalInstance.close();
        }
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "suggestionSaveEditModal.html"
    });
  };


  $scope.addOwnerConfirmModal = function (t) {

    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
        $scope.Ok = function () {
          t.updateSuggestionOwners(t)

          $uibModalInstance.close();
        }

        $scope.Close = function () {
          $uibModalInstance.close();
        }
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "addOwnerConfirmModal.html"
    });
  };



}]);
