app.controller("competitionDetailCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate', "$uibModal", 'Notification',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, $uibModal, Notification) {
        $scope.competitionId = $routeParams.competitionId;
        $scope.isLoggedIn = $rootScope.authenticated;
        $scope.isPrivate = false;
        $scope.showEvalReport = false;
        $scope.sortStr = 'idea.enterDate desc';
        $scope.filePath = 'private/download.htm';
        $scope.ideaCount = 0;
        var myDate = new Date();
        var month = myDate.getMonth();
        $scope.datenow = myDate.getFullYear() + "-" + (month < 9 ? ('0' + (month + 1)) : (month + 1)) + "-" + myDate.getDate();
        $scope.loadCompetition = function (isPrivate) {
            var path;
            if (isPrivate) {
                //private/market/retrieveProblemModel.do?marketState=O&problemState=&page=1&start=0&limit=25
                path = "private/market/retrieveProblemModel.do?problemId=" + $scope.competitionId + "&start=0&limit=1&page=1&marketState=O&problemState=";
            } else {
                path = "notsecure/getOpenCompetition.do?id=" + $scope.competitionId;
            }
            //notsecure/getOpenCompetition.do?_dc=1490096023912&id=10
            $http.get(path)
                .then(function (response) {
                    $scope.competition = response.data.data[0];
                    $scope.competition.description = $sce.trustAsHtml(response.data.data[0].description);
                    /*if($scope.competition.evaluateDate != undefined){
                        var deadline = new Date($scope.competition.evaluateDate);
                        deadline.setDate(deadline.getDate()-1); //dd.MM.YYYY 23:59
                        $scope.competition.evaluateDate = deadline;
                    }*/
                    $scope.loadFiles();
                    $scope.isAdmin = $rootScope.currentUser.authorization == undefined ? false : $rootScope.currentUser.authorization.hasAdminAccess;
                    if ($scope.isAdmin) {
                        $scope.loadCriteria();
                    }
                    if (isPrivate) {
                        $scope.loadReward();
                        $scope.checkIsEvaluator();
                        $scope.loadCategories();
                    } else {

                        //$scope.criterias = $scope.competition.acceptanceCriteria;
                        $scope.rewards = $scope.competition.rewards;
                    }
                    if ( ($scope.competition.problemState != 'D' && $scope.competition.problemState != 'S' && $scope.competition.problemState != 'A' ) && $rootScope.authenticated && ($rootScope.currentUser.authorization.hasAdminAccess || $scope.competition.isUserExpert == 1)) {
                        $scope.showEvalReport = true;
                    }
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };

        
        $scope.getSelectedDepartment = function () {
            $http.get("private/problem/listProblemSelectedDepartment.do?id=" + $scope.competitionId).then(
            function (response) {
                $scope.selectedDepartment = response.data.data;
            },
            function (response) {
                //Second function handles error
                Notification.error("hata!");
            }
            );
        };
        $scope.getSelectedDepartment();
        $scope.checkIsEvaluator = function () {
            $http.get("private/problem/retrieveIsEvaluator.do?id=" + $scope.competitionId)
                .then(function (response) {
                    $scope.isEvaluator = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: checkIsEvaluator');
                });
        };

        $scope.loadReward = function () {
            $http.get("private/market/retrieveProblemReward.do?problemId=" + $scope.competitionId + "&page=1&start=0&limit=25")
                .then(function (response) {
                    $scope.rewards = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Rewards');
                });
        };
        $scope.loadFiles = function () {
            var path = "notsecure/getOpenCompetitionFiles.do?id=" + $scope.competitionId;
            if ($scope.isPrivate)
                path = "private/market/retrieveProblemFiles.do?problemId=" + $scope.competitionId + "&page=1&start=0&limit=25";
            $http.get(path)
                .then(function (response) {
                    $scope.files = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Files');
                });
        };
        $scope.loadCategories = function () {
            $http.get("private/market/retrieveProblemCategory.do?problemId=" + $scope.competitionId + "&page=1&start=0&limit=25")
                .then(function (response) {
                    $scope.categories = response.data.data;
                    for(var i=0;i < $scope.categories.length;i++){
                        if($scope.categories[i].parent != null && $scope.categories[i].parent.name != null){
                            $scope.categories[i].selectionName = $scope.categories[i].parent.name +" > "+$scope.categories[i].name; 
                        }else{
                            $scope.categories[i].selectionName = $scope.categories[i].name; 
                        }
                    }
                    $scope.loadIdeas();
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Categories');
                });

        };
        $scope.loadCriteria = function () {
            $http.get("private/market/retrieveProblemAcceptanceCriteria.do?problemId=" + $scope.competitionId + "&page=1&start=0&limit=25")
                .then(function (response) {
                    $scope.criterias = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Criterias');
                });

        };
        $scope.loadIdeas = function () {
            var path = "notsecure/idea/retrieve.do?problemId=" + $scope.competitionId + "&page=1&start=0&limit=5";
            if ($scope.isPrivate)
                path = "private/idea/retrieve.do?problemId=" + $scope.competitionId + "&page=1&start=0&limit=5&sort=" + $scope.sortStr;
            $http.get(path)
                .then(function (response) {
                    $scope.ideas = response.data.data;
                    $scope.totalIdeaCount = response.data.total;
                    $scope.ideaList = [];

                    for(var i = 0; i < $scope.ideas.length; i++){
                        for(var j = 0; j < $scope.categories.length; j++){
                            if($scope.ideas[i].problemCategoryId === $scope.categories[j].id){
                                $scope.ideas[i].idea.category = $scope.categories[j]; 
                            }
                        }
                    }
                    for (var i = 0; i < $scope.ideas.length; i++) {
                        $scope.ideaList.push($scope.ideas[i].idea);
                    }
                    $scope.ideaCount = $scope.totalIdeaCount;

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Ideas');
                });

        };

        $scope.isMine = function (id) {
            if ($rootScope.currentUser && Number($rootScope.currentUser.id) == id) return true;
            return false;
        };
        $scope.isMineForlistParam = function (ideaOwnersModels) {
            for(var i = 0 ; i< ideaOwnersModels.length ; i++){
                if ($rootScope.currentUser && Number($rootScope.currentUser.id) == ideaOwnersModels[i].ownerId) return true;
            }
            return false;
        };
        $scope.goSubmitIdea = function () {
            if ($rootScope.authenticated) {
                $location.path('/submit-idea/' + $scope.competition.id);
            } else {
                if ($scope.competition.publicMode == 2) {
                    $location.path('/submit-idea/' + $scope.competition.id);
                } else {
                    $translate('error.login.submit.idea').then(function (msg) {
                        $scope.sureSubmitIdea($scope);
                           
                        
                    });
                }
            }
        };

        $scope.sureSubmitIdea = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.Ok = function () {
                        $rootScope.loginMsg = "error.login.submit.idea";
                        $rootScope.loginRedirect = "/submit-idea/" + t.competition.id;
                        $location.path('/login');
                        $uibModalInstance.close();
                    }
                    $scope.OkReg = function () {
                        $location.path('/register');
                        $uibModalInstance.close();
                    }
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    }
                   
                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "sureSubmitIdea.html"
            });
        };

        $scope.deleteCompetition = function () {
            var path = "private/market/deleteById.do?problemId=" + $scope.competitionId;
            $http.get(path)
                .then(function (res) {
                    if (res.data.success == true) {
                        Notification.success($translate.instant("info.draft.deleted"));
                        $scope.deleteModalClose();
                        $location.path("/competitions");
                    } else {
                        Notification.error($translate.instant("error.generic"));
                        $scope.deleteModalClose();
                    }
                });

        }
        $scope.deleteModal = function () {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: "competitionDetailCtrl",
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "sureModal.html"
            });
        };
        $scope.deleteModalClose = function () {
            $rootScope.modalInstance.close();
        };
        $scope.changeCompetitionState = function (a,selectMail) {

            var url = "private/changeStates.do";
            var data = 'marketId=' + encodeURIComponent(JSON.stringify($scope.competition.id)) +
                '&state=' + encodeURIComponent(JSON.stringify(a)) + 
                '&selectMail=' + encodeURIComponent(JSON.stringify(selectMail)) ;
            $http({
                method: 'POST',
                url: url,
                data: data,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success == true) {
                        $scope.loadCompetition(true);
                        Notification.success($translate.instant("sugg.status.completed"));
                    }
                });
        }
        $scope.completeCompetition = function (selectMail) {

            for (var i = 0; i < $scope.ideaList.length; i++) {
                delete $scope.ideaList[i].approveDate;
                delete $scope.ideaList[i].enterDate;
            }

            var url = "private/completeCompetition.do";
            var data = 'problemId=' + encodeURIComponent(JSON.stringify($scope.competition.id)) +
                '&ideaList=' + encodeURIComponent(JSON.stringify($scope.ideaList)) +
                '&selectMail=' + encodeURIComponent(JSON.stringify(selectMail)) ;
            $http({
                method: 'POST',
                url: url,
                data: data,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.success == true) {
                        $scope.loadCompetition(true);
                        Notification.success($translate.instant("sugg.status.completed"));

                    }
                });
        }

  $scope.publishCompetitionModal = function (t, i) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.value = i;
                    $scope.isEmailJurry = false;
                    $scope.isEmailUser = false;
                    $scope.mailUser = 0;
                    $scope.Ok = function () {
                            var url = "private/problem/openProblem.do";
                            var data = 'problemId=' + encodeURIComponent(JSON.stringify(t.competition.id)) +
                                '&selectMail=' + encodeURIComponent(JSON.stringify($scope.mailUser)) ;
                            $http({
                                method: 'POST',
                                url: url,
                                data: data,
                                headers: {
                                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                                }
                            })
                                .then(function (res) {
                                    
                                    if (res.data.success == true) {
                                        t.loadCompetition(true);
                                        Notification.success($translate.instant("label.save.return.success"));
                                    }
                                });
                        
                        $uibModalInstance.close();
                    }

                    $scope.Close = function () {
                        $uibModalInstance.close();
                    }


                    $scope.changeSendMail = function (mailSelect) {

                        if (mailSelect == 1){
                            $scope.isEmailJurry = $scope.decide($scope.isEmailJurry);
                        }
                        if (mailSelect == 2){
                            $scope.isEmailUser = $scope.decide($scope.isEmailUser);
                        }
                        if( $scope.isEmailUser == false && $scope.isEmailJurry == false ){
                            $scope.mailUser = 0
                        }
                        if( $scope.isEmailUser == true && $scope.isEmailJurry == false ){
                            $scope.mailUser = 1
                        }
                        if( $scope.isEmailUser == false && $scope.isEmailJurry == true ){
                            $scope.mailUser = 2
                        }
                        if( $scope.isEmailUser == true && $scope.isEmailJurry == true ){
                            $scope.mailUser = 3
                        }
                       

                    }
                   
                    $scope.decide = function (param){
                        if( param )
                           return false; 
                       else
                        return true;
                    }

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "openCompetitionModal.html"
            });
        }; 
        $scope.completeCompetitionModal = function (t, i) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.value = i;
                    $scope.isEmailJurry = false;
                    $scope.isEmailUser = false;
                    $scope.mailUser = 0;
                    $scope.Ok = function () {
                     
                        if (i == 1) t.changeCompetitionState(1, $scope.mailUser);
                        if (i == 2) t.changeCompetitionState(2, $scope.mailUser);
                        if (i == 3) t.completeCompetition( $scope.mailUser);
                        $uibModalInstance.close();
                    }

                    $scope.Close = function () {
                        $uibModalInstance.close();
                    }


                    $scope.changeSendMail = function (mailSelect) {

                        if (mailSelect == 1){
                            $scope.isEmailJurry = $scope.decide($scope.isEmailJurry);
                        }
                        if (mailSelect == 2){
                            $scope.isEmailUser = $scope.decide($scope.isEmailUser);
                        }
                        if( $scope.isEmailUser == false && $scope.isEmailJurry == false ){
                            $scope.mailUser = 0
                        }
                        if( $scope.isEmailUser == true && $scope.isEmailJurry == false ){
                            $scope.mailUser = 1
                        }
                        if( $scope.isEmailUser == false && $scope.isEmailJurry == true ){
                            $scope.mailUser = 2
                        }
                        if( $scope.isEmailUser == true && $scope.isEmailJurry == true ){
                            $scope.mailUser = 3
                        }
                       

                    }
                   
                    $scope.decide = function (param){
                        if( param )
                           return false; 
                       else
                        return true;
                    }

                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "completeCompetitionModal.html"
            });
        };
        $scope.CompetitionReport = function () {
            $http.get("private/idea/exportHtml.htm?id=" + $scope.competitionId)
                .then(function (response) {
                    $scope.report = response.data;
                    var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
                    winPrint.document.write($scope.report);
                    winPrint.document.close();
                    winPrint.focus();
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: report');
                });
        }

        $scope.checkIdeaCanVisible = function (i) {
            if ($scope.isMine(i.ownerId)) {
                return true;
            }
            if ($scope.isAdmin) {

                if ($scope.isMine(i.ownerId)) {
                    return true;
                }
                if (i.status == 9) { return false; }
                else {
                    return true;
                }
            }
            if ($scope.competition.problemState == 'A') {
                if ($scope.competition.ideaUserView == 1) {
                    if ($scope.isMine(i.ownerId)) {
                        return true;
                    }
                    if (!$scope.isMine(i.ownerId) && i.status == 9) { return false; }
                    else {
                        return true;
                    }
                }

            }
            if ($scope.competition.problemState == 'E' || $scope.competition.problemState == 'K' || $scope.competition.problemState == 'C') {
                if ($scope.competition.evalView == 1) {
                    if ($scope.isMine(i.ownerId)) {
                        return true;
                    }
                    if (!$scope.isMine(i.ownerId) && i.status == 9) { return false; }
                    else {
                        return true;
                    }
                }
                if ($scope.competition.isUserExpert == 1) {
                    if (i.status == 1) {
                        return true;
                    }
                    else { return false; }
                }


            }


        }
        $scope.loadReport = function () {
            $http.get("private/eval/retrieveEvalForCompetition.do?competitionId=" + $scope.competitionId)
                .then(function (response) {
                    $scope.reporteval = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: report');
                });
        };
        $scope.GetAvgEval = function (a) {

            if ($scope.reporteval && $scope.reporteval.length > 0) {
                for (var i = 0; i < $scope.reporteval.length; i++) {
                    if ($scope.reporteval[i].objectId == a.id) {
                        return $scope.reporteval[i].avgRating;
                    }
                }
            }

            return 0;
        };
        $scope.GetAvgEvalCount = function (a) {
            if ($scope.reporteval && $scope.reporteval.length > 0) {
                for (var i = 0; i < $scope.reporteval.length; i++) {
                    if ($scope.reporteval[i].objectId == a.id) {
                        return $scope.reporteval[i].evalCount;
                    }
                }
            }
            return 0;
        };

        var regex = /\/competition\/\d[0-9]*/g;
        //const regexOpen = /\/competition-open\/\d[0-9]*/g;
        if (!regex.test($location.path())) {
            // load open competition
            $scope.loadReport();
            $scope.loadCompetition(false);
        } else {
            $scope.loadReport();
            $scope.loadCompetition(true);
            $scope.isPrivate = true;
        }
    }
]);